<template>
  <div class="chart chart-invest">
    <div class="box">
      <div class="chart__head">
        <div class="chart__left">
          <div class="chart__wrap">
            <div class="chart__title-sm">
              <img src="@/assets/img/svg/icon-calendar-graph.svg" alt="">

              {{$t('chartDate')}}
              {{date | timestampToDatePipe}}
            </div>
            <div class="chart__value chart__calendar">
              <DatePickerItem ref="datePeckerItem" :type="'DEFAULT'" @change="setDate"/>
            </div>
          </div>
          <div class="chart__wrap" v-if="resultPercent != null">
            <div class="chart__title-sm">
              {{$t('chartResult')}}
            </div>

            <div class="chart__value default">
              {{formatNumber(resultPercent, true)}}%
            </div>
          </div>

          <div class="chart__wrap" v-if="returnsPercent != null">
            <div class="chart__title-sm">
              {{$t('chartReturns')}}
            </div>

            <div class="chart__value primary">
              {{formatNumber(returnsPercent, true)}}%
            </div>
          </div>
        </div>

<!--        <div class="chart__btn-wrap">-->
<!--          <button class="chart__btn"-->

<!--          >-->
<!--            1{{ $t("minutesShort") }}-->
<!--          </button>-->
<!--          <button class="chart__btn"-->
<!--          >-->
<!--            5{{ $t("minutesShort") }}-->
<!--          </button>-->
<!--          <button class="chart__btn"-->

<!--          >-->
<!--            15{{ $t("minutesShort") }}-->
<!--          </button>-->
<!--          <button class="chart__btn"-->
<!--          >-->
<!--            1{{ $t("hoursShort") }}-->
<!--          </button>-->
<!--          <button class="chart__btn"-->
<!--          >-->
<!--            4{{ $t("hoursShort") }}-->
<!--          </button>-->
<!--          <button class="chart__btn"-->
<!--          >-->
<!--            1{{ $t("daysShort") }}-->
<!--          </button>-->
<!--        </div>-->
      </div>
      <div class="chart-wrap">
        <div id="chart" ref="chartContainer" style="border-radius: 5px"/>
      </div>
    </div>
  </div>
</template>

<script>
import {createChart, CrosshairMode, TickMarkType} from "lightweight-charts";
import {formatNumberMixin, timestampToDateMixin} from "@/utils/mixins";
import DatePickerItem from "@/components/utility/DatePicker";

export default {
  name: "InvestmentChart",
  components: {DatePickerItem},
  mixins: [formatNumberMixin, timestampToDateMixin],
  props: {
    profits: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    date: null,
    chart: null,
    areaSeries: null,
    areaSeriesValues: [],
    resultPercent: null,
    returnsPercent: null,
  }),
  methods: {
    setDate(val) {
      this.date = Date.parse(val);
      this.$emit('setDate', this.date);
    },
    onResize() {
      this.chart.applyOptions({
        width: this.$refs.chartContainer.clientWidth,
        height: 360
      })
    },
    initResizeObserver() {
      new ResizeObserver(this.onResize).observe(this.$refs.chartContainer);
    },

    getTimezoneCorrectedTime(utcTime, returnAsUnixTimestamp = false) {
      if (utcTime instanceof Date) {
        utcTime = utcTime.getTime() / 1000
      }

      const timezoneOffsetMinutes = new Date().getTimezoneOffset()
      const correctedTime = utcTime + (timezoneOffsetMinutes * 60)

      if (returnAsUnixTimestamp) return correctedTime

      return new Date(correctedTime * 1000)
    },
    async buildChart() {
      this.chart = createChart('chart', {
        width: 600,
        height: 300,
        rightPriceScale: {
          borderVisible: false
        },
        timeScale: {
          timeVisible: true,
          visible: true,
          tickMarkFormatter: (time, tickMarkType) => {
            time = this.getTimezoneCorrectedTime(time)
            let opt = {}

            switch (tickMarkType) {
              case TickMarkType.Year:
                opt = {
                  year: 'numeric'
                }
                break
              case TickMarkType.Month:
                opt = {
                  month: 'short'
                }
                break
              case TickMarkType.DayOfMonth:
                opt = {
                  day: '2-digit'
                }
                break
              case TickMarkType.Time:
                opt = {
                  hour: '2-digit',
                  minute: '2-digit'
                }
                break
              case TickMarkType.TimeWithSeconds:
                opt = {
                  hour: '2-digit',
                  minute: '2-digit',
                  seconds: '2-digit'
                }
            }

            return time.toLocaleString('de-DE', opt)
          }
        },
        localization: {
          locale: 'de-DE',
          dateFormat: 'dd.MM.yyyy',
        },
        crosshair: {
          mode: CrosshairMode.Normal
        },
        layout: {
          backgroundColor: '#0b0a0e',
          textColor: '#898b8d',
        },
        grid: {
          vertLines: {
            color: '#181920'
          },
          horzLines: {
            color: '#181920'
          }
        }
      });

      this.areaSeries = this.chart.addAreaSeries({
        topColor: 'rgba(33, 150, 243, 0.56)',
        bottomColor: 'rgba(33, 150, 243, 0.04)',
        lineColor: 'rgba(33, 150, 243, 1)',
        lineWidth: 2,
      });
      this.areaSeriesValues = this.profits.map(e => {
        return {
          time: Math.floor(e.created / 1000),
          value: e.profitAmount,
        }
      })
      this.areaSeriesValues = this.areaSeriesValues.filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.time === value.time
              ))
      )

      this.areaSeries.setData(this.areaSeriesValues);

      this.chart.subscribeCrosshairMove((param) => {
        if(param.time) {
          let index = this.areaSeriesValues.findIndex(i => i.time === param.time);
          let data = this.profits[index];
          if(!data) {
            return;
          }
          this.resultPercent = data.resultPercent;
          this.returnsPercent = data.returnsPercent;
        } else {
          this.resultPercent = null;
          this.returnsPercent = null;
        }
      })
    },
  },
  async mounted() {
    await this.buildChart();
    this.initResizeObserver();
  }
}
</script>
