<template>
  <div class="trader-income">
   <div class="box trader-box">
      <div class="trader-box__title">
        {{$t("traderIncomeStatTitle")}}
      </div>

     <div class="trader-income__wrap">
       <div class="trader-income__stat">
         <div class="trader-income__icon">
           <img src="@/assets/img/svg/icon-dollar.svg" alt="">
         </div>

         <p>{{$t("traderIncomeStat1")}}</p>

         <div class="trader-income__amount">
           {{formatNumber(traderTotalEarningPercentage, true)}} %
         </div>
       </div>
       <div class="trader-income__stat">
         <div class="trader-income__icon">
           <img src="@/assets/img/svg/icon-calendar-gray.svg" alt="">
         </div>

         <p>{{$t("traderIncomeStat2")}}</p>

         <div class="trader-income__amount">
           {{formatNumber(bestMonthProfitPercentage, true)}} %
         </div>
       </div>
       <div class="trader-income__stat">
         <div class="trader-income__icon">
           <img src="@/assets/img/svg/icon-stats-grey.svg" alt="">
         </div>

         <p>{{$t("traderIncomeStat3")}}</p>

         <div class="trader-income__amount">
           {{formatNumber(averageMonthlyProfit, true)}} %
         </div>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: 'IncomeStatistic',
  mixins: [formatNumberMixin],
  props: {
    averageMonthlyProfit: {
      type: Number,
      required: true
    },
    bestMonthProfitPercentage: {
      type: Number,
      required: true
    },
    traderTotalEarningPercentage: {
      type: Number,
      required: true
    }
  }
}
</script>
