export default {
    methods: {
        debounce(f, ms) {
            let isCoolDown = false;
            return () => {
                if (isCoolDown) return;
                f.apply(this, arguments);
                isCoolDown = true;
                setTimeout(() => isCoolDown = false, ms);
            };
        },
    }
}