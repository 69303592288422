<template>
  <div class="banner banner-delegate">
    <div class="banner__wrap">
      <div class="logo">
        <img src="@/assets/img/svg/logo-white.svg" alt="">
      </div>

      <div class="banner__title">
        {{$t("bannerDelegateTitle1")}}
        <span class="primary">{{$t ("bannerDelegateSpan")}}</span>
        {{$t("bannerDelegateTitle2")}}
      </div>
    </div>

    <div class="banner__wrap">
      <router-link class="page-btn page-btn--border" type="button" :to="'/traders'"> {{$t("bannerDelegateBtn")}}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DelegatingBanner"
}
</script>

<style scoped>

</style>