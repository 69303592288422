<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide popup-slide--side">
      <div class="popup popup-profit">
        <div class="popup-profit__head">
          <div class="popup-profit__icon">
            <img src="@/assets/img/svg/info-icon.svg" alt="">
          </div>

          <button @click="close" class="popup__close"></button>
        </div>

        <div class="popup-profit__title">
          {{$t("popupProfitTitle")}}
        </div>

        <div class="profit-table">
          <table>
            <thead>
            <tr>
              <td>
                {{$t("profitTableTitle1")}}
              </td>
              <td>
                {{$t("profitTableTitle2")}}
                <p class="profit-table__separator">
                  <span>{{$t("investor")}}</span> / {{$t("trader")}}</p>
              </td>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(item,index) in profits" :key="index">
              <td>{{item.minInvestPrice}}</td>
              <td>
                <p class="profit-table__separator">
                <span>60%</span> / 40%
                </p>
              </td>
            </tr>

            </tbody>
          </table>
        </div>

        <div class="popup-profit__footer">
          <button class="page-btn">6 {{$t("month")}}</button>
          <button class="page-btn page-btn--border page-btn--cancel ">12 {{$t("month")}}</button>
        </div>

        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProfitDistribution',
  data() {
    return {
      profits: [
        {
          minInvestPrice: '500 USDT'
        },
        {
          minInvestPrice: '1500 USDT'
        },
        {
          minInvestPrice: '5000 USDT'
        },
        {
          minInvestPrice: '1000 USDT'
        },
        {
          minInvestPrice: '25000 USDT'
        },
        {
          minInvestPrice: '50000 USDT'
        },
        {
          minInvestPrice: '250000 USDT'
        },
      ]
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
