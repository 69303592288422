<template>
  <div class="custom-table custom-table--profile">
    <div class="box">
      <table cellpadding="0" cellspacing="0">
        <thead class="custom-table-head">
        <tr>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">
                {{ $t("profileTableDate") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("profileTableIp") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">
                {{ $t("profileTableOs") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("profileTableBrowser") }}
              </button>
            </div>
          </td>

        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in history" :key="index">
          <td>
            <p class="custom-table__date">
              {{ new Date(item.created).toLocaleDateString('en-GB') }}
              {{ new Date(item.created).toLocaleTimeString('en-US', {hour12: false}) }}
            </p>
          </td>
          <td>
            <p>{{ item.ip }}</p>
          </td>
          <td>
            <p>{{ item.deviceOs }}</p>
          </td>
          <td>
            {{ item.deviceBrowser }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: "UserEnterHistoryDesktop",
  props: {
    history: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
