<template>
  <div class="profile-page">
    <h1 class="page-title">{{ $t("userPageTitle") }}</h1>
    <UserInfoCard/>
    <UserSecurity/>
    <UserAbout/>
    <UserPersonalInfo :isVerified="getIsVerified"/>
    <UserReferral/>

    <div class="profile-page__wrap">
      <div class="profile-page__title">
        {{ $t('userAccHistoryTitle') }}
      </div>

      <UserEnterHistoryDesktop :history="history"/>
      <UserEnterHistoryMobile :history="history"/>
      <Pagination :pagesQt="total"
                  @change="togglePage"
                  v-if="total > 1"/>
    </div>

  </div>

</template>

<script>
import UserAbout from "../../components/userProfile/UserAbout";
import UserEnterHistoryDesktop from "../../components/userProfile/UserEnterHistoryDesktop";
import UserEnterHistoryMobile from "../../components/userProfile/UserEnterHistoryMobile";
import UserInfoCard from "../../components/userProfile/UserInfoCard";
import UserPersonalInfo from "../../components/userProfile/UserPersonalInfo";
import UserReferral from "../../components/userProfile/UserReferral";
import UserSecurity from "../../components/userProfile/UserSecurity";
import api from "../../api/api";
import Pagination from "../../components/utility/Pagination";
import {mapGetters} from "vuex";

export default {
  name: "UserProfile",
  data: () => ({
    history: [],
    total: 0
  }),
  computed: {
    ...mapGetters({
      getIsVerified: 'user/getIsVerified'
    })
  },
  methods: {
    async loadLoginHistory() {
      const response = await api.loadLoginHistory({
        page: this.page,
        size: 4,
        sort: 'created,desc'
      })
      response.content.forEach(i => i.isShow = false);
      this.history = response.content;
      this.total = response.totalPages;
    },
    togglePage(btn) {
      this.page = btn - 1;
      this.loadLoginHistory();
    }
  },
  mounted() {
    this.loadLoginHistory();
  },
  components: {
    Pagination,
    UserAbout,
    UserEnterHistoryDesktop,
    UserEnterHistoryMobile,
    UserInfoCard,
    UserPersonalInfo,
    UserReferral,
    UserSecurity,
  }
}
</script>
