<template>
  <div class="trader-stats" v-if="statistic">
    <div class="stat">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-dollar.svg" alt="">
      </div>

      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("traderStatResource") }}
        </div>

        <div class="stat__value">
          <span>{{formatNumber(totalInvestmentsSum)}} USDT</span>
          <span class="stat__percent" :class="loadNumberClass(newInvestmentsSum)">
            (
            <span v-if="loadNumberClass(newInvestmentsSum) === 'primary'">+</span>
            {{formatNumber(newInvestmentsSum)}}
            )
          </span>
        </div>
      </div>
    </div>

    <div class="stat">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-investors.svg" alt="">
      </div>

      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("traderStatInvestors") }}
        </div>

        <div class="stat__value">
          <span>{{allInvestorsNumber}}</span>
          <span class="stat__percent" :class="loadNumberClass(newInvestorsNumber)">(
            <span v-if="loadNumberClass(newInvestorsNumber) === 'primary'">+</span>
            {{newInvestorsNumber}})</span>
        </div>
      </div>
    </div>

    <div class="stat">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-calendar.svg" alt="">
      </div>

      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("traderStatResult") }} {{getMonthName()}}
        </div>

        <div class="stat__value">
          <span>{{formatNumber(monthlyProfit, true)}}%</span>
          <span class="stat__percent" :class="loadNumberClass(monthlyProfit)">(
            <span v-if="loadNumberClass(thisMonthComparedWithPrevious) === 'primary'">+</span>
            {{formatNumber(thisMonthComparedWithPrevious, true)}}%)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api/api";
  import {formatNumberMixin} from "@/utils/mixins";
  import loadNumberClassMixin from "@/mixins/loadNumberClassMixin";
  import getMonthNameMixin from "@/mixins/getMonthNameMixin";

  export default {
    name: 'UserStats',
    mixins: [formatNumberMixin, loadNumberClassMixin, getMonthNameMixin],
    props: {
      traderId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      statistic: null,
    }),
    computed: {
      totalInvestmentsSum() {
        return this.statistic.totalInvestmentsSum;
      },
      newInvestmentsSum() {
        return this.statistic.newInvestmentsSum;
      },
      allInvestorsNumber() {
        return this.statistic.allInvestorsNumber;
      },
      newInvestorsNumber() {
        return this.statistic.newInvestorsNumber;
      },
      monthlyProfit() {
        return this.statistic.monthlyProfit;
      },
      thisMonthComparedWithPrevious() {
        return this.statistic.thisMonthComparedWithPrevious;
      }
    },
    methods: {
      async loadTraderStatistic() {
        try {
          this.statistic = await api.loadTraderStatistic({
            traderId: this.traderId
          });
        } catch (e) {
          console.log(e);
        }
      },
    },
    mounted() {
      this.loadTraderStatistic();
    }
  }
</script>
