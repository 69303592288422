<template>
  <div class="login-page restore-page google-auth-page email-auth">
    <div class="login-wrap">
      <div class="login-page__content">
        <div class="logo">
          <img src="@/assets/img/svg/logo.svg" alt="">
        </div>


        <h1 class="login-page__title">
          {{ $t("emailAuthTitle") }}
        </h1>

        <div class="login-page__caption">
          {{ $t("emailAuthText") }}

          <span>yasonworkingfor@gmail.com</span>
        </div>

        <div class="email-auth__text-sm">
          {{ $t("emailAuthTextSm") }}
        </div>

        <form class="form">
          <div class="page-input">
            <div class="page-input__title">
              {{ $t("emailAuthCode") }}
            </div>

            <TwoFaGoogleAuthForm />
          </div>

          <div class="email-auth__btns">
            <button class="page-btn page-btn--border page-btn--cancel">
              {{ $t("restorePassDisable") }}
            </button>
            <button class="page-btn">
              {{ $t("depositContinueBtn") }}
            </button>
          </div>


        </form>

        <div class="login-page__check">
          <div class="login-page__caption">
            {{ $t("restorePassSendAgain") }}
            (48 сек.)
          </div>

          <a href="#" class="link"> {{ $t("restorePassLink") }}</a>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import TwoFaGoogleAuthForm from "../../components/utility/TwoFaGoogleAuthForm";
export default {
  name: "EmailAuthenticate",
  components: {TwoFaGoogleAuthForm}
}
</script>

<style scoped>

</style>