<template>
  <div class="custom-table custom-table--trader">
    <div class="box">
      <table cellpadding="0" cellspacing="0">
        <thead class="custom-table-head">
        <tr>
          <td>
            <div class="custom-table-head__title">

                {{ $t("profileTableDate") }}

            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
             {{ $t("monthJan") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthFeb") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthMarch") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthApr") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthMay") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthJun") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthJul") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthAug") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthSep") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthOct") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthNov") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("monthDec") }}
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              {{ $t("traderTableGeneral") }}
            </div>
          </td>

        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in profits" :key="index">
          <td>
            <p>
              {{item.year}}
            </p>
          </td>
          <td v-for="(item2, index2) in item.values" :key="index2">
            <p :class="item2.profitPercent > 0 ? 'primary' : item2.profitPercent === 0 ? '' : 'danger'">
              {{formatNumber(item2.profitPercent, true) || '-'}}
              <span v-if="item2.profitPercent">%</span>
            </p>
          </td>
          <td>
            <p :class="item.total > 0 ? 'primary' : item.total === 0 ? '' : 'danger'">
              {{formatNumber(item.total, true) || '-'}}
              <span v-if="item.total">%</span>
            </p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: 'IncomeStatisticDesktopTable',
  mixins: [formatNumberMixin],
  props: {
    profits: {
      type: Array,
      required: true
    }
  }
}
</script>
