<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide popup-slide--side">
      <div class="popup popup-notif">

        <div class="popup-notif__head">
          <div class="popup-notif__title">
            {{ $t("popupNotifTitle") }}
          </div>

          <button @click="close" class="popup__close"></button>
        </div>

        <div class="popup-notif__content">
          <div class="popup__content">
            <div class="notification-container" :class="{news: true}">
              <Notification v-for="(item, index) in notifications"
                            :key="index"
                            :notif-length="1"
                            :notification="item"
                            @deleted="deleted"
                            :is-modal="true"/>

            <ScrollLoader :loader-method="getNotifications" :loader-disable="disableLoading"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from "../utility/Notification";
import api from "@/api/api";
import {mapMutations} from "vuex";

export default {
  name: "NotificationListModal",
  components: {
    Notification
  },
  data: () => ({
    page: 0,
    size: 10,
    loading: true,
    disableLoading: false,
    notifications: [],
  }),
  methods: {
    ...mapMutations({
      readNotification: 'socket/readNotification',
    }),
    deleted(id) {
      this.notifications = this.notifications.filter(i => i.id !== id);
      this.readNotification();
    },
    async getNotifications() {
      try {
        this.loading = true;
        const response = await api.getNotifications({
          page: this.page++,
          size: this.size,
          sort: "created,desc"
        });
        this.notifications = [...this.notifications, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>