<template>
  <div class="twoFa-modal__wrap twoFa-modal__step2">
    <div class="twoFa-modal__title">
      {{$t("googleStepTwoTitle")}}

    </div>

    <div class="popup__text">
      {{$t("googleStepTwoText")}}

    </div>

   <div class="page-input page-input--padding">
     <input type="text" class="input" readonly :value="codeSecret" ref="copyInput" v-on:focus="$event.target.select()" >

     <div class="page-input__icon" @click="copy">
       <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M4 4.8061V1.78746C4 1.5206 4.10536 1.26466 4.29289 1.07596C4.48043 0.887261 4.73478 0.78125 5 0.78125H17C17.2652 0.78125 17.5196 0.887261 17.7071 1.07596C17.8946 1.26466 18 1.5206 18 1.78746V15.8744C18 16.1413 17.8946 16.3972 17.7071 16.5859C17.5196 16.7746 17.2652 16.8806 17 16.8806H14V19.8993C14 20.4547 13.55 20.9055 12.993 20.9055H1.007C0.875127 20.9063 0.744397 20.8808 0.622322 20.8306C0.500247 20.7804 0.389233 20.7065 0.295659 20.613C0.202084 20.5195 0.127793 20.4083 0.0770543 20.2858C0.0263156 20.1633 0.000129374 20.032 0 19.8993L0.00300002 5.81231C0.00300002 5.25688 0.453 4.8061 1.01 4.8061H4ZM2.003 6.81852L2 18.8931H12V6.81852H2.003ZM6 4.8061H14V14.8682H16V2.79367H6V4.8061Z" fill="#0363FE"/>
       </svg>
     </div>

     <div class="tooltip" v-show="isCopied">
       <div class="tooltip__content">
         {{ $t("tooltipCopy")}}
       </div>
     </div>
   </div>

  </div>
</template>

<script>
import api from "../../../api/api";

export default {
  name: "StepTwo",
  data: () => ({
    showTooltip: false,
    codeSecret: null,
    isCopied: false,
  }),
  methods: {
    copy() {
      this.$refs.copyInput.focus();
      document.execCommand('copy');
      this.isCopied = true;
    },
    async registerTwoFa() {
      try {
        await api.registerTwoFa();
      } catch (e) {
        console.log(e)
      }
    },
    async loadTwoFaSecret() {
      try {
        this.codeSecret = await api.loadTwoFaSecret();
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.registerTwoFa();
    await this.loadTwoFaSecret();
  }
}
</script>

<style scoped>

</style>
