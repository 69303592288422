<template>
  <div class="trading-page">
    <div class="trading-content">
        <div class="trading-page__top">
          <Stats :pairs="pairs" :leftPairPartFullName="leftPairPartFullName"/>
        </div>

        <div class="content-grid">
          <div class="content-grid__left">
            <div class="wrap">
            <div class="box-header">
              <div class="box-header-drop">
              <div class="box__title" @click="isTabListOpen = !isTabListOpen">
                {{$t(currentTab)}}

                <div class="box-header__icon" :class="{active: isTabListOpen}"></div>
              </div>
                <ul class="box-header-drop__list" v-show="isTabListOpen">
                  <li class="box-header-drop__item" v-for="(item, index) in boxTabs" :key="index"
                      @click="currentTab = item"
                  >{{$t(item)}}</li>
                </ul>

              </div>


              <div class="toggle" v-if="currentTab === 'trades' && getIsAuthorized">
                <button class="toggle__btn"
                        @click="historyTab = 'tabMine'"
                        :class="{active: historyTab === 'tabMine'}">
                  {{ $t("historyToggle1") }}
                </button>
                <button class="toggle__btn" @click="historyTab = 'tabMarket'" :class="{active: historyTab === 'tabMarket'}">
                  {{ $t("historyToggle2") }}
                </button>
              </div>
            </div>
            <SellOrders v-if="currentTab === 'orderBook'"/>
            <OrdersHistory v-if="currentTab === 'trades'" :tab="historyTab"/>
            </div>
            <BuyOrders v-if="currentTab === 'orderBook'"/>
          </div>

          <div class="content-grid__center">
            <Chart :pairs="pairs"
                   v-if="isLargeScreen"
                    @selectPair="selectPair"/>

            <div class="content-grid__wrap">
              <BuyMarket/>
              <SellMarket/>
            </div>
          </div>

          <div class="content-grid__right">
            <Pairs @pairsLoaded="pairsLoaded"
                    ref="pairs"/>

            <OpenOrder/>

          </div>
        </div>

        <div class="content-grid-mobile">
          <div class="box">
            <div class="box-tabs">
            <ul class="box-tabs__list">
              <li class="box-tabs__item" @click="mobileTabName = 'graph'" :class="{active: mobileTabName === 'graph'}">
                <span>  {{$t("tabGraphName")}}</span></li>
              <li class="box-tabs__item" @click="mobileTabName = 'orders'" :class="{active: mobileTabName === 'orders'}">
               <span v-if="mobileTabName !== 'orders'"> {{$t("tabOrdersName")}}</span>

                <div class="d-flex" style="white-space: nowrap" v-if="mobileTabName === 'orders' && orders">
                  {{$t("openOrderTitle")}}  <div class="open-order__amount">{{ ordersTotal }}</div></div>

              </li>
              <li class="box-tabs__item" @click="mobileTabName = 'pairs'" :class="{active: mobileTabName === 'pairs'}">
                <span> {{$t("tabPairsName")}}</span></li>
            </ul>

              <button class="search-btn" v-show="mobileTabName === 'pairs'" @click="isPairsPopupOpened = true"></button>
            </div>

            <Chart :pairs="pairs"
                   @selectPair="selectPair"
                   v-if="mobileTabName === 'graph' && !isLargeScreen"/>
            <OpenOrder v-if="mobileTabName === 'orders'" @passData="getOrders" @passTotal="getOrdersTotal"/>
            <Pairs v-if="mobileTabName === 'pairs'"/>
          </div>

          <div class="wrap">
            <div class="box-header">
              <div class="box-header-drop">
                <div class="box__title" @click="isTabListOpen = !isTabListOpen">
                  {{$t(currentTab)}}

                  <div class="box-header__icon" :class="{active: isTabListOpen}"></div>
                </div>
                <ul class="box-header-drop__list" v-show="isTabListOpen">
                  <li class="box-header-drop__item" v-for="(item, index) in boxTabs" :key="index"
                      @click="currentTab = item"
                  >{{$t(item)}}</li>
                </ul>

              </div>


              <div class="toggle" v-if="currentTab === 'trades'">
                <button class="toggle__btn" @click="historyTab = 'tabMine'" :class="{active: historyTab === 'tabMine'}">
                  {{ $t("historyToggle1") }}
                </button>
                <button class="toggle__btn" @click="historyTab = 'tabMarket'" :class="{active: historyTab === 'tabMarket'}">
                  {{ $t("historyToggle2") }}
                </button>
              </div>

              <div class="sort-btns" v-if="currentTab === 'orderBook'">
                <button class="sort-btn" @click="sortType = 'twoColumn'" :class="{active: sortType === 'twoColumn'}"></button>
                <button class="sort-btn" @click="sortType = 'oneColumnBuy'" :class="{active: sortType === 'oneColumnBuy'}"></button>
                <button class="sort-btn" @click="sortType = 'oneColumnSell'" :class="{active: sortType === 'oneColumnSell'}"></button>
              </div>
            </div>
            <div class="wrap__mob" v-if="currentTab === 'orderBook'" :class="{column: sortType === 'twoColumn'}">
              <SellOrders v-if="sortType !== 'oneColumnBuy'"/>
              <BuyOrders v-if="sortType !== 'oneColumnSell'"/>
            </div>
            <OrdersHistory v-if="currentTab === 'trades'" :tab="historyTab"/>
          </div>

        </div>
    </div>

    <PairsPopup v-if="isPairsPopupOpened" @close="isPairsPopupOpened = false"/>

    <div class="trading-page__btns">
      <button class="market__btn" @click="isBuyPopupOpened = true" v-show="getIsAuthorized">
        {{ $t("marketBuyBtn") }} {{getLeftPairPart}}
      </button>
      <router-link to="/sign-in" class="market__btn market__btn--border market-btn--buy" v-show="!getIsAuthorized">
        <span>Login </span>  or <span> register</span>
      </router-link>

      <button class="market__btn" @click="isSellPopupOpened= true" v-show="getIsAuthorized">
        {{ $t("marketSellBtn") }}  {{getLeftPairPart}}
      </button>
      <router-link to="/sign-in" class="market__btn market__btn--border market-btn--sell" v-show="!getIsAuthorized">
        <span>Login </span>  or <span> register</span>
      </router-link>
    </div>

    <SellMarketPopup v-if="isSellPopupOpened" @close="isSellPopupOpened= false"/>
    <BuyMarketPopup v-if="isBuyPopupOpened" @close="isBuyPopupOpened = false"/>
  </div>
</template>

<script>
import Stats from "../../components/trading/Stats";
import BuyMarket from "../../components/trading/market/BuyMarket";
import BuyOrders from "../../components/trading/BuyOrders";
import SellOrders from "../../components/trading/SellOrders";
import SellMarket from "../../components/trading/market/SellMarket";
import Chart from "../../components/trading/Chart";
import Pairs from "../../components/trading/Pairs";
import OrdersHistory from "../../components/trading/OrdersHistory";
import OpenOrder from "../../components/trading/OpenOrder";
import PairsPopup from "../../components/trading/popups/PairsPopup";
import SellMarketPopup from "../../components/trading/popups/SellMarketPopup";
import BuyMarketPopup from "../../components/trading/popups/BuyMarketPopup";
import {mapGetters, mapMutations} from "vuex";
import api from "../../api/api";

export default {
  name: 'Trading',
  props: {
    pair: {
      type: String,
      required: true
    }
  },
  data: () => ({
    pairs: [],
    currentTab: 'orderBook',
    isTabListOpen: false,
    sortType: 'oneColumnBuy',
    mobileTabName: 'graph',
    historyTab: 'tabMarket',
    isPairsPopupOpened: false,
    isSellPopupOpened: false,
    isBuyPopupOpened: false,
    isLargeScreen: false,
    boxTabs: ['trades', 'orderBook'],
    leftPairPartFullName: '',
    orders: [],
    ordersTotal: 0,
  }),
  computed: {
    ...mapGetters({
      getPairName: 'pair/getPairName',
      getIsAuthorized: 'user/getIsAuthorized'

    }),
    getLeftPairPart() {
      return this.getPairName.split('-')[0];
    },
    getRightPairPart() {
      return this.getPairName.split('-')[1];
    }
  },
  watch: {
    async getPairName(val) {
      const pair = await this.loadPairInfo(val);
      this.leftPairPartFullName = pair.firstCurrencyFullName;
    },
    async pair(current, prev) {
      if(!prev) {
        const pair = await this.loadPairInfo(current);
        this.leftPairPartFullName = pair.firstCurrencyFullName;
      }
    }
  },
  methods: {
    ...mapMutations({
      setPair: 'pair/setPair',
      setLoading: 'general/setLoading',
      setError: 'general/setError'
    }),
    selectPair(pair) {
      this.$refs.pairs.selectPair(pair.id, pair.pair)
    },
    pairsLoaded(pairs) {
      this.pairs = pairs;
    },
    getOrders(value) {
      this.orders = value
    },
    getOrdersTotal(value) {
      this.ordersTotal = value
    },
    async loadPairInfo(pair) {
      try {
        return await api.loadPairInfo({
          firstCurrency: pair.split('-')[0],
          quotedCurrency: pair.split('-')[1]
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
  created() {
    this.isLargeScreen = window.innerWidth >= 992;
  },
  mounted() {
    this.leftPairPartFullName = this.$route.params.firstCurrencyFullName;
    document.addEventListener('resize', () => {
      this.isLargeScreen = window.innerWidth >= 992
    })
    document.body.addEventListener('click',(e)=>{
      if (!e.target.closest('.box__title') ){
        this.isTabListOpen = false;
      }
    })

  },
  components: {
    Pairs,
    Chart,
    SellMarket,
    SellOrders,
    BuyOrders,
    BuyMarket,
    Stats,
    OrdersHistory,
    OpenOrder,
    PairsPopup,
    SellMarketPopup,
    BuyMarketPopup
  },
}
</script>
