export default {
    namespaced: true,
    state: {
        error: null,
        success: null,
        loading: false,
        allowChartRefresh: true
    },
    mutations: {
        setError(state, error) {
            state.error = error;
        },
        setSuccess(state, success) {
            state.success = success;
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setAllowChartRefresh(state, payload) {
            state.allowChartRefresh = payload;
        }
    },
    getters: {
        getError: state => state.error,
        getSuccess: state => state.success,
        getLoading: state => state.loading,
        getAllowChartRefresh: state => state.allowChartRefresh
    }
}
