<template>
  <div class="mobile-table mobile-table--orders">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("ordersTablePair") }}</button>
      </p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("ordersTableDate") }}</button>
      </p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("ordersTablePrice") }}</button>
      </p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("ordersTableAmountShort") }}</button>
      </p>
      <p class="mobile-table__title">{{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item"
         v-for="(item, index) in orders"
         :class="{active: item.isShow}"
         :key="index">

      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">
          <div class="mobile-table-item__col">
            <div class="mobile-table__wrap">
              <div class="custom-table__coins">
                <div class="coin-sm">
                  <img :src="loadImage(item.pairName.split('-')[0])" alt="">
                </div>
                <div class="coin-bg">
                  <img :src="loadImage(item.pairName.split('-')[1])" alt="">
                </div>
              </div>

              <p class="custom-table__coin-name" :class="{active: item.isShow}">
                <span>{{ item.pairName.replace('-', '/ ') }}</span>
              </p>
            </div>
          </div>

          <div class="mobile-table-item__col">
            <p class="mobile-table__date">
              <span>
                {{ new Date(item.date).toLocaleDateString('en-GB') }}
              </span>
              <span>
                {{ new Date(item.date).toLocaleTimeString('en-US', {hour12: false}) }}
              </span>
            </p>
          </div>

          <div class="mobile-table-item__col">
            <p>{{formatNumber(item.price)}}</p>
          </div>

          <div class="mobile-table-item__col">
            <p>{{formatNumber(item.initialQuantity)}}</p>
          </div>
        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("ordersTableType") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{ $t("ordersTableTypeValue") }}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">Side</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{ item.side === 'SELL' ? 'Sell' : 'Buy' }}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("ordersTableFilled") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>0.00%</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("ordersTableAll") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>11.00 USDT</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("ordersTableStatus") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p class="primary">{{ $t("ordersTableStatusValue") }}</p>
            </div>
          </div>
          <div class="mobile-table-item__row " v-if="showCloseBtn">
            <div class="mobile-table-item__col d-flex w-100">
              <button class="custom-table__btn" @click="deleteOrderByUser(item.id)">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                      fill="#DE2A4A"/>
                </svg>
                {{ $t("tableCloseAction") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {formatNumberMixin, loadImageMixin} from "@/utils/mixins";

export default {
  name: 'OrdersMobileTable',
  mixins: [formatNumberMixin, loadImageMixin],
  props: {
    orders: {
      type: Array,
      required: true
    },
    showCloseBtn: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    deleteOrderByUser(id) {
      this.$emit('deleteOrderByUser', id);
    }
  }
}
</script>
