<template>
  <div class="orders-history">
    <div class="box box--shadow">


      <div class="box__header">
        <p class="box__header-title">
          {{ $t("price") }} ({{ getRightPairPart }})
        </p>
        <p class="box__header-title">
          {{ $t("amount") }} ({{ getLeftPairPart }})
        </p>
        <p class="box__header-title">
          {{ $t("time") }}
        </p>
      </div>

      <div class="orders-history-table">
        <vue-scroll :ops="ops" ref="vueScroll">
          <div class="box-table">
            <ul>
              <li v-for="(item, index) in history" :key="index">
                <p class="box-table__value" :class="item.side === 'BUY' ? 'primary' : 'danger'">
                  {{ formatNumber(item.price) }}
                </p>
                <p class="box-table__value">{{ formatNumber(item.quantity) }}</p>
                <p class="box-table__value">{{
                    new Date(item.created).toLocaleTimeString('en-Us', {hour12: false})
                  }}</p>
              </li>
            </ul>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import api from "../../api/api";
import {formatNumberMixin, opsMixin} from "@/utils/mixins";

export default {
  name: 'OrdersHistory',
  mixins: [formatNumberMixin, opsMixin],
  props: {
    tab: {
      default: 'tabMarket'
    }
  },
  data: () => ({
    history: [],
    loading: false
  }),
  computed: {
    ...mapGetters({
      getPairName: 'pair/getPairName',
      getTrade: 'socket/getTrade',
      getIsAuthorized: 'user/getIsAuthorized'
    }),
    getLeftPairPart() {
      return this.getPairName.split('-')[0];
    },
    getRightPairPart() {
      return this.getPairName.split('-')[1];
    }
  },
  watch: {
    tab(val) {
      if (val === 'tabMine') {
        this.history = [];
        return
      }
      this.loadLastTrades();
    },
    getPairName() {
      this.loadLastTrades();
    },
    getTrade(val) {
      this.history.unshift(val);
      this.history.length = this.history.length - 1;
    }
  },
  methods: {
    async loadLastTrades() {
      this.loading = true;
      try {
        this.history = await api.loadLastTrades({
          page: 0,
          size: 100,
          pairName: this.getPairName,
          sort: 'created,desc'
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.loadLastTrades();
  }
}
</script>
