<template>
  <div class="popup-container" v-show="!getLoading">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-statistic">
        <button @click="close" class="popup__close"></button>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("statisticModalTitle") }}
          </div>

          <!--          <div class="popup-statistic__id">-->
          <!--            ID: 6H71X-->
          <!--          </div>-->
        </div>


        <div class="popup__content">

          <div class="popup-statistic__wrap">
            <div class="popup-statistic__title">
              {{ $t("statisticModalGraph") }}

              <div class="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                      fill="#0363FE"/>
                </svg>

                <div class="tooltip__content" v-show="showTooltip">
                  {{ $t("tooltipText") }}
                </div>
              </div>
            </div>

            <div class="toggle">
              <button class="toggle__btn"
                      :class="{active: measurement === 'PERCENT'}"
                      @click="setMeasurement('PERCENT')"
              >%
              </button>
              <button class="toggle__btn"
                      :class="{active: measurement === 'CURRENCY'}"
                      @click="setMeasurement('CURRENCY')"
              >$
              </button>
            </div>
          </div>

          <div class="popup-statistic__graph">
            <InvestmentChart v-if="profits.length" :profits="profits" @setDate="setDate" />
          </div>

          <div class="popup-statistic__wrap">
            <div class="d-flex flex-column">
              <div class="popup-statistic__title">
                {{ $t("statisticModalHistory") }}

                <div class="tooltip" @mouseover="showTooltip2 = true" @mouseleave="showTooltip2 = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                        fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showTooltip2">
                    {{ $t("tooltipText") }}
                  </div>
                </div>
              </div>

              <div class="popup-statistic__subtitle">
                {{ $t('statisticModalSubtitle') }} {{ startDateCopy | timestampToDatePipe }} —
                {{ endDateCopy | timestampToDatePipe }}
              </div>
            </div>


            <DateRangeFilter v-if="startDate && endDate"
                             @change="setTimeRange"
                             :startDate="startDate"
                             :endDate="endDate"/>
          </div>
        </div>

        <TradeHistoryDesktopTable :history="history"/>
        <TradeHistoryMobileTable :history="history"/>
        <Pagination :pages-qt="total" v-if="total > 1" @change="togglePage"/>
        <NoRecordsFound v-if="!getLoading && !history.length"/>

      </div>
    </div>
  </div>
</template>

<script>
import DateRangeFilter from "../../utility/DateRangeFilter";
import Pagination from "../../utility/Pagination";
import api from "@/api/api";
import InvestmentChart from "@/components/charts/InvestmentChart";
import {formatNumberMixin, timestampToDateMixin} from "@/utils/mixins";
import {mapGetters} from "vuex";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import TradeHistoryDesktopTable from "@/components/traderCabinet/TradeHistoryDesktopTable";
import TradeHistoryMobileTable from "@/components/traderCabinet/TradeHistoryMobileTable";
import loadTraderProfitHistoryMixin from "@/mixins/loadTraderProfitHistoryMixin";
import loadTraderProfitHistoryTimeRangeMixin from "@/mixins/loadTraderProfitHistoryTimeRangeMixin";
import setLoadingMixin from "@/mixins/setLoadingMixin";

export default {
  name: "StatisticModal",
  mixins: [formatNumberMixin, timestampToDateMixin, loadTraderProfitHistoryMixin, loadTraderProfitHistoryTimeRangeMixin, setLoadingMixin],
  props: {
    investmentId: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showTooltip: false,
    showTooltip2: false,
    profits: [],
    measurement: 'PERCENT',
    date: null,
  }),
  computed: {
    ...mapGetters({
      getLoading: 'general/getLoading'
    })
  },
  methods: {
    async setDate(date) {
      this.setLoading(true);
      this.date = date;
      await this.loadInvestmentProfits();
      this.setLoading(false);
    },
    setMeasurement(measurement) {
      this.measurement = measurement;
      this.loadInvestmentProfits();
    },
    togglePage(btn) {
      this.toggleHistoryPage(btn);
    },
    async setTimeRange(val) {
      this.page = 0;
      this.startDate = val.start.getTime();
      this.endDate = val.end.getTime();
      await this.loadTraderProfitHistory();
    },
    async loadInvestmentProfits() {
      try {
        this.profits = await api.loadInvestmentProfits({
          investmentId: this.investmentId,
          measurement: this.measurement,
          day: this.date || Date.now(),
        })
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.loadTraderProfitHistoryTimeRange();
    await Promise.all([
      this.loadTraderProfitHistory(),
      this.loadInvestmentProfits()
    ])
    this.setLoading(false);
  },
  components: {
    TradeHistoryMobileTable,
    TradeHistoryDesktopTable, NoRecordsFound, InvestmentChart, Pagination, DateRangeFilter},
}
</script>

<style scoped>

</style>
