<template>
  <div class="deposit-page withdraw-page" v-show="!getLoading">

    <div class="deposit-page__col">
      <h1 class="page-title">{{ $t("withdrawPageTitle") }}</h1>
      <CoinSelect v-if="wallets.length"
                  :wallets="wallets"
                  :currentWallet="currentWallet"
                  @select="selectWallet"/>

      <div v-if="currentWallet && !currentWallet.needImport">

        <div class="deposit-page__wrap">
          <div class="title-sm">
            {{ $t("networkTransferTitle") }}
          </div>

          <div class="d-flex" >
            <button class="transfer-btn transfer-btn--border"
                    v-for="(item, index) in networks" @click="selectNetwork(item)" :key="index"
                    :class="{active:  currentNetwork.network  === item.network && !isCardNetwork}">
              <div class="transfer-btn__lg">
                {{ item.network === 'CURRENCY_DEFAULT' ? currentWallet.currency : item.network }}
              </div>

              <div class="transfer-btn__sm">
                {{ item.fullName }}
              </div>
            </button>

<!--            <button disabled class="transfer-btn transfer-btn&#45;&#45;black" :class="{active: isCardNetwork}"-->
<!--                    @click="isCardNetwork = true">-->
<!--              <div class="transfer-btn__icon">-->
<!--                <img src="@/assets/img/svg/logo-visa.svg" alt="">-->
<!--              </div>-->
<!--              <div class="transfer-btn__icon">-->
<!--                <img src="@/assets/img/svg/logo-mastercard.svg" alt="">-->
<!--              </div>-->
<!--            </button>-->
          </div>
        </div>


        <div class="deposit-page__wrap">
          <div class="title-sm" v-if="currentWallet && currentNetwork">
            {{ currentNetwork.network === 'CURRENCY_DEFAULT' ? currentWallet.currency : currentNetwork.network }}
            {{ $t("btcAddressTitle") }}

            <div class="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
              <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                    fill="#0363FE"/>
              </svg>

              <div class="tooltip__content" v-show="showTooltip">
                {{ $t("tooltipText") }}
              </div>
            </div>
          </div>

          <div class="page-input page-input--padding" v-if="currentNetwork">
            <input type="text" class="input" placeholder="0" v-model="amount">
            {{amountError}}
            <button class="page-input__icon">
              {{ currentNetwork.network === 'CURRENCY_DEFAULT' ? currentWallet.currency : currentNetwork.network }}
            </button>
          </div>

          <div class="withdraw-btns">
            <button class="withdraw-btns__btn"
                    :class="{ active: currentPercent === 0.25 }"
                    @click="calcPercentWithdrawAmount(0.25)">25%
            </button>
            <button class="withdraw-btns__btn"
                    :class="{ active: currentPercent === 0.50 }"
                    @click="calcPercentWithdrawAmount(0.50)">50%
            </button>
            <button class="withdraw-btns__btn"
                    :class="{ active: currentPercent === 0.75 }"
                    @click="calcPercentWithdrawAmount(0.75)">75%
            </button>
            <button class="withdraw-btns__btn"
                    :class="{ active: currentPercent === 1 }"
                    @click="calcPercentWithdrawAmount(1)">100%
            </button>
          </div>
        </div>


        <div class="deposit-page__wrap">
          <div class="title-sm">
            {{ $t("withdrawRecipientTitle") }}
          </div>

          <div class="page-input">
            <input type="text" class="input" v-model="address" @keyup.enter="walletWithdraw">
            {{addressError}}
          </div>

        </div>

        <button class="page-btn" :disabled="!getIsVerified" @click="walletWithdraw">
          {{ $t("withdrawBtn") }}
        </button>
      </div>
      <div v-else>
        <div class="d-flex">
          <button class="page-btn page-btn--border" @click="createWalletByUser">

            {{$t("createWallet")}}

          </button>
        </div>
      </div>

    </div>


    <div class="deposit-page__col">
      <CoinBalance v-if="wallet && currentWallet && !currentWallet.needImport"
                   :isWithdraw="true"
                   :wallet="wallet"
                   :fee="fee"
                   :willGet="willGet"/>
    </div>

  </div>
</template>

<script>
import CoinSelect from "../../../components/wallet/CoinSelect";
import CoinBalance from "../../../components/wallet/CoinBalance";
import {mapGetters, mapMutations} from "vuex";
import api from "../../../api/api";
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: "Withdraw",
  mixins: [formatNumberMixin],
  data: () => ({
    showTooltip: false,
    isCardNetwork: false,
    currentPercent: 0,
    wallets: [],
    currentWallet: null,
    wallet: null,
    networks: [],
    pairs: [],
    currentNetwork: null,
    amount: 0,
    amountError: null,
    address: null,
    addressError: null,
    fee: 0,
    willGet: 0,
  }),
  computed: {
    ...mapGetters({
      getPairName: 'pair/getPairName',
      getIsVerified: 'user/getIsVerified',
      getLoading: 'general/getLoading'
    }),
    rightPairPart() {
      return this.getPairName.split('-')[1]
    }
  },
  methods: {
    ...mapMutations({
      setError: 'general/setError',
      setSuccess: 'general/setSuccess',
      setLoading: 'general/setLoading',
    }),
    async createWalletByUser() {
      try {
        await api.createWalletByUser({
          currency: this.currentWallet.currency
        })
        this.currentWallet.needImport = false;
        await this.loadWalletDetailsByUser();
        await this.loadWalletByUser();
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
      }
    },
    clearSelectedPercent() {
      this.willGet = Number(this.amount) - this.fee;
      if (!this.amount) {
        this.willGet = 0;
      }
      this.currentPercent = 0;
    },
    selectNetwork(network) {
      this.amount = 0;
      this.isCardNetwork = false;
      this.currentNetwork = network;
      this.loadCurrencyDetails();
    },
    calcPercentWithdrawAmount(percent) {
      this.currentPercent = percent;
      this.amount = this.formatNumber(this.wallet.availableBalance * percent)
      this.willGet = Number(this.amount) - this.fee;
      if (!this.amount) {
        this.willGet = 0;
      }
    },
    async validate() {
      if(!this.amount || this.amount <= 0) {
        this.amountError = 'Wrong withdraw amount';
        return false;
      }
      await this.loadWalletByUser();
      if (!this.amount || this.amount <= 0 || this.amount > this.wallet.availableBalance) {
        this.amountError = 'Wrong withdraw amount';
        return false;
      }
      if (!this.address) {
        this.addressError = 'Enter valid recipient address';
        return false;
      }
      return true;
    },
    async walletWithdraw() {
      this.setLoading(true);
      const isValid = await this.validate();
      if (!isValid) {
        this.setLoading(false);
        return;
      }
      try {
        await api.walletWithdraw({
          amount: this.amount - this.fee,
          currency: this.currentWallet.currency,
          network: this.currentNetwork.network,
          toAddress: this.address
        })
        this.setSuccess({
          message: 'Withdraw request has been sent'
        })
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
      } finally {
        this.setLoading(false)
      }
    },
    async loadWalletByUser() {
      try {
        this.wallet = await api.loadWalletByUser({
          currency: this.currentWallet.currency
        })
      } catch (e) {
        this.wallet = {
          totalBalance: 0,
          availableBalance: 0,
          lockedBalance: 0
        }
        console.log(e);
      }
    },
    async loadWalletCurrencyByUser() {
      try {
        let response = await api.loadWalletCurrencyByUser();
        response = response.map(i => ({
          currency: i,
        }))
        response.forEach(i => this.$set(i, 'needImport', false));
        return response;
      } catch (e) {
        console.log(e);
      }
    },
    async selectWallet(wallet) {
      this.currentWallet = wallet;
      if (!wallet.needImport) {
        await this.loadWalletDetailsByUser();
        await this.loadWalletByUser();
        await this.loadCurrencyDetails();
      }
    },
    async loadWalletDetailsByUser() {
      try {
        const response = await api.loadWalletDetailsByUser({
          currency: this.currentWallet.currency
        })
        const jsonCoins = await import('@/utils/coins.json');
        this.networks = Object.entries(response.addresses).map(([prop, value]) => ({
          network: prop,
          address: value,
          fullName: jsonCoins.coins.find(i => i.currency === (prop === 'CURRENCY_DEFAULT' ? this.currentWallet.currency : prop)).fullName
        }));
        this.pairs = Object.values(response.tradingPairs);
        this.currentNetwork = this.networks[0];
      } catch (e) {
        console.log(e);
      }
    },
    async loadAvailableWalletsByUser() {
      try {
        let response = await api.loadAvailableWalletsByUser({
          page: 0,
          size: 100
        });
        response = response.map(i => ({
          currency: i,
        }))
        response.forEach(i => this.$set(i, 'needImport', true));
        return response;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    async loadCurrencyDetails() {
      try {
        const response = await api.loadCurrencyDetails({
          currency: this.currentWallet.currency,
          network: this.currentNetwork.network
        });
        this.fee = response.outerFee;
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      this.setLoading(true);
      const response = await Promise.all([
        this.loadWalletCurrencyByUser(),
        this.loadAvailableWalletsByUser()
      ]);
      this.wallets = [...response[0], ...response[1]]
      if (this.$route.params.currency) {
        this.currentWallet = this.wallets.find(i => i.currency === this.$route.params.currency);
      } else {
        this.currentWallet = this.wallets.find(i => i.currency === this.rightPairPart);
      }
      if (!this.currentWallet.needImport) {
        await this.loadWalletDetailsByUser();
        await this.loadWalletByUser();
      }
      await this.loadCurrencyDetails();
      this.setLoading(false);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    CoinSelect,
    CoinBalance
  },
}
</script>
