<template>
  <div class="notif">



    <!-- desktop ver     -->
    <div class="notification-wrap desktop" :class="{news: isNewEvent}">
      <VueDragResize
          :isActive="true"
          :isResizable="false"
          axis="x"
          :w="530"
          :h="90"
          :x="156"
          :parentLimitation="true"
          :parentW="686"
          :z="1"
      >
        <div class="notification animateOpen"
             :class="{fadeOut: hideNotifItem === true}">
          <div class="notification__top">
            <div class="logo logo-sm">
              <div class="logo__icon">
                <img src="@/assets/img/svg/logo-sm.svg" alt="">
              </div>

              <div class="logo__caption">
                ConeCoin
              </div>

              <div class="date" v-if="isCreatedNow">
                {{ $t("notificationDateNow") }}
              </div>
            </div>

            <button @click="closeItem(notification.id)" class="notification__close"></button>
          </div>

          <div class="notification__content">


            <div class="d-flex">
              <div class="notification__logo">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isChangeTraderLimit">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="withdrawRequestCancel">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isVerificationRequestInProcess">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isVerificationRequestCancel">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isTraderWithdrawRequestInProcess">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isTraderWithdrawRequestCancel">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isOrderCanceled">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isDepositInPrecess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="withdrawRequestSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isVerificationRequestSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isDepositSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isTraderWithdrawRequestSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isOrderSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isOrderPlaced">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isProfitDepositSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isInvestmentClosingRequestSuccess">
                <img src="@/assets/img/notification-logo-news.png" alt="" v-show="isNewEvent">
              </div>

              <div class="notification__text">
                <p v-show="isChangeTraderLimit">{{$t("CHANGE_TRADER_LIMIT_LEFT")}}
                  <router-link :to="`/trader-profile/${notification.notificationParameters.traderId}`" class="link" tag="span">
                    {{notification.notificationParameters.traderName}}
                  </router-link>
                  {{$t("CHANGE_TRADER_LIMIT_RIGHT")}}
                </p>

                <p v-show="isDepositInPrecess">{{$t("APPLICATION")}}
                  <span>#5159 </span>{{$t("DEPOSIT_IN_PROCESS_SECOND")}}
                  <span class="green">{{$t("SUCCESSFULLY")}} </span>{{$t("DECORATED")}}.
                </p>

                <p v-show="isDepositSuccess">{{$t("APPLICATION")}}
                  <span>#5159 </span> {{$t("DEPOSIT_IN_PROCESS_SECOND")}}
                  <span class="green"> {{$t("SUCCESSFULLY")}} </span>{{$t("PROCESSED")}}.
                </p>

                <p v-show="isNewEvent">
                  {{notification.notificationParameters.eventHeader}}
                </p>

                <p v-show="isInvestmentClosingRequestSuccess">
                  Запрос на закрытие инвестиции успешен
                </p>
                <p v-show="isProfitDepositSuccess">
                  Депозит профита успешен
                </p>
                <p v-show="isTraderWithdrawRequestCancel">
                  Запрос на снятие средств отклонен
                </p>
                <p v-show="isOrderPlaced">
                  Ордер выставлен
                </p>
                <p v-show="isOrderCanceled">
                  Ордер отменен
                </p>
                <p v-show="isOrderSuccess">
                  Ордер успешен
                </p>
                <p v-show="isTraderWithdrawRequestInProcess">
                  Запрос на снятие средств отправлен в обработку
                </p>
                <p v-show="isTraderWithdrawRequestSuccess">
                  Запрос на снятие средств успешен
                </p>
                <p v-show="isVerificationRequestCancel">
                  Запрос на верификацию отменен
                </p>
                <p v-show="isVerificationRequestInProcess">
                  Запрос на верификацию в обработке
                </p>
                <p v-show="isVerificationRequestSuccess">
                  Запрос на верификацию успешен
                </p>
                <p v-show="withdrawRequestCancel">
                  Запрос на снятие средств отклонен
                </p>
                <p v-show="withdrawRequestSuccess">
                  Запрос на снятие средств успешен
                </p>
              </div>
            </div>


            <div class="notification__banner" v-show="isNewEvent">
              <img :src="notification.notificationParameters.image" alt="">
            </div>

            <div class="notification__footer" v-show="isNewEvent">
              <div class="notification__location">
                <img src="@/assets/img/svg/icon-location.svg" alt="">
                {{ notification.notificationParameters.eventLocation }}
              </div>

              <div class="notification__location">
                <img src="@/assets/img/svg/icon-calendar.svg" alt="">
                {{ eventDate(notification.notificationParameters.eventDate / 1000) }}

                <div class="date">
                  {{ eventTime(notification.notificationParameters.eventDate / 1000)}} Мск
                </div>
              </div>
            </div>

          </div>
        </div>
        <button class="notification__clear" v-show="isModal" @click="readNotification(notification.id)">
          {{ $t("notificationClear") }}
        </button>
      </VueDragResize>
    </div>
    <!-- end desktop ver     -->

    <!-- mobile ver     -->
    <div class="notification-wrap mobile">
      <VueDragResize
          :isActive="true"
          :isResizable="false"
          axis="x"
          :w="388"
          :h="83"
          :x="93"
          :parentLimitation="true"
          :parentW="482"
          :z="1"
      >
        <div class="notification animateOpen"
             :class="{fadeOut: hideNotifItem === true}">
          <div class="notification__top">
            <div class="logo logo-sm">
              <div class="logo__icon">
                <img src="@/assets/img/svg/logo-sm.svg" alt="">
              </div>

              <div class="logo__caption">
                ConeCoin
              </div>

              <div class="date" v-if="isCreatedNow">
                {{ $t("notificationDateNow") }}
              </div>
            </div>

            <button @click="closeItem(notification.id)" class="notification__close"></button>
          </div>

          <div class="notification__content">


            <div class="d-flex">
              <div class="notification__logo">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isChangeTraderLimit">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="withdrawRequestCancel">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isVerificationRequestInProcess">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isVerificationRequestCancel">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isTraderWithdrawRequestInProcess">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isOrderCanceled">
                <img src="@/assets/img/notification-logo-user.png" alt="" v-show="isTraderWithdrawRequestCancel">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isDepositInPrecess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="withdrawRequestSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isOrderPlaced">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isOrderSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isTraderWithdrawRequestSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isVerificationRequestSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isDepositSuccess">
                <img src="@/assets/img/notification-logo-news.png" alt="" v-show="isNewEvent">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isProfitDepositSuccess">
                <img src="@/assets/img/notification-logo.png" alt="" v-show="isInvestmentClosingRequestSuccess">
              </div>

              <div class="notification__text">
                <p v-show="isChangeTraderLimit">{{$t("CHANGE_TRADER_LIMIT_LEFT")}}
                  <router-link :to="`/trader-profile/${notification.notificationParameters.traderId}`" class="link" tag="span">
                    {{notification.notificationParameters.traderName}}
                  </router-link>
                  {{$t("CHANGE_TRADER_LIMIT_RIGHT")}}
                </p>

                <p v-show="isDepositInPrecess">{{$t("APPLICATION")}}
                  <span>#5159 </span>{{$t("DEPOSIT_IN_PROCESS_SECOND")}}
                  <span class="green">{{$t("SUCCESSFULLY")}} </span>{{$t("DECORATED")}}.
                </p>

                <p v-show="isDepositSuccess">{{$t("APPLICATION")}}
                  <span>#5159 </span> {{$t("DEPOSIT_IN_PROCESS_SECOND")}}
                  <span class="green"> {{$t("SUCCESSFULLY")}} </span>{{$t("PROCESSED")}}.
                </p>

                <p v-show="isNewEvent">
                  {{notification.notificationParameters.eventHeader}}
                </p>
                <p v-show="isInvestmentClosingRequestSuccess">
                  Запрос на закрытие инвестиции успешен
                </p>
                <p v-show="isProfitDepositSuccess">
                  Депозит профита успешен
                </p>
                <p v-show="isTraderWithdrawRequestCancel">
                  Запрос на снятие средств отклонен
                </p>
                <p v-show="isOrderPlaced">
                  Ордер выставлен
                </p>
                <p v-show="isOrderCanceled">
                  Ордер отменен
                </p>
                <p v-show="isOrderSuccess">
                  Ордер успешен
                </p>
                <p v-show="isTraderWithdrawRequestInProcess">
                  Запрос на снятие средств отправлен в обработку
                </p>
                <p v-show="isTraderWithdrawRequestSuccess">
                  Запрос на снятие средств успешен
                </p>
                <p v-show="isVerificationRequestCancel">
                  Запрос на верификацию отменен
                </p>
                <p v-show="isVerificationRequestInProcess">
                  Запрос на верификацию в обработке
                </p>
                <p v-show="isVerificationRequestSuccess">
                  Запрос на верификацию успешен
                </p>
                <p v-show="withdrawRequestCancel">
                  Запрос на снятие средств отклонен
                </p>
                <p v-show="withdrawRequestSuccess">
                  Запрос на снятие средств успешен
                </p>
              </div>

            </div>


            <div class="notification__banner" v-show="isNewEvent">
              <img :src="notification.notificationParameters.image" alt="">
            </div>

            <div class="notification__footer" v-show="isNewEvent">
              <div class="notification__location">
                <img src="@/assets/img/svg/icon-location.svg" alt="">
                Нур-Султан, Казахстан
              </div>

              <div class="notification__location">
                <img src="@/assets/img/svg/icon-calendar.svg" alt="">
                {{ eventDate(notification.notificationParameters.eventDate / 1000) }}

                <div class="date">
                  {{ eventTime(notification.notificationParameters.eventDate / 1000) }} Мск
                </div>
              </div>
            </div>

          </div>
        </div>
        <button class="notification__clear" v-show="isModal" @click="readNotification(notification.id)">
          {{ $t("notificationClear") }}
        </button>
      </VueDragResize>
    </div>
    <!-- end mobile ver -->
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import api from "@/api/api";
import eventIdentifierMixin from "@/mixins/eventIdentifierMixin";

export default {
  name: "Notification",
  mixins: [eventIdentifierMixin],
  components: {
    VueDragResize
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    isNewsNotif: {
      default: false
    },
    isModal: {
      default: false,
    }
  },
  data() {
    return {
      hideNotifItem: false,
      left: Number, //the X position of the component
      top: Number, //the Y position of the component
      width: Number, //the width of the component
      height: Number, //the height of the component
    }
  },
  computed: {
    isCreatedNow() {
      let t1 = new Date(this.notification.created);
      let t2 = new Date(Date.now());
      return ( t2. getTime() - t1. getTime() ) / 1000 < 300;
    },
    isChangeTraderLimit() {
      return this.notification.type === 'CHANGE_TRADER_LIMIT'
    },
    isDepositInPrecess() {
      return this.notification.type === 'DEPOSIT_IN_PROCESS'
    },
    isDepositSuccess() {
      return this.notification.type === 'DEPOSIT_SUCCESS'
    },
    isInvestmentClosingRequestSuccess() {
      return this.notification.type === 'INVESTMENT_CLOSING_REQUEST_SUCCESS'
    },
    isNewChangelogItem() {
      return this.notification.type === 'NEW_CHANGELOG_ITEM'
    },
    isNewEvent() {
      return this.notification.type === 'NEW_EVENT'
    },
    isNewNewsPost() {
      return this.notification.type === 'NEW_NEWS_POST'
    },
    isOrderCanceled() {
      return this.notification.type === 'ORDER_CANCELLED'
    },
    isOrderPlaced() {
      return this.notification.type === 'ORDER_PLACED'
    },
    isOrderSuccess() {
      return this.notification.type === 'ORDER_SUCCESS'
    },
    isProfitDepositSuccess() {
      return this.notification.type === 'PROFIT_DEPOSIT_SUCCESS'
    },
    isTraderWithdrawRequestCancel() {
      return this.notification.type === 'TRADER_WITHDRAW_REQUEST_CANCEL'
    },
    isTraderWithdrawRequestInProcess() {
      return this.notification.type === 'TRADER_WITHDRAW_REQUEST_IN_PROCESS'
    },
    isTraderWithdrawRequestSuccess() {
      return this.notification.type === 'TRADER_WITHDRAW_REQUEST_SUCCESS'
    },
    isVerificationRequestCancel() {
      return this.notification.type === 'VERIFICATION_REQUEST_CANCEL'
    },
    isVerificationRequestInProcess() {
      return this.notification.type === 'VERIFICATION_REQUEST_IN_PROCESS'
    },
    isVerificationRequestSuccess() {
      return this.notification.type === 'VERIFICATION_REQUEST_SUCCESS'
    },
    withdrawRequestCancel() {
      return this.notification.type === 'WITHDRAW_REQUEST_CANCEL'
    },
    withdrawRequestInProcess() {
      return this.notification.type === 'WITHDRAW_REQUEST_IN_PROCESS'
    },
    withdrawRequestSuccess() {
      return this.notification.type === 'WITHDRAW_REQUEST_SUCCESS'
    },
  },
  methods: {
    async readNotification(notificationId) {
      try {
        await api.readNotification({
          notificationId
        });
        this.$emit('deleted', notificationId);
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit('close')
    },
    closeItem(id) {
      this.hideNotifItem = true;
      this.$emit('closeItem', id);
    },
    onDragging(newRect) {
      if (newRect.left >= 210) {
        this.left = 0
      }
    }
  },

}
</script>

<style scoped>
.slide-fade-enter-from {
  transform: translateY(50px);
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all 1s ease 5s;
}

.slide-fade-enter-to {
  transform: translateY(0px);
  opacity: 1;
}

.slide-fade-leave-from {
  /* default opacity: 1;*/
}

.slide-fade-leave-active {
  transition: opacity 1s;
}

.slide-fade-leave-to {
  opacity: 0;
}
</style>