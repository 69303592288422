<template>
  <div class="login-page restore-page">
    <div class="login-wrap">
      <div class="login-page__content">
        <div class="logo">
          <img src="@/assets/img/svg/logo.svg" alt="">
        </div>

        <template>
          <h1 class="login-page__title">
            {{ $t("restorePassSendTitle") }}
          </h1>

          <div class="login-page__img">
            <img src="@/assets/img/success.png" alt="">
          </div>

          <p>yasonworkingfor@gmail.com</p>

          <div class="login-page__check">
            <div class="login-page__caption">
              {{ $t("restorePassSendAgain") }}
              (48 сек.)
            </div>

            <a href="#" class="link"> {{ $t("restorePassLink") }}</a>
          </div>
        </template>

        <template>
          <h1 class="login-page__title">
            {{ $t("restorePassNotValid") }}
          </h1>

          <div class="login-page__img">
            <img src="@/assets/img/error.png" alt="">
          </div>

          <div class="login-page__caption">
            {{ $t("restorePassWaitLink1") }}
            <span class="link">{{ $t("restorePassWaitLinkColor") }}</span>
            {{ $t("restorePassWaitLink2") }}

          </div>


          <router-link to="/sign-in" tag="button" class="page-btn page-btn--border page-btn--neutral">{{ $t("restorePassBack") }}</router-link>
        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPage',
  data() {
    return {

    }
  },
  props: {
    type: {
      type: String,
      required: true,
      default: 'success', // or error,
      isSentValid: true
    }
  }
}
</script>
