<template>
  <div class="my-deposit-stats">
      <div class="balance-wrap">
        <div class="balance-wrap__icon">
          <img src="@/assets/img/svg/stats-dollar.svg" alt="">
        </div>

        <div class="balance-wrap__content">
          <div class="balance-wrap__title">{{ $t("myBalanceStatsTitle1")}}:</div>
          <div class="balance-wrap__amount">{{formatNumber(profitInfo.investmentsSum)}} USDT</div>
        </div>
      </div>

      <div class="balance-wrap">
        <div class="balance-wrap__icon">
          <img src="@/assets/img/svg/stats-value.svg" alt="">
        </div>

        <div class="balance-wrap__content">
          <div class="balance-wrap__title">{{ $t("myBalanceStatsTitle2")}}:</div>
          <div class="balance-wrap__amount">{{formatNumber(profitInfo.totalProfit)}} USDT</div>
        </div>
      </div>

      <div class="balance-wrap">
        <div class="balance-wrap__icon">
          <img src="@/assets/img/svg/stats-calendar.svg" alt="">
        </div>

        <div class="balance-wrap__content">
          <div class="balance-wrap__title">{{ $t("myBalanceStatsTitle3")}} {{getMonthName()}}:</div>
          <div class="balance-wrap__amount">{{formatNumber(profitInfo.totalMonthlyProfit)}} USDT</div>
        </div>
      </div>

  </div>
</template>

<script>
  import {formatNumberMixin} from "@/utils/mixins";
  import getMonthNameMixin from "@/mixins/getMonthNameMixin";

  export default {
    name: 'DepositsStats',
    mixins: [formatNumberMixin, getMonthNameMixin],
    props: {
      profitInfo: {
        type: Object,
        required: true
      }
    }
  }
</script>
