import api from "@/api/api";

export default {
    data: () => ({
        profitsHistory: [],
        profitsHistoryPage: 0,
        profitsHistorySize: 36,
        profitsHistoryTotal: 0,
    }),
    methods: {
        async loadTraderMonthlyProfitHistory() {
            try {
                const response = await api.loadTraderMonthlyProfitHistory({
                    traderId: this.id || this.traderId,
                    size: this.profitsHistorySize,
                    page: this.profitsHistoryPage,
                });
                this.total = this.profitsHistoryTotal = response.totalPages;
                this.profitsHistory = [];
                let rowIndex = 0;
                response.content.forEach(i => {
                    if(!this.profitsHistory.length) {
                        this.profitsHistory.push({
                            year: i.year,
                            values: [],
                            total: 0,
                            isShow: false
                        });
                    }
                    if(this.profitsHistory[rowIndex].values.length <= 12) {
                        this.profitsHistory[rowIndex].values.push(i);
                    }
                    if(this.profitsHistory[rowIndex].values.length === 12) {
                        this.profitsHistory[rowIndex].total =
                            this.profitsHistory[rowIndex].values.map(i => i.profitPercent).reduce((prev, next) => prev + next)
                        rowIndex++;
                    }
                })
                if(this.profitsHistory[rowIndex].values.length !== 12) {
                    let remainder = 12 - this.profitsHistory[rowIndex].values.length;
                    while(remainder !== 0) {
                        this.profitsHistory[rowIndex].values.push({
                            profitPercent: 0
                        })
                        remainder--;
                    }
                    this.profitsHistory[rowIndex].total =
                        this.profitsHistory[rowIndex].values.map(i => i.profitPercent).reduce((prev, next) => prev + next)
                }
            } catch (e) {
                console.log(e);
            }
        },
        toggleProfitsHistory(page) {
            this.profitsHistoryPage = page - 1;
            this.loadTraderMonthlyProfitHistory();
        },
    }
}