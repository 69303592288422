import {mapMutations} from "vuex";

export default {
    methods: {
        ...mapMutations({
            setLoading: 'general/setLoading',
            setSuccess: 'general/setSuccess',
            setError: 'general/setError',
        })
    }
}