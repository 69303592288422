<template>
  <div class="login-page restore-page google-auth-page">
    <div class="login-wrap">
      <div class="login-page__content">
        <div class="logo">
          <img src="@/assets/img/svg/logo.svg" alt="">
        </div>


        <h1 class="login-page__title">
          {{ $t("googleAuthTitle") }}
        </h1>

        <div class="login-page__caption">
          {{ $t("googleAuthText") }}
        </div>

        <form class="form" @submit.prevent="confirmLogin">
          <div class="page-input">
            <div class="page-input__title">
              {{ $t("emailAuthCode") }}
            </div>

            <TwoFaGoogleAuthForm @twaCode="setTwaCode"/>
          </div>


          <button class="page-btn">
            {{ $t("googleAuthBtn") }}
          </button>

        </form>


      </div>

    </div>
  </div>
</template>

<script>
import TwoFaGoogleAuthForm from "../../components/utility/TwoFaGoogleAuthForm";
import api from "../../api/api";
import {mapMutations} from "vuex";

export default {
  name: 'GoogleAuthenticate',
  data: () => ({
    twaCode: null
  }),
  methods: {
    ...mapMutations({
      setToken: 'user/setToken',
      setError: 'general/setError',
      setLoading: 'general/setLoading'
    }),
    setTwaCode(twaCode) {
      this.twaCode = twaCode;
    },
    async confirmLogin() {
      try {
        this.setLoading(true);
        const email = this.$route.query.email;
        const response = await api.confirmLogin({
          email,
          mailCode: '',
          twoFaCode: this.twaCode
        });
        this.setToken({
          token: response.token,
          userId: response.userId,
        });
        await this.$router.push('/')
      } catch (e) {
          this.setError({
            message: e.response.data.message
          })
        console.log(e);
      } finally {
        this.setLoading(false)
      }
    },
  },
  components: {
    TwoFaGoogleAuthForm
  }
}
</script>
