import http from "../utils/http";

const userEndpoints = {
  SIGNIN: '/auth/authorization',
  SIGNUP: '/auth/registration',
  RESTORE_PASSWORD: '/restore_password',
  CONFIRM_REGISTRATION: '/auth/confirm_registration',
  GET_USER_INFO: '/user_info',
  GET_USER_SETTINGS: '/confirm_login/settings',
  GET_LOGIN_HISTORY: '/history',
  UPLOAD_AVATAR: '/user_info/upload_avatar',
  DELETE_AVATAR: '/user_info/delete_avatar',
  CHANGE_PASSWORD: '/user_info/change_password',
  CHANGE_PERSONAL_INFO: '/user_info/change_personal_info',
  CHANGE_EMAIL: '/user_info/change_email',
  GET_USER_IP: 'https://api.ipify.org?format=json'
}

const transactionEndpoints = {
  GET_TRANSACTIONS_BY_USER: '/transactions/get_transactions_by_user',
  GET_WITHDRAW_REQUESTS_BY_USER: '/wallets/get_withdraw_requests_by_user',
  CANCEL_WITHDRAW_REQUESTS_BY_USER: '/wallets/cancel_withdraw_requests_by_user',
  GET_TRANSACTIONS_TIME_RANGE: '/transactions/get_transactions_time_range',
}

const exchangeEndpoints = {
  PLACE_LIMIT_ORDER: '/trade/place_limit_order',
  PLACE_MARKET_ORDER: '/trade/place_market_order'
}

const candlesEndpoints = {
  GET_CANDLES: '/candle'
}

const historyEndpoints = {
  GET_LAST_TRADES: '/trade/last_trades'
}


const pairsEndpoints = {
  GET_PAIRS: '/pairs',
  GET_PAIR_INFO: '/pairs/info',
  ADD_TO_FAVOURITE: '/pairs/add_to_favourite',
  REMOVE_FROM_FAVOURITE: '/pairs/remove_from_favourite',
  GET_FAVOURITE_PAIRS: '/pairs/favourite'
}

const walletEndpoints = {
  GET_WALLETS_BY_USER: '/wallets/get_wallets_by_user',
  GET_WALLET_BY_USER: '/wallets/get_wallet_by_user',
  GET_AVAILABLE_WALLETS_BY_USER: '/wallets/get_available_wallets_by_user',
  CREATE_WALLET_BY_USER: '/wallets/create_wallet_by_user',
  GET_WALLET_DETAILS_BY_USER: '/wallets/get_wallet_details_by_user',
  GET_WALLET_CURRENCY_BY_USER: '/wallets/get_wallets_currency_by_user',
  GET_ESTIMATED_BALANCE: '/wallets/get_estimated_balance',
  WITHDRAW: '/wallets/withdraw',
  CHECK_TRANSACTION_BY_HASH: '/wallets/check_transaction_by_hash'
}

const orderBookEndpoints = {
  GET_ORDER_BOOK: '/trade/get_order_book',
  GET_ORDERS_BY_USER: '/trade/get_orders_by_user',
  GET_HISTORY_ORDERS_BY_USER: '/trade/get_history_orders_by_user',
  DELETE_ALL_ORDERS_BY_USER: '/trade/delete_all_orders_by_user',
  DELETE_ORDER_BY_USER: '/trade/delete_order_by_user',
  GET_ORDERS_TIME_RANGE: '/trade/get_orders_time_range',
  GET_HISTORY_ORDERS_TIME_RANGE: '/trade/get_history_orders_time_range'
}

const twoFaEndpoints = {
  REGISTER: '/confirm_login/register',
  GET_TWO_FA_QR_LINK: '/confirm_login/get_two_fa_qr_link',
  GET_TWO_FA_SECRET: '/confirm_login/get_two_fa_secret',
  ENABLE_TWO_FA: '/confirm_login/enable_two_fa',
  DISABLE_TWO_FA: '/confirm_login/disable_two_fa',
  CONFIRM_LOGIN: '/confirm_login'
}

const verificationEndpoints = {
  GET_KYC: '/kyc',
  DETAILS: '/kyc/details',
  DOCUMENT: (type, side) => `/kyc/document/${type}/${side}`,
  DELETE_DOCUMENT: '/kyc/document',
  SEND_TO_VERIFICATION: '/kyc/send_to_verification'
}

const currencyEndpoints = {
  GET_CURRENCY_DETAILS: '/currency_details'
}

const tradersEndpoints = {
  GET_TRADERS: '/investor_trader/get_traders',
  ADD_TO_FAVORITE: '/investor_trader/add_to_favorite',
  DELETE_FROM_FAVORITE: '/investor_trader/delete_from_favorite',
  GET_TRADER_PROFIT_HISTORY: '/investor_trader/get_trader_profit_history',
  GET_USER_INVESTOR_PROFIT_INFO: '/investor_investment/get_user_investor_profit_info',
  GET_TRADER_PROFIT_HISTORY_TIME_RANGE: '/investor_trader/get_trader_profit_history_time_range',
  GET_OPEN_INVESTMENTS_TIME_RANGE: '/investor_investment/get_open_investments_time_range',
  GET_CLOSED_INVESTMENTS_TIME_RANGE: '/investor_investment/get_closed_investments_time_range',
  GET_TRADER_INFO: '/investor_trader/get_trader_info',
  CALCULATE_PROFIT: '/investor_trader/calculate_profit',
  GET_TRADER_MONTHLY_PROFIT_HISTORY: '/investor_trader/get_trader_monthly_profit_history',
  GET_TRADERS_PROFITS: '/investor_trader/get_traders_profits',
  CHANGE_TRADER_STATUS: `/admin_trader/change_trader_status`,
  GET_TRADER_STATISTIC: '/investor_trader/get_trader_statistic',
  GET_TRADER_MANAGER: '/admin_trader/get_trader_manager',
  GET_TRADER_MANAGER_CODE: '/admin_trader/get_trader_manager_code'
}

const investmentEndpoints = {
  CREATE_INVESTMENT: '/investor_investment/create_investment',
  GET_INVESTMENTS_FOR_USER: '/investor_investment/get_investments_for_user',
  CREATE_CLOSING_INVESTMENT_REQUEST: '/investor_investment/create_closing_investment_request',
  CHANGE_INVESTMENT_AUTO_REINVEST: '/investor_investment/change_investment_auto_reinvest',
  GET_INVESTOR_PERCENT: '/investor_investment/get_investor_percent',
  GET_INVESTMENT_PROFITS: '/investor_investment/get_investment_profits',
  GET_TRADER_OPEN_INVESTMENTS: '/investor_investment/get_trader_open_investments',
  GET_TRADER_CLOSED_INVESTMENTS: '/investor_investment/get_trader_closed_investments',
}

const newsEndpoints = {
  GET_NEWS: '/get_news',
  GET_ALL_NEWS: '/get_all_news',
  GET_NEWS_MANAGER: '/get_news_manager',
  GET_THE_MOST_POPULAR_NEWS: '/get_the_most_popular_news'
}

const changelogEndpoints = {
  GET_ALL_CHANGELOGS: '/get_all_changelogs',
  GET_CHANGELOG: '/get_changelog',
}

const eventEndpoints = {
  GET_ALL_EVENTS: '/get_all_events',
  GET_EVENT: '/get_event',
}

const notificationEndpoints = {
  GET_NOTIFICATIONS: '/notifications',
  READ_NOTIFICATION: '/notifications',
  GET_NOTIFICATIONS_NUMBER: '/notifications/get_notifications_number'
}

const getNotifications = async (payload) => {
  const response = await http.get(notificationEndpoints.GET_NOTIFICATIONS, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const getNotificationsNumber = async () => {
  const response = await http.get(notificationEndpoints.GET_NOTIFICATIONS_NUMBER, {
    params: {
      isPublic: false
    }
  });
  return response.data;
}

const readNotification = async (payload) => {
  const response = await http.post(notificationEndpoints.READ_NOTIFICATION, null, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const getUserIp = async () => {
  const response = await http.get(userEndpoints.GET_USER_IP, {
    params: {isPublic: true}
  });
  return response.data;
}

const getOrderTimeRange = async () => {
  const response = await http.get(orderBookEndpoints.GET_ORDERS_TIME_RANGE, {
    params: {
      isPublic: false,
    }
  });
  return response.data;
}

const getHistoryOrderTimeRange = async () => {
  const response = await http.get(orderBookEndpoints.GET_HISTORY_ORDERS_TIME_RANGE, {
    params: {
      isPublic: false
    }
  });
  return response.data;
}

const getAllNews = async (payload) => {
  const response = await http.get(newsEndpoints.GET_ALL_NEWS, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getTheMostPopularNews = async (payload) => {
  const response = await http.get(newsEndpoints.GET_THE_MOST_POPULAR_NEWS, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getNews = async (payload) => {
  const response = await http.get(newsEndpoints.GET_NEWS, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getNewsManager = async (payload) => {
  const response = await http.get(newsEndpoints.GET_NEWS_MANAGER, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getAllChangelogs = async (payload) => {
  const response = await http.get(changelogEndpoints.GET_ALL_CHANGELOGS, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getChangelog = async (payload) => {
  const response = await http.get(changelogEndpoints.GET_CHANGELOG, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getAllEvents = async (payload) => {
  const response = await http.get(eventEndpoints.GET_ALL_EVENTS, {
    params: {
      ...payload,
      isPublic: true
    },
  });
  return response.data;
};

const getEvent = async (payload) => {
  const response = await http.get(eventEndpoints.GET_EVENT, {
    params: {
      ...payload,
      isPublic: false
    },
  });
  return response.data;
};


const loadTraderOpenInvestments = async (payload) => {
  const response = await http.get(
      investmentEndpoints.GET_TRADER_OPEN_INVESTMENTS, {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const loadTraderClosedInvestments = async (payload) => {
  const response = await http.get(
      investmentEndpoints.GET_TRADER_CLOSED_INVESTMENTS, {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const changeTraderStatus = async (payload) => {
  const response = await http.put(
      tradersEndpoints.CHANGE_TRADER_STATUS,
      null,
      {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const loadTraderStatistic = async (payload) => {
  const response = await http.get(
      tradersEndpoints.GET_TRADER_STATISTIC, {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const loadTraderManager = async (payload) => {
  const response = await http.get(
      tradersEndpoints.GET_TRADER_MANAGER, {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const getTraderManagerCode = async (payload) => {
  const response = await http.get(
      tradersEndpoints.GET_TRADER_MANAGER_CODE, {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const loadTradersProfits = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_TRADERS_PROFITS, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadTraderMonthlyProfitHistory = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_TRADER_MONTHLY_PROFIT_HISTORY, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const calculateProfit = async (payload) => {
  const response = await http.get(tradersEndpoints.CALCULATE_PROFIT, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadTraderInfo = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_TRADER_INFO, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadUserInvestorProfitInfo = async () => {
  const response = await http.get(tradersEndpoints.GET_USER_INVESTOR_PROFIT_INFO, {
    params: {
      isPublic: false
    }
  });
  return response.data;
}

const loadTraderProfitHistoryTimeRange = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_TRADER_PROFIT_HISTORY_TIME_RANGE, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadOpenInvestmentsTimeRange = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_OPEN_INVESTMENTS_TIME_RANGE, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadClosedInvestmentsTimeRange = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_CLOSED_INVESTMENTS_TIME_RANGE, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadTraderProfitHistory = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_TRADER_PROFIT_HISTORY, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const loadInvestmentProfits = async (payload) => {
  const response = await http.get(investmentEndpoints.GET_INVESTMENT_PROFITS, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const addToFavorite = async (payload) => {
  const response = await http.post(tradersEndpoints.ADD_TO_FAVORITE, null, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const deleteFromFavorite = async (payload) => {
  const response = await http.delete(tradersEndpoints.DELETE_FROM_FAVORITE, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}


const loadInvestorPercent = async (payload) => {
  const response = await http.get(investmentEndpoints.GET_INVESTOR_PERCENT, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const changeInvestmentAutoReinvest = async (payload) => {
  const response = await http.put(investmentEndpoints.CHANGE_INVESTMENT_AUTO_REINVEST, null, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data;
}

const createClosingInvestmentRequest = async (payload) => {
  const response = await http.put(
      investmentEndpoints.CREATE_CLOSING_INVESTMENT_REQUEST, null, {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const loadInvestmentsForUser = async (payload) => {
  const response = await http.get(
      investmentEndpoints.GET_INVESTMENTS_FOR_USER,
      {
        params: {
          isPublic: false,
          ...payload
        },
      }
  );
  return response.data;
};

const loadTransactionsByUser = async payload => {
  const response = await http.get(transactionEndpoints.GET_TRANSACTIONS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  });
  return response.data
}

const loadWithdrawRequestsByUser = payload => {
  return http.get(transactionEndpoints.GET_WITHDRAW_REQUESTS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const cancelWithdrawRequestByUser = payload => {
  return http.post(transactionEndpoints.CANCEL_WITHDRAW_REQUESTS_BY_USER, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const getTransactionsTimeRange = async () => {
  const response = await http.get(transactionEndpoints.GET_TRANSACTIONS_TIME_RANGE, {
    params: {
      isPublic: false
    }
  });
  return response.data;
}

const loadCurrencyDetails = payload => {
  return http.get(currencyEndpoints.GET_CURRENCY_DETAILS, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const sendToVerification = () => {
  return http.post(verificationEndpoints.SEND_TO_VERIFICATION, null, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const deleteDocument = payload => {
  return http.delete(verificationEndpoints.DELETE_DOCUMENT, {
    params: {
      ...payload,
      isPublic: false
    }
  })
}

const uploadDocument = payload => {
  let formData = new FormData();
  formData.append("document", payload.file);
  return http.post(verificationEndpoints.DOCUMENT(payload.type, payload.side), formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      isPublic: false
    }
  })
    .then(res => {
      console.log(res.data)
      return res.data
    })
    .catch(e => {
      throw e
    })
}

const loadDocument = payload => {
  return http.get(verificationEndpoints.DOCUMENT(payload.type, payload.side), {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const uploadKycDetails = payload => {
  return http.post(verificationEndpoints.DETAILS, payload, {
    params: {
      isPublic: false
    }
  })
}

const loadKyc = () => {
  return http.get(verificationEndpoints.GET_KYC, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const registerTwoFa = () => {
  return http.post(twoFaEndpoints.REGISTER, null, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadTwoFaQrLink = () => {
  return http.get(twoFaEndpoints.GET_TWO_FA_QR_LINK, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const enableTwoFa = payload => {
  return http.post(twoFaEndpoints.ENABLE_TWO_FA, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const disableTwoFa = payload => {
  return http.post(twoFaEndpoints.DISABLE_TWO_FA, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const confirmLogin = payload => {
  return http.post(twoFaEndpoints.CONFIRM_LOGIN, payload, {
    params: {
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadTwoFaSecret = () => {
  return http.get(twoFaEndpoints.GET_TWO_FA_SECRET, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadUserInfo = () => {
  return http.get(userEndpoints.GET_USER_INFO, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadSettings = () => {
  return http.get(userEndpoints.GET_USER_SETTINGS, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadLoginHistory = payload => {
  return http.get(userEndpoints.GET_LOGIN_HISTORY, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const uploadAvatar = payload => {
  let formData = new FormData();
  formData.append("document", payload);
  return http.post(userEndpoints.UPLOAD_AVATAR, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const deleteAvatar = () => {
  return http.post(userEndpoints.DELETE_AVATAR, null, {
    params: {
      isPublic: false
    }
  })
      .then(res => res.data)
}

const changePassword = payload => {
  return http.post(userEndpoints.CHANGE_PASSWORD, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
}

const changeEmail = payload => {
  return http.put(userEndpoints.CHANGE_EMAIL, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
}

const changePersonalInfo = payload => {
  return http.post(userEndpoints.CHANGE_PERSONAL_INFO, payload, {
    params: {
      isPublic: false
    }
  })
}

const loadLastTrades = payload => {
  return http.get(historyEndpoints.GET_LAST_TRADES, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data.content)
    .catch(e => {
      throw e
    })
}

const placeLimitOrder = payload => {
  return http.post(exchangeEndpoints.PLACE_LIMIT_ORDER, {
    ...payload
  }, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const placeMarketOrder = payload => {
  return http.post(exchangeEndpoints.PLACE_MARKET_ORDER, {
    ...payload
  }, {
    params: {
      isPublic: false
    }
  })
}

const addPairToFavourite = payload => {
  return http.post(pairsEndpoints.ADD_TO_FAVOURITE, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const removePairFromFavourite = payload => {
  return http.post(pairsEndpoints.REMOVE_FROM_FAVOURITE, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const signIn = payload => {
  return http.post(userEndpoints.SIGNIN, payload, {
    params: {
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const signUp = payload => {
  return http.post(userEndpoints.SIGNUP, payload, {
    params: {
      isPublic: true
    }
  })
    .then(res => res.data.response)
    .catch(e => {
      throw e
    })
}

const restorePassword = payload => {
  return http.put(userEndpoints.RESTORE_PASSWORD, null, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const confirmRegistration = payload => {
  return http.post(userEndpoints.CONFIRM_REGISTRATION, null, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const setNewPassword = payload => {
  return http.post(userEndpoints.RESTORE_PASSWORD, null, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadCandles = payload => {
  return http.get(candlesEndpoints.GET_CANDLES, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadOrderBook = payload => {
  return http.get(orderBookEndpoints.GET_ORDER_BOOK, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadTrades = payload => {
  return http.get(orderBookEndpoints.GET_ORDERS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadHistoryTrades = payload => {
  return http.get(orderBookEndpoints.GET_HISTORY_ORDERS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const deleteOrderByUser = payload => {
  return http.delete(orderBookEndpoints.DELETE_ORDER_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const deleteAllOrderByUser = payload => {
  return http.delete(orderBookEndpoints.DELETE_ALL_ORDERS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
}

const loadPairs = payload => {
  return http.get(pairsEndpoints.GET_PAIRS, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadPairInfo = payload => {
  return http.get(pairsEndpoints.GET_PAIR_INFO, {
    params: {
      ...payload,
      isPublic: true
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadFavouritePairs = payload => {
  return http.post(pairsEndpoints.GET_FAVOURITE_PAIRS, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadWalletsByUser = payload => {
  return http.get(walletEndpoints.GET_WALLETS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadWalletByUser = payload => {
  return http.get(walletEndpoints.GET_WALLET_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadAvailableWalletsByUser = payload => {
  return http.get(walletEndpoints.GET_AVAILABLE_WALLETS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const createWalletByUser = payload => {
  return http.post(walletEndpoints.CREATE_WALLET_BY_USER, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const walletWithdraw = payload => {
  return http.post(walletEndpoints.WITHDRAW, payload, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const checkTransactionByHash = payload => {
  return http.post(walletEndpoints.CHECK_TRANSACTION_BY_HASH, null, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadWalletDetailsByUser = payload => {
  return http.get(walletEndpoints.GET_WALLET_DETAILS_BY_USER, {
    params: {
      ...payload,
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadWalletCurrencyByUser = () => {
  return http.get(walletEndpoints.GET_WALLET_CURRENCY_BY_USER, {
    params: {

      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadEstimatedBalance = () => {
  return http.get(walletEndpoints.GET_ESTIMATED_BALANCE, {
    params: {
      isPublic: false
    }
  })
    .then(res => res.data)
    .catch(e => {
      throw e
    })
}

const loadTraders = async (payload) => {
  const response = await http.get(tradersEndpoints.GET_TRADERS, {
    params: payload
  })
  return response.data;
}

const createInvestment = async (payload, password) => {
  const response = await http.post(investmentEndpoints.CREATE_INVESTMENT, payload, {
    params: {
      password
    }
  })
  return response.data;
}

export default {
  signIn,
  signUp,
  restorePassword,
  confirmRegistration,
  setNewPassword,
  loadCandles,
  loadPairs,
  placeLimitOrder,
  placeMarketOrder,
  addPairToFavourite,
  removePairFromFavourite,
  loadWalletsByUser,
  loadWalletByUser,
  loadFavouritePairs,
  loadLastTrades,
  loadOrderBook,
  loadTrades,
  deleteOrderByUser,
  deleteAllOrderByUser,
  loadHistoryTrades,
  loadUserInfo,
  loadAvailableWalletsByUser,
  createWalletByUser,
  loadLoginHistory,
  loadSettings,
  uploadAvatar,
  loadTwoFaQrLink,
  registerTwoFa,
  loadWalletDetailsByUser,
  loadWalletCurrencyByUser,
  loadPairInfo,
  loadTwoFaSecret,
  loadKyc,
  uploadKycDetails,
  uploadDocument,
  loadEstimatedBalance,
  enableTwoFa,
  changePassword,
  disableTwoFa,
  confirmLogin,
  loadDocument,
  deleteDocument,
  sendToVerification,
  loadCurrencyDetails,
  loadTransactionsByUser,
  walletWithdraw,
  checkTransactionByHash,
  loadWithdrawRequestsByUser,
  cancelWithdrawRequestByUser,
  loadTraders,
  createInvestment,
  loadInvestmentsForUser,
  createClosingInvestmentRequest,
  changeInvestmentAutoReinvest,
  loadInvestorPercent,
  addToFavorite,
  deleteFromFavorite,
  loadInvestmentProfits,
  loadTraderProfitHistory,
  loadTraderProfitHistoryTimeRange,
  loadUserInvestorProfitInfo,
  loadTraderInfo,
  calculateProfit,
  loadTraderMonthlyProfitHistory,
  loadTradersProfits,
  changeTraderStatus,
  loadTraderOpenInvestments,
  loadTraderClosedInvestments,
  loadOpenInvestmentsTimeRange,
  loadClosedInvestmentsTimeRange,
  loadTraderStatistic,
  changePersonalInfo,
  changeEmail,
  loadTraderManager,
  getAllNews,
  getNews,
  getAllChangelogs,
  getChangelog,
  getAllEvents,
  getEvent,
  getOrderTimeRange,
  getHistoryOrderTimeRange,
  getNewsManager,
  getTransactionsTimeRange,
  getUserIp,
  getTheMostPopularNews,
  getNotifications,
  readNotification,
  getTraderManagerCode,
  getNotificationsNumber,
  deleteAvatar,
}
