<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-invest" v-show="!getLoading">
        <button @click="close" class="popup__close"></button>
        <InvestTrader ref="step1"
                      :minInvestmentSum="minInvestmentSum"
                      :traderId="traderId"
                      :name="name"
                      :surname="surname"
                      :avatar="avatar"
                      v-show="currentStep === 1"
                      @next="currentStep = 2"/>
        <InvestConfirm ref="step2" :step1="$refs.step1" v-show="currentStep === 2"/>
        <InvestError v-if="currentStep === 3"/>
        <InvestSuccess v-if="currentStep === 4"/>

        <div class="stepper-footer" v-show="currentStep !== 1 && currentStep !== 4">
          <div class="stepper-footer__control">
            <button
                class="page-btn page-btn--border"
                @click="currentStep = currentStep - 1"
            >
              {{$t("backBtn")}}
            </button>
            <button
                v-focus
                v-show="currentStep === 2"
                class="page-btn"
                @click="createInvestment"
            >
              {{$t("confirmBtn")}}
            </button>
            <button
                v-focus
                v-show="currentStep === 3"
                class="page-btn"
                @click="currentStep = currentStep + 1"
            >
              {{$t("menuDeposit")}}
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import InvestTrader from "./InvestTrader";
import InvestConfirm from "./InvestConfirm";
import InvestError from "./InvestError";
import InvestSuccess from "./InvestSuccess";
import api from "@/api/api";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "InvestModal",
  props: {
    minInvestmentSum: {
      type: Number,
      required: true
    },
    traderId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    surname: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      required: true
    }
  },
  data: () => ({
    currentStep: 1
  }),
  computed: {
    ...mapGetters({
      getLoading: 'general/getLoading'
    })
  },
  methods: {
    ...mapMutations({
      setError: 'general/setError',
      setLoading: 'general/setLoading',
    }),
    async createInvestment() {
      try {
        this.setLoading(true);
        this.$refs.step2.errorPassword = '';
        await api.createInvestment({
          amount: this.$refs.step1.amount,
          autoReinvest: false,
          currency: 'USDT',
          duration: this.$refs.step1.duration,
          traderId: this.traderId
        }, this.$refs.step2.password)
        this.currentStep = 4;
        this.$emit('investmentCreated');
      } catch (e) {
        switch (e.response.data.status) {
          case 5001: this.$refs.step2.errorPassword = e.response.data.message;break;
          case 0: this.currentStep = 3;break;
          default: this.setError({
            message: e.response.data.message
          });
        }
      } finally {
        this.setLoading(false);
      }
    },
    close() {
      this.$emit('close')
    },
  },
  components: {
    InvestTrader,
    InvestConfirm,
    InvestError,
    InvestSuccess,
  },
}
</script>