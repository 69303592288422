<template>
  <div class="trader-info">
    <div class="box trader-box">
      <div class="trader-box__title">
       <span class="desktop"> {{$t("traderInfoTitle")}} </span>
       <span class="mobile"> {{$t("traderInfoTitleMob")}} </span>
      </div>

      <div class="trader-info__row">
        <div class="trader-info__col">
          <div class="trader-box__title-sm">
            {{$t("traderInfoPassport")}}
          </div>

          <div class="trader-info__value">
            <span v-if="passportVerified">{{$t("traderInfoChecked")}}</span>
            <span v-else>{{$t("traderInfoNotChecked")}}</span>
            <svg v-if="passportVerified" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.5" width="12" height="12" rx="6" fill="#0363FE"/>
              <path d="M5.06695 9.50985L2.80029 7.24319L3.59929 6.4442L5.06695 7.90619L8.80126 4.17188L9.60026 4.97654L5.06695 9.50985Z" fill="#FCFCFC"/>
            </svg>

          </div>
        </div>

        <div class="trader-info__col">
          <div class="trader-box__title-sm">
            <span>{{$t("traderInfoCompany")}}</span>

          </div>

          <div class="trader-info__value">
            <span v-if="companyVerified">{{$t("traderInfoChecked2")}}</span>
            <span v-else>{{$t("traderInfoNotChecked2")}}</span>
            <svg v-if="companyVerified" width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.5" width="12" height="12" rx="6" fill="#0363FE"/>
              <path d="M5.06695 9.50985L2.80029 7.24319L3.59929 6.4442L5.06695 7.90619L8.80126 4.17188L9.60026 4.97654L5.06695 9.50985Z" fill="#FCFCFC"/>
            </svg>

          </div>
        </div>
      </div>
      <div class="trader-info__row">
        <div class="trader-info__col">
          <div class="trader-box__title-sm" v-if="traderSite">
            {{$t("traderInfoSite")}}
          </div>

          <a :href="traderSite" target="_blank" class="trader-info__value">
            {{traderSite}}
          </a>
        </div>

        <div class="trader-info__col">
          <div class="trader-box__title-sm" v-if="traderTwitter || traderFacebook || traderTelegram">
            {{$t("traderInfoSocial")}}

          </div>

          <div class="trader-info__value">
            <div class="trader-info__social" v-if="traderTelegram">
              <img src="@/assets/img/svg/logo-telegram.svg" alt="">
              <a :href="traderTelegram" class="cover-link"></a>
            </div>

            <div class="trader-info__social" v-if="traderFacebook">
              <img src="@/assets/img/svg/logo-facebook.svg" alt="">
              <a :href="traderFacebook" class="cover-link"></a>
            </div>

            <div class="trader-info__social" v-if="traderTwitter">
              <img src="@/assets/img/svg/logo-twitter.svg" alt="">
              <a :href="traderTwitter" class="cover-link"></a>
            </div>

<!--            <div class="trader-info__social">-->
<!--              <img src="@/assets/img/svg/logo-cloud.svg" alt="">-->
<!--              <router-link to="/" class="cover-link"></router-link>-->
<!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TraderInfo',
    props: {
      companyVerified: {
        type: Boolean,
        required: true,
      },
      passportVerified: {
        type: Boolean,
        required: true
      },
      traderSite: {
        type: Boolean,
      },
      traderFacebook: {
        type: Boolean,
      },
      traderTelegram: {
        type: Boolean,
      },
      traderTwitter: {
        type: Boolean,
      }
    }
  }
</script>
