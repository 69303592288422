<template>
  <div class="user-page" v-if="trader ">
    <h1 class="page-title">{{ $t("traderPageTitle") }}</h1>
    <div class="user-page__col-1">
      <UserInfoCard :trader="trader"/>
      <IncomeStatistic :averageMonthlyProfit="averageMonthlyProfit"
                       :bestMonthProfitPercentage="bestMonthProfitPercentage"
                       :traderTotalEarningPercentage="traderTotalEarningPercentage"/>
    </div>

    <div class="user-page__col-2">
      <div class="user-page__row">
        <div class="user-page__col-sm">
          <DealCorrelation :winningDeals="winningDeals" :loosingDeals="loosingDeals"/>
        </div>
        <div class="user-page__col-sm">
          <TraderInfo
              :traderFacebook="traderFacebook"
              :traderSite="traderSite"
              :traderTelegram="traderTelegram"
              :traderTwitter="traderTwitter"
              :passportVerified="passportVerified"
              :companyVerified="companyVerified" />
        </div>
      </div>

      <div class="user-page__row">
        <div class="user-page__col-sm">
          <IncomeCalculator :traderId="traderId"/>
        </div>
        <div class="user-page__col-sm">
          <StrategyDescription :description="traderDescription"/>
        </div>
      </div>

      <div class="user-page__row">
        <div class="user-page__wrap">
          <div class="user-page__title">
            {{ $t("traderStatsTableTitle") }}

            <div class="tooltip" @mouseover="showStatsTooltip = true" @mouseleave="showStatsTooltip = false">
              <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                    fill="#0363FE"/>
              </svg>

              <div class="tooltip__content" v-show="showStatsTooltip">
                {{ $t("tooltipText") }}
              </div>
            </div>
          </div>

          <IncomeStatisticDesktopTable :profits="profitsHistory" />
          <IncomeStatisticMobileTable :profits="profitsHistory" />
          <Pagination :pages-qt="profitsHistoryTotal" v-if="profitsHistoryTotal > 1" @change="toggleProfitsHistory"/>
        </div>
      </div>

      <div class="user-page__row">
        <div class="user-page__wrap">
          <div class="user-page__head">
            <div class="user-page__title">
              {{ $t("traderGraphTitle") }}

              <div class="tooltip" @mouseover="showGraphTooltip = true" @mouseleave="showGraphTooltip = false">
                <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                      fill="#0363FE"/>
                </svg>

                <div class="tooltip__content" v-show="showGraphTooltip">
                  {{ $t("tooltipText") }}
                </div>
              </div>
            </div>

            <div class="toggle">
              <button class="toggle__btn"
                      :class="{active: measurement === 'PERCENT'}"
                      @click="setMeasurement('PERCENT')"
              >%
              </button>
              <button class="toggle__btn"
                      :class="{active: measurement === 'CURRENCY'}"
                      @click="setMeasurement('CURRENCY')"
              >$
              </button>
            </div>
          </div>
          <div class="trader-chart">
            <InvestmentChart :profits="profits" v-if="profits.length"/>
            <NoRecordsFound v-if="!profits.length && !getLoading"/>
          </div>
        </div>
      </div>

      <div class="user-page__row">
        <div class="user-page__wrap">

          <div class="trader-filter">
            <div class="trader-filter__wrap">
              <div class="user-page__title">
                {{$t("traderHistoryTitle")}}

                <div class="tooltip"  @mouseover="showHistoryTooltip = true"  @mouseleave="showHistoryTooltip = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showHistoryTooltip">
                    {{ $t("tooltipText")}}
                  </div>
                </div>
              </div>

              <div class="trader-filter__caption" v-if="startDateCopy">
                {{$t("traderFilterPeriod")}} {{startDateCopy | timestampToDatePipe}} — {{endDateCopy | timestampToDatePipe}}
              </div>
            </div>

            <DateRangeFilter v-if="startDate && endDate"
                             @change="setTimeRange"
                             :startDate="startDate"
                             :endDate="endDate"/>
          </div>


          <TradeHistoryDesktopTable :history="history"/>
          <TradeHistoryMobileTable :history="history"/>
          <NoRecordsFound v-if="!history.length && !getLoading"/>
          <Pagination :pages-qt="total" v-if="total > 1" @change="toggleHistoryPage"/>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import UserInfoCard from "../../components/traderCabinet/UserInfoCard";
import DealCorrelation from "../../components/traderCabinet/DealCorrelation";
import TraderInfo from "../../components/traderProfile/TraderInfo";
import IncomeCalculator from "../../components/traderProfile/IncomeCalculator";
import StrategyDescription from "../../components/traderCabinet/StrategyDescription";
import IncomeStatistic from "../../components/traderProfile/IncomeStatistic";
import IncomeStatisticDesktopTable from "../../components/traderProfile/IncomeStatisticDesktopTable";
import IncomeStatisticMobileTable from "../../components/traderProfile/IncomeStatisticMobileTable";
import TradeHistoryDesktopTable from "../../components/traderCabinet/TradeHistoryDesktopTable";
import TradeHistoryMobileTable from "../../components/traderCabinet/TradeHistoryMobileTable";
import Pagination from "../../components/utility/Pagination";
import api from "@/api/api";
import DateRangeFilter from "@/components/utility/DateRangeFilter";
import {mapGetters} from "vuex";
import InvestmentChart from "@/components/charts/InvestmentChart";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import loadTraderProfitHistoryTimeRangeMixin from "@/mixins/loadTraderProfitHistoryTimeRangeMixin";
import loadTraderProfitHistoryMixin from "@/mixins/loadTraderProfitHistoryMixin";
import loadTraderInfoMixin from "@/mixins/loadTraderInfoMixin";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import {traderInfoMixin} from "@/mixins/traderInfoMixin";
import loadTraderMonthlyProfitHistory from "@/mixins/loadTraderMonthlyProfitHistoryMixin";
import {timestampToDateMixin} from "@/utils/mixins";

export default {
  name: 'User',
  mixins: [
      loadTraderProfitHistoryTimeRangeMixin,
      loadTraderProfitHistoryMixin,
      loadTraderInfoMixin,
      setLoadingMixin,
      traderInfoMixin,
      loadTraderMonthlyProfitHistory,
      timestampToDateMixin
  ],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showHistoryTooltip: false,
    showStatsTooltip: false,
    showGraphTooltip: false,
    measurement: 'PERCENT',
    profits: [],

  }),
  computed: {
    ...mapGetters({
      getLoading: 'general/getLoading'
    }),
    traderDescription() {
      return this.trader.traderDescription.description;
    },
  },
  methods: {
    async setTimeRange(val) {
      this.setLoading(true);
      this.page = 0;
      this.startDate = val.start.getTime();
      this.endDate = val.end.getTime();
      await this.loadTraderProfitHistory();
      this.setLoading(false);
    },
    async loadTradersProfits() {
      try {
        this.profits = await api.loadTradersProfits({
          measurement: this.measurement,
          day: Date.now(),
          traderId: this.id,
        })
      } catch (e) {
        console.log(e);
      }
    },

  },
  async mounted() {
    this.setLoading(true);
    await this.loadTraderProfitHistoryTimeRange();
    await Promise.all([
      this.loadTraderMonthlyProfitHistory(),
      this.loadTraderProfitHistory(),
      this.loadTradersProfits(),
      this.loadTraderInfo()
    ]);
    this.setLoading(false);

  },
  components: {
    NoRecordsFound,
    Pagination,
    TradeHistoryMobileTable,
    TradeHistoryDesktopTable,
    IncomeStatisticMobileTable,
    IncomeStatisticDesktopTable,
    IncomeStatistic, StrategyDescription, IncomeCalculator, TraderInfo, DealCorrelation, UserInfoCard, DateRangeFilter, InvestmentChart
  }
}
</script>
