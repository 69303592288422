export default {
    methods: {
        loadNumberClass(number) {
            if(number > 0) {
                return 'primary';
            }
            if(number < 0) {
                return 'danger';
            }
            if(number === 0) {
                return ''
            }
        }
    }
}