import { render, staticRenderFns } from "./BuyMarket.vue?vue&type=template&id=5e0899e4&"
import script from "./BuyMarket.vue?vue&type=script&lang=js&"
export * from "./BuyMarket.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports