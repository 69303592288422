<template>
  <div class="page-news__content page-news__changelog">
    <div class="changelog-banner" v-if="currentLog">
      <div class="changelog-banner__img">

        <div class="changelog__notice">
          changelog
        </div>

        <img :src="currentLog.imageUrl" alt="">

        <div class="changelog-banner__logo">
          <img src="@/assets/img/svg/logo-sm-white.svg" alt="">
        </div>
      </div>

        <div class="changelog-banner__content">
          <div class="changelog__subtitle">
            changelog
          </div>

          <div class="changelog-banner__title">
            {{currentLog.header}}
          </div>

          <div class="changelog__text">
            <span v-html="truncate(currentLog.changelogDescription, 450)"></span>
          </div>

          <div class="changelog__date">
            <img src="@/assets/img/svg/icon-calendar.svg" alt="">
            {{currentLog.created | timestampToDatePipe}}
          </div>
        </div>
      </div>

    <div class="changelog-list">
      <div class="changelog" v-for="(item, index) in filteredLogs" :key="index">
        <div class="changelog__img">

          <img :src="item.imageUrl" alt="">

          <div class="changelog__notice">
            changelog
          </div>
          <div class="changelog__logo">
            <img src="@/assets/img/svg/logo-sm-white.svg" alt="">
          </div>

        </div>



        <div class="changelog__content">
          <div class="changelog__subtitle">
            changelog
          </div>

          <div class="changelog__title" @click="getChangelogById(item.id)">
            {{item.header}}
          </div>

          <div class="changelog__text">
            <span v-html="truncate(item.changelogDescription, 110)"></span>
          </div>

          <div class="changelog__date">
            <img src="@/assets/img/svg/icon-calendar.svg" alt="">

            {{item.created | timestampToDatePipe }}
          </div>
        </div>
      </div>
    </div>
    <NoRecordsFound v-if="!logs.length && !getLoading"/>
    <Pagination v-if="total > 1" :pagesQt="total" @change="togglePage"/>
  </div>
</template>

<script>
import api from "@/api/api";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import {timestampToDateMixin, truncateMixin} from "@/utils/mixins";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import getLoadingMixin from "@/mixins/getLoadingMixin";
import Pagination from "@/components/utility/Pagination";

export default {
  name: "TabChangelog",
  components: {Pagination, NoRecordsFound},
  mixins: [setLoadingMixin, timestampToDateMixin, getLoadingMixin, truncateMixin],
  data: () => ({
    logs: [],
    page: 1,
    size: 7,
    total: 0,
    currentLog: null,
    currentLogId: null,
    firstLoading: true,
  }),
  computed: {
    filteredLogs() {
      let logs = this.logs;
      return logs.filter( i => i.id !== this.currentLogId);
    },
  },
  methods: {
    async togglePage(page) {
      this.setLoading(true);
      this.page = page;
      await this.getAllChangelogs();
      this.setLoading(false);
    },
    async getChangelogById(id) {
      this.setLoading(true);
      this.currentLogId = id;
      await this.getChangelog();
      this.setLoading(false);
    },
    async getAllChangelogs() {
      try {
        const response = await api.getAllChangelogs({
          page: this.page - 1,
          size: this.size,
          sort: "created,desc",
        });
        this.logs = response.content;
        this.total = response.totalPages;
        if(this.logs.length && this.firstLoading) {
          this.currentLogId = this.logs[0].id;
          this.firstLoading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getChangelog() {
      try {
        this.currentLog = await api.getChangelog({
          changelogId: this.currentLogId,
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getAllChangelogs();
    if(this.currentLogId) {
      await this.getChangelog();
    }
    this.setLoading(false);
  }
}
</script>