export default {
  namespaced: true,
  state: {
    pair: localStorage.getItem('pair') ? JSON.parse(localStorage.getItem('pair')) : {
      pair: 'ETH-USDT',
      index: 2,
    }
  },
  mutations: {
    setPair(state, pair) {
      localStorage.setItem('pair', JSON.stringify(pair));
      state.pair = pair;
    }
  },
  getters: {
    getPairIndex: state => state.pair.index,
    getPairName: state => state.pair.pair
  }
}
