<template>
  <div class="mobile-table mobile-table--transactions">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        <button class="table-filter">
          {{ $t("transactionsTableDate") }}
        </button></p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("transactionsTableCoin") }}
        </button></p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("transactionsTableSum") }}
        </button></p>
      <p class="mobile-table__title">{{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item"
         v-show="transactions.length"
         v-for="(item, index) in transactions" :key="index">

      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">
          <div class="mobile-table-item__col">
            <p class="custom-table__date"><span> {{ new Date(item.created).toLocaleDateString("en-GB")}}</span>
              {{new Date(item.created).toLocaleTimeString('en-US', {hour12: false})}}</p>
          </div>

          <div class="mobile-table-item__col">
            <div class="custom-table__coins">
              <div class="coin-bg">
                <img :src="loadImage(item.currency, false)" alt="" class="coin-bg">
              </div>

              <div class="coin-name">{{item.currency}}</div>
            </div>
          </div>

          <div class="mobile-table-item__col">
            <p class="primary" v-if="item.type === 'DEPOSIT'">+{{formatNumber(item.amount)}}</p>
            <p class="danger" v-else>-{{formatNumber(item.amount)}}</p>
          </div>
        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title"> {{ $t("transactionsTableTx") }}</p>
            </div>

            <div class="mobile-table-item__col">
             <p>{{formatNumber(item.fee)}}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("transactionsTableSum") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p class="primary" v-if="item.type === 'DEPOSIT'">+{{formatNumber(item.amount)}}</p>
              <p class="danger" v-else>-{{formatNumber(item.amount)}}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("transactionsTableType") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p v-if="item.type === 'DEPOSIT'">{{ $t("deposit") }}</p>
              <p v-else>{{ $t("withdraw-analog") }}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("transactionsTableStatus") }}</p>
            </div>

            <div class="mobile-table-item__col">
<!--              <p class="primary">{{ $t("transactionStatusActive")}}</p>-->
              <p class="danger" v-if="false">{{ $t("transactionStatusDecline")}}</p>
              <p class="neutral" v-if="false">{{ $t("transactionStatusWaiting")}}</p>
              <p style="color: #5C93E5" v-if="item.status === 'SUCCESS'" >{{ $t("transactionStatusDone")}}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title"> {{ $t("transactionsTableHash") }}</p>
              <div class="tooltip"  @mouseover="showTooltip = true"  @mouseleave="showTooltip = false">
                <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
                </svg>

                <div class="tooltip__content" v-show="showTooltip">
                  {{ $t("tooltipText")}}
                </div>
              </div>
            </div>

            <div class="mobile-table-item__col">
              <a :href="generateExplorerLink(true, item.network, item.hash, item.currency, 'tx')"
                 target="_blank"
                 class="hash-link">
                {{item.hash | cutHash}}
              </a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {cutHashMixin, formatNumberMixin, generateExplorerLinkMixin, loadImageMixin} from "@/utils/mixins";

export default {
  name: 'TransactionsHistoryMobileTable',
  mixins: [formatNumberMixin, loadImageMixin, generateExplorerLinkMixin, cutHashMixin],
  props: {
    transactions: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showTooltip: false
  })
}
</script>
