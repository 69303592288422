<template>
  <div class="trader-calc">
    <div class="box trader-box">
      <div class="trader-box__title">
        {{$t("traderCalcTitle")}}
      </div>

      <div class="trader-calc__row">
        <div class="trader-calc__col">
          <div class="trader-box__title-sm">
            {{$t("traderCalcDate")}}
          </div>

          <div class="trader-calc__value">
            {{date | timestampToDatePipe}}

            <DatePickerItem ref="datePeckerItem" type="DEFAULT" @change="setDate"/>
          </div>
        </div>

        <div class="page-input">
          <input type="number" class="input" :placeholder="$t('traderCalcInput')" v-model="amount">

          <div class="page-input__icon">
            USDT
          </div>
        </div>
      </div>

        <div class="trader-calc__row">
          <div class="trader-calc__col">
          <div class="trader-calc__text">
            {{$t("traderCalcBalance")}}

            <span> ({{$t("traderCalcDays")}} {{ difference }} {{$t("traderCalcDays2")}})</span>
          </div>


        </div>
          <div class="trader-calc__amount">
            {{formatNumber(forecast)}} USDT
          </div>
        </div>

        <div class="trader-calc__info">
          <div class="trader-calc__icon">
            <img src="@/assets/img/svg/info-icon.svg" alt="">
          </div>

          <p>
            {{$t("traderCalcInfo")}} <br>
            <span> {{$t("traderCalcInfoText")}}</span>
          </p>
        </div>
      </div>

  </div>
</template>

<script>
import debounceMixin from "@/mixins/debounceMixin";
import api from "@/api/api";
import {formatNumberMixin, timestampToDateMixin} from "@/utils/mixins";
import DatePickerItem from "@/components/utility/DatePicker";

export default {
    name: 'IncomeCalculator',
    mixins: [debounceMixin, timestampToDateMixin, formatNumberMixin],
    props: {
      traderId: {
        type: String,
        required: true
      }
    },
    data: () => ({
      debouncedLoadProfit: null,
      amount: null,
      forecast: 0,
      date: null,
      difference: 0,
    }),
    watch: {
      amount(val) {
        this.debouncedLoadProfit();
        if(!val) {
          this.calculateProfit();
        }
      },
    },
    methods: {
      setDate(val) {
        this.date = Date.parse(val);
        this.difference = Math.ceil(Math.abs(new Date(val) - new Date(Date.now())) / (1000 * 60 * 60 * 24));
      },
      async calculateProfit() {
        try {
          this.forecast = await api.calculateProfit({
            date: Date.now(),
            investmentSum: this.amount,
            traderId: this.traderId
          })
        } catch (e) {
          this.forecast = 0;
        }
      }
    },
    mounted() {
      this.debouncedLoadProfit = this.debounce(this.calculateProfit, 10);
    },
    components: {
      DatePickerItem,

    }
  }
</script>
