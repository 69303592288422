<template>
  <div class="page404 page505">
    <div class="container">
      <div class="page404__wrap">
        <div class="page404__title-big">
          505
        </div>
        <div class="page404__title">
          {{ $t("page505Title") }}
        </div>

        <div class="page404__text">
          {{ $t("page505Text") }}
        </div>

        <router-link to="/" class="page-btn">{{ $t("page404Btn") }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServerError'
}
</script>
