<template>
  <div class="page-news__content page-news__events">
    <div class="event" v-for="(item, index) in events" :class="{disabled: isOver(item.date)}" :key="index">
      <div class="event__img">
        <img :src="item.imageUrl" alt="">

        <div class="news-item__plash" :class="{event: isEvent(item.eventType), online: isOnline(item.eventType)}">
          <span v-show="isEvent(item.eventType)">{{$t('newsEvent')}}</span>
          <span v-show="isOnline(item.eventType)">Online</span>
        </div>
      </div>

      <div class="event__content">
        <div class="news-item__mark" :class="{primary: isNearest(item.date), danger: isOver(item.date)}">
          <span v-show="isNearest(item.date)">{{$t('newsNearest')}}</span>
          <span v-show="isOver(item.date)">{{$t('newsOver')}}</span>
        </div>

        <div class="news-item__speakers">
          <div class="news-item__wrap">
            <img src="@/assets/img/svg/icon-speakers.svg" alt="">
            {{$t('newsSpeakers')}}
          </div>

          <p>
            {{item.speakers}}
          </p>

        </div>

        <div class="event__title">
          {{item.header}}
        </div>

        <div class="event__text">
          <span v-html="truncate(item.eventDescription, 550)"></span>
        </div>

          <div class="event__bottom">
            <div class="event__wrap">
              <div class="event__title-sm">
                <span v-show="isEvent(item.eventType)">{{$t('eventWeb')}}</span>
                <span v-show="isEvent(item.eventType)">{{$t('eventPlace')}}</span>
              </div>

              <div class="news-item__place" v-show="isEvent(item.eventType)">
                <img src="@/assets/img/svg/icon-location.svg" alt="">

                {{item.location}}
              </div>

              <div class="news-item__web" v-show="isOnline(item.eventType)">
                <img src="@/assets/img/svg/icon-vebinar.svg" alt="">

                <a :href="item.link" target="_blank" class="news-item__link">
                  {{item.link}}
                </a>
              </div>
            </div>


              <div class="item-calendar">
                <div class="item-calendar__icon">
                  <img src="@/assets/img/svg/icon-calendar.svg" alt="">
                  {{ eventDate(item.date) }}
                </div>

                <div class="item-calendar__time">
                  {{eventTime(item.date)}} Мск
                </div>

            </div>
          </div>
      </div>
    </div>
    <NoRecordsFound v-if="!events.length && !getLoading"/>
    <Pagination :pagesQt="total" v-if="total > 1" @change="togglePage"/>
  </div>
</template>

<script>
import setLoadingMixin from "@/mixins/setLoadingMixin";
import api from "@/api/api";
import {truncateMixin} from "@/utils/mixins";
import eventIdentifierMixin from "@/mixins/eventIdentifierMixin";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import getLoadingMixin from "@/mixins/getLoadingMixin";
import Pagination from "@/components/utility/Pagination";

export default {
  name: "TabEvents",
  components: {Pagination, NoRecordsFound},
  mixins: [setLoadingMixin, truncateMixin, eventIdentifierMixin, getLoadingMixin],
  data: () => ({
    events: [],
    page: 1,
    size: 10,
    total: 0,
  }),
  methods: {
    async togglePage(page) {
      this.setLoading(true);
      this.page = page;
      await this.getAllEvents();
      this.setLoading(false);
    },
    async getAllEvents() {
      try {
        this.setLoading(true);
        const response = await api.getAllEvents({
          page: this.page - 1,
          size: this.size,
          sort: "created,desc",
        });
        this.events = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      } finally {
        this.setLoading(false);
      }
    },
  },
  mounted() {
    this.getAllEvents();
  },
}
</script>

<style scoped>

</style>