<template>
  <div class="news-list">
    <div class="news-list__title">
      {{ $t('newsListTitle') }}
    </div>

    <div class="news-list__wrap">
      <NewsEventsItem v-for="(item, index) in events" :key="index"
                      :item-title="item.header"
                      :link="item.link"
                      :image="item.imageUrl"
                      :date="item.date"
                      :speakers="item.speakers"/>
    </div>

    <button class="news-list__btn" @click="showAllEvents">
      <span>{{ $t('newsListBtn') }}</span>

      <div class="news-list__icon">
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M10.625 2.375H13.125C13.2908 2.375 13.4497 2.44085 13.5669 2.55806C13.6842 2.67527 13.75 2.83424 13.75 3V13C13.75 13.1658 13.6842 13.3247 13.5669 13.4419C13.4497 13.5592 13.2908 13.625 13.125 13.625H1.875C1.70924 13.625 1.55027 13.5592 1.43306 13.4419C1.31585 13.3247 1.25 13.1658 1.25 13V3C1.25 2.83424 1.31585 2.67527 1.43306 2.55806C1.55027 2.44085 1.70924 2.375 1.875 2.375H4.375V1.125H5.625V2.375H9.375V1.125H10.625V2.375ZM12.5 7.375H2.5V12.375H12.5V7.375ZM9.375 3.625H5.625V4.875H4.375V3.625H2.5V6.125H12.5V3.625H10.625V4.875H9.375V3.625ZM3.75 8.625H5V9.875H3.75V8.625ZM6.875 8.625H8.125V9.875H6.875V8.625ZM10 8.625H11.25V9.875H10V8.625Z"
              fill="#FCFCFC"/>
        </svg>

      </div>
    </button>
  </div>
</template>

<script>
import NewsEventsItem from "./newsEventsList/NewsEventsItem";

export default {
  name: 'NewsEventsList',
  props: {
    events: {
      type: Array,
      required: true,
    }
  },
  methods: {
    showAllEvents() {
      this.$emit('showAllEvents');
    }
  },
  components: {NewsEventsItem}
}
</script>
