<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup twoFa-popup">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="twoFa-popup__head">
            <div class="twoFa-popup__img">
            <img src="@/assets/img/2fa-img.png" alt="" >
            </div>

            <div class="twoFa-popup__title">
              {{$t("twoFaModalTitle")}}
              <span>(2FA)</span>
            </div>
          </div>

          <div class="popup__text">
            {{$t("twoFaModalText1")}} <span>ConeCoin</span>
            {{$t("twoFaModalText2")}}
          </div>

          <div class="popup__wrap">
            <button v-if="!getIsTwoFaEnabled" class="twoFa-popup__btn" @click="isTwoFaGoogleModal = true">
              <span class="twoFa-popup__icon">
                <img src="@/assets/img/2fa-google.png" alt="">
              </span>
              <span class="twoFa-popup__btn-text">{{$t("twoFaModalGoogleBtn")}}</span>
            </button>

            <button v-else class="twoFa-popup__btn" @click="isTwoFaGoogleDisable = true">
              <span class="twoFa-popup__icon">
                <img src="@/assets/img/2fa-google.png" alt="">
              </span>
              <span class="twoFa-popup__btn-text">{{$t("disableTwoFaModalGoogleBtn")}}</span>
            </button>

            <button class="twoFa-popup__btn" @click="isTwoFaEmailModal = true">
              <span class="twoFa-popup__icon">
                <img src="@/assets/img/2fa-email.png" alt="">
              </span>

              <span class="twoFa-popup__btn-text">{{$t("twoFaModalEmailBtn")}}</span>
            </button>
          </div>

          <div class="popup__footer">
            <button class="popup__close-link" @click="close">
              {{$t("twoFaModalLater")}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <TwoFaGoogleEnable v-if="isTwoFaGoogleModal" @close="isTwoFaGoogleModal = false"/>
    <TwoFaGoogleDisable v-if="isTwoFaGoogleDisable" @close="isTwoFaGoogleDisable = false"/>
    <TwoFaEmailEnable v-if="isTwoFaEmailModal" @close="isTwoFaEmailModal = false"/>
  </div>
</template>

<script>
import TwoFaGoogleEnable from "./TwoFaGoogleEnable";
import TwoFaGoogleDisable from "./TwoFaGoogleDisable";
import TwoFaEmailEnable from "./TwoFaEmailEnable";
import {mapGetters} from "vuex";

export default {
  name: "TwoFaModal",
  components: {
    TwoFaGoogleEnable,
    TwoFaGoogleDisable,
    TwoFaEmailEnable
  },
  data() {
    return {
      isTwoFaGoogleModal: false,
      isTwoFaGoogleDisable: false,
      isTwoFaEmailModal: false,
    }
  },
  computed: {
    ...mapGetters({
      getIsTwoFaEnabled: 'user/getIsTwoFaEnabled'
    })
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
