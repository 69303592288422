<template>
  <div class="orders-page">
    <OrdersFilter :startDate="startDate" :endDate="endDate" @selectTab="selectTab"
                  @makeInput="makeInput"
                  @setTimeRange="setTimeRange" ref="filter" v-show="!getLoading"/>
    <div class="orders-page__content">
      <OrdersMobileTable :orders="orders"
                         :showCloseBtn="showCloseBtn"
                          @deleteOrderByUser="deleteOrderByUser"/>
      <OrdersDesktopTable :orders="orders"
                          :showCloseBtn="showCloseBtn"
                          @deleteOrderByUser="deleteOrderByUser"/>

      <Pagination ref="paginator" v-if="total > 1" :pagesQt="total" @change="togglePage"/>
      <NoRecordsFound v-if="!orders.length && !getLoading"/>
    </div>


  </div>
</template>

<script>
import OrdersFilter from "../../components/orders/OrdersFilter";
import OrdersDesktopTable from "../../components/orders/OrdersDesktopTable";
import OrdersMobileTable from "../../components/orders/OrdersMobileTable";
import api from "../../api/api";
import Pagination from "../../components/utility/Pagination";
import {mapGetters, mapMutations} from "vuex";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import debounceMixin from "@/mixins/debounceMixin";
export default {
  name: 'Orders',
  mixins: [debounceMixin],
  data: () => ({
    debouncedLoadHistoryOrders: null,
    debouncedLoadOpenOrders: null,
    ordersTab: 'open',
    page: 0,
    size: 10,
    orders: [],
    total: 0,
    startDate: null,
    startDateCopy: null,
    endDate: null,
    endDateCopy: null,
    search: '',
  }),
  computed: {
    ...mapGetters({
      getLoading: 'general/getLoading'
    }),
    showCloseBtn() {
      return this.ordersTab !== 'history';
    }
  },
  methods: {
    ...mapMutations({
      setError: 'general/setError',
      setSuccess: 'general/setSuccess',
      setLoading: 'general/setLoading'
    }),
    makeInput(val) {
      this.search = val;
      switch (this.ordersTab) {
        case "open":
          this.debouncedLoadOpenOrders();
          if(!val) {
            this.loadOpenTrades();
          }
          break;
        case "history":
          this.debouncedLoadHistoryOrders();
          if(!val) {
            this.loadHistoryTrades();
          }
      }
    },
    async setTimeRange(val) {
      this.setLoading(true);
      this.page = 0;
      this.startDate = val.start.getTime();
      this.endDate = val.end.getTime();
      switch (this.ordersTab) {
        case 'open':
          await this.loadOpenTrades();break;
        case 'history':
          await this.loadHistoryTrades();break;
      }
      this.setLoading(false);
    },
    async getOrderTimeRange() {
      try {
        const response = await api.getOrderTimeRange();
        this.startDate = this.startDateCopy = response.timeOfTheFirstOrder;
        this.endDate = this.endDateCopy = response.timeOfTheLastOrder;
      } catch (e) {
        console.log(e);
      }
    },
    async getHistoryOrderTimeRange() {
      try {
        const response = await api.getHistoryOrderTimeRange();
        this.startDate = this.startDateCopy = response.timeOfTheFirstOrder;
        this.endDate = this.endDateCopy = response.timeOfTheLastOrder;
      } catch (e) {
        console.log(e);
      }
    },
    togglePage(page) {
      this.page = page - 1;
      this.selectTab();
    },
    async selectTab(tab) {
      this.setLoading(true);
      if(tab) {
        this.ordersTab = tab;
        this.page = 0;
        this.orders = [];
      }
      switch (this.ordersTab) {
        case 'open':
          await this.getOrderTimeRange();
          await this.loadOpenTrades();break;
        case 'history':
          await this.getHistoryOrderTimeRange();
          await this.loadHistoryTrades();break;
      }
      this.setLoading(false);
    },
    async deleteOrderByUser(orderId) {
      this.setLoading(true);
      try {
        await api.deleteOrderByUser({
          orderId
        })
        await this.loadOpenTrades();
      } catch (e) {
        console.log(e);
      } finally {
        this.setLoading(false);
      }
    },
    async loadOpenTrades() {
      if(!this.startDate) {
        return;
      }
      try {
        const response = await api.loadTrades({
          page: this.page,
          size: this.size,
          sort: 'date,desc',
          keyword: this.search || null,
          startDate: this.startDate,
          endDate: this.endDate,
        });
        response.content.forEach(i => this.$set(i, 'isShow', false))
        this.orders = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async loadHistoryTrades() {
      if(!this.startDate) {
        return;
      }
      try {
        const response = await api.loadHistoryTrades({
          page: this.page,
          size: this.size,
          sort: 'date,desc',
          keyword: this.search || null,
          startDate: this.startDate,
          endDate: this.endDate
        });
        response.content.forEach(i => this.$set(i, 'isShow', false))
        this.orders = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      this.setLoading(true);
      await this.getOrderTimeRange();
      await this.loadOpenTrades();
      this.debouncedLoadOpenOrders = this.debounce(this.loadOpenTrades, 10);
      this.debouncedLoadHistoryOrders = this.debounce(this.loadHistoryTrades, 10);
      this.setLoading(false);
    }
  },
  async mounted() {
    await this.init()
  },
  components: {NoRecordsFound, Pagination, OrdersMobileTable, OrdersDesktopTable, OrdersFilter}
}
</script>
