<template>
  <div class="stats">
    <div class="stats__left">
    <div class="stat">
      <div class="stat__icon">
<!--        <img src="@/assets/img/svg/stats-eth.svg" alt="">-->
        <img :src="loadImage(getLeftPairPart, true)" alt="">
      </div>
      <div class="stat__wrap stat__wrap--adaptive">
        <div class="stat__name" @click="isStatsDropOpen = !isStatsDropOpen" >
         <span> {{leftPairPartFullName}} </span>
          <div class="stat__value--mobile">
            <span>{{ getLeftPairPart }}</span> <span style="font-size: 10px">/{{getRightPairPart}}</span>
          </div>

          <button class="stat-drop__icon"></button>
        </div>

        <div class="stat__value">
          {{getPairName.replace('-', '/')}}
        </div>

        <div class="stat-drop" v-show="isStatsDropOpen">
          <ul>
            <li class="stat-drop__item"
                v-for="(item,index) in pairs"
                :key="index"
                @click="selectPair(item.firstCurrency)">{{ item.firstCurrency }}</li>
          </ul>
        </div>
      </div>
    </div>

      <div class="stat__amount" v-if="getStatistic">
        <div class="stat__amount--big">{{Number(getStatistic.actualPrice.toFixed(2))}}</div>
        <div class="d-flex justify-content-between">
        <div class="stat__amount--sm">1 253.84</div>
        <div class="stat__amount--sm">+2.65%</div>
        </div>
      </div>
    </div>

    <div class="stats__right">
    <div class="stat" v-if="getStatistic">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-dollar.svg" alt="">
      </div>
      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("statsLastName") }}
        </div>

        <div class="stat__value">
         <span>{{formatNumber(getStatistic.actualPrice)}}</span>
        </div>
      </div>
    </div>

    <div class="stat" v-if="getStatistic">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-trade.svg" alt="">
      </div>
      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("statsChanges") }}
        </div>

        <div class="stat__value">
         <span>{{formatNumber(getStatistic.changePriceValue)}}</span>
          <span :class="`stat__percent stat__percent--${getStatistic.changePricePercent > 0 ? 'primary' : 'danger'}`">
            {{formatNumber(getStatistic.changePricePercent)}}%
          </span>
        </div>
      </div>
    </div>

    <div class="stat" v-if="getStatistic">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-withdraw.svg" alt="">
      </div>
      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("statsWithdraw") }}
        </div>

        <div class="stat__value">
         <span>{{formatNumber(getStatistic.highPrice)}}</span>
<!--          <span class="stat__percent stat__percent&#45;&#45;primary">-->
<!--          2.5%-->
<!--         </span>-->
        </div>
      </div>
    </div>

    <div class="stat" v-if="getStatistic">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-deposit.svg" alt="">
      </div>
      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("statsDeposit") }}
        </div>

        <div class="stat__value">
         <span>{{formatNumber(getStatistic.lowPrice)}}</span>
<!--          <span class="stat__percent stat__percent&#45;&#45;danger">-->
<!--            11.5%-->
<!--          </span>-->
        </div>
      </div>
    </div>

    <div class="stat" v-if="getStatistic">
      <div class="stat__icon">
        <img src="@/assets/img/svg/stats-value.svg" alt="">
      </div>
      <div class="stat__wrap">
        <div class="stat__name">
          {{ $t("statsValue") }}
        </div>

        <div class="stat__value">
         <span>{{formatNumber(getStatistic.basicVolume)}}</span>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "../../api/api";
import {formatNumberMixin, loadImageMixin, opsMixin} from "@/utils/mixins";

export default {
  name: 'Stats',
  mixins: [formatNumberMixin, opsMixin, loadImageMixin],
  props: {
    pairs: {
      type: Array,
      required: true
    },
    leftPairPartFullName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isStatsDropOpen: false
  }),
  computed: {
    ...mapGetters({
      getStatistic: 'socket/getStatistic',
      getPairName: 'pair/getPairName'
    }),
    getLeftPairPart() {
      return this.getPairName.split('-')[0];
    },
    getRightPairPart() {
      return this.getPairName.split('-')[1];
    }
  },
  methods: {
    ...mapMutations({
      setPair: 'pair/setPair'
    }),
    async selectPair(currency) {
      if(currency === this.getLeftPairPart) {
        return
      }
      const pair = await this.loadPairInfo(`${currency}-${this.getRightPairPart}`);
      this.leftPairPartFullName = pair.firstCurrencyFullName;
      this.setPair({
        pair: pair.pairName,
        index: pair.id
      })
    },
    async loadPairInfo(pair) {
      try {
        return await api.loadPairInfo({
          firstCurrency: pair.split('-')[0],
          quotedCurrency: pair.split('-')[1]
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  },
  async mounted() {
    document.body.addEventListener('click',(e)=>{
      if (!e.target.closest('.stat__name') ){
        this.isStatsDropOpen = false;
      }
    })
  }
}
</script>
