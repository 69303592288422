import Store from '@/store'
import api from "@/api/api";

const AuthGuard = async (to, from, next) => {
    if(Store.getters['user/getIsAuthorized']) {
        next({
            path: '/'
        })
    }
    else {
        if(to.query.token) {
            let token = to.query.token;
            try {
                await api.confirmRegistration({
                    token,
                    isPublic: true
                })
                next();
            } catch (e) {
                next()
            }
        }
        else {
            next()
        }
    }
}

export default AuthGuard
