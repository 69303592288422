<template>
  <div class="pagination">
    <button type="button" class="pagination__first" v-if="currentPage !== 1" @click="togglePage(1 )">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 0V14M13 1L7 7L13 13" stroke="#0363FE" stroke-width="2"/>
      </svg>
    </button>
    <button type="button" class="pagination__prev"
            v-if="currentPage !== 1"
            @click="togglePage(currentPage - 1)"
    >
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 1L2 7L8 13" stroke="#0363FE" stroke-width="2"/>
      </svg>

    </button>

    <ul class="pagination__list">
      <li class="pagination__item" v-for="(btn, index) in paginatorButtons" :key="index"
          :class="{active: btn === currentPage}">
        <button class="pagination__btn" @click="togglePage(btn)" :disabled="!isFinite(btn)">{{ btn }}</button>
      </li>
    </ul>
    <button type="button"
            class="pagination__next"
            v-if="currentPage !== paginatorButtons[paginatorButtons.length-1]"
            @click="togglePage(currentPage+1)">
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L7 7L1 13" stroke="#0363FE" stroke-width="2"/>
      </svg>
    </button>
    <button type="button"
            class="pagination__last"
            v-if="currentPage !== paginatorButtons[paginatorButtons.length-1]"
            @click="togglePage(pagesQt)">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 0V14M1 1L7 7L1 13" stroke="#0363FE" stroke-width="2"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    pagesQt: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    paginatorButtons: [],
    currentPage: 1,
  }),
  methods: {
    resetPagination() {
      this.currentPage = 1;
      this.initPaginator();
    },
    initPaginator() {
      this.paginatorButtons = [];
      let counter = 0;
      if (this.pagesQt <= 4) {
        while (counter < this.pagesQt) {
          counter++;
          this.paginatorButtons.push(counter);
        }
        return
      }
      counter = 0;
      while (counter < this.pagesQt) {
        counter++;
        this.paginatorButtons.push(counter)
      }
      let limit = 3;
      if (this.currentPage - 1 > 2 && this.currentPage + 1 < this.pagesQt - 1 && this.currentPage !== 3) {
        this.paginatorButtons[0] = 1;
        this.paginatorButtons[1] = '...';
        this.paginatorButtons[2] = this.currentPage - 1;
        this.paginatorButtons[3] = this.currentPage;
        this.paginatorButtons[4] = this.currentPage + 1;
        this.paginatorButtons[5] = '...';
        this.paginatorButtons[6] = this.pagesQt;
        this.paginatorButtons.length = 7
      } else if (this.currentPage < this.paginatorButtons[Math.floor(this.paginatorButtons.length / 2)]) {
        this.paginatorButtons.splice(limit, this.paginatorButtons.length - (limit + 1), '...')
      } else if (this.currentPage > this.paginatorButtons[Math.floor(this.paginatorButtons.length / 2)]) {
        this.paginatorButtons = [];
        counter = this.pagesQt;
        let i = 0;
        while (i < 3) {
          if (!this.paginatorButtons.includes(counter)) {
            this.paginatorButtons.unshift(counter)
          }
          counter--;
          i++;
        }
        this.paginatorButtons.unshift('...');
        this.paginatorButtons.unshift(1);
      }
      if (this.currentPage === 3) {
        this.paginatorButtons.splice(3, 0, 4)
      }
      if (this.currentPage === this.pagesQt - 2) {
        this.paginatorButtons.splice(-this.paginatorButtons.length + limit - 1, 0, this.pagesQt - limit)
      }
      this.paginatorButtons = this.paginatorButtons.filter((item, pos) => {
        if (item === '...') return this.paginatorButtons;
        return this.paginatorButtons.indexOf(item) === pos;
      })
    },
    togglePage(btn) {
      this.$emit('change', btn);
      this.currentPage = btn;
      this.initPaginator();
    }
  },
  mounted() {
    this.initPaginator();
  }
}
</script>

