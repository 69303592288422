<template>
  <footer class="footer">

      <div class="footer-links">
        <ul>
          <li class="footer-links__item">
            <div class="copyright">
              © {{new Date().getFullYear()}} “ConeCoin”
            </div>
          </li>
          <li class="footer-links__item">
            <a href="#"> {{ $t("footerLinkPolitics") }}</a>
          </li>
          <li class="footer-links__item">
            <a href="#"> {{ $t("footerLinkPersonal") }}</a>
          </li>
          <li class="footer-links__item">
            <a href="#"> {{ $t("footerLinkLicence") }}</a>
          </li>
        </ul>
      </div>

  </footer>
</template>

<script>
  export default {
    name: 'AppFooter'
  }
</script>
