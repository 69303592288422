export default {
    methods: {
        isEvent(eventType) {
            return eventType === 'Event';
        },
        isOnline(eventType) {
            return eventType === 'Online';
        },
        isOver(date) {
            return (date - Date.now()) < 0;
        },
        isNearest(date) {
            const diffDays = Math.ceil((new Date(date) - new Date(Date.now())) / (1000 * 60 * 60 * 24));
            return diffDays <= 7 && diffDays > 0;
        },
        eventDate(date) {
          const dateAndMonth = new Date(date).toLocaleDateString("pt-BR").split('/');
          return `${dateAndMonth[0]}.${dateAndMonth[1]}`
        },
        eventTime(date) {
            const time =new Date(date).toLocaleTimeString("pt-BR").split(':');
            return `${time[0]}.${time[1]}`
        },
    }
}