<template>
  <div class="box about-box referral-box">
    <div class="about-box__head">
      <div class="about-box__title">
        {{$t('userAccRefTitle')}}
      </div>
    </div>

    <div class="about-box__content">
      <div class="about-box__wrap">
        <div class="about-box__title-sm">
          {{$t('userAccRefCode')}}
        </div>
        <div class="about-box__value">
          9401581
        </div>
      </div>
      <div class="about-box__wrap">
        <div class="about-box__title-sm">
          {{$t('userAccRefLink')}}
        </div>
        <div class="about-box__value">
          <a href="#">https://conecoin.com/94015</a>
        </div>
      </div>
      <div class="about-box__wrap">
        <div class="about-box__title-sm">
          {{$t('userAccRefAmount')}}
        </div>
        <div class="about-box__value">
          9
        </div>
      </div>
      <div class="about-box__wrap">
        <div class="about-box__title-sm">
          {{$t('userAccRefTurn')}}
        </div>
        <div class="about-box__value">
          91582 USDT
        </div>
      </div>
    </div>
    <div class="about-box__footer">
      <div class="referral-box__level">
        {{$t('userAccRefLevel')}} : 1
      </div>

      <a href="#"> {{$t('userAccRefMore')}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserReferral"
}
</script>

<style scoped>

</style>