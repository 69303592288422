<template>
  <div class="traders-top">
    <div class="traders-top__left">
      <div class="traders-top__wrap">
        <h1 class="page-title">{{ $t("menuTraders") }}</h1>
        <div class="page-input">

          <div class="page-input__wrap">
            <input type="number" class="input" v-model="investmentSum" :placeholder="$t('inputEnterSum')">

            <div class="page-input__icon">
              USDT
            </div>
          </div>
        </div>
      </div>

      <div class="toggle">
        <button class="toggle__btn"
                :class="{active: investmentDuration === 'SIX_MONTH'}"
                @click="setDuration('SIX_MONTH')">6 {{ $t("toggleTabMonth") }}
        </button>
        <button class="toggle__btn"
                :class="{active: investmentDuration === 'TWELVE_MONTH'}"
                @click="setDuration('TWELVE_MONTH')">12 {{ $t("toggleTabMonth") }}
        </button>
      </div>

      <div class="fill-frame-wrap">
        <div class="fill-frame" @mousedown="moveSlider" @mouseup="stopSlider" @mouseleave="stopSlider">
          <div class="fill-frame__item">
            <span>{{ investorPercent }}%</span>
            {{ $t("investor") }}
          </div>

          <div class="fill-frame__item">
            <span>{{ 100 - investorPercent }}%</span>
            {{ $t("trader") }}
          </div>
          <span class="fill-frame__color" :style="`width: ${investorPercent}%`"></span>
        </div>
        <div class="tooltip">
          <svg @click="showTooltip = true" class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                fill="#0363FE"/>
          </svg>

          <div class="tooltip__content" v-show="showTooltip">
            {{ $t("tradersTopToolText1") }}
            <span> {{ $t("tradersTopToolText2") }}</span>
            <a href="#" class="link" @click="openModal"> {{ $t("tradersTopToolLink") }}</a>
          </div>


          <div class="tooltip__descr">
            {{ $t("tradersTopToolText1") }}
            <span> {{ $t("tradersTopToolText2") }}</span>
            <a href="#" class="link" @click="openModal"> {{ $t("tradersTopToolLink") }}</a>
          </div>
        </div>
      </div>

      <div class="traders-top__right">
        <button class="star-btn" :class="{active: favorite}" @click="loadFavorite">
          <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7 10L3.47329 11.8541L4.14683 7.92705L1.29366 5.1459L5.23664 4.57295L7 1L8.76336 4.57295L12.7063 5.1459L9.85317 7.92705L10.5267 11.8541L7 10Z"
                  stroke="#888888" stroke-width="0.5"/>
          </svg>

        </button>

        <div class="select">
          <button class="select__btn" @click="isOpenSelect = !isOpenSelect" :class="{active: isOpenSelect}">
            <span>{{ selectCurrent }} </span>

            <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M4.70312 3.15475L7.81438 0.0999756L8.70312 0.97259L4.70312 4.89998L0.703125 0.97259L1.59187 0.0999756L4.70312 3.15475Z"
                  fill="#FCFCFC"/>
            </svg>

          </button>


          <div class="select-drop" v-show="isOpenSelect">
            <ul class="select-drop__list">
              <li class="select-drop__item"
                  v-for="(item,index) in tradersFilter"
                  :key="index"
                  @click="selectCurrent = item.title">
                {{ item.title }}
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: 'TradersFilter',
  data: () => ({
    investorPercent: 50,
    isMouseDown: false,
    mouseMoveListener: null,
    investmentSum: '',
    isOpenSelect: false,
    selectCurrent: 'По умолчанию',
    favorite: false,
    showTooltip: false,
    tradersFilter: [
      {
        title: 'По рейтингу'
      },
      {
        title: 'По общей прибыли'
      },
      {
        title: 'По среднемесячной прибыли'
      },
      {
        title: 'По лимиту трейдера'
      },
      {
        title: 'По количеству инвесторов'
      },
    ],
    investmentDuration: "SIX_MONTH"
  }),
  methods: {
    moveSlider(event) {
      if(event.stopPropagation) event.stopPropagation();
      if(event.preventDefault) event.preventDefault();
      event.cancelBubble=true;
      event.returnValue=false;
      this.isMouseDown = true;
      const startX = event.pageX;
      let currentX = startX;
      let prevX = startX;
      this.mouseMoveListener = (e) => {
        prevX = currentX;
        currentX = e.pageX;
        if(prevX < currentX && this.investorPercent < 100) {
          this.investorPercent += 1;
        }
        if(prevX > currentX && this.investorPercent > 0) {
          this.investorPercent -= 1;
        }
      }
      document.addEventListener('mousemove', this.mouseMoveListener)
    },
    stopSlider() {
      if(!this.isMouseDown) {
        return
      }
      this.isMouseDown = false;
      document.removeEventListener('mousemove', this.mouseMoveListener, false);
      this.$emit("stopSlider", {
        investorPercent: this.investorPercent
      });
    },
    loadFavorite() {
      this.favorite = !this.favorite;
      this.$emit('loadFavorite', this.favorite);
    },
    openModal() {
      this.$emit('click')
    },
    setDuration(duration) {
      this.investmentDuration = duration;
      this.$emit("setDuration", duration);
    }
  },
  mounted() {
    document.body.addEventListener('click', (e) => {
      if (!e.target.closest('.select__btn')) {
        this.isOpenSelect = false;
      }
    })
  }
}
</script>
