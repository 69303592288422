<template>
  <div class="mobile-table mobile-table--trader mobile-table--income">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        {{ $t("incomeTableDate") }}
      </p>
      <p class="mobile-table__title">
        {{ $t("incomeTableType") }}
      </p>
      <p class="mobile-table__title">
        {{ $t("incomeTableSum") }}
      </p>
      <p class="mobile-table__title">{{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item"
         v-for="(item, index) in items"
         :class="{active: item.isShow}"
         :key="index">

      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">
          <div class="mobile-table-item__col">
            <p>{{item.openingDate | timestampToDatePipe}}</p>
          </div>

          <div class="mobile-table-item__col">
            <p class="primary">{{ $t("incomeTableAdd") }}</p>
            <p v-show="false" class="danger">{{ $t("incomeTableWithdraw") }}</p>
          </div>

          <div class="mobile-table-item__col">
            <p style="color: #5C93E5">{{formatNumber(item.body)}} USDT</p>
          </div>
        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("incomeTableTrans") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p class="default">#915891</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("incomeTableUser") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>015859</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("incomeTableComm") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>40</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("incomeTableEnd") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{item.updatingDate | timestampToDatePipe}}</p>
            </div>
          </div>


        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {formatNumberMixin, timestampToDateMixin} from "@/utils/mixins";

export default {
  name: "IncomeHistoryMobileTable",
  mixins: [timestampToDateMixin, formatNumberMixin],
  props: {
    items: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>