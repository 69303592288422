import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
import General from './modules/general'
import Market from './modules/market';
import Pair from "./modules/pair";
import Socket from './modules/socket';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general: General,
    user: User,
    market: Market,
    pair: Pair,
    socket: Socket
  }
})
