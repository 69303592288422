<template>
  <div class="switcher">
    <label>
      <input type="checkbox" @change="change" v-model="val"/>
      <span class="switcher__box"></span>
    </label>
    <div class="switcher__text">
      <p>
        {{ title }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PrimaryCheckBox',
    data: () => ({
      val: false
    }),
    props: {
      title: {
        default: ''
      }
    },
    methods: {
      change() {
        this.$emit('change', this.val);
      }
    }
  }
</script>
