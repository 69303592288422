<template>
  <div class="login-page register-page">
    <div class="login-wrap">
      <div class="login-page__content">
        <div class="logo">
          <img src="@/assets/img/svg/logo.svg" alt="">
        </div>

        <h1 class="login-page__title">
          {{ $t("signUpTitle") }}
        </h1>

        <form class="form" @submit.prevent="createAccount">
<!--          <div class="page-input" :class="{error:errorName}">-->
<!--            <div class="page-input__title">-->
<!--              {{ $t("name") }}-->
<!--            </div>-->

<!--            <input type="text" class="input"-->
<!--                   @focus="deleteError('name')"-->
<!--                   @keyup.enter="createAccount"-->
<!--                   v-model="name">-->

<!--            <div class="error__msg">-->
<!--              <p>{{ errorName }}</p>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="page-input" :class="{error:errorSurname}">-->
<!--            <div class="page-input__title">-->
<!--              {{ $t("surname") }}-->
<!--            </div>-->

<!--            <input type="text" class="input"-->
<!--                   @focus="deleteError('surname')"-->
<!--                   @keyup.enter="createAccount"-->
<!--                   v-model="surname">-->

<!--            <div class="error__msg">-->
<!--              <p>{{ errorSurname }}</p>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="page-input" :class="{error:errorReferral}">-->
<!--            <div class="page-input__title">-->
<!--              {{ $t("referral") }}-->
<!--            </div>-->

<!--            <input type="text" class="input"-->
<!--                   @focus="deleteError('referral')"-->
<!--                   @keyup.enter="createAccount"-->
<!--                   v-model="referral">-->

<!--            <div class="error__msg">-->
<!--              <p>{{ errorReferral }}</p>-->
<!--            </div>-->
<!--          </div>-->

          <div class="page-input" :class="{error:errorEmail}">
            <div class="page-input__title">
              {{ $t("email") }}
            </div>

            <input type="text" class="input"
                   @focus="deleteError('email')"
                   @keyup.enter="createAccount"
                   v-model="email">

            <div class="error__msg">
              <p>{{ errorEmail }}</p>
            </div>
          </div>

          <div class="page-input" :class="{error:errorPassword}">
            <div class="page-input__title">
              {{ $t("password") }}
            </div>

            <div class="page-input__wrap">
              <input :type="passFieldType"
                     class="input"
                     @focus="deleteError('password')"
                     @keyup.enter="createAccount"
                     v-model="password">



              <button @click="togglePasswordVisible"
                      type="button"
                      class="page-input__icon pass-toggle"
                      :class="{active: showPass}"

              ></button>
            </div>
            <div class="error__msg">
              <p>{{ errorPassword }}</p>
            </div>
          </div>

          <div class="page-input" :class="{error:errorRepeatPassword}">
            <div class="page-input__title">
              {{ $t("repeatPassword") }}
            </div>

            <div class="page-input__wrap">
              <input :type="passFieldType2"
                     class="input"
                     @focus="deleteError('repeatPassword')"
                     @keyup.enter="createAccount"
                     v-model="repeatPassword">



              <button @click="toggleRepeatPasswordVisible"
                      type="button"
                      class="page-input__icon pass-toggle"
                      :class="{active: showPass2}"

              ></button>
            </div>
            <div class="error__msg">
              <p>{{ errorRepeatPassword }}</p>
            </div>
          </div>

          <button class="page-btn" v-focus type="submit">
            {{ $t("signInBtnCreate") }}
          </button>


          <div class="login-page__check">
            <div class="default-checkbox">
              <div class="checkbox">
                <label>
                  <input type="checkbox" name="remember" v-model="termsAgree"/>
                  <span class="checkbox__content">
               <span class="checkbox__box"></span>
               <span class="checkbox__text">{{ $t("signUpAgreement") }}</span>
             </span>
                </label>
              </div>
            </div>


            <div class="page-input error" v-show="errorTermsAgree">
              <div class="error__msg">
                <p>{{ errorTermsAgree }}</p>
              </div>
            </div>
          </div>

        </form>

        <div class="login-page__text">
          {{ $t("signUpText") }}
        </div>

        <router-link to="/sign-in"
                     tag="button"
                     class="page-btn page-btn--border">{{ $t("signInBtn") }}</router-link>
      </div>

    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import api from "../../api/api";

export default {
  name: 'SignUp',
  data() {
    return {
      passFieldType: 'password',
      passFieldType2: 'password',
      showPass: false,
      showPass2: false,
      errorPassword: '',
      errorName: '',
      errorSurname: '',
      //errorReferral: '',
      errorEmail: '',
      errorRepeatPassword: '',
      errorTermsAgree: '',
      // name: null,
      // surname: null,
      //referral: null,
      email: null,
      password: null,
      repeatPassword: null,
      termsAgree: false,
    }
  },
  methods: {
    ...mapMutations({
      setSuccess: 'general/setSuccess',
      setError: 'general/setError'
    }),
    async createAccount() {
      try {
        if(!this.isValid()) {
          return;
        }
        await api.signUp({
          email: this.email,
          password: this.password,
          name: this.name,
          surname: this.surname,
          //referral: this.referral,
        })
        this.setSuccess({
          message: 'signInTitle'
        })
      } catch (e) {
        console.log(e)
        this.setError({
          message: e.response.data.message
        })
      }
    },
    isValid() {
      let rules = {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$/,
      }
      if(!rules.email.test(String(this.email).toLowerCase())) {
        this.errorEmail = this.$t('wrongEmail');
        return false;
      }
      if(!rules.password.test(String(this.password))) {
        this.errorPassword = this.$t('wrongPassword');
        return false;
      }
      if(this.password !== this.repeatPassword) {
        this.errorRepeatPassword = this.$t('passwordsMismatch');
        return false;
      }
      // if(!this.name) {
      //   this.errorName = this.$t('passwordsMismatch');
      //   return false;
      // }
      // if(!this.surname) {
      //   this.errorSurname = this.$t('passwordsMismatch');
      //   return false;
      // }
      if(!this.termsAgree) {
        this.errorTermsAgree = this.$t('t&cError');
        return false;
      }
      return true;
    },
    togglePasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
    toggleRepeatPasswordVisible() {
      this.passFieldType2 === 'password' ? this.passFieldType2 = 'text' : this.passFieldType2 = 'password';
      this.showPass2 = !this.showPass2;
    },
    deleteError(type) {
      switch (type) {
        case 'email':
          this.errorEmail = ""
          break;
        case 'password':
          this.errorPassword = ""
          break;
        case 'repeatPassword':
          this.errorRepeatPassword = ""
          break;
      }
    },
  }
}
</script>
