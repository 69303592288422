<template>
  <div class="mobile-table mobile-table--profile">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("profileTableDate") }}</button>
      </p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("profileTableIp") }}</button>
      </p>
      <p class="mobile-table__title">{{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item"
         v-for="(item, index) in history"
         :key="index"
         :class="{active: item.isShow}">

      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">
          <div class="mobile-table-item__col">
            <p>
              {{ new Date(item.created).toLocaleDateString('en-GB') }}
              {{ new Date(item.created).toLocaleTimeString('en-US', {hour12: false}) }}
            </p>
          </div>

          <div class="mobile-table-item__col" :class="{active : item.isShow}">
            <p>{{ item.ip }}</p>
          </div>

        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("profileTableOs") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p><strong>{{ item.deviceOs }}</strong></p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("profileTableBrowser") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{ item.deviceBrowser }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "UserEnterHistoryMobile",
  props: {
    history: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
