import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes.js"
import beforeEachGuard from "./guards/BeforeEachGuard";


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(beforeEachGuard)

export default router
