<template>
  <div class="chart">
    <div class="box">
      <div class="chart__head">
        <div class="select">
          <button class="select__btn" @click="isOpenSelect = !isOpenSelect">
            <span>{{ getPairName }} </span>
            <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M4.70312 3.15475L7.81438 0.0999756L8.70312 0.97259L4.70312 4.89998L0.703125 0.97259L1.59187 0.0999756L4.70312 3.15475Z"
                  fill="#FCFCFC"/>
            </svg>

          </button>

          <div class="select__drop" v-show="isOpenSelect">
            <ul>
              <li v-for="(item, index) in pairs"
                  @click="selectPair(item.pairName, item.id)"
                  :key="index"> {{ item.pairName }}
              </li>
            </ul>
          </div>
        </div>

        <div class="chart__btn-wrap">
          <button class="chart__btn"
                  :class="{active: getTimeFrame === 'MINUTE'}"
                  @click="toggleTimeFrame('MINUTE')">
            1{{ $t("minutesShort") }}
          </button>
          <button class="chart__btn"
                  :class="{active: getTimeFrame === 'FIVE_MINUTE'}"
                  @click="toggleTimeFrame('FIVE_MINUTE')">
            5{{ $t("minutesShort") }}
          </button>
          <button class="chart__btn"
                  :class="{active: getTimeFrame === 'FIFTEEN_MINUTE'}"
                  @click="toggleTimeFrame('FIFTEEN_MINUTE')">
            15{{ $t("minutesShort") }}
          </button>
          <button class="chart__btn"
                  :class="{active: getTimeFrame === 'HOUR'}"
                  @click="toggleTimeFrame('HOUR')">
            1{{ $t("hoursShort") }}
          </button>
          <button class="chart__btn"
                  :class="{active: getTimeFrame === 'FOUR_HOUR'}"
                  @click="toggleTimeFrame('FOUR_HOUR')">
            4{{ $t("hoursShort") }}
          </button>
          <button class="chart__btn"
                  :class="{active: getTimeFrame === 'DAY'}"
                  @click="toggleTimeFrame('DAY')">
            1{{ $t("daysShort") }}
          </button>
        </div>
      </div>
      <div class="chart-wrap">
        <div v-show="!getLoading || !getAllowChartRefresh" id="chart" ref="chartContainer" style="border-radius: 5px"/>
      </div>
    </div>
  </div>
</template>

<script>

import {createChart, CrosshairMode, TickMarkType} from 'lightweight-charts';
import api from "../../api/api";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'Chart',
  props: {
    pairs: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isOpenSelect: false,
    chart: null,
    chartWidth: 600,
    chartHeight: 360,
    candleSeries: null,
    volumeSeries: null,
    candlesData: [],
    volumesData: [],
    endDate: new Date(),
    loading: false,
    disableLoading: false,
  }),
  computed: {
    ...mapGetters({
      getPairIndex: 'pair/getPairIndex',
      getPairName: 'pair/getPairName',
      getCandle: 'socket/getCandle',
      getTimeFrame: 'socket/getTimeFrame',
      getLoading: 'general/getLoading',
      getAllowChartRefresh: 'general/getAllowChartRefresh'
    })
  },
  watch: {
    getCandle: {
      handler(val) {
        if (val) {
          this.candleSeries.update({
            time: Math.floor(val.created / 1000),
            open: val.openPrice,
            close: val.closePrice,
            high: val.highPrice,
            low: val.lowPrice
          })
          this.volumeSeries.update({
            time: Math.floor(val.created / 1000),
            value: val.volume,
            color: val.openPrice > val.closePrice ? '#f4344e' : '#0bbe6d'
          })
        }
      },
      deep: true
    },
    getPairIndex(val) {
      if (val) {
        this.init();
      }
    },
    getTimeFrame(val) {
      if (val) {
        this.init();
      }
    }
  },
  methods: {
    ...mapMutations({
      setTimeFrame: 'socket/setTimeFrame',
      setLoading: 'general/setLoading'
    }),
    toggleTimeFrame(timeFrame) {
      this.disableLoading = false;
      this.endDate = new Date();
      this.setTimeFrame(timeFrame);
    },
    selectPair(pair, id) {
      this.$emit('selectPair', {pair, id});
      this.isOpenSelect = false;
    },
    async init() {
      await this.buildChart();
      this.initResizeObserver();
    },
    onResize() {
      if(this.$route.name === "Trading") {
        this.chart.applyOptions({
          width: this.$refs.chartContainer.clientWidth,
          height: 360
        })
      }
    },
    initResizeObserver() {
      new ResizeObserver(this.onResize).observe(this.$refs.chartContainer);
    },
    async onVisibleLogicalRangeChanged(newVisibleLogicalRange) {
      const barsInfo = this.candleSeries.barsInLogicalRange(newVisibleLogicalRange);
      console.log(barsInfo.barsBefore)
      if (barsInfo.barsBefore < 50 && !this.loading && !this.disableLoading) {
        this.loading = true;
        let data = await this.loadCandles();
        this.disableLoading = !data.length;
        let candlesData = data.map(e => {
          return {
            time: Math.floor(e.created / 1000),
            open: e.openPrice,
            close: e.closePrice,
            high: e.highPrice,
            low: e.lowPrice
          }
        }).reverse()
        let volumesData = data.map(e => {
          return {
            time: Math.floor(e.created / 1000),
            value: e.volume,
            color: e.openPrice > e.closePrice ? '#f4344e' : '#0bbe6d'
          }
        }).reverse();
        this.candlesData = [...candlesData, ...this.candlesData];
        this.volumesData = [...volumesData, ...this.volumesData];
        // console.log(candlesData)
        this.candleSeries.setData(this.candlesData);
        this.volumeSeries.setData(this.volumesData)
        this.loading = false;
      }
    },
    async buildChart() {
      this.setLoading(true);
      this.$refs.chartContainer.innerHTML = '';
      this.chart = createChart('chart', {
        width: this.chartWidth,
        height: this.chartHeight,
        timeScale: {
          timeVisible: true,
          visible: true,
          tickMarkFormatter: (time, tickMarkType) => {
            time = this.getTimezoneCorrectedTime(time)
            let opt = {}

            switch (tickMarkType) {
              case TickMarkType.Year:
                opt = {
                  year: 'numeric'
                }
                break
              case TickMarkType.Month:
                opt = {
                  month: 'short'
                }
                break
              case TickMarkType.DayOfMonth:
                opt = {
                  day: '2-digit'
                }
                break
              case TickMarkType.Time:
                opt = {
                  hour: '2-digit',
                  minute: '2-digit'
                }
                break
              case TickMarkType.TimeWithSeconds:
                opt = {
                  hour: '2-digit',
                  minute: '2-digit',
                  seconds: '2-digit'
                }
            }

            return time.toLocaleString('de-DE', opt)
          }
        },
        localization: {
          locale: 'de-DE',
          dateFormat: 'dd.MM.yyyy',
        },
        crosshair: {
          mode: CrosshairMode.Normal
        },
        watermark: {
          color: '#181920',
          visible: true,
          fontSize: 70,
          fontStyle: 900,
          text: 'ConeCoin',
          horzAlign: 'center',
          vertAlign: 'center',
        },
        rightPriceScale: {
          visible: true,
        },
        layout: {
          backgroundColor: '#0b0a0e',
          textColor: '#898b8d',
        },
        grid: {
          vertLines: {
            color: '#181920'
          },
          horzLines: {
            color: '#181920'
          }
        }
      });
      this.volumeSeries = this.chart.addHistogramSeries({
        priceScaleId: 'right',
        color: "#0bbe6d",
        lineWidth: 2,
        priceFormat: {
          type: "volume"
        },
        overlay: true,
        scaleMargins: {
          top: 0.8,
          bottom: 0.02
        }
      });
      this.candleSeries = this.chart.addCandlestickSeries({
        priceScaleId: 'right',
        priceLineColor: '#0bbe6d',
        upColor: '#0bbe6d',
        borderUpColor: '#0bbe6d',
        borderDownColor: '#f4344e',
        downColor: '#f4344e'
      });
      this.candleSeries.applyOptions({
        priceFormat: {
          type: 'price',
          precision: 6,
          minMove: 0.000001,
        },
      });
      let data = await this.loadCandles();
      this.disableLoading = !data.length;
      this.candlesData = data.map(e => {
        return {
          time: Math.floor(e.created / 1000),
          open: e.openPrice,
          close: e.closePrice,
          high: e.highPrice,
          low: e.lowPrice
        }
      })
      this.volumesData = data.map(e => {
        return {
          time: Math.floor(e.created / 1000),
          value: e.volume,
          color: e.openPrice > e.closePrice ? '#f4344e' : '#0bbe6d'
        }
      })
      this.volumeSeries.setData(this.volumesData.reverse())
      this.candleSeries.setData(this.candlesData.reverse());
      this.chart.timeScale().subscribeVisibleLogicalRangeChange(this.onVisibleLogicalRangeChanged)
      this.setLoading(false);
    },
    getTimezoneCorrectedTime(utcTime, returnAsUnixTimestamp = false) {
      if (utcTime instanceof Date) {
        utcTime = utcTime.getTime() / 1000
      }

      const timezoneOffsetMinutes = new Date().getTimezoneOffset()
      const correctedTime = utcTime + (timezoneOffsetMinutes * 60)

      if (returnAsUnixTimestamp) return correctedTime

      return new Date(correctedTime * 1000)
    },
    getLoadingInterval() {
      switch (this.getTimeFrame) {
        case "MINUTE": this.endDate.setHours(this.endDate.getHours() - 2);break;
        case "FIVE_MINUTE": this.endDate.setHours(this.endDate.getHours() - 24);break;
        case "FIFTEEN_MINUTE": this.endDate.setHours(this.endDate.getHours() - 24);break;
        case "HOUR": this.endDate.setHours(this.endDate.getHours() - 80);break;
        case "FOUR_HOUR": this.endDate.setHours(this.endDate.getHours() - 300);break;
        case "DAY": this.endDate.setDate(this.endDate.getDate() - 80);break;
      }
      return Date.parse(this.endDate);
    },
    async loadCandles() {
      try {
        let from = Date.parse(this.endDate);
        const to = this.getLoadingInterval();
        return await api.loadCandles({
          from,
          to,
          frame: this.getTimeFrame,
          pairIndex: this.getPairIndex
        });

      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.init();
    document.body.addEventListener('click', (e) => {
      if (!e.target.closest('.select__btn')) {
        this.isOpenSelect = false;
      }
    })
  }
}
</script>

<style>
.tv-lightweight-charts {
  border: 1px solid #1c1f25;
}
</style>
