import AppMain from "../components/containers/AppMain";
import SignIn from "../views/pages/SignIn";
import RestorePassword from "../views/pages/RestorePassword";
import SetNewPassword from "../views/pages/SetNewPassword";
import ModalPage from "../views/pages/ModalPage";
import Trading from "../views/tabs/Trading";
import PageNotFound from "../views/tabs/PageNotFound";
import ServerError from "../views/tabs/ServerError";
import Orders from "../views/tabs/Orders";
import TransactionsHistory from "../views/tabs/Wallet/TransactionsHistory";
import Traders from "../views/tabs/Traders";
import SignUp from "../views/pages/SignUp";
import GoogleAuthenticate from "../views/pages/GoogleAuthenticate";
import Balance from "../views/tabs/Wallet/Balance";
import AuthGuard from "./guards/AuthGuard";
import Profile from "../views/tabs/TraderCabinet";
import User from "../views/tabs/TraderProfile";
import Deposit from "../views/tabs/Wallet/Deposit";
import Withdraw from "../views/tabs/Wallet/Withdraw";
import UserProfile from "../views/tabs/UserProfile";
import Deposits from "../views/tabs/Deposits";
import News from "../views/tabs/News";
import NewsItem from "../views/tabs/NewsItem";
import EmailAuthenticate from "../views/pages/EmailAuthenticate";
import TradingGuard from "@/router/guards/TradingGuard";

export default [
    {
        name: 'Exchange',
        path: '/',
        redirect: '/trading/ETH-USDT',
        component: AppMain,
        children: [
            {
                name: 'Trading',
                props: true,
                path: "trading/:pair",
                component: Trading,
                beforeEnter: TradingGuard,
                meta: {
                    title: 'Trading',
                }
            },
            {
              name: 'Traders',
              path: '/traders',
              component: Traders,
                meta: {
                    title: 'Traders',
                    requiresAuth: true
                }
            },
            {
                name: 'Orders',
                path: '/orders',
                component: Orders,
                meta: {
                    title: 'Orders',
                    requiresAuth: true
                }
            },
            {
                name: 'TransactionsHistory',
                path: '/transactions-history',
                component: TransactionsHistory,
                meta: {
                    title: 'Transactions History',
                    requiresAuth: true
                }
            },
            {
                name: 'Balance',
                path: '/balance',
                component: Balance,
                meta: {
                    title: 'Balance',
                    requiresAuth: true
                }
            },
            {
                name: 'Withdraw',
                path: '/withdraw',
                component: Withdraw,
                meta: {
                    title: 'Withdraw',
                    requiresAuth: true
                }
            },
            {
                name: 'Deposit',
                path: '/deposit',
                component: Deposit,
                meta: {
                    title: 'Deposit',
                    requiresAuth: true
                }
            },
            {
                name: 'Profile',
                path: '/trader-cabinet',
                component: Profile,
                meta: {
                    title: 'Profile',
                    requiresAuth: true
                }
            },
            {
                name: 'User',
                path: '/trader-profile/:id',
                props: true,
                component: User,
                meta: {
                    title: 'User',
                    requiresAuth: true
                }
            },
            {
                name: 'UserProfile',
                path: '/profile',
                component: UserProfile,
                meta: {
                    title: 'User Profile',
                    requiresAuth: true
                }
            },
            {
                name: 'MyDeposits',
                path: '/deposits',
                component: Deposits,
                meta: {
                    title: 'My Deposits',
                    requiresAuth: true
                }
            },
            {
                name: 'News',
                path: '/news',
                component: News,
                meta: {
                    title: 'News',
                    requiresAuth: false
                }
            },
            {
                name: 'NewsItem',
                path: '/news-item/:id',
                props: true,
                component: NewsItem,
                meta: {
                    requiresAuth: false
                }
            },
        ]
    },
    {
        name: 'SignIn',
        path: '/sign-in',
        alias: '/confirm_registration',
        component: SignIn,
        beforeEnter: AuthGuard
    },
    {
        name: 'SignUp',
        path: '/sign-up',
        component: SignUp,
        beforeEnter: AuthGuard
    },
    {
        name: 'RestorePassword',
        path: '/restore-password',
        component: RestorePassword,
        beforeEnter: AuthGuard
    },
    {
        name: 'SetNewPassword',
        path: '/confirm_restore_password',
        component: SetNewPassword,
        beforeEnter: AuthGuard
    },
    {
        name: 'GoogleAuthenticate',
        path: '/google-authenticate',
        component: GoogleAuthenticate,
        beforeEnter: AuthGuard
    },
    {
        name: 'EmailAuthenticate',
        path: '/email-authenticate',
        component: EmailAuthenticate,
        beforeEnter: AuthGuard
    },
    {
        name: 'ModalPage',
        path: '/modal-page',
        component: ModalPage
    },
    {
        name: 'PageNotFound',
        path: '/404',
        component: PageNotFound
    },
    {
        name: 'ServerError',
        path: '/502',
        component: ServerError
    },
    {
        path: '*',
        redirect: '/404'
    }
]
