<template>
  <div class="invest-confirm invest-success">

    <div class="popup__content">

      <div class="invest-success__head">
        <div class="invest-success__img">
          <img src="@/assets/img/success-green.png" alt="">
        </div>

        <div>
          <div class="popup__title popup__title--sm">
            {{$t("investSuccessTitle")}}
          </div>

          <div class="invest-success__text">
            {{$t("investSuccessHeadText")}}
          </div>
        </div>
      </div>

      <div class="hint-wrap">
        <div class="hint-wrap__title">
          {{$t("investSuccessWrapTitle")}}
        </div>

        <div class="hint-wrap__text">
          {{$t("investSuccessText1")}}
          <span>{{$t("investSuccessText1Span")}}</span>
          {{$t("investSuccessText2")}}
        </div>

        <div class="hint-wrap__text">
          {{$t("investSuccessText3")}}
          <span>{{$t("investSuccessText2Span")}}</span>
          {{$t("investSuccessText4")}}
        </div>

        <div class="hint-wrap-range">
          <div class="hint-wrap-range__line">
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div class="hint-wrap-range__captions">
            <span>{{$t("hintRangeCaption1")}}</span>
            <span>{{$t("hintRangeCaption2")}}</span>
            <span>{{$t("hintRangeCaption3")}}</span>
          </div>
        </div>
      </div>

      <button class="page-btn page-btn--border" type="button">{{$t("menuProgram")}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestSuccess"
}
</script>

<style scoped>

</style>