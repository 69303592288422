<template>
  <div class="page-news">

    <h1 class="page-title" v-show="currentTab === 0">{{ $t("menuNews") }}</h1>
    <h1 class="page-title" v-show="currentTab === 1">{{ $t("newsListTitle") }}</h1>
    <h1 class="page-title" v-show="currentTab === 2">Changelog</h1>
    <Tabs :tabs="tabNames" @clicked="setTab" ref="tabs"/>

    <TabNews v-if="currentTab === 0" @showAllEvents="showAllEvents"/>
    <TabEvents v-if="currentTab === 1"/>
    <TabChangelog v-if="currentTab === 2"/>
  </div>
</template>

<script>
import Tabs from "../../components/utility/Tabs";
import TabNews from "../../components/news/tabs/TabNews";
import TabEvents from "../../components/news/tabs/TabEvents";
import TabChangelog from "../../components/news/tabs/TabChangelog";

export default {
  name: 'News',
  components: {TabChangelog, TabEvents, TabNews, Tabs},
  data() {
    return {
      tabNames: [
        {
          name: this.$t('newsTab1')
        },
        {
          name: this.$t('newsTab3')
        },
        {
          name: this.$t('newsTab4')
        },
      ],
      isNews: false,
      isEvents: false,
      isChangelog: true,
      currentTab: 0,
    }
  },
  methods: {
    showAllEvents() {
      this.$refs.tabs.clicked(1);
    },
    setTab(value) {
      this.currentTab = value;
    }
  }
}
</script>
