import Store from '@/store'
import i18n from "../../i18n";

const beforeEachGuard = async (to, from, next) => {

    const language =
        to.query.lang && ["en", "ru"].includes(to.query.lang)
            ? to.query.lang
            : to.query.lang === undefined && from.query.lang
                ? from.query.lang
                : 'ru';
    const messages = await import(`@/locales/${language}.json`)
    i18n.setLocaleMessage(language, messages);
    i18n.locale = language;
    window.document.title = "Conecoin";

    if(to.query.lang !== language) {
        next({
            path: to.path,
            query: Object.assign(to.query, {lang: language})
        })
        return
    }
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if(!Store.getters['user/getIsAuthorized']) {
            next({
                path: '/sign-in'
            })
        }
        else {
            next()
        }
    }
    else {
        next();
    }
}

export default beforeEachGuard
