import api from "@/api/api";

export default {
    data: () => ({
        startDate: null,
        endDate: null,
        startDateCopy: null,
        endDateCopy: null,
    }),
    methods: {
        async loadTraderProfitHistoryTimeRange() {
            try {
                const response = await api.loadTraderProfitHistoryTimeRange({
                    traderId: this.id || this.traderId
                });
                this.startDate = this.startDateCopy = response.periodStart;
                this.endDate = this.endDateCopy = response.periodEnd;
            } catch (e) {
                console.log(e);
            }
        },
    }
}