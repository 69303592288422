<template>
  <div class="traders-page">
    <TradersFilter @loadFavorite="loadFavorite" @setDuration="setDuration" @stopSlider="stopSlider" ref="filter"/>

    <div class="traders-page-grid">
      <TradersCard v-for="(item, index) in traders"
                   :key="index"
                   @investmentCreated="loadTraders"
                   :trader="item"/>
    </div>

    <NoRecordsFound v-if="!traders.length && !getLoading"/>

    <ReferralBanner v-if="!getLoading"/>

    <transition name="slide-fade" mode="out-in">
      <ProfitDistribution v-if="isProfitModalVisible" @close="isProfitModalVisible = false"/>
    </transition>


  </div>
</template>

<script>
import TradersFilter from "../../components/traders/TradersFilter";
import TradersCard from "../../components/traders/TradersCard";
import ProfitDistribution from "../../components/traders/ProfitDistribution";
import ReferralBanner from "../../components/utility/ReferralBanner";
import api from "@/api/api";
import {mapGetters, mapMutations} from "vuex";
import NoRecordsFound from "@/components/utility/NoRecordsFound";

export default {
  name: 'Traders',
  components: {NoRecordsFound, ProfitDistribution, TradersCard, TradersFilter, ReferralBanner},
  data: () => ({
    isProfitModalVisible: false,
    page: 0,
    size: 10,
    traders: [],
    isFavorite: false,
    investmentDuration: "SIX_MONTH",
    investorPercent: '',
  }),
  computed: {
    ...mapGetters({
      getLoading: 'general/getLoading'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'general/setLoading'
    }),
    stopSlider(payload) {
      this.investorPercent = payload.investorPercent;
      this.loadTraders();
    },
    setDuration(duration) {
      this.investmentDuration = duration;
      this.loadTraders();
    },
    loadFavorite(val) {
      this.page = 0;
      this.isFavorite = val;
      this.loadTraders();
    },
    async loadTraders() {
      try {
        this.setLoading(true);
        const response = await api.loadTraders({
          page: this.page,
          size: this.size,
          sort: 'created,desc',
          onlyFavorite: this.isFavorite,
          duration: this.investmentDuration,
          investmentSum: this.$refs.filter.investmentSum,
          investorPercent: this.investorPercent
        })
        this.traders = response.content;
      } catch (e) {
        console.log(e);
      } finally {
        this.setLoading(false);
      }
    }
  },
  mounted() {
    this.loadTraders();
  }
}
</script>
