export default {
  namespaced: true,
  state: {
    sellPrice: 0,
    sellAmount: 0,
    sellTotal: 0,

    buyPrice: 0,
    buyAmount: 0,
    buyTotal: 0
  },
  mutations: {
    setSellValues(state, payload) {
      console.log(payload)
      state.sellPrice = payload.sellPrice;
      state.sellAmount = payload.sellAmount;
    },
    setBuyValues(state, payload) {
      state.buyPrice = payload.buyPrice;
      state.buyAmount = payload.buyAmount;
    }
  },
  getters: {
    getSellPrice: state => state.sellPrice,
    getSellAmount: state => state.sellAmount,
    getBuyPrice: state => state.buyPrice,
    getBuyAmount: state => state.buyAmount
  }
}
