<template>
  <div class="market-wrap market market--buy">
    <div class="box">
    <div class="market__head">
      <div class="market__title">
        {{ $t("marketBuyTitle") }}
      </div>
    <div class="toggle-wrap" >
      <div class="toggle">
        <button class="toggle__btn" :class="{active: isLimitTab}" @click="currentTab = 'limit'">
          {{ $t("marketToggleLeft") }}
        </button>
        <button class="toggle__btn" :class="{active: !isLimitTab}" @click="currentTab = 'market'">
          {{ $t("marketToggleRight") }}
        </button>


      </div>
      <div class="tooltip"  @mouseover="showTooltip = true"  @mouseleave="showTooltip = false">
        <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
        </svg>

        <div class="tooltip__content" v-show="showTooltip">
          {{ $t("tooltipText")}}
        </div>
      </div>
    </div>

    </div>

    <div class="market__field">
      <p>  {{ $t("marketField") }}</p>

      <p> {{formatNumber(rightPairBalance)}} {{getRightPairPart}} </p>
    </div>

    <div class="market-input">
      <div class="market-input__title">{{ $t("marketInputPrice") }}</div>
      <input type="text"
             class="market-input__input"
             v-if="!isLimitTab"
             disabled
             :placeholder="'Market'">
      <input type="number"
             v-else
             class="market-input__input"
             @keyup.enter="placeBuyOrder"
             v-model.number="buyPrice"
             @input="calcTotalBuy">
      <div class="market-input__icon">
        {{getRightPairPart}}
      </div>
    </div>
    <div class="market-input">
      <div class="market-input__title">{{ $t("marketInputAmount") }}</div>
      <input type="number"
             class="market-input__input"
             placeholder="0.00"
             @keyup.enter="placeBuyOrder"
             @input="calcTotalBuy"
             v-model.number="buyAmount">
      <div class="market-input__icon">
        {{getLeftPairPart}}
      </div>
    </div>

    <div class="market-percents">
      <button class="market-percents__btn"
              :class="{ active: buyPercent === 25 }"
              @click="setBuyAmount(0.25)"
      >
        25%
      </button>
      <button class="market-percents__btn"
              :class="{ active: buyPercent === 50 }"
              @click="setBuyAmount(0.5)"
      >
        50%
      </button>
      <button class="market-percents__btn"
              :class="{ active: buyPercent === 75 }"
              @click="setBuyAmount(0.75)"
      >
        75%
      </button>
      <button class="market-percents__btn"
              :class="{ active: buyPercent === 100 }"
              @click="setBuyAmount(1)">
        100%
      </button>
    </div>

    <div class="market-input" v-if="isLimitTab">
      <div class="market-input__title">{{ $t("marketInputTotal") }}</div>
      <input type="number"
             class="market-input__input"
             placeholder="0.00"
             v-model.number="buyTotal"
             @keyup.enter="placeBuyOrder"
             @input="calcPriceAndAmountBuy">
      <div class="market-input__icon">
        {{getRightPairPart}}
      </div>
    </div>

    <button class="market__btn" @click="placeBuyOrder" v-if="getIsAuthorized">
      {{ $t("marketBuyBtn") }} {{getLeftPairPart}}
    </button>
    <router-link v-else to="/sign-in" class="market__btn market__btn--border">
      <span>Login </span>  or <span> register</span> to trade
    </router-link>

  </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "../../../api/api";
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: 'BuyMarket',
  mixins: [formatNumberMixin],
  data: () => ({
    currentTab: 'limit',
    showTooltip: false,
    buyPrice: null,
    buyAmount: null,
    buyTotal: null,
    rightPairBalance: 0,
    buyPercent: 0,
  }),
  computed: {
    ...mapGetters({
      getPairName: 'pair/getPairName',
      getIsAuthorized: 'user/getIsAuthorized',
      getLastPrice: 'socket/getLastPrice',
      getBuyPrice: 'market/getBuyPrice',
      getBuyAmount: 'market/getBuyAmount'
    }),
    getLeftPairPart() {
      return this.getPairName.split('-')[0];
    },
    getRightPairPart() {
      return this.getPairName.split('-')[1];
    },
    isLimitTab() {
      return this.currentTab === 'limit'
    }
  },
  watch: {
    currentTab() {
      this.buyPrice = this.formatNumber(this.getLastPrice);
      this.sellPrice = this.formatNumber(this.getLastPrice);
    },
    async getPairName() {
      if(this.getIsAuthorized) {
        await this.checkRightBalance();
      }
    },
    getBuyPrice(val) {
      this.buyPrice = this.formatNumber(val);
      this.buyAmount = this.formatNumber(this.getBuyAmount);
      this.calcTotalBuy();
    },
    getLastPrice(val) {
      this.buyPrice = this.formatNumber(val);
    }
  },
  methods: {
    ...mapMutations({
      setAllowChartRefresh: 'general/setAllowChartRefresh',
      setSuccess: 'general/setSuccess',
      setError: 'general/setError',
      setLoading: 'general/setLoading'
    }),
    setBuyAmount(percent) {
      this.buyPercent = percent * 100;
      this.buyTotal = this.formatNumber(this.rightPairBalance * percent);
      console.log(this.buyTotal)
      if(this.buyTotal) {
        this.buyAmount = this.formatNumber(this.buyTotal / this.buyPrice);
      }
    },
    calcTotalBuy() {
      this.buyTotal = this.formatNumber(this.buyPrice * this.buyAmount);
    },
    calcPriceAndAmountBuy() {
      if(this.buyPrice) {
        this.buyAmount = this.formatNumber(this.buyTotal / this.buyPrice);
        return;
      }
      if(this.buyAmount) {
        this.buyPrice = this.formatNumber(this.buyTotal / this.buyAmount);
      }
    },
    async loadAvailableWalletsByUser() {
      try {
        return await api.loadAvailableWalletsByUser();
      } catch (e) {
        await this.$router.push('/502');
        console.log(e);
      }
    },
    async validate() {
      const availableWallets = await this.loadAvailableWalletsByUser();
      if(availableWallets.includes(this.getLeftPairPart)) {
        this.setError({
          message: `Please create the ${this.getLeftPairPart} wallet`
        })
        return;
      }
      if(this.currentTab === 'limit' && (!this.buyPrice || !this.buyAmount)) {
        this.setError({
          message: 'Please fill all fields'
        })
        return false;
      }
      if(this.currentTab === 'market' && !this.buyAmount) {
        this.setError({
          message: 'Please fill all fields'
        })
        return false;
      }
      return true
    },
    async checkRightBalance() {
      try {
        let wallet = await api.loadWalletByUser({
          currency: this.getRightPairPart
        });
        this.rightPairBalance = this.formatNumber(wallet.availableBalance);
      } catch (e) {
        console.log(e);
      }
    },
    async placeBuyOrder() {
      this.setAllowChartRefresh(false);
      this.setLoading(true);
      const isValid = await this.validate();
      if(!isValid) {
        this.setLoading(false);
        return;
      }
      try {
        switch (this.currentTab) {
          case 'limit':
            await api.placeLimitOrder({
              pairName: this.getPairName,
              price: this.buyPrice,
              quantity: this.buyAmount,
              side: 'BUY'
            });break;
          case 'market':
            await api.placeMarketOrder({
              pairName: this.getPairName,
              price: 0,
              quantity: this.buyAmount,
              side: 'BUY'
            });break;
        }
        this.setSuccess({
          message: 'Order placed'
        })
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
        console.log(e);
      } finally {
        await this.checkRightBalance();
        this.setLoading(false);
        this.setAllowChartRefresh(true);
      }
    },
    initPrices() {
      this.buyPrice = this.formatNumber(this.getLastPrice);
    }
  },
  mounted() {
    this.initPrices();
    if(this.getIsAuthorized) {
      this.checkRightBalance();
    }
  }
}
</script>
