import { render, staticRenderFns } from "./InvestConfirm.vue?vue&type=template&id=3aba7b84&scoped=true&"
import script from "./InvestConfirm.vue?vue&type=script&lang=js&"
export * from "./InvestConfirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aba7b84",
  null
  
)

export default component.exports