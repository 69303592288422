<template>
  <div class="deposit-page" v-show="!getLoading">
    <div class="deposit-page__col">
      <h1 class="page-title">{{ $t("menuDeposit") }}</h1>

      <CoinSelect
          v-if="wallets.length"
          :wallets="wallets"
          :currentWallet="currentWallet"
          @select="selectWallet"
      />

      <div v-if="currentWallet && !currentWallet.needImport">

        <div class="deposit-page__wrap">
          <div class="title-sm">
            {{ $t("networkTransferTitle") }}
          </div>

          <div class="d-flex">
            <button class="transfer-btn transfer-btn--border"
                    v-for="(item, index) in networks" @click="selectNetwork(item)" :key="index"
                    :class="{active: currentNetwork.network  === item.network && !isCardNetwork}">
              <div class="transfer-btn__lg">
                {{ item.network === 'CURRENCY_DEFAULT' ? currentWallet.currency : item.network }}
              </div>

              <div class="transfer-btn__sm">
                {{ item.fullName }}
              </div>
            </button>

<!--            <button disabled class="transfer-btn transfer-btn&#45;&#45;black"-->
<!--                    :class="{active: isCardNetwork}"-->
<!--                    @click="setCardNetwork">-->
<!--              <div class="transfer-btn__icon">-->
<!--                <img src="@/assets/img/svg/logo-visa.svg" alt="">-->
<!--              </div>-->
<!--              <div class="transfer-btn__icon">-->
<!--                <img src="@/assets/img/svg/logo-mastercard.svg" alt="">-->
<!--              </div>-->
<!--            </button>-->
          </div>
        </div>

        <div v-if="!isCardNetwork">
          <div class="deposit-page__wrap">
            <div class="title-sm" v-if="currentNetwork && currentWallet">
              {{currentNetwork.network === 'CURRENCY_DEFAULT' ? currentWallet.currency : currentNetwork.network}}
              {{ $t("btcAddressTitle") }}

              <div class="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                      fill="#0363FE"/>
                </svg>

                <div class="tooltip__content" v-show="showTooltip">
                  {{ $t("tooltipText") }}
                </div>
              </div>
            </div>

            <div class="page-input">
              <input v-if="currentNetwork" type="text" class="input" readonly :value="currentNetwork.address" ref="copyInput"
                     v-on:focus="$event.target.select()">
              <button class="page-input__icon" @click="doCopy">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3842C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.01 4H4ZM2.003 6L2 18H12V6H2.003ZM6 4H14V14H16V2H6V4Z"
                      fill="#5C93E5"/>
                </svg>
              </button>

              <div class="tooltip" v-show="isCopied">
                <div class="tooltip__content">
                  {{ $t("tooltipCopy") }}
                </div>
              </div>
            </div>
          </div>

          <div class="deposit-btns">
            <div class="deposit-btns__title">
              {{ $t("depositBtnsTitle") }}
            </div>

            <div class="deposit-btns__wrap">
              <button class="deposit-btns__btn" v-for="item in pairs"
                      :key="item"
                      @click="goToTrade(item)">{{ item.replace('-', '/') }}</button>
            </div>
          </div>
        </div>

        <div v-else>

          <div v-show="!getIsVerified">
            <div class="deposit-page__box attention">
              <div class="attention__icon">
                <img src="@/assets/img/svg/icon-attention.svg" alt="">
              </div>

              <div class="attention__text">
                {{ $t("attentionDepositText") }}
              </div>
            </div>

            <div class="deposit-page__box">
              <div class="table-sm">
                <table cellpadding="0" cellspacing="0">
                  <thead class="custom-table-head">
                  <tr>
                    <td>
                    </td>
                    <td>
                      {{ $t("userNotVerified") }}
                    </td>
                    <td>
                      {{ $t("userVerified") }}
                    </td>
                  </tr>
                  </thead>

                  <tbody>
                  <tr>
                    <td>
                      <p class="table-sm__title">
                        {{ $t("tableSmDepositTitle1") }}
                      </p>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-ok.svg" alt="">
                      </div>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-ok.svg" alt="">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="table-sm__title">
                        {{ $t("tableSmDepositTitle2") }}
                      </p>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-ok.svg" alt="">
                      </div>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-ok.svg" alt="">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="table-sm__title">
                        <span class="desktop">{{ $t("tableSmDepositTitle3") }} </span>
                        <span class="mobile">{{ $t("tableSmDepositTitle3Mob") }}</span>
                      </p>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-no.svg" alt="">
                      </div>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-ok.svg" alt="">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="table-sm__title">
                        <span class="desktop"> {{ $t("tableSmDepositTitle4") }} </span>
                        <span class="mobile">{{ $t("tableSmDepositTitle4Mob") }}</span>
                      </p>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-no.svg" alt="">
                      </div>
                    </td>
                    <td>
                      <div class="table-sm__icon">
                        <img src="@/assets/img/svg/icon-ok.svg" alt="">

                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-show="getIsVerified">
            <div class="deposit-page__wrap">
              <div class="title-sm">
                {{ $t("btcAddressTitle") }}

                <div class="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                        fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showTooltip">
                    {{ $t("tooltipText") }}
                  </div>
                </div>
              </div>

              <div class="page-input">
                <input v-if="currentNetwork" type="text" class="input" readonly :value="currentNetwork.address" ref="copyInput"
                       v-on:focus="$event.target.select()">
                <button class="page-input__icon" @click="doCopy">
                  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4 4V1C4 0.734784 4.10536 0.48043 4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V15C18 15.2652 17.8946 15.5196 17.7071 15.7071C17.5196 15.8946 17.2652 16 17 16H14V19C14 19.552 13.55 20 12.993 20H1.007C0.875127 20.0008 0.744397 19.9755 0.622322 19.9256C0.500247 19.8757 0.389233 19.8022 0.295659 19.7093C0.202084 19.6164 0.127793 19.5059 0.0770543 19.3842C0.0263156 19.2624 0.000129374 19.1319 0 19L0.00300002 5C0.00300002 4.448 0.453 4 1.01 4H4ZM2.003 6L2 18H12V6H2.003ZM6 4H14V14H16V2H6V4Z"
                        fill="#5C93E5"/>
                  </svg>
                </button>

                <div class="tooltip" v-show="isCopied">
                  <div class="tooltip__content">
                    {{ $t("tooltipCopy") }}
                  </div>
                </div>
              </div>
            </div>

            <div class="deposit-page__box attention attention--primary">
              <div class="attention__icon">
                <img src="@/assets/img/svg/icon-verified.svg" alt="">
              </div>

              <div class="attention__text">
                {{ $t("verifiedDepositText") }}
                <span> {{ $t("verifiedBoldDepositText") }}</span>
              </div>
            </div>
          </div>

        </div>

        <button class="page-btn" :disabled="!getIsVerified" v-if="isCardNetwork">
          {{ $t("depositContinueBtn") }}
        </button>
      </div>

      <div v-else>
        <div class="d-flex" v-if="currentWallet && currentWallet.needImport">
          <button class="page-btn page-btn--border" @click="createWalletByUser">
          
              {{$t("createWallet")}}
        
          </button>
        </div>
      </div>

    </div>


    <div class="deposit-page__col">
      <CoinBalance
          v-if="wallet && currentWallet && !currentWallet.needImport"
          :isWithdraw="false"
          :wallet="wallet"/>
    </div>

  </div>
</template>

<script>
import CoinSelect from "../../../components/wallet/CoinSelect";
import CoinBalance from "../../../components/wallet/CoinBalance";
import {mapGetters, mapMutations} from "vuex";
import api from "../../../api/api";

export default {
  name: "Deposit",
  components: {
    CoinSelect,
    CoinBalance
  },
  data: () => ({
    showTooltip: false,
    addressValue: '0xjdkf9ficksock4m4g354l5k6mdj5',
    isCopied: false,
    isCardNetwork: false,

    wallets: [],
    currentWallet: null,
    wallet: null,
    networks: [],
    pairs: [],
    currentNetwork: null
  }),
  computed: {
    ...mapGetters({
      getPairName: 'pair/getPairName',
      getIsVerified: 'user/getIsVerified',
      getLoading: 'general/getLoading'
    }),
    rightPairPart() {
      return this.getPairName.split('-')[1]
    }
  },
  methods: {
    ...mapMutations({
      setPair: 'pair/setPair',
      setLoading: 'general/setLoading'
    }),
    doCopy() {
      this.$refs.copyInput.focus();
      document.execCommand('copy');
      this.isCopied = true;
    },
    setCardNetwork() {
      this.isCardNetwork = true;
    },
    selectNetwork(network) {
      this.isCardNetwork = false;
      this.currentNetwork = network;
    },
    selectWallet(wallet) {
      this.currentWallet = wallet;
      this.loadWalletDetailsByUser();
      this.loadWalletByUser();
    },
    async createWalletByUser() {
      try {
        await api.createWalletByUser({
          currency: this.currentWallet.currency
        })
        this.currentWallet.needImport = false;
        await this.loadWalletDetailsByUser();
        await this.loadWalletByUser();
      } catch (e) {
        console.log(e)
      }
    },
    async goToTrade(payload) {
      const pair = await this.loadPairInfo(payload);
      if (pair) {
        this.setPair({
          index: pair.id,
          pair: pair.pairName
        });
        await this.$router.push(`/trading/${pair.pairName}`)
      } else {
        await this.$router.push(`/404`)
      }
    },
    async loadPairInfo(pair) {
      try {
        return await api.loadPairInfo({
          firstCurrency: pair.split('-')[0],
          quotedCurrency: pair.split('-')[1]
        });
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    async loadWalletCurrencyByUser() {
      try {
        let response = await api.loadWalletCurrencyByUser();
        response = response.map(i => ({
          currency: i,
        }))
        response.forEach(i => this.$set(i, 'needImport', false));
        return response;
      } catch (e) {
        console.log(e);
      }
    },
    async loadAvailableWalletsByUser() {
      try {
        let response = await api.loadAvailableWalletsByUser({
          page: 0,
          size: 100
        });
        response = response.map(i => ({
          currency: i,
        }))
        response.forEach(i => this.$set(i, 'needImport', true));
        return response;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    async init() {
      this.setLoading(true);
      const response = await Promise.all([
        this.loadWalletCurrencyByUser(),
        this.loadAvailableWalletsByUser()
      ]);
      this.wallets = [...response[0], ...response[1]]
      if (this.$route.params.currency) {
        this.currentWallet = this.wallets.find(i => i.currency === this.$route.params.currency);
      } else {
        this.currentWallet = this.wallets.find(i => i.currency === this.rightPairPart);
      }
      if (!this.currentWallet.needImport) {
        await this.loadWalletDetailsByUser();
        await this.loadWalletByUser();
      }
      this.setLoading(false);
    },
    async loadWalletByUser() {
      try {
        this.wallet = await api.loadWalletByUser({
          currency: this.currentWallet.currency
        })
      } catch (e) {
        this.wallet = {
          totalBalance: 0,
          availableBalance: 0,
          lockedBalance: 0
        }
        console.log(e);
      }
    },
    async loadWalletDetailsByUser() {
      try {
        const response = await api.loadWalletDetailsByUser({
          currency: this.currentWallet.currency
        })
        const jsonCoins = await import('@/utils/coins.json');
        this.networks = Object.entries(response.addresses).map(([prop, value]) => ({
          network: prop,
          address: value,
          fullName: jsonCoins.coins.find(i => i.currency === (prop === 'CURRENCY_DEFAULT' ? this.currentWallet.currency : prop)).fullName
        }));
        this.pairs = Object.values(response.tradingPairs);
        this.currentNetwork = this.networks[0];
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.init();
  }

}
</script>
