<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-accepted">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="popup__img">
            <img src="@/assets/img/success.png" alt="">
          </div>

          <div class="popup__title">
            {{ $t("appAcceptedTitle") }}

          </div>

          <div class="popup-accepted__msg">
            {{$t("appAcceptedMsg")}}
           <strong> {{$t("appAcceptedMsgStrong")}} </strong>
            <a href="#" class="link">{{$t("appAcceptedMsgLink")}}</a>
          </div>

          <button class="page-btn" @click="close">OK</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "AppAccepted",
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>