<template>
  <div class="card-user card-trader">
    <div class="card-user__top">
      <div class="card-traders__elect"
           :class="{active: isTraderSelected}"
           @click="isTraderSelected = !isTraderSelected"
           v-show="$route.name !== 'Profile'">
        <div class="star">
          <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.0002 14.3992L4.82768 17.1186L5.81555 11.3589L1.6309 7.27987L7.41394 6.43954L10.0002 1.19922L12.5864 6.43954L18.3695 7.27987L14.1848 11.3589L15.1727 17.1186L10.0002 14.3992Z"
                  stroke="#70707C"/>
          </svg>
        </div>
      </div>

      <div class="card-user__avatar">

        <img :src="loadAvatar(traderAvatar, true)" alt="trader-avatar">

        <div class="card-user__icon" v-show="traderVerified">
          <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66667 14L0 8.0552L1.9975 5.95966L5.66667 9.79406L15.0025 0L17 2.1104L5.66667 14Z"
                  fill="#FCFCFC"/>
          </svg>

        </div>

      </div>

      <div class="card-user__name">
        {{ traderFullName }}
      </div>

      <div class="card-user__wrap">
        <div class="card-traders__status" :class="{topM: isTopOfTheMonth, new: isNew, topY: isTopOfTheYear,
        topW: isTopOfTheWeek}" v-if="!isNone || isNew">
          <span v-show="isTopOfTheMonth"> {{ $t("topOfTheMonth") }}</span>
          <span v-show="isTopOfTheYear"> {{ $t("topOfTheYear") }}</span>
          <span v-show="isTopOfTheWeek"> {{ $t("topOfTheWeek") }} 🔥</span>
          <span v-show="isNew"> {{ $t("cardNew") }}</span>
        </div>

        <!--        <div class="card-trader__id">-->
        <!--          ID 5915-->
        <!--        </div>-->
      </div>


    </div>

    <div class="card-trader__bottom">
      <div class="card-traders__row">
        <div class="card-trader__limit">
          <div class="title-sm" v-show="!isLimitOver">
            {{ $t("tradersCardLimitTitle") }}
          </div>
          <div class="title-sm danger" v-show="isLimitOver">
            Достигнут лимит
          </div>

          <div class="status-amount">
            <p>${{ formatNumber(traderBagAmount) }} </p>
            <p>${{ formatNumber(traderMaxBagAmount) }}</p>
          </div>

          <div class="status-bar" :class="{danger: isLimitOver}">
            <span :style="{width: `${limitPercent}%`}"></span>
          </div>
        </div>
      </div>


      <div class="card-traders__block">
        <div class="card-traders__row">
          <div class="card-traders__col">
            <div class="d-flex">
              <div class="title-sm">
                {{ $t("tradersCardRate") }}
              </div>
              <div class="card-traders__icon shadow card-trader__crown" v-if="isCrown">
                <img src="@/assets/img/svg/crown.svg" alt="">
              </div>
            </div>

            <div class="d-flex">
<!--              <p>{{ formatNumber(traderPercent, true) }} %</p>-->
              <div class="tooltip" @click="showTooltip = !showTooltip">
                <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                      fill="#0363FE"/>
                </svg>

                <div class="tooltip__content" v-show="showTooltip">
                  {{ $t("tooltipText") }}
                </div>
              </div>
            </div>
          </div>

          <div class="card-traders__col">
            <div class="d-flex">
              <div class="title-sm">
                Api Binance
              </div>
              <div class="card-traders__icon" style="margin-left: 7px">
                <img src="@/assets/img/svg/traders-check.svg" alt="">
              </div>

            </div>

            <div class="select-sm">
              <div class="select-sm__current" @click="isOpenSelect = !isOpenSelect">
                <p> Проверен</p>
                <div class="select-sm__icon">
                </div>

              </div>

              <div class="select-sm__drop" v-show="isOpenSelect">
                <ul>
                  <li class="select-sm__item">
                    API Binance
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="card-traders__row" v-show="$route.name !== 'Profile'">
          <div class="card-traders__col">
            <div class="title-sm">Срок</div>
            <p>{{ traderTerm }} дня</p>
          </div>

          <div class="card-traders__col">
            <div class="title-sm"><span class="desktop">Количество </span> инвесторов</div>
            <p>{{ traderInvestmentsCount }}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="card-trader__btn" v-show="$route.name !== 'Profile'">
      <button class="traders-btn" @click="isInvestModalVisible = true"
              :class="{yellow : isInvest & !isSubscribe, danger : !isSubscribe & !isInvest}">
        <div class="traders-btn__top">
          <p class="traders-btn__price">{{traderMinInvestmentSum}} USDT</p>

          <span v-show="!isSubscribe & !isInvest">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M13.6471 7.44186C13.6471 5.96158 13.0521 4.54193 11.9931 3.49522C10.934 2.4485 9.49769 1.86047 8 1.86047C6.50231 1.86047 5.06596 2.4485 4.00693 3.49522C2.9479 4.54193 2.35294 5.96158 2.35294 7.44186V14.8837H13.6471V7.44186ZM15.5294 15.5042L15.9059 16C15.9583 16.0691 15.9903 16.1513 15.9981 16.2373C16.0059 16.3233 15.9894 16.4098 15.9503 16.4871C15.9112 16.5643 15.8512 16.6293 15.7768 16.6747C15.7025 16.7201 15.6168 16.7442 15.5294 16.7442H0.470588C0.383195 16.7442 0.297527 16.7201 0.223186 16.6747C0.148844 16.6293 0.0887652 16.5643 0.0496815 16.4871C0.0105978 16.4098 -0.00594673 16.3233 0.00190173 16.2373C0.00975018 16.1513 0.0416815 16.0691 0.0941178 16L0.470588 15.5042V7.44186C0.470588 5.46816 1.26386 3.57529 2.6759 2.17967C4.08794 0.784051 6.00308 0 8 0C9.99692 0 11.9121 0.784051 13.3241 2.17967C14.7361 3.57529 15.5294 5.46816 15.5294 7.44186V15.5042ZM5.64706 17.6744H10.3529C10.3529 18.2912 10.105 18.8827 9.66378 19.3189C9.22252 19.755 8.62404 20 8 20C7.37596 20 6.77748 19.755 6.33622 19.3189C5.89496 18.8827 5.64706 18.2912 5.64706 17.6744Z"
                  fill="#FD3844"/>
              </svg>
            </span>
        </div>

        <div class="traders-btn__bottom">
          <span v-show="isInvest & !isSubscribe"> Подписаны на уведомление </span>
          <span v-show="isInvest & isSubscribe"> Передать в управление </span>
          <span v-show="!isSubscribe & !isInvest">Подписка на уведомление</span>
        </div>

      </button>
    </div>

    <div class="card-trader__option" v-show="$route.name === 'Profile'">
      <div class="title-sm">
        {{ $t("traderCardShowTitle") }}
      </div>

      <div class="card-trader__btns">
        <button class="card-trader__toggle" :class="{active: !isShow}" @click="changeTraderStatus('HIDDEN')">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.882 18.2517C15.1232 19.3603 13.0828 19.9474 11 19.9441C5.60803 19.9441 1.12203 16.0882 0.18103 11C0.61106 8.68517 1.78266 6.57056 3.52103 4.97166L0.39203 1.86507L1.80703 0.458862L21.606 20.1359L20.191 21.5411L16.881 18.2517H16.882ZM4.93503 6.37886C3.57604 7.60678 2.62935 9.21989 2.22303 11C2.53532 12.358 3.16229 13.6247 4.05403 14.6995C4.94577 15.7742 6.07766 16.6272 7.35958 17.1906C8.64151 17.754 10.0381 18.0122 11.4381 17.9447C12.8382 17.8772 14.203 17.4858 15.424 16.8017L13.396 14.7863C12.5327 15.3268 11.5102 15.5596 10.4963 15.4466C9.48231 15.3337 8.53707 14.8816 7.8156 14.1646C7.09413 13.4476 6.63926 12.5083 6.52559 11.5006C6.41193 10.493 6.64621 9.47681 7.19003 8.61886L4.93503 6.37886ZM11.914 13.3135L8.67203 10.0917C8.49409 10.5418 8.45222 11.0337 8.55154 11.5072C8.65085 11.9806 8.88705 12.4149 9.23115 12.7569C9.57525 13.0988 10.0123 13.3336 10.4887 13.4323C10.9651 13.531 11.4601 13.4894 11.913 13.3125L11.914 13.3135ZM19.807 15.5635L18.376 14.1423C19.0445 13.1956 19.5204 12.1281 19.777 11C19.5053 9.8171 18.9943 8.70149 18.2752 7.72097C17.5561 6.74045 16.6438 5.91549 15.5939 5.29623C14.544 4.67698 13.3783 4.27637 12.1678 4.11875C10.9572 3.96113 9.72704 4.0498 8.55203 4.37936L6.97403 2.81116C8.22103 2.3242 9.58003 2.05588 11 2.05588C16.392 2.05588 20.878 5.91178 21.819 11C21.5126 12.6553 20.8239 14.2175 19.807 15.5635ZM10.723 6.53588C11.3595 6.49679 11.9971 6.59259 12.5936 6.81694C13.19 7.04129 13.7316 7.38906 14.1826 7.8372C14.6335 8.28534 14.9835 8.82361 15.2092 9.41634C15.435 10.0091 15.5314 10.6427 15.492 11.2753L10.722 6.53588H10.723Z"
                fill="#71727D"/>
          </svg>

          {{ $t("traderCardHide") }}
        </button>
        <button class="card-trader__toggle" :class="{active: isShow}" @click="changeTraderStatus('REAL')">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 0C16.392 0 20.878 3.88 21.819 9C20.879 14.12 16.392 18 11 18C5.60803 18 1.12203 14.12 0.18103 9C1.12103 3.88 5.60803 0 11 0ZM11 16C13.0395 15.9996 15.0184 15.3068 16.6129 14.0352C18.2074 12.7635 19.3229 10.9883 19.777 9C19.3213 7.0133 18.205 5.24 16.6107 3.97003C15.0163 2.70005 13.0383 2.00853 11 2.00853C8.96173 2.00853 6.98372 2.70005 5.38941 3.97003C3.79509 5.24 2.6788 7.0133 2.22303 9C2.67713 10.9883 3.7927 12.7635 5.38717 14.0352C6.98164 15.3068 8.96056 15.9996 11 16ZM11 13.5C9.80656 13.5 8.66196 13.0259 7.81805 12.182C6.97414 11.3381 6.50003 10.1935 6.50003 9C6.50003 7.80653 6.97414 6.66193 7.81805 5.81802C8.66196 4.97411 9.80656 4.5 11 4.5C12.1935 4.5 13.3381 4.97411 14.182 5.81802C15.0259 6.66193 15.5 7.80653 15.5 9C15.5 10.1935 15.0259 11.3381 14.182 12.182C13.3381 13.0259 12.1935 13.5 11 13.5ZM11 11.5C11.6631 11.5 12.299 11.2366 12.7678 10.7678C13.2366 10.2989 13.5 9.66304 13.5 9C13.5 8.33696 13.2366 7.70107 12.7678 7.23223C12.299 6.76339 11.6631 6.5 11 6.5C10.337 6.5 9.7011 6.76339 9.23226 7.23223C8.76342 7.70107 8.50003 8.33696 8.50003 9C8.50003 9.66304 8.76342 10.2989 9.23226 10.7678C9.7011 11.2366 10.337 11.5 11 11.5Z"
                fill="#70707C"/>
          </svg>

          {{ $t("traderCardShow") }}
        </button>
      </div>
    </div>

    <InvestModal v-if="isInvestModalVisible"
                 @close="isInvestModalVisible = false"
                 :avatar="traderAvatar"
                 :name="traderName"
                 :surname="traderSurname"
                 :minInvestmentSum="traderMinInvestmentSum"
                 :traderId="traderId"/>
  </div>

</template>

<script>
import InvestModal from "../modals/Invest/InvestModal";
import {formatNumberMixin, loadAvatarMixin} from "@/utils/mixins";
import {traderInfoMixin} from "@/mixins/traderInfoMixin";
import api from "@/api/api";

export default {
  name: 'UserInfoCard',
  mixins: [loadAvatarMixin, traderInfoMixin, formatNumberMixin],
  props: {
    trader: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isTraderSelected: false,
    isInvestModalVisible: false,
    isInvest: true,
    isSubscribe: true,
    showTooltip: false,
    isOpenSelect: false,
  }),
  computed: {
    isShow: {
      get() {
        return this.traderStatus === 'REAL';
      },
      set() {
        this.traderStatus === 'REAL' ? this.traderStatus = 'HIDDEN' : this.traderStatus = 'REAL';
      }
    }
  },
  methods: {
    async changeTraderStatus(status) {
      try {
        await api.changeTraderStatus({
          status, traderId: this.traderId
        })
        this.isShow = !this.isShow
      } catch (e) {
        console.log(e);
      }
    }
  },
  components: {InvestModal},
}
</script>
