import Store from '@/store'

import api from "@/api/api";

const TradingGuard = async (to, from, next) => {
    let pair = to.params.pair;
    const loadPairInfo = async (pair) => {
        try {
            return await api.loadPairInfo({
                firstCurrency: pair.split('-')[0],
                quotedCurrency: pair.split('-')[1]
            });
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    pair = await loadPairInfo(pair);
    if(!pair) {
        pair = JSON.parse(localStorage.getItem('pair'));
        if(pair) {
            pair = await loadPairInfo(pair.pair);
            if(!pair) {
                this.localStorage.removeItem('pair');
                Store.commit("pair/setPair", {
                    pair: 'EHT-USDT',
                    index: 2
                })
            }
            next({
                path: `/trading/ETH-USDT`
            });
            return
        } else {
            Store.commit("pair/setPair", {
                pair: 'EHT-USDT',
                index: 2
            })
            next()
            return
        }
    }
    to.params.firstCurrencyFullName = pair.firstCurrencyFullName;
    Store.commit("pair/setPair", {
        pair: pair.pairName,
        index: pair.id
    })
    next();
}

export default TradingGuard
