<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="popup__img">
            <img src="@/assets/img/error.png" alt="">
          </div>

          <div class="popup__title">
            {{ $t("modalError") }}

          </div>

          <div class="popup__msg">
            {{$t(message)}}
          </div>

          <button class="page-btn" @click="close">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalError',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
