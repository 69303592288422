<template>
  <div class="page-news__content">

    <div class="page-news__posts">
      <NewsPost v-for="(item,index) in news"
                :key="index"
                :id="item.id"
                :post-img="item.imageUrl"
                :post-hashtags="item.tags.split(' ')"
                :post-title="item.header"
                :post-text="item.newsText"
                :post-date="item.created"
                :reading-time="item.readingTime"
      />
      <NoRecordsFound v-if="!news.length && !getLoading"/>
      <Pagination v-if="total > 1" :pages-qt="total" @change="togglePage"/>
    </div>
    <NewsEventsList :events="events" @showAllEvents="showAllEvents"/>
  </div>
</template>

<script>
import NewsEventsList from "../NewsEventsList";
import NewsPost from "../NewsPost";
import api from "@/api/api";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import getLoadingMixin from "@/mixins/getLoadingMixin";
import Pagination from "@/components/utility/Pagination";

export default {
  name: "TabNews",
  mixins: [setLoadingMixin, getLoadingMixin],
  data: () => ({
    news: [],
    page: 1,
    size: 10,
    total: 0,
    events: [],
  }),
  methods: {
    showAllEvents() {
      this.$emit('showAllEvents');
    },
    async togglePage(btn) {
      this.setLoading(true);
      this.page = btn;
      await this.getAllNews();
      this.setLoading(false);
    },
    async getAllNews() {
      try {
        const response = await api.getAllNews({
          page: this.page - 1,
          size: this.size,
          sort: "created,desc",
        });
        this.news = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async getAllEvents() {
      try {
        const response = await api.getAllEvents({
          page: 0,
          size: 5,
          sort: "created,desc",
        });
        this.events = response.content;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getAllNews();
    await this.getAllEvents();
    this.setLoading(false);
  },
  components: {Pagination, NoRecordsFound, NewsEventsList, NewsPost},
}
</script>

<style scoped>

</style>