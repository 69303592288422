<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup" v-if="!type || type === 'DEFAULT'">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="popup__img">
            <img src="@/assets/img/success.png" alt="">
          </div>

          <div class="popup__title">
            {{ $t("modalSuccess") }}

          </div>

          <div class="popup__msg">
            {{$t(message)}}
          </div>

          <button class="page-btn" @click="close">OK</button>
        </div>
      </div>

      <div class="popup twoFa-modal" v-if="type === '2FA' || type === 'MAIL'">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="popup__img">
            <img src="@/assets/img/2fa-enabled.png" alt="" v-if="twoFaStatus === 'ENABLED'">
            <img src="@/assets/img/2fa-disabled.png" alt="" v-else>
          </div>

          <div class="twoFa-modal__title" v-show="twoFaStatus === 'ENABLED' && type === '2FA'">
            {{ $t("twoFaGoogleEnabledTitle") }}
          </div>
          <div class="twoFa-modal__title" v-show="twoFaStatus === 'DISABLED' && type === '2FA'">
            {{ $t("twoFaGoogleDisabledTitle") }}
          </div>
          <div class="twoFa-modal__title" v-show="twoFaStatus === 'ENABLED' && type === 'MAIL'">
            {{ $t("twoFaEnabledTitle") }}
          </div>

          <div class="twoFa-modal__title" v-show="twoFaStatus === 'DISABLED' && type === 'MAIL'">
            {{ $t("twoFaDisabledTitle") }}
          </div>


          <button class="page-btn" @click="close">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSuccess',
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true,
      default: () => 'DEFAULT'
    },
    twoFaStatus: {
      required: false,
      default: () => 'NO_STATUS'
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>
