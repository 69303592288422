<template>
  <div class="document">
    <div class="document__img" v-if="!isLoaded">
      <img src="@/assets/img/document-passport.png" alt="" v-show="isPassport">
      <img src="@/assets/img/document-id.png" alt="" v-show="isId">
      <img src="@/assets/img/document-driver.png" alt="" v-show="isDriver">
      <img src="@/assets/img/document-selfie.png" alt="" v-show="isSelfie">
    </div>

    <div class="document__img" v-else>
      <img :src="document" alt=""/>
    </div>

    <div class="document__title">
      <span v-show="isPassport">{{ $t("documentPass") }}</span>
      <span v-show="isId">{{ $t("documentId") }}</span>
      <span v-show="isDriver">{{ $t("documentDriver") }}</span>
      <span v-show="isSelfie">{{ $t("documentSelfie") }}</span>
    </div>

    <button class="upload-btn" v-if="!isLoaded" @click="openFileInput">
      <input
          type="file"
          accept="image/jpeg,image/png,image/jpeg"
          class="file-input"
          ref="passportInput"
          @change="uploadDocument"
      />
      <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.25 13.25H13.75V14.75H0.25V13.25ZM7.75 3.371V11.75H6.25V3.371L1.69675 7.925L0.63625 6.8645L7 0.5L13.3638 6.86375L12.3032 7.92425L7.75 3.3725V3.371Z"
            fill="white"/>
      </svg>

      <span v-show="!isSelfie"> {{ $t("uploadBtn") }} </span>
      <span v-show="isSelfie"> {{ $t("uploadBtnSelfie") }} </span>
    </button>

    <button class="upload-btn upload-btn--delete" v-if="isLoaded" @click="deleteDocument">
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.00005 3.93906L8.71255 0.226562L9.77305 1.28706L6.06055 4.99956L9.77305 8.71206L8.71255 9.77256L5.00005 6.06006L1.28755 9.77256L0.227051 8.71206L3.93955 4.99956L0.227051 1.28706L1.28755 0.226562L5.00005 3.93906Z"
            fill="white"/>
      </svg>


      {{ $t("deleteBtn") }}
    </button>
  </div>
</template>

<script>
import api from "@/api/api";
import processingMixin from "@/mixins/processingMixin";

export default {
  name: "DocumentUpload",
  mixins: [processingMixin],
  props: {
    isPassport: {
      type: Boolean,
      default: false
    },
    isId: {
      type: Boolean,
      default: false
    },
    isDriver: {
      type: Boolean,
      default: false
    },
    isSelfie: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
    }
  },
  data: () => ({
    isLoaded: false,
    document: null,
    id: null,
  }),
  methods: {
    openFileInput() {
      this.$refs.passportInput.click();
    },
    async loadDocument() {
      try {
        const response = await api.loadDocument({
          side: 'FRONT',
          type: this.type,
        });
        if(response) {
          this.document = `data:image/png;base64, ${response.document}`;
          this.id = response.id;
          this.isLoaded = true;
          this.$emit('uploaded');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async deleteDocument() {
      try {
        this.setLoading(true);
        await api.deleteDocument({
          docId: this.id,
        })
        this.document = null;
        this.id = null;
        this.isLoaded = false;
        this.$emit('removed');
      } catch (e) {
        console.log(e);
        this.setError({
          message: e.response.data.message,
        })
      } finally {
        this.setLoading(false);
      }
    },
    async uploadDocument() {
      try {
        this.setLoading(true);
        const response = await api.uploadDocument({
          file: this.$refs.passportInput.files[0],
          type: this.type,
          side: 'FRONT'
        });
        this.document = `data:image/png;base64, ${response.document}`;
        this.id = response.id;
        this.isLoaded = true;
        this.$emit('uploaded');
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
        console.log(e);
      } finally {
        this.setLoading(false);
      }
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.loadDocument();
    this.setLoading(false);
  }
}
</script>

<style scoped>

</style>