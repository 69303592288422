<template>
  <div class="twoFa-modal__wrap">
    <div class="twoFa-modal__title">
      {{ $t("googleStepOneTitle") }}
    </div>

    <div class="popup__text">
      {{ $t("googleStepFourText") }}

    </div>

    <form class="twoFa-modal__form">
      <div class="page-input" :class="{error: errorRepeatPassword}">
        <div class="page-input__title">
          {{ $t("repeatPassword") }}
        </div>

        <div class="page-input__wrap">
          <input :type="passFieldType"
                 class="input"
                 @focus="deleteError()"
                 v-model="repeatPassword">


          <button @click="toggleRepeatPasswordVisible"
                  type="button"
                  class="page-input__icon pass-toggle"
                  :class="{active: showPass}"

          ></button>
        </div>
        <div class="error__msg">
          <p>{{ errorRepeatPassword }}</p>
        </div>
      </div>

      <div class="page-input">
        <div class="page-input__title">
          {{ $t("googleAuthCodeTitle") }}
        </div>
      </div>
      <TwoFaGoogleAuthForm @twaCode="setTwaCode"/>
    </form>

  </div>
</template>

<script>
import TwoFaGoogleAuthForm from "../../utility/TwoFaGoogleAuthForm";

export default {
  name: "StepFour",
  components: {
    TwoFaGoogleAuthForm
  },
  data: () => ({
    errorRepeatPassword: '',
    passFieldType: 'password',
    repeatPassword: null,
    showPass: false
  }),
  methods: {
    setTwaCode(twaCode) {
      this.$emit('setTwaCode', {
        twaCode,
        password: this.repeatPassword
      });
    },
    deleteError() {
      this.errorRepeatPassword = ""
    },
    toggleRepeatPasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
  },
}
</script>
