<template>
  <div class="popup-bottom-container">
    <div class="popup-bottom__close" @click="close"></div>
    <div class="popup-bottom popup-bottom--buy active" >
      <BuyMarket/>
    </div>
  </div>
</template>

<script>
import BuyMarket from "../market/BuyMarket";

export default {
  name: "BuyMarketPopup",
  components: {
    BuyMarket
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>