<template>
  <div class="trader-deal">
    <div class="box trader-box trader-deal--desktop">
      <div class="trader-box__title">
        {{ $t("traderDealTitle") }}
      </div>

      <div class="trader-deal__wrap">
        <div class="deal-diagram">
          <div class="deal-diagram__image">
            <svg class="diagram" width="155" height="97" viewBox="0 0 155 97" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M143.968 95.9242C148.076 97.025 152.34 94.589 153.025 90.3911C154.507 81.313 154.354 72.0215 152.551 62.9594C150.247 51.3731 145.308 40.4723 138.116 31.1002C130.925 21.7282 121.674 14.136 111.079 8.91116C100.484 3.6863 88.8289 0.96875 77.0156 0.96875C65.2024 0.96875 53.5475 3.6863 42.9525 8.91116C32.3575 14.136 23.1065 21.7282 15.915 31.1002C8.72357 40.4723 3.78448 51.3731 1.47983 62.9594C-0.32274 72.0215 -0.475895 81.313 1.00588 90.3911C1.69108 94.589 5.95486 97.0251 10.0634 95.9242C14.1719 94.8233 16.5588 90.6003 15.9787 86.3866C15.0459 79.6105 15.2456 72.708 16.587 65.9644C18.4307 56.6954 22.382 47.9747 28.1351 40.4771C33.8883 32.9794 41.2891 26.9057 49.7651 22.7258C58.2411 18.5459 67.565 16.3719 77.0156 16.3719C86.4662 16.3719 95.7902 18.5459 104.266 22.7258C112.742 26.9057 120.143 32.9794 125.896 40.4771C131.649 47.9747 135.601 56.6954 137.444 65.9644C138.786 72.708 138.985 79.6105 138.053 86.3866C137.472 90.6003 139.859 94.8233 143.968 95.9242Z"
                  fill="#100F14"/>
            </svg>

            <svg class="diagram-fill" width="155" height="100" viewBox="0 0 155 100" fill="none"
                 xmlns="http://www.w3.org/2000/svg">

              <defs>
                <linearGradient id="half" v-if="diagramPercent">
                  <stop offset="0%" stop-color="#0ECB81"/>
                  <stop offset="50%" stop-color="#0ECB81"/>
                  <stop :offset="diagramPercent + '%'" stop-color="#100F14" stop-opacity="0"/>
                  <stop offset="100%" stop-color="#100F14" stop-opacity="0"/>
                </linearGradient>
              </defs>

              <path
                  d="M141.565 98.5153C146.964 100.244 152.812 97.2587 153.799 91.6758C155.324 83.0615 155.401 74.2257 154.005 65.54C152.063 53.4607 147.324 42.0107 140.172 32.1155C133.02 22.2202 123.655 14.1574 112.834 8.57848C102.013 2.99961 90.0399 0.0612719 77.8821 0.000947845C65.7242 -0.0593762 53.7228 2.76001 42.8478 8.23123C31.9728 13.7025 22.5293 21.672 15.2807 31.4958C8.03195 41.3196 3.18139 52.722 1.12099 64.7815C-0.360642 73.4534 -0.369576 82.2902 1.07052 90.9197C2.00365 96.5114 7.82081 99.5542 13.2366 97.8789V97.8789C18.6248 96.2122 21.5518 90.4952 20.8785 84.8955C20.2156 79.3814 20.3492 73.7815 21.2905 68.2721C22.8068 59.3972 26.3765 51.0058 31.711 43.7762C37.0455 36.5466 43.9952 30.6816 51.9985 26.6552C60.0017 22.6288 68.8339 20.5539 77.7812 20.5983C86.7285 20.6427 95.5399 22.8051 103.503 26.9107C111.467 31.0164 118.359 36.9501 123.622 44.2323C128.885 51.5145 132.373 59.9408 133.802 68.8304C134.689 74.349 134.768 79.95 134.051 85.4574C133.323 91.0501 136.193 96.7953 141.565 98.5153V98.5153Z"
                  fill="url(#half)"/>
            </svg>

          </div>

          <div class="deal-diagram__percent">
            {{ diagramPercent + '%'}}
          </div>

          <div class="deal-diagram__bottom">
            <span>0%</span>
            <span>100%</span>
          </div>
        </div>

        <div class="trader-deal__amounts">
          <div class="trader-deal__row" v-if="$route.name !== 'Profile'">
            <div>
              <div class="trader-box__title-sm">
                {{ $t("traderDealTotal") }}
              </div>

              <div class="trader-box__amount">
                {{totalDeals}}
              </div>
            </div>
          </div>
          <div class="trader-deal__row">
            <div class="trader-deal__col">
              <div class="trader-box__title-sm">
                {{ $t("traderDealWinning") }}
              </div>

              <div class="trader-box__amount primary">
                {{winningDeals}}
              </div>
            </div>

            <div class="trader-deal__col">
              <div class="trader-box__title-sm">
                {{ $t("traderDealLosing") }}
              </div>

              <div class="trader-box__amount danger">
                {{loosingDeals}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="box trader-box trader-deal--mobile">
      <div class="trader-box__title">
        {{ $t("traderDealTitle") }}
      </div>

      <div class="trader-deal__wrap">
        <VueSlickCarousel :arrows="false" :dots="true">
          <div>
            <div class="deal-diagram">
              <div class="deal-diagram__image">
                <svg class="diagram" width="155" height="97" viewBox="0 0 155 97" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M143.968 95.9242C148.076 97.025 152.34 94.589 153.025 90.3911C154.507 81.313 154.354 72.0215 152.551 62.9594C150.247 51.3731 145.308 40.4723 138.116 31.1002C130.925 21.7282 121.674 14.136 111.079 8.91116C100.484 3.6863 88.8289 0.96875 77.0156 0.96875C65.2024 0.96875 53.5475 3.6863 42.9525 8.91116C32.3575 14.136 23.1065 21.7282 15.915 31.1002C8.72357 40.4723 3.78448 51.3731 1.47983 62.9594C-0.32274 72.0215 -0.475895 81.313 1.00588 90.3911C1.69108 94.589 5.95486 97.0251 10.0634 95.9242C14.1719 94.8233 16.5588 90.6003 15.9787 86.3866C15.0459 79.6105 15.2456 72.708 16.587 65.9644C18.4307 56.6954 22.382 47.9747 28.1351 40.4771C33.8883 32.9794 41.2891 26.9057 49.7651 22.7258C58.2411 18.5459 67.565 16.3719 77.0156 16.3719C86.4662 16.3719 95.7902 18.5459 104.266 22.7258C112.742 26.9057 120.143 32.9794 125.896 40.4771C131.649 47.9747 135.601 56.6954 137.444 65.9644C138.786 72.708 138.985 79.6105 138.053 86.3866C137.472 90.6003 139.859 94.8233 143.968 95.9242Z"
                      fill="#100F14"/>
                </svg>

                <svg class="diagram-fill" width="155" height="100" viewBox="0 0 155 100" fill="none"
                     xmlns="http://www.w3.org/2000/svg">

                  <defs>
                    <linearGradient id="half">
                      <stop offset="0%" stop-color="#0ECB81"/>
                      <stop offset="50%" stop-color="#0ECB81"/>
                      <stop :offset="diagramPercent" stop-color="#100F14" stop-opacity="0"/>
                      <stop offset="100%" stop-color="#100F14" stop-opacity="0"/>
                    </linearGradient>
                  </defs>

                  <path
                      d="M141.565 98.5153C146.964 100.244 152.812 97.2587 153.799 91.6758C155.324 83.0615 155.401 74.2257 154.005 65.54C152.063 53.4607 147.324 42.0107 140.172 32.1155C133.02 22.2202 123.655 14.1574 112.834 8.57848C102.013 2.99961 90.0399 0.0612719 77.8821 0.000947845C65.7242 -0.0593762 53.7228 2.76001 42.8478 8.23123C31.9728 13.7025 22.5293 21.672 15.2807 31.4958C8.03195 41.3196 3.18139 52.722 1.12099 64.7815C-0.360642 73.4534 -0.369576 82.2902 1.07052 90.9197C2.00365 96.5114 7.82081 99.5542 13.2366 97.8789V97.8789C18.6248 96.2122 21.5518 90.4952 20.8785 84.8955C20.2156 79.3814 20.3492 73.7815 21.2905 68.2721C22.8068 59.3972 26.3765 51.0058 31.711 43.7762C37.0455 36.5466 43.9952 30.6816 51.9985 26.6552C60.0017 22.6288 68.8339 20.5539 77.7812 20.5983C86.7285 20.6427 95.5399 22.8051 103.503 26.9107C111.467 31.0164 118.359 36.9501 123.622 44.2323C128.885 51.5145 132.373 59.9408 133.802 68.8304C134.689 74.349 134.768 79.95 134.051 85.4574C133.323 91.0501 136.193 96.7953 141.565 98.5153V98.5153Z"
                      fill="url(#half)"/>
                </svg>

              </div>

              <div class="deal-diagram__percent">
                {{ diagramPercent }}
              </div>

              <div class="deal-diagram__bottom">
                <span>0%</span>
                <span>100%</span>
              </div>
            </div>
          </div>
          <div>
            <div class="trader-deal__amounts">
              <div class="trader-deal__row" v-if="$route.name !== 'Profile'">
                <div class="justify-col-center">
                  <div class="trader-box__title-sm">
                    {{ $t("traderDealTotal") }}
                  </div>

                  <div class="trader-box__amount">
                    {{ totalDeals }}
                  </div>
                </div>
              </div>
              <div class="trader-deal__row">
                <div class="trader-deal__col">
                  <div class="trader-box__title-sm">
                    {{ $t("traderDealWinning") }}
                  </div>

                  <div class="trader-box__amount primary">
                    {{winningDeals}}
                  </div>
                </div>

                <div class="trader-deal__col">
                  <div class="trader-box__title-sm">
                    {{ $t("traderDealLosing") }}
                  </div>

                  <div class="trader-box__amount danger">
                    {{loosingDeals}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>

  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'


export default {
  name: 'DealCorrelation',
  props: {
    winningDeals: {
      type: Number,
      required: true
    },
    loosingDeals: {
      type: Number,
      required: true
    },
  },
  computed: {
    totalDeals() {
      return this.winningDeals + this.loosingDeals;
    },
    diagramPercent() {
      return this.winningDeals * 100 / this.totalDeals || 0;
    }
  },
  components: {VueSlickCarousel},
}
</script>

<style>
.slick-slider {
  width: 100%;
}

.slick-dots li {
  width: 6px;
}

.slick-dots li.slick-active button:before {
  color: #0363FE;
}

.slick-dots li button:before {
  color: #262531;
}
</style>