<template>
  <div class="main-wrapper">
  <AppSidebar/>


        <div class="main-content">
          <div class="container" style="min-height: 100%">
        <AppHeader/>
        <router-view/>
        <AppFooter/>
      </div>
    </div>

  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import AppFooter from "./AppFooter";
export default {
  name: 'AppMain',
  components: {AppFooter, AppSidebar, AppHeader}
}
</script>
