<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup change-popup">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="popup__title popup__title--sm">
            {{ $t("changePassTitle") }}
          </div>

          <form class="popup__form" @submit.prevent="changePassword">
            <div class="page-input" :class="{error:errorPassword}">
              <div class="page-input__title">
                {{ $t("changePassOld") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType" v-model="password"  class="input" @blur="deleteError('password')"  @focus="deleteError('password')">



                <button @click="togglePasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorPassword }}</p>
              </div>
            </div>

            <div class="page-input">
              <div class="page-input__title">
                {{ $t("changePassNew") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType2" v-model="passwordNew"  class="input">



                <button @click="toggleNewPasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass2}"

                ></button>
              </div>
            </div>

            <div class="page-input" :class="{error:errorRepeatPassword}">
              <div class="page-input__title">
                {{ $t("changePassRepeat") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType3" v-model="passwordRepeat"  class="input"  @focus="deleteError('passwordRepeat')" @blur="deleteError('passwordRepeat')">



                <button @click="toggleRepeatPasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass3}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorRepeatPassword }}</p>
              </div>
            </div>

            <button class="page-btn" type="submit">
              {{$t('submitBtn')}}
            </button>
          </form>



        </div>
      </div>
    </div>
  </div>
</template>

<script>
import processingMixin from "@/mixins/processingMixin";
import api from "@/api/api";

export default {
  name: "ChangePass",
  mixins: [processingMixin],
  data: () => ({
    errorPassword: '',
    errorRepeatPassword: '',
    passFieldType: 'password',
    passFieldType2: 'password',
    passFieldType3: 'password',
    showPass: false,
    showPass2: false,
    showPass3: false,
    password: null,
    passwordNew: null,
    passwordRepeat: null,
  }),
  methods: {
    isValid() {
      let rules = {
        password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$/,
      }
      if(!this.password) {
        this.errorPassword = 'Required field';
        return false;
      }
      if(!rules.password.test(String(this.password))) {
        this.errorPassword = this.$t('wrongPassword');
      }
      if (!this.passwordRepeat || this.passwordRepeat !== this.passwordNew) {
        this.errorRepeatPassword = this.$t('passwordsMismatch');
        return false;
      }
      return true;
    },
    async changePassword() {
      if(!this.isValid()) {
        return;
      }
      try {
        this.setLoading(true);
        await api.changePassword({
          newPassword: this.passwordNew,
          oldPassword: this.password,
        });
        this.setSuccess({
          message: 'Password has been changed',
        });
        this.close();
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
      } finally {
        this.setLoading(false);
      }
    },
    deleteError(type) {
      switch (type) {
        case 'password':
          this.errorPassword=""
          break;
        case 'passwordRepeat':
          this.errorRepeatPassword = "";
          break;
      }
      this.errorPassword=""
    },
    togglePasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
    toggleNewPasswordVisible() {
      this.passFieldType2 === 'password' ? this.passFieldType2 = 'text' : this.passFieldType2 = 'password';
      this.showPass2 = !this.showPass2;
    },
    toggleRepeatPasswordVisible() {
      this.passFieldType3 === 'password' ? this.passFieldType3 = 'text' : this.passFieldType3 = 'password';
      this.showPass3 = !this.showPass3;
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>