<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-invest">
        <button @click="close" class="popup__close"></button>

        <div class="invest-confirm invest-withdraw">

    <div class="popup__content">

      <div class="invest-error__img">
        <img src="@/assets/img/are-you-sure.png" alt="">
      </div>

      <div class="popup__title popup__title--sm">
        {{ $t("investWithdrawTitle") }}

      </div>

      <div class="invest-withdraw__amount">
        {{formatNumber(amount)}} USDT
      </div>

    <div class="invest-withdraw__text">
     <p><span>{{ $t("investWithdrawTextSpan1") }}</span>
      {{ $t("investWithdrawText1") }}</p>

      <p>
        {{ $t("investWithdrawText2") }}
       <span> {{ $t("investWithdrawTextSpan2") }}</span></p>
    </div>

    </div>
  </div>

        <div class="stepper-footer" >
          <div class="stepper-footer__control">
            <button
                class="page-btn page-btn--border"
                @click="close"
            >
              {{$t("backBtn")}}
            </button>
            <button
                class="page-btn"
                @click="createClosingInvestmentRequest"
            >
              {{$t("withdrawConfirmBtn")}}
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapMutations} from "vuex";
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: "InvestWithdraw",
  mixins: [formatNumberMixin],
  props: {
    amount: {
      type: Number,
      required: true
    },
    investmentId: {
      type: String,
      required :true
    }
  },
  methods: {
    ...mapMutations({
      setError: 'general/setError',
      setLoading: 'general/setLoading'
    }),
    async createClosingInvestmentRequest() {
      try {
        this.setLoading(true);
        await api.createClosingInvestmentRequest({
          investmentId: this.investmentId
        });
        this.next();
        this.close();

      } catch (e) {
        console.log(e);
        this.setError({
          message: e.response.data.message
        })
      } finally {
        this.setLoading(false);
      }
    },
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    }
  }
}
</script>

<style scoped>

</style>