<template>
  <div class="mobile-table mobile-table--deposit">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("depositsTableDate") }}
        </button></p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("depositsTableTrader") }}
        </button></p>
      <p class="mobile-table__title">
        <button class="table-filter">{{ $t("depositsTableStatus") }}
        </button></p>
      <p class="mobile-table__title">
        {{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item" v-for="(item, index) in deposits" :key="index" :class="{disabled: isClosed}">
      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">
          <div class="mobile-table-item__col">
            <p>{{ item.openingDate | timestampToDatePipe }}</p>
          </div>

          <div class="mobile-table-item__col">
            <div class="custom-table-user">
              <div class="custom-table-user__icon">
                <img :src="loadAvatar(item.avatar)" alt="">
              </div>

              <div class="custom-table-user__wrap">
                <p> {{ truncate(item.traderName) }} {{truncate(item.traderSurname)}} </p>
                <router-link :to="`/trader-profile/${item.traderId}`" class="link"> {{ $t("openProfileLink") }}  </router-link>
              </div>
            </div>
          </div>

          <div class="mobile-table-item__col">
            <p class="default" v-if="item.status === 'ACTIVE'">   {{ $t("depositsTableActive") }}</p>
            <p class="neutral" v-if="false">   {{ $t("depositsTableProcess") }}</p>
            <p class="danger" v-if="false"> {{ $t("depositsTableClosed") }}</p>
          </div>
        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
<!--            <div class="mobile-table-item__col">-->
<!--              <p class="mobile-table__title">{{ $t("depositsTableId") }}</p>-->
<!--            </div>-->

<!--            <div class="mobile-table-item__col">-->
<!--              <p>6H71X</p>-->
<!--            </div>-->
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <div class="mobile-table__title">{{ $t("depositsTableDist") }}
                <div class="tooltip"  @mouseover="showTooltip = true"  @mouseleave="showTooltip = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showTooltip">
                    {{ $t("tooltipText")}}
                  </div>
                </div>
              </div>
            </div>

            <div class="mobile-table-item__col">
              <div class="fill-frame">
                <div class="fill-frame__item">
                  <span>{{item.investorPercent}}%</span>
                  {{$t("investor")}}
                </div>

                <div class="fill-frame__item">
                  <span>{{item.traderPercent}}%</span>
                  {{$t("trader")}}
                </div>
                <span class="fill-frame__color" :style="`width: ${item.investorPercent}%`"></span>
              </div>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <div class="mobile-table__title">{{ $t("depositsTableProfit") }}
                <div class="tooltip"  @mouseover="showTooltip2 = true"  @mouseleave="showTooltip2 = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showTooltip2">
                    {{ $t("tooltipText")}}
                  </div>
                </div>
              </div>
            </div>

            <div class="mobile-table-item__col">
              <p> {{formatNumber(item.totalProfitAmount)}} USDT <span>({{formatNumber(item.totalProfitPercent, true)}}%)</span></p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <div class="mobile-table__title">{{ $t("depositsTableMonth") }}
                <div class="tooltip"  @mouseover="showTooltip3 = true"  @mouseleave="showTooltip3 = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showTooltip3">
                    {{ $t("tooltipText")}}
                  </div>
                </div>
              </div>
            </div>

            <div class="mobile-table-item__col">
              <p>{{formatNumber(item.monthlyAverageProfitAmount)}} USDT<span> ({{formatNumber(item.monthlyAverageProfitPercent, true)}}%)</span></p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <div class="mobile-table__title">{{ $t("depositsTableSwitcher") }} </div>
            </div>

            <div class="mobile-table-item__col">
              <div class="switcher">
                <label>
                  <input type="checkbox" v-model="item.autoReinvest" @change="changeInvestmentAutoReinvest(item.id, item.autoReinvest)"/>
                  <span class="switcher__box"></span>
                </label>
              </div>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <button @click="openWithdrawModal(item.body, item.id)" class="traders-btn"
                    :class="{default : item.status === 'ACTIVE' , danger : isLocked, primary: isWithdraw}">
              <div class="traders-btn__top">
                <p class="traders-btn__price">{{formatNumber(item.body)}} USDT</p>
              </div>

              <div class="traders-btn__bottom" >
                <div class="d-flex w-100 justify-content-between" v-if="isAvailable" >
                  <span > Доступно </span>
                  <strong>Вывести</strong>
                </div>
                <span v-show="isLocked"> 218 дней до разблокировки </span>
                <span v-show="isWithdraw">Выведено</span>
              </div>

            </button>
            <button class="card-traders__btn" @click="openInvestmentStatistic(item.id, item.traderId)"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatNumberMixin, loadAvatarMixin, timestampToDateMixin, truncateMixin} from "@/utils/mixins";

export default {
  name: 'DepositsMobileTable',
  mixins: [timestampToDateMixin, loadAvatarMixin, truncateMixin, formatNumberMixin],
  props: {
    deposits: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showTooltip: false,
    showTooltip2: false,
    showTooltip3: false,
    isAvailable: true,
    isLocked: false,
    isWithdraw: false,
    isShow: false,
    isClosed: false,
  }),
  methods: {
    openInvestmentStatistic(investmentId, traderId) {
      this.$emit('openInvestmentStatistic', {
        investmentId, traderId
      });
    },
    changeInvestmentAutoReinvest(investmentId, autoReinvest) {
      this.$emit('changeInvestmentAutoReinvest', {investmentId, autoReinvest});
    },
    openWithdrawModal(amount, investmentId) {
      this.$emit('openWithdrawModal', {
        amount, investmentId
      })
    }
  }
}
</script>
