<template>
  <div class="invest-confirm invest-error">

    <div class="popup__content">
      
      <div class="invest-error__img">
        <img src="@/assets/img/error.png" alt="">
      </div>

      <div class="popup__title popup__title--sm">
        {{ $t("investErrorTitle") }}

      </div>

      <div class="popup__wrap">
        <div class="popup__col">
          <div class="title-sm">
            {{$t ("menuBalance")}}
          </div>

          <p>17 000 USDT <span>(Erc-20)</span></p>
        </div>
        <div class="popup__col">
          <div class="title-sm">
            {{$t ("investConfirmBonus")}}
          </div>

          <p>0 USD</p>
        </div>
      </div>

      <div class="invest-error__wrap">
        <p class="error"> {{ $t("investErrorText") }}</p>
        <span class="invest-error__text"> {{$t("investErrorText1")}} <span class="blue">1000 USTD </span> (Erc-20),
          {{$t("investErrorText2")}}  </span>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "InvestError"
}
</script>

<style scoped>

</style>