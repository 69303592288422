<template>
  <div class="custom-table custom-table--income custom-table--withdraw">
    <div class="box">
      <table cellpadding="0" cellspacing="0">
        <thead class="custom-table-head">
        <tr>
          <td>
            <div class="custom-table-head__title">
              {{$t("incomeTableDate")}}</div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableTrans") }}
              </div>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableUser") }}
              </div>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableType") }}
              </div>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableSum") }}
              </div>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableComm") }}
              </div>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableFine") }}
              </div>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <div class="custom-table-head__title">
                {{ $t("incomeTableEnd") }}
              </div>
            </div>
          </td>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in items" :key="index">
          <td>
            <p>{{item.openingDate | timestampToDatePipe}}</p>
          </td>
          <td>
            <p class="default">#915891</p>
          </td>
          <td>
            <p>015859</p>
          </td>
          <td>
            <p class="primary">{{ $t("incomeTableAdd") }}</p>
            <p v-show="false" class="danger">{{ $t("incomeTableWithdraw") }}</p>
          </td>
          <td>
            <p style="color: #5C93E5">{{ formatNumber(item.body) }} USDT</p>
          </td>
          <td>
            <p>40%</p>
          </td>
          <td>
            <p>0 USDT</p>
            <p v-show="isFee" style="color: #FCCE5E"> 23 USDT</p>
          </td>
          <td>
            <p>28.04.2022</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {formatNumberMixin, timestampToDateMixin} from "@/utils/mixins";

export default {
  name: "WithdrawHistoryDesktopTable",
  mixins: [formatNumberMixin, timestampToDateMixin],
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isFee: false
  }),
}
</script>

<style scoped>

</style>