<template>
  <div class="custom-table custom-table--orders">
    <div class="box">
      <table cellpadding="0" cellspacing="0">
        <thead class="custom-table-head">
        <tr>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">
                {{ $t("ordersTablePair") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTableDate") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTableType") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">Side
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTablePrice") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTableAmount") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTableFilled") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTableAll") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("ordersTableStatus") }}
              </button>
            </div>
          </td>
          <td>
<!--            <button class="custom-table-head__title" v-if="showCloseBtn">-->
<!--              {{ $t("ordersTableAction") }}-->
<!--            </button>-->
          </td>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in orders" :key="index">
          <td>
            <div class="custom-table__wrap">
              <div class="custom-table__coins">
                <div class="coin-sm">
                  <img :src="loadImage(item.pairName.split('-')[0])" alt="">
                </div>
                <div class="coin-bg">
                  <img :src="loadImage(item.pairName.split('-')[1])" alt="">
                </div>
              </div>

              <p class="custom-table__coin-name">
                <span>{{ item.pairName.replace('-', '/') }}</span>
              </p>
            </div>
          </td>
          <td>
            <p>
              {{ new Date(item.date).toLocaleDateString('en-GB') }}
              {{ new Date(item.date).toLocaleTimeString('en-US', {hour12: false}) }}
            </p>
          </td>
          <td>
            <p>{{ item.market ? 'Market' : 'Limit' }}</p>
          </td>
          <td>
            <p>{{ item.side === 'SELL' ? 'Sell' : 'Buy' }}</p>
          </td>
          <td>
            <p>{{ formatNumber(item.price) }}</p>
          </td>
          <td>
            <p>{{ formatNumber(item.initialQuantity) }}</p>
          </td>
          <td>
            <p>0.00%</p>
          </td>
          <td>
            <p>11.00 USDT</p>
          </td>
          <td>
            <p :class="item.active ? 'primary' : 'danger'">{{ item.active ? 'Active' : 'Closed' }}</p>
          </td>
          <td>
            <button class="custom-table__btn" @click="deleteOrderByUser(item.id)" v-if="showCloseBtn">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                    fill="#DE2A4A"/>
              </svg>
              Close
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {formatNumberMixin, loadImageMixin} from "@/utils/mixins";

export default {
  name: 'OrdersDesktopTable',
  mixins: [formatNumberMixin, loadImageMixin],
  props: {
    orders: {
      type: Array,
      required: true
    },
    showCloseBtn: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    deleteOrderByUser(id) {
      this.$emit('deleteOrderByUser', id);
    }
  }
}
</script>
