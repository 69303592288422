<template>
<div class="tutorial-step tutorial-step--two">
  <div class="tutorial-step__title">
    {{$t('tutorialTwoTitle')}}
  </div>

  <div class="tutorial-step__text">
    {{$t('tutorialTwoText')}}
  </div>
</div>
</template>

<script>
export default {
  name: "TutorialStep2"
}
</script>

<style scoped>

</style>