<template>
  <div class="login-page restore-page">
    <div class="login-wrap">
      <div class="login-page__content">
        <div class="logo">
          <img src="@/assets/img/svg/logo.svg" alt="">
        </div>

          <h1 class="login-page__title">
            {{ $t("restorePassTitle") }}
          </h1>

          <div class="login-page__caption">
            {{ $t("setNewPassText") }}
          </div>

          <form class="form" @submit.prevent="setNewPassword">
            <div class="page-input" :class="{error:errorPassword}">
              <div class="page-input__title">
                {{ $t("password") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType"
                       class="input"
                       v-model="password"
                       v-focus
                       @keyup.enter="setNewPassword"
                       @focus="deleteError('password')">



                <button @click="togglePasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorPassword }}</p>
              </div>
            </div>

            <div class="page-input" :class="{error:errorRepeatPassword}">
              <div class="page-input__title">
                {{ $t("repeatPassword") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType2"
                       class="input"
                       v-model="repeatPassword"
                       @keyup.enter="setNewPassword"
                       @focus="deleteError('repeatPassword')">



                <button @click="toggleRepeatPasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass2}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorRepeatPassword }}</p>
              </div>
            </div>


            <button class="page-btn">
              {{ $t("setNewPassBtn") }}
            </button>

            <router-link to="/sign-in" tag="button" class="page-btn page-btn--border page-btn--neutral">{{ $t("restorePassDisable") }}</router-link>
          </form>

      </div>

    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: 'SetNewPassword',
  data() {
    return {
      passFieldType: 'password',
      passFieldType2: 'password',
      showPass: false,
      showPass2: false,
      errorPassword: '',
      errorRepeatPassword: '',

      password: null,
      repeatPassword: null,
      code: null
    }
  },
  computed: {
    ...mapGetters({
      getSuccess: 'general/getSuccess'
    })
  },
  watch: {
    getSuccess(val) {
      if(!val) {
        this.$router.push('/sign-in')
      }
    }
  },
  methods: {
    ...mapMutations({
      setSuccess: 'general/setSuccess',
      setError: 'general/setError'
    }),
    isValid() {
      let rules = {
        password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{4,}$/
      }
      if(!rules.password.test(String(this.password))) {
        this.errorPassword = this.$t('wrongPassword');
        return false;
      }
      if(this.password !== this.repeatPassword) {
        this.errorRepeatPassword = this.$t('passwordsMismatch');
        return false;
      }
      return true;
    },
    setNewPassword() {
      if(!this.isValid()) {
        return
      }
      api.setNewPassword({
        code: this.code,
        newPassword: this.password,
        isPublic: true
      })
        .then(() => {
          this.setSuccess({
            message: 'signInTitle'
          })
        })
        .catch(() => {
          this.setError({
            message: 'signInTitle'
          })
        })
    },
    toggleRepeatPasswordVisible() {
      this.passFieldType2 === 'password' ? this.passFieldType2 = 'text' : this.passFieldType2 = 'password';
      this.showPass2 = !this.showPass2;
    },
    togglePasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
    deleteError(type) {
      switch (type) {
        case 'password':
          this.errorPassword = ""
          break;
        case 'repeatPassword':
          this.errorRepeatPassword = ""
          break;
      }
    },
  },
  mounted() {
    this.code = this.$route.query.code;
  }
}
</script>
