<template>
  <div class="open-order">
    <div class="box box--shadow">
      <div class="open-order__head">
        <div class="open-order__title">
          <p>{{ $t("openOrderTitle") }}</p>
          <div class="open-order__amount" v-if="orders.length">{{ total }}</div>
        </div>

        <div class="open-order__wrap" v-if="orders.length">
          <div class="checkbox-wrap">
            <div class="default-checkbox">
              <div class="checkbox">
                <label>
                  <input type="checkbox" name="remember"/>
                  <span class="checkbox__content">
               <span class="checkbox__box"></span>
               <span class="checkbox__text">{{ $t("openOrderCheck") }}</span>
             </span>
                </label>
              </div>
            </div>
          </div>

          <button class="open-order__btn" @click="deleteAllOrderByUser"
                  type="button">{{ $t("openOrderHeadBtn") }}
          </button>
        </div>

      </div>

      <div class="open-orders-table">
        <vue-scroll :ops="ops" ref="vueScroll">
          <div class="open-order__content">
            <div class="open-order-item" v-for="(item, index) in orders" :key="index">
              <div class="open-order-item__col">
                <p class="green">{{ $t("openOrderBuy") }}</p>

                <!--                <div class="open-order-item__wrap">-->
                <!--                  <p class="grey">{{ $t("openOrderDone") }}</p>-->
                <!--                  <p>0,0000/0,00006</p>-->
                <!--                </div>-->
              </div>
              <div class="open-order-item__col">
                <p class="pair-name">{{ item.pairName.replace('-', '/') }}</p>

                <div class="open-order-item__row">
                  <div>
                    <p class="grey"> {{ $t("openOrderSize") }}</p>
                    <p class="grey"> {{ $t("openOrderPrice") }}</p>
                  </div>

                  <div>
                    <p>{{ formatNumber(item.initialQuantity) }}</p>
                    <p class="green">{{ formatNumber(item.price) }}</p>
                  </div>
                </div>
              </div>
              <div class="open-order-item__col">
                <div class="open-order-item__date">
                  {{ new Date(item.date).toLocaleDateString('en-GB') }}
                  {{ new Date(item.date).toLocaleTimeString('en-US', {hour12: false}) }}
                </div>

                <button class="open-order__btn"
                        @click="deleteOrderByUser(item.id)"
                        type="button">{{ $t("openOrderBtn") }}
                </button>
              </div>
            </div>
            <ScrollLoader :loader-method="loadOpenTrades" :loader-disable="disableLoading"/>

            <div class="no-records-orders" v-show="!orders.length && !loading && getIsAuthorized">
              <div class="no-records-orders__title">
                <p>{{ $t("openOrderNoOrder") }} </p>
                <div class="no-records-orders__icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.34326 4.92871L19.0712 17.6566L17.657 19.0708L4.92905 6.34292L6.34326 4.92871Z"
                          fill="white"/>
                    <path d="M17.6567 4.92871L4.92882 17.6566L6.34303 19.0708L19.071 6.34292L17.6567 4.92871Z"
                          fill="white"/>
                  </svg>
                </div>
              </div>

              <div class="no-records-orders__msg">
                {{ $t("openOrderNoOrderMsg") }}
              </div>
            </div>

          </div>


        </vue-scroll>
        <div v-if="!getIsAuthorized" class="open-order__login">
          <router-link to="/sign-in" class="market__btn market__btn--border">
            <span>Login </span> or <span> register</span> to trade
          </router-link>
        </div>

      </div>


    </div>
  </div>
</template>

<script>
import api from "../../api/api";
import {mapGetters, mapMutations} from "vuex";
import {formatNumberMixin, opsMixin} from "@/utils/mixins";

export default {
  name: "OpenOrder",
  mixins: [formatNumberMixin, opsMixin],
  data: () => ({
    disableLoading: false,
    loading: false,
    orders: [],
    page: 0,
    size: 10,
    total: 0,
    startDate: null,
    endDate: null,
    firstLoading: true,
    ordersTotal: 0,
    isOrders: ''
  }),
  computed: {
    ...mapGetters({
      getIsAuthorized: 'user/getIsAuthorized'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'general/setLoading',
      setError: 'general/setError'
    }),
    async deleteAllOrderByUser() {
      this.setLoading(true);
      try {
        await api.deleteAllOrderByUser();
        this.orders = [];
      } catch (e) {
        console.log(e);
        this.setError({
          message: e.response.data.message
        })
      } finally {
        this.setLoading(false);
      }
    },
    async deleteOrderByUser(orderId) {
      this.setLoading(true);
      try {
        await api.deleteOrderByUser({
          orderId
        })
        this.orders = this.orders.filter(i => i.id !== orderId);
      } catch (e) {
        console.log(e);
        this.setError({
          message: e.response.data.message
        })
      } finally {
        this.setLoading(false);
      }
    },
    async getOrderTimeRange() {
      try {
        const response = await api.getOrderTimeRange();
        this.startDate = this.startDateCopy = response.timeOfTheFirstOrder;
        this.endDate = this.endDateCopy = response.timeOfTheLastOrder;
      } catch (e) {
        console.log(e);
      }
    },
    async loadOpenTrades() {
      try {
        this.loading = true;
        if (!this.getIsAuthorized) {
          this.disableLoading = true;
          return
        }
        if(this.firstLoading) {
          await this.getOrderTimeRange();
          this.firstLoading = false;
          if(!this.startDate) {
            this.disableLoading = true;
            return
          }
        }
        const response = await api.loadTrades({
          page: this.page++,
          size: this.size,
          sort: 'date,desc',
          endDate: this.endDate,
          startDate: this.startDate
        });
        this.orders = [...this.orders, ...response.content];
        this.isOrders = true;
        this.total = response.totalElements;
        this.ordersTotal = this.total;
        this.ordersPassData();
        this.ordersPassTotal();
        this.disableLoading = response.content.length < this.size;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    ordersPassData() {
      this.$emit('passData', this.isOrders);
    },
     ordersPassTotal() {
      this.$emit('passTotal', this.ordersTotal)
    }
  },
  mounted() {

  }
}
</script>
