import axios from "axios";
import store from "@/store";
import router from "@/router";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 500000,
    contentType: "application/json"
});

http.axios = axios;

http.interceptors.request.use( config => {
    if(config.params && config.params.isPublic) {
        config.params.isPublic = null;
        return config;
    }
    config.params.isPublic = null;
    let token = localStorage.getItem('at');
    if(token) {
        config.headers.Authorization = `Bearer_${token}`
    }
    else {
        throw new axios.Cancel('USER_CANCEL')
    }
    return config;
}, () => {
    store.commit('user/unsetUser');
    if(router.currentRoute.meta.requiresAuth) {
        router.push('/sign-in')
    }
});


http.interceptors.response.use(
    response => response,
    error => {
        let status = error.response?.data.status
        if(status === 403) {
            store.commit('user/unsetUser');
            if(router.currentRoute.meta.requiresAuth) {
                router.push('/sign-in')
            }
        }
        return Promise.reject(error)
    }
);

export default http;
