export default {
    methods: {
        getMonthName() {
            let monthNumber = new Date(Date.now()).getMonth();
            switch (monthNumber) {
                case 0: return this.$t("January");
                case 1: return this.$t("February");
                case 2: return this.$t("March");
                case 3: return this.$t("April");
                case 4: return this.$t("May");
                case 5: return this.$t("June");
                case 6: return this.$t("July");
                case 7: return this.$t("August");
                case 8: return this.$t("September");
                case 9: return this.$t("October");
                case 10: return this.$t("November");
                case 11: return this.$t("December");
            }
        }
    }
}