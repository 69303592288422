export const timestampToDateMixin = {
  filters: {
    timestampToDatePipe(val) {
      return new Date(+val).toLocaleDateString("ro-RO")
    }
  }
}

export const timestampToDateTimeMixin = {
  filters: {
    timestampToDateTimePipe(val) {
      return `${new Date(val).toLocaleDateString('en-GB')} ${new Date(val).toLocaleTimeString('en-US', {hour12: false})}`
    }
  }
}

export const formatNumberMixin = {
  methods: {
    formatNumber(val, isPercent = false) {

      function toFixed(x) {
        if (Math.abs(x) < 1.0) {
          let e = parseInt(x.toString().split('e-')[1]);
          if (e) {
            x *= Math.pow(10,e-1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
          }
        } else {
          let e = parseInt(x.toString().split('+')[1]);
          if (e > 20) {
            e -= 20;
            x /= Math.pow(10,e);
            x += (new Array(e+1)).join('0');
          }
        }
        return x;
      }
      if(!val) {
        return 0;
      }
      if(isPercent) {
        return (+val).toFixed(2);
      }
      if (val >= 1 || val <= -1) {
        return +(val.toFixed(2))
      }
      if(val.toString().includes('e')) {
        return toFixed(val);
      }
      return +(val.toFixed(8))
    },
  }
};

export const opsMixin = {
  data: () => ({
    ops: {
      scrollPanel: {
        scrollingX: false,
      },
      bar: {
        minSize: 0.2,
        background: '#262531',
        hoverStyle: false,
        size: '3px'
      },
    }
  })
}

export const loadImageMixin = {
  methods: {
    loadImage(currency, transparent = false) {
      let image = null;
      try {
        if(!transparent) {
          image = require(`@/assets/img/coins/${currency}.svg`)
        } else {
          image = require(`@/assets/img/trade-coins/${currency}.svg`)
        }
      } catch {
        if(!transparent) {
          image = require('@/assets/img/coins/DFLT.svg');
        } else {
          image = require('@/assets/img/trade-coins/DFLT.svg');
        }
      }
      return image;
    }
  }
}

export const loadAvatarMixin = {
  methods: {
    loadAvatar(avatar, isProfile = false) {
      try {
        if(avatar) {
          return `data:image/png;base64,${avatar}`
        }
        if(isProfile) {
          return require('@/assets/img/svg/user-default.svg');
        }
        return require('@/assets/img/user-image.png');
      } catch {
        return '';
      }
    }
  }
}

export const generateExplorerLinkMixin = {
  methods: {
    generateExplorerLink(isTestnet, network, hash, currency, type) {
      console.log(isTestnet, network, hash, currency, type)
      if(network === 'CURRENCY_DEFAULT') {
        network = currency;
      }
      if(!isTestnet) {
        switch (network) {
          case 'ETH': return `https://etherscan.io/${type}/${hash}`;
          case 'C98': return `https://etherscan.io/${type}/${hash}`;
          case 'BNB': return `https://bscscan.com/${type}/${hash}`;
          case 'BSC': return `https://bscscan.com/${type}/${hash}`;
          case 'BTC': return `https://www.blockchain.com/btc/${type}/${hash}`;
        }
      } else {
        switch (network) {
          case 'ETH': return `https://ropsten.etherscan.io/${type}/${hash}`;
          case 'C98': return `https://ropsten.etherscan.io/${type}/${hash}`;
          case 'BNB': return `https://testnet.bscscan.com/${type}/${hash}`;
          case 'BSC': return `https://testnet.bscscan.com/${type}/${hash}`;
          case 'BTC': return `https://www.blockchain.com/btc/${type}/${hash}`;
        }
      }
    },
  }
}

export const cutHashMixin = {
  filters: {
    cutHash(v){
      if(!v) {
        return ''
      }
      let valStart = v.substring(0, 10)
      let valEnd = v.substring(v.length - 5, v.length);
      return `${valStart}...${valEnd}`
    }
  },
}

export const truncateMixin = {
  methods: {
    truncate(content, length) {
      if(!content) {
        return '';
      }
      if(length) {
        return content.substring(0, length) + '...';
      }
      let size = 25;
      if (content.length > size) {
        return content.substring(0, size) + '...';
      } else {
        return content;
      }

    }
  }
}