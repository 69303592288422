<template>
  <div class="login-page">
   <div class="login-wrap">
     <div class="login-page__content">
     <div class="logo">
       <img src="@/assets/img/svg/logo.svg" alt="">

     </div>

     <h1 class="login-page__title">
       {{ $t("signInTitle") }}
     </h1>

       <form class="form" @submit.prevent="login">
     <div class="page-input" :class="{error:errorEmail}">
       <div class="page-input__title">
         {{ $t("email") }}
       </div>

       <input type="text" class="input" v-model="email"

              @focus="deleteError('email')">

       <div class="error__msg">
         <p>{{ errorEmail }}</p>
       </div>
     </div>

     <div class="page-input" :class="{error:errorPassword}">
       <div class="page-input__title">
         {{ $t("password") }}
       </div>

       <div class="page-input__wrap">
       <input :type="passFieldType" v-model="password"  class="input"  @focus="deleteError('password')">



       <button @click="passFieldType === 'password' ? passFieldType = 'text' : passFieldType = 'password'; showPass = !showPass"
               type="button"
               class="page-input__icon pass-toggle"
               :class="{active: showPass}"

       ></button>
       </div>
       <div class="error__msg">
         <p>{{ errorPassword }}</p>
       </div>
     </div>

     <button class="page-btn">
       {{ $t("signInBtn") }}
     </button>

     <div class="login-page__check">
       <div class="default-checkbox">
         <div class="checkbox">
           <label>
             <input type="checkbox" name="remember"/>
              <span class="checkbox__content">
               <span class="checkbox__box"></span>
               <span class="checkbox__text">{{ $t("signInRemember") }}</span>
             </span>
           </label>
         </div>
       </div>

       <router-link to="/restore-password" class="login-page__link"> {{ $t("signInForget") }}</router-link>
     </div>

       </form>

     <div class="login-page__text">
       {{ $t("signInText") }}
     </div>

     <router-link to="/sign-up" tag="button" class="page-btn page-btn--border">{{ $t("signInBtnCreate") }}</router-link>
   </div>

   </div>
  </div>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: 'SignIn',
  data() {
    return {
      passFieldType: 'password',
      showPass: false,
      errorPassword: '',
      errorEmail: '',

      email: null,
      password: null
    }
  },
  methods: {
    ...mapActions({
      signIn: 'user/signIn'
    }),
    ...mapMutations({
      setSuccess: 'general/setSuccess',
      setError: 'general/setError',
      setLoading: 'general/setLoading'
    }),
    async login() {
      try {
        await this.signIn({
          email: this.email,
          password: this.password,
        })
        await this.$router.push('/');
      } catch (e) {
        console.log(e)
        if(e.response.status === 403) {
          await this.$router.push({
            path: '/google-authenticate',
            query: {
              email: this.email
            }
          });
          return
        }
        this.setError({
          message: e.response.data.message
        })
      }
    },
    deleteError(type) {
      switch (type) {
        case 'email':
          this.errorEmail = "";
          break;
        case 'password':
          this.errorPassword=""
          break;
      }
    },
  }
}
</script>
