<template>
<div class="no-deposits">
  <div class="no-deposits__text">
    {{$t("noDepositsText1")}} <br>
    <span class="primary"> {{$t("noDepositsTextSpan")}}</span>
    {{$t("noDepositsText2")}}
  </div>
</div>
</template>

<script>
export default {
  name: "NoDepositsFound"
}
</script>

<style scoped>

</style>