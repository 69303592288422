<template>
  <div class="mobile-table mobile-table--trader">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        {{ $t("profileTableDate") }}
      </p>
      <p class="mobile-table__title">
        {{ $t("traderTableGeneral") }}
      </p>
      <p class="mobile-table__title">{{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item"
         v-for="(item, index) in profits"
         :class="{active: item.isShow}"
         :key="index">

      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">
          <div class="mobile-table-item__col">
           <p>{{ item.year }}</p>
          </div>

          <div class="mobile-table-item__col">
            <p :class="item.total > 0 ? 'primary' : item.total === 0 ? '' : 'danger'">
              {{formatNumber(item.total)}}%
            </p>
          </div>
        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthJan") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[0].profitPercent > 0 ? 'primary' : item.values[0].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[0].profitPercent, true) || '-'}}%</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthFeb") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[1].profitPercent > 0 ? 'primary' : item.values[1].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[1].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthMarch") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[2].profitPercent > 0 ? 'primary' : item.values[2].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[2].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthApr") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[3].profitPercent > 0 ? 'primary' : item.values[3].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[3].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthMay") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[4].profitPercent > 0 ? 'primary' : item.values[4].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[4].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthJun") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[5].profitPercent > 0 ? 'primary' : item.values[5].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[5].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthJul") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[6].profitPercent > 0 ? 'primary' : item.values[6].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[6].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthAug") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[7].profitPercent > 0 ? 'primary' : item.values[7].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[7].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthSep") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[8].profitPercent > 0 ? 'primary' : item.values[8].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[8].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthOct") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[9].profitPercent > 0 ? 'primary' : item.values[9].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[9].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthNov") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[10].profitPercent > 0 ? 'primary' : item.values[10].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[10].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("monthDec") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p :class="item.values[11].profitPercent > 0 ? 'primary' : item.values[11].profitPercent === 0 ? '' : 'danger'">{{formatNumber(item.values[11].profitPercent, true) || '-'}}%</p>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: 'IncomeStatisticMobileTable',
  mixins: [formatNumberMixin],
  props: {
    profits: {
      type: Array,
      required: true
    }
  }
}
</script>
