<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup twoFa-modal">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">
        <ul class="steps">
          <li
              class="steps__item"
              :class="
              currentStep === 1
                ? 'active'
                : '' || currentStep > 1
                ? 'disabled'
                : ''
            "
          >
            <p>1</p>
          </li>
          <li
              class="steps__item"
              :class="
              currentStep === 2
                ? 'active'
                : '' || currentStep > 2
                ? 'disabled'
                : ''
            "
          >
            <p>2</p>
          </li>
          <li
              class="steps__item"
              :class="
              currentStep === 3
                ? 'active'
                : '' || currentStep > 3
                ? 'disabled'
                : ''
            "
          >
            <p>3</p>
          </li>
          <li
              class="steps__item"
              :class="
              currentStep === 4
                ? 'active'
                : '' || currentStep > 4
                ? 'disabled'
                : ''
            "
          >
            <p>4</p>
          </li>
        </ul>
        <StepOne v-if="currentStep === 1" />
        <StepTwo v-if="currentStep === 2" />
        <StepThree v-if="currentStep === 3" />
        <StepFour v-if="currentStep === 4" @setTwaCode="setTwaCode"/>
        <div class="stepper-footer">
          <div class="stepper-footer__control">
            <button
                class="page-btn page-btn--border"
                v-if="currentStep !== 1"
                @click="prevStep"
            >
              {{$t("backBtn")}}
            </button>
            <button
                v-focus
                class="page-btn"
                v-if="currentStep !== 4"
                @click="nextStep"
            >
              {{$t("nextBtn")}}
            </button>

            <button
                class="page-btn"
                v-focus
                @click="enableTwaFa"
                v-if="currentStep === 4"
            >
              {{$t("nextBtn")}}
            </button>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from "./TwoFaGoogle/StepOne";
import StepTwo from "./TwoFaGoogle/StepTwo";
import StepThree from "./TwoFaGoogle/StepThree";
import StepFour from "./TwoFaGoogle/StepFour";
import api from "../../api/api";
import {mapGetters, mapMutations} from "vuex";


export default {
  name: "TwoFaGoogleEnable",
  data: () => ({
    currentStep: 1,
    code: null,
    password: null
  }),
  computed: {
    ...mapGetters({
      getSettings: 'user/getSettings'
    })
  },
  methods: {
    ...mapMutations({
      setSettings: 'user/setSettings',
      setLoading: 'general/setLoading',
      setError: 'general/setError',
      setSuccess: 'general/setSuccess'
    }),
    setTwaCode({twaCode, password}) {
      this.code = twaCode;
      this.password = password;
    },
    validate() {
      if(!this.code || !this.password) {
        this.setError({
          message: 'Please enter password and 2fa code'
        });
        return false;
      }
      return true;
    },
    async enableTwaFa() {
      if(!this.validate()) {
        return;
      }
      try {
        await api.enableTwoFa({
          code: this.code,
          password: this.password
        });
        this.setSettings({
          mailCode: this.getSettings.mailCode,
          twoFa: true
        })
        this.setSuccess({
          message: '2fa set successfully',
          type: '2FA',
          twoFaStatus: 'ENABLED'
        });
        this.close();
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
      }
    },
    nextStep() {
      if (this.currentStep !== 4) this.currentStep++;
    },
    prevStep() {
      if (this.currentStep !== 1) this.currentStep--;
    },
    close() {
      this.$emit("close");
    }
  },
  components: {
    StepOne,
    StepTwo,
    StepThree,
    StepFour
  },
}
</script>
