<template>
  <div id="app">
    <ModalError v-if="getError"
                :message="getError.message"
                @close="closeError"/>
    <ModalSuccess v-if="getSuccess"
                  :twoFaStatus="getSuccess.twoFaStatus"
                  :type="getSuccess.type"
                  :message="getSuccess.message"
                  @close="closeSuccess"/>
    <Preloader v-if="getLoading"/>

    <div  class="notification-container" :class="{news: true}" v-if="notifications.length">
      <div class="notification animateOpen notification--hide">
        <button class="notification__text notification__btn" @click="closeAllNotifications">
          {{ $t("notificationHideBtn") }}
        </button>
      </div>
    <Notification v-for="(item, index) in notifications" :key="index" :notification="item" @closeItem="closeItem"/>
    </div>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "assets/scss/style";
</style>
<script>
import ModalError from "./components/modals/ModalError";
import ModalSuccess from "./components/modals/ModalSuccess";
import {mapGetters, mapMutations} from "vuex";
import socket from "./api/socket";
import api from "./api/api";
import Preloader from "./components/utility/Preloader";
import Notification from "@/components/utility/Notification";
export default {
  name: 'App',
  data: () => ({
    notifications: [],
  }),
  computed: {
    ...mapGetters({
      getError: 'general/getError',
      getLoading: 'general/getLoading',
      getSuccess: 'general/getSuccess',
      getPairIndex: 'pair/getPairIndex',
      getTimeFrame: 'socket/getTimeFrame',
      getIsAuthorized: 'user/getIsAuthorized',
      getNotificationsLength: 'socket/getNotificationsLength',
      getNotifications: 'socket/getNotifications'
    })
  },
  watch: {
    getNotificationsLength(val) {
      if(val === 4) {
        this.closeNotification(null);
        return
      }
      this.notifications = [...this.getNotifications];
    },
    getIsAuthorized(val) {
      if(val) {
        this.loadKyc();
        this.loadUserInfo();
        this.loadUserSettings();
        this.loadEstimatedBalance();
        socket.disconnectSocket();
        this.initSocketConnection();
      }
    },
    getTimeFrame(val) {
      if(val) {
        socket.disconnectSocket();
        this.unsetLastPrice();
        this.initSocketConnection();
      }
    },
    getPairIndex(val) {
      if(val) {
        socket.disconnectSocket();
        this.unsetLastPrice();
        this.initSocketConnection();
      }
    }
  },
  methods: {
    ...mapMutations({
      setError: 'general/setError',
      setSuccess: 'general/setSuccess',
      unsetLastPrice: 'socket/unsetLastPrice',
      setPersonalInfo: 'user/setPersonalInfo',
      setSettings: 'user/setSettings',
      setEstimatedValues: 'user/setEstimatedValues',
      setVerification: 'user/setVerification',
      setIpAddress: 'user/setIpAddress',
      closeNotification: 'socket/closeNotification',
      closeAllNotifications: 'socket/closeAllNotifications',
      setKyc: 'user/setKyc',
    }),
    closeItem(id) {
      this.closeNotification(id)
    },
    closeError() {
      this.setError(null);
    },
    closeSuccess() {
      this.setSuccess(null);
    },
    async getUserIp() {
      try {
        const response = await api.getUserIp();
        this.setIpAddress(response.ip);
      } catch (e) {
        console.log(e);
      }
    },
    async loadKyc() {
      try {
        const response = await api.loadKyc();
        this.setVerification(response.status);
        this.setKyc(response);
      } catch (e) {
        console.log(e);
      }
    },
    async loadEstimatedBalance() {
      try {
        const response = await api.loadEstimatedBalance();
        this.setEstimatedValues(response);
      } catch (e) {
        console.log(e);
      }
    },
    initSocketConnection() {
      socket.initSocketConnection();
    },
    async loadUserInfo() {
      try {
        const user = await api.loadUserInfo();
        this.setPersonalInfo(user);
      } catch (e) {
        console.log(e)
      }
    },
    async loadUserSettings() {
      try {
        const settings = await api.loadSettings();
        this.setSettings(settings);
      } catch (e) {
        console.log(e);
      }
    }
  },
  async mounted() {
    await this.getUserIp();
    if(this.getIsAuthorized) {
      this.loadUserSettings();
      this.loadUserInfo();
      this.loadKyc();
      this.loadEstimatedBalance();
    }
    this.initSocketConnection();
  },
  components: {Notification, Preloader, ModalSuccess, ModalError}
}
</script>
