var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-container z-upper"},[_c('div',{staticClass:"popup-bg"}),_c('div',{staticClass:"popup-slide"},[_c('div',{staticClass:"popup twoFa-modal"},[_c('button',{staticClass:"popup__close",on:{"click":_vm.close}}),_c('div',{staticClass:"popup__content"},[_c('ul',{staticClass:"steps"},[_c('li',{staticClass:"steps__item",class:_vm.currentStep === 1
              ? 'active'
              : '' || _vm.currentStep > 1
              ? 'disabled'
              : ''},[_c('p',[_vm._v("1")])]),_c('li',{staticClass:"steps__item",class:_vm.currentStep === 2
              ? 'active'
              : '' || _vm.currentStep > 2
              ? 'disabled'
              : ''},[_c('p',[_vm._v("2")])]),_c('li',{staticClass:"steps__item",class:_vm.currentStep === 3
              ? 'active'
              : '' || _vm.currentStep > 3
              ? 'disabled'
              : ''},[_c('p',[_vm._v("3")])]),_c('li',{staticClass:"steps__item",class:_vm.currentStep === 4
              ? 'active'
              : '' || _vm.currentStep > 4
              ? 'disabled'
              : ''},[_c('p',[_vm._v("4")])])]),(_vm.currentStep === 1)?_c('StepOne'):_vm._e(),(_vm.currentStep === 2)?_c('StepTwo'):_vm._e(),(_vm.currentStep === 3)?_c('StepThree'):_vm._e(),(_vm.currentStep === 4)?_c('StepFour',{on:{"setTwaCode":_vm.setTwaCode}}):_vm._e(),_c('div',{staticClass:"stepper-footer"},[_c('div',{staticClass:"stepper-footer__control"},[(_vm.currentStep !== 1)?_c('button',{staticClass:"page-btn page-btn--border",on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t("backBtn"))+" ")]):_vm._e(),(_vm.currentStep !== 4)?_c('button',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"page-btn",on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.$t("nextBtn"))+" ")]):_vm._e(),(_vm.currentStep === 4)?_c('button',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"page-btn",on:{"click":_vm.enableTwaFa}},[_vm._v(" "+_vm._s(_vm.$t("nextBtn"))+" ")]):_vm._e()])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }