<template>
  <div class="page-art" v-if="info">

    <router-link to="/news" tag="div" class="header__back">
      <div class="header__back-icon">
        <img src="@/assets/img/svg/arrow-left.svg" alt="">
      </div>

      {{ $t("headerNewsGoBack") }}
    </router-link>

    <!--     desktop title     -->
    <div class="page-art__title">
      {{ info.header }}
    </div>

    <div class="page-art__block">
      <div class="page-art__img">
        <img :src="loadAvatar(info.image)" alt="">

        <div class="post__plash">
          {{ $t('news') }}
        </div>
      </div>

      <div class="page-art__top">
        <div class="page-art__wrap">

          <div class="post__hashtags">
            <span v-for="(item, index) in info.tags.split(' ')" :key="index">
              #{{ item }}
            </span>
          </div>

          <!--     mobile title     -->
          <div class="page-art__title-sm">
            Что такое метаверс и при чем тут блокчейн?
          </div>

          <div class="post__notice">
            <img src="@/assets/img/svg/icon-timer.svg" alt="">

            <span class="desktop">{{ $t('postItemRead') }} </span>
            {{info.readingTime}} {{ $t('minutes') }}
          </div>
        </div>

        <div class="page-art__right">
          <div class="page-art__logo post__icon">
            <img :src="loadAvatar(info.newsManager.data)" alt="">
          </div>

          <div class="page-art__wrap">
            <div class="title-sm">
              Менеджер по коммуникациям
            </div>

            <div class="page-art__name">
              {{info.newsManager.nickname}}
            </div>

            <div class="page-art__date">
              <img src="@/assets/img/svg/icon-calendar.svg" alt="">
              {{info.created | timestampToDatePipe}}
            </div>
          </div>
        </div>
      </div>

      <div class="page-art__content">

        <div class="page-art__text" v-html="info.newsText"></div>


        <div class="social-menu" :class="{open: showSocial}">

          <div class="social-menu__title">
            {{ $t('socialMenuTitle') }}
          </div>

          <div class="social-menu__list">
            <div class="social-menu__item" :class="{hidden: index > 5}" v-for="(item, index) in shareNetworks" :key="index">
              <ShareNetwork
                  class="social-menu__link"
                  :network="item.network"
                  :url="shareUrl"
                  title="See the article here"
                  hashtags="conecoin,exchange"
              >
              </ShareNetwork>
              <img :src="item.image" alt="">
            </div>
            <div class="social-menu__item more" @click="showSocial = !showSocial">
              <img src="@/assets/img/svg/social-more.svg" alt="">
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="page-art__footer">
      <div class="title">
        {{ $t('newsItemPopular') }}
      </div>

      <div class="page-art__cards">
        <NewsPost v-for="(item, index) in news" :key="index"
                  :id="item.id"
                  :post-img="item.image"
                  :post-hashtags="item.tags.split(' ')"
                  :post-title="item.header"
                  :post-text="item.newsText"
                  :post-date="item.created"
                  :reading-time="item.readingTime"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewsPost from "../../components/news/NewsPost";
import api from "@/api/api";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import {loadAvatarMixin, timestampToDateMixin} from "@/utils/mixins";
import {mapGetters} from "vuex";

export default {
  name: "NewsItem",
  mixins: [setLoadingMixin, timestampToDateMixin, loadAvatarMixin],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    showSocial: false,
    info: null,
    news: [],
    manager: null,
    shareUrl: window.location.href,
    shareNetworks: [
      {
        network: 'twitter',
        image: require('@/assets/img/svg/social-twitter.svg')
      },
      {
        network: 'LinkedIn',
        image: require('@/assets/img/svg/social-linkedin.svg')
      },
      {
        network: 'Facebook',
        image: require('@/assets/img/svg/social-facebook.svg')
      },
      {
        network: 'Reddit',
        image: require('@/assets/img/svg/social-reddit.svg')
      },
      {
        network: 'Pinterest',
        image: require('@/assets/img/svg/social-pinterest.svg')
      },
      {
        network: 'Telegram',
        image: require('@/assets/img/svg/social-telegram.svg')
      },
      {
        network: 'Viber',
        image: require('@/assets/img/svg/social-viber.svg')
      },
      {
        network: 'WhatsApp',
        image: require('@/assets/img/svg/social-whatsup.svg')
      },
      {
        network: 'Email',
        image: require('@/assets/img/svg/social-mail.svg')
      },
    ]
  }),
  computed: {
    ...mapGetters({
      getIpAddress: 'user/getIpAddress'
    }),
  },
  watch: {
    async getIpAddress(val) {
      if(val) {
        await this.init();
      }
    },
    async id() {
      this.setLoading(true);
      await this.getNews();
      this.setLoading(false);
    }
  },
  methods: {
    async getNews() {
      try {
        this.info = await api.getNews({
          newsId: this.id,
          ip: this.getIpAddress
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getTheMostPopularNews() {
      try {
        const response = await api.getTheMostPopularNews({
          page: 0,
          size: 3,
          sort: "created,desc",
        });
        this.news = response.content;
      } catch (e) {
        console.log(e);
      }
    },
    async init() {
      this.setLoading(true);
      await Promise.all([
        this.getNews(),
        this.getTheMostPopularNews(),
      ]);
      this.setLoading(false);
    }
  },
  async mounted() {
    if(this.getIpAddress) {
      await this.init();
    }
  },
  components: {NewsPost},
}
</script>

<style scoped>

</style>