<template>
  <div class="banner">
    <div class="banner__wrap">
      <div class="logo">
        <img src="@/assets/img/svg/logo.svg" alt="">
      </div>

      <div class="banner__title">
        <span>{{$t ("bannerTitleSpan")}}</span>
        {{$t("bannerTitle")}}
      </div>

      <div class="banner__caption">
        {{$t("bannerText1")}} <span>{{$t("bannerTextSpan")}}</span> {{$t("bannerText2")}}
      </div>
    </div>

    <div class="banner__wrap">
      <button class="page-btn page-btn--border" type="button"> {{$t("bannerBtn")}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferralBanner'
}
</script>
