<template>
      <div class="invest-confirm">

        <div class="popup__content">

          <div class="popup__title popup__title--sm">
            {{ $t("investConfirmTitle") }}

          </div>

          <div class="popup__wrap">
            <div class="popup__col">
              <div class="title-sm">
                {{$t ("menuBalance")}}
              </div>

              <p>{{formatNumber(balance, true)}} USDT</p>
<!--              <span>(Erc-20)</span>-->
            </div>
            <div class="popup__col">
              <div class="title-sm">
                {{$t ("investConfirmBonus")}}
              </div>

              <p>50 USD</p>
            </div>
          </div>

          <div class="invest-confirm__wrap">
            <p> {{ $t("investConfirmText") }}</p>
           <span class="invest-confirm__text" v-if="step1"> {{step1.amount}} USDT + 50 USD ({{$t("investConfirmTextBonus")}}) </span>
          </div>

          <form class="popup__form">
            <div class="page-input" :class="{error:errorPassword}">
              <div class="page-input__title">
                {{ $t("investConfirmPassword") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType" v-model="password"  class="input"  @focus="deleteError">



                <button @click="togglePasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorPassword }}</p>
              </div>
            </div>
          </form>

        </div>
      </div>
</template>

<script>
import api from "@/api/api";
import {mapMutations} from "vuex";
import {formatNumberMixin} from "@/utils/mixins";

export default {
  name: "InvestConfirm",
  mixins: [formatNumberMixin],
  props: {
    step1: {
      required: true
    }
  },
  data: () => ({
    errorPassword: '',
    passFieldType: 'password',
    password: null,
    showPass: false,
    balance: null
  }),
  methods: {
    ...mapMutations({
      setLoading: 'general/setLoading'
    }),
    async checkBalance() {
      try {
        let wallet = await api.loadWalletByUser({
          currency: 'USDT'
        });
        this.balance = wallet.availableBalance;
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.$emit('close');
    },
    deleteError() {
      this.errorPassword=""
    },
    togglePasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.checkBalance();
    this.setLoading(false);
  }
}
</script>

<style scoped>

</style>