<template>
  <div class="trader-manager">
    <div class="box trader-box">
      <div class="trader-box__title">
        {{$t("traderManagerTitle")}}
      </div>

      <div class="trader-manager__row">
        <div class="trader-manager__wrap">
        <div class="trader-manager__icon">
          <img src="@/assets/img/user-image.png" alt="">
        </div>

        <div class="trader-manager__name">
          {{managerNickName}}
        </div>
        </div>
      </div>

      <div class="trader-manager__row">
        <div class="trader-manager__col">
          <div class="trader-box__title-sm">
            {{$t("traderManagerMail")}}
          </div>

          <div class="trader-box__value">
            {{managerEmail}}
          </div>
        </div>

        <div class="trader-manager__col">
          <div class="trader-box__title-sm">
            {{$t("traderManagerTelegram")}}
          </div>

          <div class="trader-manager__social">
            <img src="@/assets/img/svg/logo-telegram.svg" alt="">
            <a style="color: #fff; margin-left: 5px" :href="managerTelegram" target="_blank">{{managerTelegram}}</a>
          </div>
        </div>
      </div>

      <div class="trader-manager__row">
        <div class="trader-manager__col">
          <div class="code">
            <div class="code__item">
             <span v-show="isCodeVisible">{{codeArray[0]}}</span>
              <span v-show="!isCodeVisible">*</span>
            </div>
            <div class="code__item">
              <span v-show="isCodeVisible">{{codeArray[1]}}</span>
              <span v-show="!isCodeVisible">*</span>
            </div>
            <div class="code__item">
              <span v-show="isCodeVisible">{{codeArray[2]}}</span>
              <span v-show="!isCodeVisible">*</span>
            </div>
            <div class="code__item">
              <span v-show="isCodeVisible">{{codeArray[3]}}</span>
              <span v-show="!isCodeVisible">*</span>
            </div>
          </div>
        </div>
        <div class="trader-manager__col">
         <button class="trader-manager__btn" @click="isCodeVisible = !isCodeVisible">
          <span v-show="isCodeVisible">{{$t("traderManagerHide")}} </span>
          <span v-show="!isCodeVisible" >{{$t("traderManagerShow")}} </span>
         </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'PersonalManager',
    props: {
      manager: {
        type: Object,
        required: true
      },
      code: {
        type: String,
        required: true
      }
    },
    data: () => ({
      isCodeVisible: false
    }),
    computed: {
      codeArray() {
        return this.code.split('');
      },
      managerNickName() {
        return this.manager.nickname;
      },
      managerEmail() {
        return this.manager.email;
      },
      managerTelegram() {
        return this.manager.telegram;
      }
    }
  }
</script>
