<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup verification-popup">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">

          <div class="popup__title twoFa-modal__title">
            {{ $t("modalVerificationTitle") }}

          </div>

          <div class="popup__text">
            {{ $t("modalVerificationText") }}
          </div>

          <div class="page-input">
            <div class="page-input__title">
              {{$t("googleAuthCodeTitle")}}
            </div>
            <TwoFaGoogleAuthForm/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoFaGoogleAuthForm from "../utility/TwoFaGoogleAuthForm";

export default {
  name: "VerificationModal",
  components: {
    TwoFaGoogleAuthForm
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>