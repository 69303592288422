<template>
  <div class="verif-step verif-step-1">
    <div class="verif-step__row">
      <div class="verif-step__col">
        <div class="verif-step__title">
          {{ $t("verifStepPersonalTitle") }}
        </div>

        <div class="verif-step__form">
          <div class="page-input">
            <div class="page-input__title">
              {{ $t("verifStepCountry") }}
            </div>

            <div class="select">
              <div class="select__current select-nation"
                   @click="isOpenSelect = !isOpenSelect"
                   :class="{active: isOpenSelect}">
                <img :src="countryFlag" alt="">
                {{ country }}

                <div class="select__icon">
                  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.70312 3.15475L7.81438 0.0999756L8.70312 0.97259L4.70312 4.89998L0.703125 0.97259L1.59187 0.0999756L4.70312 3.15475Z"
                        fill="#FCFCFC"/>
                  </svg>
                </div>
              </div>

              <div class="select-drop" v-show="isOpenSelect">
                <ul>
                  <li class="select-drop__item" v-for="(item,index) in countries" :key="index"
                      @click="setCountry(item.name.official, item.flags.png)">
                    <img :src="item.flags.png" alt="">
                    {{ item.name.official }}
                  </li>
                </ul>
              </div>

            </div>
          </div>


          <div class="d-flex">
            <div class="page-input" :class="{error: firstNameError}">
              <div class="page-input__title">
                {{ $t("name") }}
              </div>

              <input type="text" class="input" v-model="firstNameComputed">

              <div class="error__msg" v-show="firstNameError">
                <p>{{ $t('errorNecessarily') }}</p>
              </div>
            </div>

            <div class="page-input" :class="{error: middleNameError}">
              <div class="page-input__title">
                {{ $t("surname") }}
              </div>

              <input type="text" class="input" v-model="middleNameComputed">

              <div class="error__msg" v-show="middleNameError">
                <p>{{ $t('errorNecessarily') }}</p>
              </div>
            </div>
          </div>

          <div class="page-input" :class="{error: lastNameError}">
            <div class="page-input__title">
              {{ $t("middleName") }}
            </div>

            <input type="text" class="input" v-model="lastNameComputed">

            <div class="error__msg" v-show="lastNameError">
              <p>{{ $t('errorNecessarily') }}</p>
            </div>
          </div>

          <div class="page-input" :class="{error: birthDateError}">
            <div class="page-input__title">
              {{ $t("userAccPersonalDate") }}
            </div>

            <DatePickerItem ref="datePeckerItem" @change="setDate" :type="'INPUT'"/>

            <div class="error__msg" v-show="birthDateError">
              <p>{{ $t('errorNecessarily') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="verif-step__col">
        <div class="verif-step__title">
          {{ $t("verifStepAddressTitle") }}
        </div>

        <div class="verif-step__form">
          <div class="page-input">
            <div class="page-input__title">
              {{$t("verifStepCitizenship")}}
            </div>

            <div class="select">
              <div class="select__current select-country"
                   :class="{active: isOpenSelectCountry}"
                   @click="isOpenSelectCountry = !isOpenSelectCountry">
                <img :src="citizenshipFlag" alt="">
                {{ citizenship }}

                <div class="select__icon">
                  <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M4.70312 3.15475L7.81438 0.0999756L8.70312 0.97259L4.70312 4.89998L0.703125 0.97259L1.59187 0.0999756L4.70312 3.15475Z"
                        fill="#FCFCFC"/>
                  </svg>
                </div>
              </div>

              <div class="select-drop" v-show="isOpenSelectCountry">
                <ul>
                  <li class="select-drop__item" v-for="(item, index) in countries" :key="index"
                      @click="setCitizenship(item.name.official, item.flags.png)">
                    <img :src="item.flags.png" alt="">
                    {{ item.name.official }}
                  </li>
                </ul>
              </div>

            </div>
          </div>


          <div class="d-flex d-flex-mobile">
            <div class="page-input" :class="{error: addressError}">
              <div class="page-input__title">
                {{ $t("verifStepStreet") }}
              </div>

              <input type="text" class="input" v-model="addressComputed">

              <div class="error__msg" v-show="addressError">
                <p>{{ $t('errorNecessarily') }}</p>
              </div>
            </div>

            <div class="page-input" :class="{error: apartmentNumberError}">
              <div class="page-input__title">
                {{ $t("verifStepApt") }}
              </div>

              <input type="text" class="input" v-model="apartmentNumberComputed">

              <div class="error__msg" v-show="apartmentNumberError">
                <p>{{ $t('errorNecessarily') }}</p>
              </div>
            </div>
          </div>

          <div class="page-input">
            <div class="page-input__title" :class="{error: postalCodeError}">
              {{ $t("verifStepIndex") }}
            </div>

            <input type="text" class="input" v-model="postalCodeComputed">

            <div class="error__msg" v-show="postalCodeError">
              <p>{{ $t('errorNecessarily') }}</p>
            </div>
          </div>

          <div class="page-input">
            <div class="page-input__title" :class="{error: phoneError}">
              {{ $t("verifStepPhone") }}
            </div>

            <input type="text" class="input" v-model="phoneComputed">

            <div class="error__msg" v-show="phoneError">
              <p>{{ $t('errorNecessarily') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import http from "@/utils/http";
import processingMixin from "@/mixins/processingMixin";
import api from "@/api/api";
import DatePickerItem from "@/components/utility/DatePicker";

export default {
  name: "Step1",
  mixins: [processingMixin],
  components: {
    DatePickerItem,
  },
  data: () => ({
    isOpenSelect: false,
    isOpenSelectCountry: false,
    countries: [],
    country: null,
    countryFlag: null,
    firstName: null,
    middleName: null,
    lastName: null,
    birthDate: null,
    citizenship: null,
    citizenshipFlag: null,
    address: null,
    postalCode: null,
    phone: null,
    apartmentNumber: null,

    countryError: null,
    firstNameError: null,
    middleNameError: null,
    lastNameError: null,
    birthDateError: null,
    citizenshipError: null,
    addressError: null,
    apartmentNumberError: null,
    postalCodeError: null,
    phoneError: null,
  }),
  computed: {
    firstNameComputed: {
      get() {
        return this.firstName;
      },
      set(val) {
        this.firstNameError = null;
        if(!val) {
          this.firstNameError = "Required field";
        }
        this.firstName = val;
      }
    },
    middleNameComputed: {
      get() {
        return this.middleName;
      },
      set(val) {
        this.middleNameError = null;
        if(!val) {
          this.middleNameError = "Required field";
        }
        this.middleName = val;
      }
    },
    lastNameComputed: {
      get() {
        return this.lastName;
      },
      set(val) {
        this.lastNameError = null;
        if(!val) {
          this.lastNameError = "Required field";
        }
        this.lastName = val;
      }
    },
    addressComputed: {
      get() {
        return this.address;
      },
      set(val) {
        this.addressError = null;
        if(!val) {
          this.addressError = "Required field";
        }
        this.address = val;
      }
    },
    apartmentNumberComputed: {
      get() {
        return this.apartmentNumber;
      },
      set(val) {
        this.apartmentNumberError = null;
        if(!val) {
          this.apartmentNumberError = "Required field";
        }
        this.apartmentNumber = val;
      }
    },
    postalCodeComputed: {
      get() {
        return this.postalCode;
      },
      set(val) {
        this.postalCodeError = null;
        if(!val) {
          this.postalCodeError = "Required field";
        }
        this.postalCode = val;
      }
    },
    phoneComputed: {
      get() {
        return this.phone;
      },
      set(val) {
        this.phoneError = null;
        if(!val) {
          this.phoneError = "Required field";
        }
        this.phone = val;
      }
    },
  },
  methods: {
    validate() {
      if(!this.firstName) {
        this.firstNameError = 'Required field';
        return false;
      }
      if(!this.middleName) {
        this.middleNameError = 'Required field';
        return false;
      }
      if(!this.lastName) {
        this.lastNameError = 'Required field';
        return false;
      }
      if(!this.birthDate) {
        this.birthDateError = 'Required field';
        return false;
      }
      if(!this.address) {
        this.addressError = 'Required field';
        return false;
      }
      if(!this.apartmentNumber) {
        this.apartmentNumberError = 'Required field';
        return false;
      }
      if(!this.postalCode) {
        this.postalCodeError = 'Required field';
        return false;
      }
      if(!this.phone) {
        this.phoneError = 'Required field';
        return false;
      }
      return true
    },
    setDate(val) {
      this.birthDate = Date.parse(val);
    },
    async uploadKycDetails() {
      if(!this.validate()) {
        return;
      }
      try {
        this.setLoading(true);
        await api.uploadKycDetails({
          address: this.address,
          birthDate: this.birthDate,
          country: this.country,
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          postalCode: this.postalCode,
          citizenship: this.citizenship,
          apartmentNumber: this.apartmentNumber,
          city: "Kyiv",
          phone: this.phone,
        });
        this.$emit('next');
      } catch (e) {
        this.setError(e.response.data.message);
      } finally {
        this.setLoading(false);
      }
    },
    setCountry(country, flag) {
      this.country = country;
      this.countryFlag = flag;
    },
    setCitizenship(country, flag) {
      this.citizenship = country;
      this.citizenshipFlag = flag;
    },
    async getAllCountries() {
      let response = await http.get('https://restcountries.com/v3.1/all', {
        params: {
          isPublic: true
        }
      });

      this.countries = response.data.sort((a,b) => {
        const aName = a.name.official;
        const bName = b.name.official;
        return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
      });
    },
    async getCountryByIp() {
      let response = await http.get("http://ip-api.com/json", {
        params: {
          isPublic: true
        }
      })
      return response.data.country;
    },
  },
  async mounted() {
    await this.getAllCountries();
    let country = await this.getCountryByIp();
    country = this.countries.find(i => i.name.official === country);
    if (country) {
      this.country = this.citizenship = country.name.official;
      this.countryFlag = this.citizenshipFlag = country.flags.png;
    }
    document.body.addEventListener('click', (e) => {
      if (!e.target.closest('.select-nation')) {
        this.isOpenSelect = false;
      }
      if (!e.target.closest('.select-country')) {
        this.isOpenSelectCountry = false;
      }
    })
  }
}
</script>

<style scoped>

</style>
