<template>
  <div class="tutorial-step tutorial-step--two">
    <div class="tutorial-step__title">
      Trust Score
    </div>

    <div class="tutorial-step__text">
      {{$t('tutorialThreeText')}}
    </div>
  </div>
</template>

<script>
export default {
  name: "TutorialStep3"
}
</script>

<style scoped>

</style>