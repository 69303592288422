<template>
  <div class="card-user">
    <div class="card-user__top">


      <div class="card-user__avatar">
        <label :class="{delete: getAvatar}">
          <input type="file" ref="file" @click="avatarClickHandler" @change="uploadAvatar">
        </label>
        <img :src="getAvatar || require('@/assets/img/svg/user-default.svg')" alt="">

        <div class="card-user__icon" v-show="getIsVerified">
          <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66667 14L0 8.0552L1.9975 5.95966L5.66667 9.79406L15.0025 0L17 2.1104L5.66667 14Z"
                  fill="#FCFCFC"/>
          </svg>

        </div>

      </div>

      <div class="card-user__name">
        {{ getFullName }}
      </div>

      <div class="card-user__email">
        {{ getEmail }}
      </div>

      <button class="page-btn page-btn--border" @click="isSettingsVisible = true">{{ $t('userCardBtn') }}</button>

    </div>

    <div class="card-user__bottom">

      <div class="card-user__wrap">
        <div class="title-sm">
          {{ $t('coinBalanceTotal') }}
        </div>

        <div class="card-user__value">
          {{btcAmount}} BTC
        </div>
      </div>

      <div class="card-user__wrap">
        <div class="title-sm">
          {{ $t('userCardEstimated') }}
        </div>

        <div class="card-user__value card-user__value--sm">
          $ {{usdAmount}} USD
        </div>
      </div>

      <button class="card-user-btn" @click="isTwoFaModalVisible = true">
        <span class="card-user-btn__icon">
         <img src="@/assets/img/svg/icon-2fa.svg" alt="">
        </span>

        <span class="card-user-btn__name">2FA</span>

        <span class="card-user-btn__status"
              :class="{danger: getIsTwoFaEnabled}">{{ getIsTwoFaEnabled ? 'Disable' : 'Enable' }}
        </span>
      </button>

      <button class="card-user-btn" @click="isConfirmationModalVisible = true">
        <span class="card-user-btn__icon">
         <img src="@/assets/img/svg/icon-verification.svg" alt="">
        </span>

        <div class="card-user-btn__wrap">
          <span class="card-user-btn__name">Verification</span>
          <span class="card-user-btn__edit" v-show="getVerificationStatus === 'PENDING'">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.39175 11.0829H12.25V12.2496H1.75V9.77448L7.525 3.99948L9.9995 6.47514L5.39117 11.0829H5.39175ZM8.34925 3.17523L9.58708 1.93739C9.69647 1.82804 9.84482 1.7666 9.9995 1.7666C10.1542 1.7666 10.3025 1.82804 10.4119 1.93739L12.0622 3.58764C12.1715 3.69703 12.233 3.84538 12.233 4.00006C12.233 4.15474 12.1715 4.30309 12.0622 4.41248L10.8243 5.64973L8.34983 3.17523H8.34925Z"
                  fill="#71727D"/>
            </svg>

            {{ $t("verificationEdit") }}
          </span>
        </div>

        <span class="card-user-btn__status"
              :class="{verified : getVerificationStatus === 'APPROVE',
                                  danger : getVerificationStatus === 'CREATED',
                                  pending: getVerificationStatus === 'PENDING'}">{{
            getVerificationStatus === 'APPROVE' ?
                'APPROVE' : getVerificationStatus === 'CREATED' ? 'NOT VERIFIED' : getVerificationStatus === 'PENDING' ? 'PENDING' : ''}}
        </span>
      </button>
    </div>

    <TwoFaModal v-if="isTwoFaModalVisible"
                @close="isTwoFaModalVisible = false"/>
    <AccountSettings v-if="isSettingsVisible"
                     @close="isSettingsVisible = false"/>
    <VerificationModal v-if="isVerificationModalVisible && getVerificationStatus === 'CREATED'"
                       @close="isVerificationModalVisible = false"/>
    <ConfirmationModal v-if="isConfirmationModalVisible" @close="isConfirmationModalVisible = false"
                       @open="isIdentificationVisible = true;isConfirmationModalVisible = false"/>
    <Identification v-if="isIdentificationVisible" @close="isIdentificationVisible = false"/>
  </div>
</template>

<script>
import TwoFaModal from "../modals/TwoFaModal";
import AccountSettings from "../settings/AccountSettings";
import VerificationModal from "../modals/VerificationModal";
import {mapGetters, mapMutations} from "vuex";
import api from "../../api/api";
import ConfirmationModal from "../verification/ConfirmationModal";
import Identification from "../verification/Identification";

export default {
  name: 'UserInfoCard',
  data: () => ({
    file: null,
    status2fa: 'disable',
    isTwoFaModalVisible: false,
    isSettingsVisible: false,
    isVerificationModalVisible: false,
    isConfirmationModalVisible: false,
    isIdentificationVisible: false,
  }),
  computed: {
    ...mapGetters({
      getIsTwoFaEnabled: 'user/getIsTwoFaEnabled',
      getEstimatedValues: 'user/getEstimatedValues',
      getFullName: 'user/getFullName',
      getEmail: 'user/getEmail',
      getAvatar: 'user/getAvatar',
      getIsVerified: 'user/getIsVerified',
      getVerificationStatus: 'user/getVerificationStatus',
      getSettings: 'user/getSettings'
    }),
    btcAmount() {
      return this.getEstimatedValues ? +(this.getEstimatedValues.btcAmount.toFixed(8)): 0;

    },
    usdAmount() {
      if(this.getEstimatedValues) {
        if(this.getEstimatedValues.usdAmount >= 1) {
          return +(this.getEstimatedValues.usdAmount.toFixed(2))
        } else {
          return +(this.getEstimatedValues.usdAmount.toFixed(2))
        }
      }
      return 0
    },
  },
  methods: {
    ...mapMutations({
      setLoading: 'general/setLoading',
      setError: 'general/setError',
      setPersonalInfo: 'user/setPersonalInfo'
    }),
    async avatarClickHandler($event) {
      if (this.getAvatar) {
        $event.preventDefault();
        try {
          this.setLoading(true);
          await api.deleteAvatar();
          let user = await api.loadUserInfo();
          this.setPersonalInfo(user);
        } catch (e) {
          this.setError(e.response.data.code);
        } finally {
          this.setLoading(false);
        }
      }
    },
    async uploadAvatar(event) {
      this.setLoading(true);
      this.file = this.$refs.file.files[0]
      const images = ['jpeg', 'jpg', 'PNG', 'JPG', 'JPEG', 'img', 'png', 'svg'];
      if (!images.includes(this.file.type.split('/')[1])) {
        this.setLoading(false);
        this.setError({
          message: 'Wrong type file'
        })
        event.target.value = '';
        this.file = null;
        return
      }
      try {
        await api.uploadAvatar(this.$refs.file.files[0])
        this.setLoading(false);
        event.target.value = '';
        let user = await api.loadUserInfo();
        this.setPersonalInfo(user);
      } catch (e) {
        console.log(e)
      }
    },
  },

  components: {
    Identification,
    ConfirmationModal,
    TwoFaModal,
    AccountSettings,
    VerificationModal
  }
}
</script>
