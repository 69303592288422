<template>
<div class="security-card" :class="{low: securityStatus === 'low', mid: securityStatus === 'mid', high: securityStatus === 'high'}">
  <div class="security-card__title">
    {{$t("securityCardTitle")}}
  </div>

  <div class="security-status">
    <div class="security-status__title">
     <p v-show="securityStatus === 'low'"> {{$t("securityCardStatusLow")}} </p>
     <p v-show="securityStatus === 'mid'"> {{$t("securityCardStatusMid")}} </p>
     <p v-show="securityStatus === 'high'"> {{$t("securityCardStatusHigh")}} </p>
    </div>

    <div class="security-status__range">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>

  <div class="security-card__msg">
    <div class="security-card__icon">
      <img src="@/assets/img/svg/security-low.svg" alt="" v-show="securityStatus === 'low'">
      <img src="@/assets/img/svg/security-mid.svg" alt="" v-show="securityStatus === 'mid'">
      <img src="@/assets/img/svg/security-high.svg" alt="" v-show="securityStatus === 'high'">
    </div>

    <div class="security-card__text">
      <p v-show="securityStatus === 'low'">
        {{$t("securityCardMsgLow1")}}
     <span>   {{$t("securityCardMsgLowStrong")}} </span>{{$t("securityCardMsgLow2")}}
      </p>

      <p v-show="securityStatus === 'mid'">
        {{$t("securityCardMsgMid1")}}
        <span>    {{$t("securityCardMsgMidStrong")}}</span>{{$t("securityCardMsgMid2")}}
      </p>

      <p v-show="securityStatus === 'high'">
        {{$t("securityCardMsgHigh")}}
        <span> {{$t("securityCardMsgHighStrong")}}</span>

      </p>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserSecurity",
  computed: {
    ...mapGetters({
      getIsTwoFaEnabled: 'user/getIsTwoFaEnabled',
      getIsVerified: 'user/getIsVerified'
    }),
    securityStatus() {
      let status = 'low';
      if(this.getIsTwoFaEnabled) {
        status = 'mid'
      }
      if(this.getIsVerified) {
        status = 'high'
      }
      return status;
    },
  }
}
</script>

<style scoped>

</style>