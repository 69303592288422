import api from "@/api/api";

export default {
    props: {
        id: {
            type: String,
            required: false,
        }
    },
    data: () => ({
        trader: null
    }),
    methods: {
        async loadTraderInfo() {
            try {
                this.trader = await api.loadTraderInfo({
                    traderId: this.id,
                });
            } catch (e) {
                console.log(e);
            }
        },
    }
}