<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup identification">
        <button @click="close" class="popup__close"></button>
        <div class="popup__head">
          <div class="steps">
            <div class="steps__item" :class="{active: index === currentStep, disabled: currentStep > index}"
                 v-for="index in stepsLength" :key="index">
              {{ index }}
            </div>
          </div>
        </div>
        <div class="popup__content">

          <keep-alive>
            <Step1 v-if="currentStep === 1" ref="step1" @next="nextStep"/>
            <Step2 v-if="currentStep === 2" ref="step2" @openModal='isInfoModalVisible = true'/>
            <Step3 v-if="currentStep === 3" ref="step3" @openSelfieModal='isSelfieModalVisible = true'/>
          </keep-alive>

          <div class="stepper-footer">
            <div class="stepper-footer__control">
              <button
                  class="page-btn page-btn--border page-btn--cancel"
                  v-if="currentStep === 1"
                  @click="close"
              >
                {{ $t("cancelBtn") }}
              </button>
              <button
                  class="page-btn page-btn--border"
                  @click="prevStep"
                  v-if="currentStep !== 1"
              >
                {{ $t("backBtn") }}
              </button>
              <button
                  v-focus
                  class="page-btn"
                  @click="makeAction"
                  :disabled="nextBtnDisabled"
              >
                {{ $t("nextBtn") }}
              </button>


            </div>
          </div>

        </div>
      </div>
    </div>
    <ConfirmationInfoModal v-if="isInfoModalVisible" @close="isInfoModalVisible = false"/>
    <SelfieModal v-if="isSelfieModalVisible" @close="isSelfieModalVisible = false"/>
  </div>
</template>

<script>
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import ConfirmationInfoModal from "./ConfirmationInfoModal";
import SelfieModal from "./SelfieModal";
import api from "@/api/api";
import processingMixin from "@/mixins/processingMixin";
import {mapMutations} from "vuex";

export default {
  name: "Identification",
  mixins: [processingMixin],
  components: {SelfieModal, ConfirmationInfoModal, Step3, Step2, Step1},
  data: () => ({
    stepsLength: 3,
    currentStep: 1,
    isInfoModalVisible: false,
    isSelfieModalVisible: false,
    isMounted: false,

  }),
  computed: {
    nextBtnDisabled() {
      if (this.isMounted && this.currentStep === 2) {
        return this.currentStep === 2 && this.$refs.step2 && this.$refs.step2.uploaded !== 3;
      }
      return false;
    }
  },
  watch: {
    currentStep(val) {
      if (val === 2) {
        setTimeout(() => {
          this.isMounted = true;
        }, 10);
      } else {
        this.isMounted = false;
      }
    }
  },
  methods: {
    ...mapMutations({
      setVerification: "user/setVerification",
    }),
    async makeAction() {
      if(this.currentStep === 3) {
        if(this.$refs.step3.uploaded !== 1) {
          this.setError({
            message: 'Please upload selfie',
          });
          return;
        }
        await this.sendToVerification();
        this.close();
      }
      if(this.currentStep === 2) {
        if(this.$refs.step2.uploaded !== 3) {
          this.setError({
            message: 'Please upload all photos',
          });
          return;
        }
        this.currentStep++;
      }
      if(this.currentStep === 1) {
        await this.$refs.step1.uploadKycDetails();
      }

      if(this.currentStep === 4) {
        await this.sendToVerification();
      }
    },
    async sendToVerification() {
      try {
        this.setLoading(true);
        await api.sendToVerification();
        this.setSuccess({
          message: 'Verification has been sent',
        });
        this.setVerification('PENDING');
      } catch (e) {
        console.log(e);
        this.setError({
          message: e.response.data.message,
        })
      } finally {
        this.setLoading(false);
      }
    },
    async nextStep() {
      if (this.currentStep !== 4) {
        this.currentStep++;
      }

    },
    prevStep() {
      if (this.currentStep !== 1) this.currentStep--;
    },
    close() {
      this.$emit("close");
    }
  },
}
</script>

<style scoped>

</style>
