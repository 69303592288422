import api from '@/api/api'
export default {
    namespaced: true,
    state: {
        isAuthorized: !!localStorage.getItem('at'),
        user: null,
        settings: null,
        estimatedValues: null,
        verification: 'CREATED',
        userId: localStorage.getItem('userId') || null,
        ip: null,
        kyc: null,
    },
    mutations: {
        setKyc(state, payload) {
          state.kyc = payload;
        },
        setIpAddress(state, payload) {
          state.ip = payload;
        },
        setName(state, payload) {
          state.user.name = payload.split(' ')[0];
          state.user.surname = payload.split(' ')[1];
        },
        setEmail(state, payload) {
          state.user.email = payload;
        },
        setVerification(state, payload) {
            state.verification = payload;
        },
        setEstimatedValues(state, payload) {
            state.estimatedValues = payload;
        },
        setPersonalInfo(state, payload) {
            state.user = payload;
        },
        setSettings(state, payload) {
            state.settings = payload;
        },
        setToken(state, payload) {
            state.isAuthorized = true;
            localStorage.setItem('at', payload.token);
            state.userId = payload.userId;
            localStorage.setItem('userId', payload.userId);
        },
        unsetUser(state) {
            state.isAuthorized = false;
            state.user = null;
            localStorage.removeItem('at');
        }
    },
    actions: {
        signIn({commit}, payload) {
            return api.signIn(payload)
                .then(res => {
                    commit('setToken', res);
                    return res
                })
                .catch(e => {
                    throw e
                })

        }
    },
    getters: {
        getId: state => state.userId,
        getIsAuthorized: state => state.isAuthorized,
        getFullName: state => `${state.user?.name || ''} ${state.user?.surname || ''}`,
        getNickName: state => state.user ? state.user.nickName || state.user.name : '',
        getEmail: state => state.user ? state.user.email : '',
        getAvatar: state => {
            return state.user?.avatar ? `data:image/png;base64, ${state.user.avatar}` : null
        },
        getSettings: state => state.settings,
        getEstimatedValues: state => state.estimatedValues,
        getIsVerified: state => state.verification === 'APPROVE',
        getVerificationStatus: state => state.verification,
        getIsTwoFaEnabled: state => state.settings && state.settings.twoFa,
        getIpAddress: state => state.ip,
        getKyc: state => state.kyc,
    }
}
