<template>
  <div class="post">
    <div class="post__plash">
      {{$t('news')}}
    </div>

    <div class="post__img">
      <img :src="postImg" alt="">

      <div class="post__icon">
        <img src="@/assets/img/svg/logo-sm-white.svg" alt="">
      </div>
    </div>



    <div class="post__content">
      <div class="post__hashtags">
        <span v-for="(item,index) in postHashtags" :key="index">
          #{{item}}
        </span>
      </div>

      <router-link :to="`/news-item/${id}`" tag="div" class="post__title">
        {{postTitle}}
      </router-link>

      <div class="post__text">
        <span v-html="truncate(postText, 194)"></span>
      </div>

      <div class="post__bottom">
        <div class="post__date">
          <img src="@/assets/img/svg/icon-calendar.svg" alt="">

          {{postDate | timestampToDatePipe}}
        </div>

        <div class="post__notice">
          <img src="@/assets/img/svg/icon-timer.svg" alt="">

          {{$t('postReading')}} {{readingTime}} {{$t('minute')}}
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {timestampToDateMixin, truncateMixin} from "@/utils/mixins";

  export default {
    name: 'NewsPost',
    mixins: [timestampToDateMixin, truncateMixin],
    props: {
      id: {
        type: String,
        required: true,
      },
      postImg: {
        type: String,
        required: true
      },
      postHashtags: {
        type: Array,
      },
      postTitle: {
        type: String,
        required: true
      },
      postText: {
        type: String,
        required: true
      },
      postDate: {
        type: Number
      },
      readingTime: {
        type: String
      }
    }
  }
</script>
