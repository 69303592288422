<template>
  <div class="popup-bottom-container">
    <div class="popup-bottom__close" @click="close"></div>
  <div class="popup-bottom popup-bottom--buy active" >
    <SellMarket/>
  </div>
  </div>
</template>

<script>
import SellMarket from "../market/SellMarket";

export default {
  name: "SellMarketPopup",
  components: {
    SellMarket
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>