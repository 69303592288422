<template>
<div class="twoFa-modal__wrap twoFa-modal__step1">
  <div class="twoFa-modal__title">
    {{$t("googleStepOneTitle")}}
  </div>

  <div class="popup__text">
    {{$t("googleStepOneText")}}

  </div>

  <div class="twoFa-modal__btn">
    <a href="#"></a>
    <img src="@/assets/img/svg/appStore.svg" alt="">
  </div>

  <div class="twoFa-modal__btn">
    <a href="#"></a>
    <img src="@/assets/img/svg/googlePlay.svg" alt="">
  </div>

</div>
</template>

<script>
export default {
  name: "StepOne"
}
</script>

<style scoped>

</style>