<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup photo-modal">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
            <div class="photo-modal__title">
              {{ $t("photoModalTitle") }}
            </div>

          <div class="photo-modal__subtitle">
            {{ $t("photoModalSubtitle") }}
          </div>

         <div class="photo-modal__wrap">
           <div class="photo">
             <div class="photo__img">
               <img src="@/assets/img/card-img.png" alt="">
             </div>

             <div class="photo__icon photo__icon--checked">
               <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M1 3.43596L3.85148 6L9 1" stroke="#F2F2F2" stroke-width="2"/>
               </svg>

             </div>

             <div class="photo__caption">
               {{ $t("photoPassed") }}
             </div>
           </div>

           <div class="photo">
             <div class="photo__img photo__img--cut">
               <img src="@/assets/img/card-img.png" alt="">
             </div>

             <div class="photo__icon">
               <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#fff"/>
               </svg>


             </div>

             <div class="photo__caption">
               {{ $t("photoCutted") }}
             </div>
           </div>

           <div class="photo">
             <div class="photo__img photo__img--blur">
               <img src="@/assets/img/card-img.png" alt="">
             </div>

             <div class="photo__icon">
               <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#fff"/>
               </svg>


             </div>

             <div class="photo__caption">
               {{ $t("photoBlurred") }}
             </div>
           </div>

           <div class="photo">
             <div class="photo__img photo__img--blick">
               <img src="@/assets/img/card-img-blick.png" alt="">
             </div>

             <div class="photo__icon">
               <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#fff"/>
               </svg>


             </div>

             <div class="photo__caption">
               {{ $t("photoBlicks") }}
             </div>
           </div>
         </div>

          <ul class="modal-list">
            <li class="modal-list__item passed">
              {{ $t("photoListText1") }}
            </li>

            <li class="modal-list__item passed">
              {{ $t("photoListText2") }}
            </li>

            <li class="modal-list__item passed">
              {{ $t("photoListText3") }}
            </li>

            <li class="modal-list__item passed">
              {{ $t("photoListText4") }}
            </li>

            <li class="modal-list__item">
              {{ $t("photoListText5") }}
            </li>

            <li class="modal-list__item">
              {{ $t("photoListText6") }}
            </li>
          </ul>

          <div class="photo-modal__notice">
            {{ $t("photoModalNotice") }}
          </div>

          <button class="page-btn" @click="close">
            {{ $t("photoModalBtn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationInfoModal",
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>