import SockJs from 'sockjs-client';
import Stomp from 'webstomp-client';
import store from '@/store';

const baseUrl = 'https://conecoin.com/api/wss'
//const baseUrl = 'http://64.227.41.197:8088/ws';

const orderBookEndpoints = {
  GET_ORDERS: pairIndex => `/user/${pairIndex}/orderbook/receive`,
}

const candlesEndpoints = {
  RECEIVE_CANDLES: (pairIndex, timeFrame) => `/user/${timeFrame}_${pairIndex}/candles/receive`,
}

const pairsEndpoints = {
  UPDATE_PAIRS: () => '/user/exchange/pairs/receive'
}

const statisticEndpoints = {
  GET_STATISTIC: pairIndex => `/user/${pairIndex}/statistic/receive`
}

const tradesEndpoints = {
  GET_TRADES: pairIndex => `/user/${pairIndex}/history/orders/receive`
}

const notificationsEndpoints = {
  GET_NOTIFICATIONS: (userId) => `/user/${userId}/notifications/receive`
}

let stompClient = null;

const initSocketConnection = () => {
  stompClient = null;
  const socket = new SockJs(baseUrl);
  stompClient = Stomp.over(socket);
  stompClient.debug = () => {}
  stompClient.connect({}, () => {
    let pairIndex = store.getters['pair/getPairIndex'];
    let timeFrame = store.getters['socket/getTimeFrame'];
    stompClient.subscribe(orderBookEndpoints.GET_ORDERS(pairIndex), tick => {
      store.commit('socket/setOrders', {orders: JSON.parse(tick.body)})
    })
    stompClient.subscribe(candlesEndpoints.RECEIVE_CANDLES(pairIndex, timeFrame), tick => {
      store.commit('socket/setCandle', {candle: JSON.parse(tick.body)})
    })
    stompClient.subscribe(statisticEndpoints.GET_STATISTIC(pairIndex), tick => {
      store.commit('socket/setStatistic', {statistic: JSON.parse(tick.body)})
    });
    stompClient.subscribe(tradesEndpoints.GET_TRADES(pairIndex), tick => {
      store.commit('socket/setTrades', {trade: JSON.parse(tick.body)})
    });
    stompClient.subscribe(pairsEndpoints.UPDATE_PAIRS(), tick => {
      store.commit('socket/setPairs', {pairs: JSON.parse(tick.body)})
    });
    if(store.getters["user/getIsAuthorized"]) {
      stompClient.subscribe(notificationsEndpoints.GET_NOTIFICATIONS(store.getters["user/getId"]), tick => {
        store.commit('socket/setNotifications', {notifications: JSON.parse(tick.body)})
      });
    }

  })
}

const disconnectSocket = () => {
  stompClient.disconnect();
}

export default {
  initSocketConnection,
  disconnectSocket
}
