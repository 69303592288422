<template>
  <div class="preloader" :class="{'preloader-sm': isSmall}">
    <div class="preloader-wrap">
      <lottie
          :options="defaultOptions"
          path="preloader.json"
          :loop="true"
          :autoPlay="true"
          :speed="1"
          :width="setAnimWidth"
      ></lottie>
    </div>
  </div>
</template>
<script>
import Lottie from 'vue-lottie';
import * as animationData from '/public/preloader.json';

export default {
  name: 'Preloader',
  props:{
    isSmall:{
      default:false,
    },
  },
  data() {
    return {
      defaultOptions: {animationData: animationData.default},
      windowWidth: window.innerWidth,
    }
  },
  components: {
    'lottie': Lottie
  },
  computed: {
    setAnimWidth () {
      if (this.isSmall) {
        return  250
      }
      return 400;
    }



  }
};
</script>
<style scoped lang="scss">
.preloader{
  position: fixed;
  z-index: 99994;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background:rgba(0,0,0,0.4);
  .text{
    font-size: 2rem;
    color: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 30px;
  }
}
.preloader-wrap{
  // max-height: 80vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

}

.preloader-sm {
  position: absolute;
  width: 100%;
  height: 100%;
  background: none;
}

</style>
