<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide popup-slide--side popup-slide--tutorial">
      <div class="popup tutorial-modal">
        <button @click="close" class="popup__close"></button>
        <div class="popup__head">
          <div class="steps">
            <div class="steps__icon">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
              </svg>

            </div>

            <div class="steps__num">
             <span>  {{$t('steps')}}

              {{currentStep}} </span>/{{stepsLength}}
            </div>
          </div>
        </div>
        <div class="popup__content">


          <TutorialStep1 v-if="currentStep === 1"/>
          <TutorialStep2 v-if="currentStep === 2"/>
          <TutorialStep3 v-if="currentStep === 3"/>

          <div class="stepper-footer">
            <div class="stepper-footer__control">
              <button
                  class="page-btn page-btn--border"
                  @click="prevStep"
                  :disabled="currentStep === 1"
              >
                {{$t("backBtn")}}
              </button>
              <button
                  v-focus
                  class="page-btn"
                  v-if="currentStep !== 4"
                  @click="nextStep"
              >
                {{$t("nextBtn")}}
              </button>


            </div>
          </div>

          <div class="tutorial-notice">
            <div class="tutorial-notice__text">
              {{$t("tutorialNoticeText")}}
            </div>

            <button @click="close" class="tutorial-notice__link">
              {{$t("tutorialNoticeLink")}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TutorialStep1 from "./TutorialStep1";
import TutorialStep2 from "./TutorialStep2";
import TutorialStep3 from "./TutorialStep3";
export default {
  name: "Tutorial",
  components: {TutorialStep3, TutorialStep2, TutorialStep1},
  data() {
    return {
      stepsLength: 3,
      currentStep: 1,
    }
  },
  methods: {
    nextStep() {
      if (this.currentStep !== 4) this.currentStep++;
    },
    prevStep() {
      if (this.currentStep !== 1) this.currentStep--;
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style scoped>

</style>