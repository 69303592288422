<template>
  <div class="login-page restore-page">
    <div class="login-wrap">
      <div class="login-page__content">
        <div class="logo">
          <img src="@/assets/img/svg/logo.svg" alt="">
        </div>


        <h1 class="login-page__title">
          {{ $t("restorePassTitle") }}
        </h1>

        <div class="login-page__caption">
          {{ $t("restorePassCaption") }}
        </div>

        <form class="form" @submit.prevent="restorePassword">
          <div class="page-input" :class="{error:errorEmail}">
            <div class="page-input__title">
              {{ $t("email") }}
            </div>

            <input type="text" class="input"
                   @focus="deleteError('email')"
                   v-focus
                    v-model="email">

            <div class="error__msg">
              <p>{{ errorEmail }}</p>
            </div>
          </div>


          <button class="page-btn">
            {{ $t("restorePassBtn") }}
          </button>

          <router-link to="/sign-in" tag="button" class="page-btn page-btn--border page-btn--neutral">{{ $t("restorePassDisable") }}</router-link>
        </form>

      </div>

    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: 'RestorePassword',
  data: () => ({
    errorEmail: '',
    isRestore: true,
    isSentValid: true,
    email: null
  }),
  computed: {
    ...mapGetters({
      getSuccess: 'general/getSuccess'
    })
  },
  watch: {
    getSuccess(val) {
      if(!val) {
        this.$router.push('/sign-in')
      }
    }
  },
  methods: {
    ...mapMutations({
      setSuccess: 'general/setSuccess',
      setError: 'general/setError'
    }),
    isValid() {
      let rules = {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      }
      if(!rules.email.test(String(this.email).toLowerCase())) {
        this.errorEmail = this.$t('wrongEmail');
        return false;
      }
      return true;
    },
    async restorePassword() {
      try {
        if(!this.isValid()) {
          return;
        }
        await api.restorePassword({
          email: this.email,
          isPublic: true
        });
        this.setSuccess({
          message: 'Check your email'
        })
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
      }
    },
    deleteError(type) {
      switch (type) {
        case 'email':
          this.errorEmail = "";break;
      }
    },
  }
}
</script>
