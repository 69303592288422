<template>
  <div class="custom-table custom-table--balance">
    <div class="box">
      <table cellpadding="0" cellspacing="0">
        <thead class="custom-table-head">
        <tr>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">
                {{ $t("balanceTableCoin") }}
              </button>
            </div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("balanceTableTotal") }}
              </button></div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("balanceTableAvaliable") }}
              </button></div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("balanceTableLocked") }}
              </button></div>
          </td>
          <td>
            <div class="custom-table-head__title">
              <button class="table-filter">{{ $t("balanceTableBTC") }}
              </button></div>
          </td>
          <td>
            <div class="custom-table-head__title">
             {{ $t("balanceTableAction") }}
             </div>
          </td>

        </tr>
        </thead>

        <tbody>
        <tr v-for="(item, index) in wallets" :key="index">
          <td>
            <div class="custom-table__wrap coin-wrap">
                <div class="coin">
                  <img :src="loadImage(item.currency)" alt="">
                </div>

              <div class="coin-name">
                <span class="coin-name__short"><strong>{{item.currency}}</strong></span>
<!--                <span class="coin-name__long">Etherium</span>-->
              </div>
            </div>
          </td>
          <td>
            <p>{{!item.needImport ? Number(item.totalBalance.toFixed(8)) : item.totalBalance}}</p>
          </td>
          <td>
            <p>{{!item.needImport ? Number(item.availableBalance.toFixed(8)) : item.availableBalance}}</p>
          </td>
          <td>
            <p>{{!item.needImport ? Number(item.lockedBalance.toFixed(8)) : item.lockedBalance}}</p>
          </td>
          <td>
            <p>{{!item.needImport ? Number(item.usdValue.toFixed(2)) : item.usdValue}}</p>
          </td>
          <td>
            <button class="custom-table__btn primary"
                    v-if="!item.needImport"
                    @click="goToDeposit(item.currency)">
              <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 18C13 17.4477 12.5523 17 12 17H1.99999C1.4477 17 0.999987 17.4477 0.999987 18C0.999987 18.5523 1.4477 19 1.99999 19H12C12.5523 19 13 18.5523 13 18ZM6.99999 15.3271L12.9768 9.54306C13.3737 9.15899 13.3841 8.5259 13 8.12903C12.6159 7.73216 11.9828 7.72178 11.586 8.10585L7.99999 11.5762V1C7.99999 0.447716 7.55227 0 6.99999 0C6.4477 0 5.99999 0.447716 5.99999 1V11.5762L2.41401 8.10585C2.01714 7.72178 1.38406 7.73216 0.999987 8.12903C0.615916 8.52591 0.626294 9.15899 1.02317 9.54306L6.99999 15.3271Z" fill="#0ECB81"/>
              </svg>

              {{ $t("deposit") }}
            </button>
            <button class="custom-table__btn danger"
                    v-if="!item.needImport"
                    @click="goToWithdraw(item.currency)">
              <svg width="13" height="19" viewBox="0 0 13 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 1C12.5 1.55228 12.0523 2 11.5 2H1.49999C0.947702 2 0.499987 1.55228 0.499987 1C0.499987 0.447715 0.947702 0 1.49999 0H11.5C12.0523 0 12.5 0.447715 12.5 1ZM6.49999 3.67293L12.4768 9.45694C12.8737 9.84101 12.8841 10.4741 12.5 10.871C12.1159 11.2678 11.4828 11.2782 11.086 10.8941L7.49999 7.42385V18C7.49999 18.5523 7.05227 19 6.49999 19C5.9477 19 5.49999 18.5523 5.49999 18V7.42385L1.91401 10.8941C1.51714 11.2782 0.884057 11.2678 0.499987 10.871C0.115916 10.4741 0.126294 9.84101 0.523167 9.45694L6.49999 3.67293Z" fill="#DE2A4A"/>
              </svg>
              {{ $t("withdraw") }}
            </button>
            <button class="custom-table__btn default"
                    v-if="!item.needImport"
                    @click="goToTrade(item.currency)">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.30457 1.34581C6.93911 0.731735 7.99999 1.18139 7.99999 2.06441V18.9999C7.99999 19.5522 7.55227 19.9999 6.99999 19.9999C6.4477 19.9999 5.99999 19.5522 5.99999 18.9999V4.42374L2.41401 7.89404C2.01714 8.27811 1.38406 8.26774 0.999987 7.87086C0.615916 7.47399 0.626296 6.84091 1.02317 6.45684L6.30457 1.34581ZM12 0.999958C12 0.447673 12.4477 -4.23789e-05 13 -4.23789e-05C13.5523 -4.23789e-05 14 0.447674 14 0.999958V15.5761L17.586 12.1058C17.9828 11.7217 18.6159 11.7321 19 12.129C19.3841 12.5259 19.3737 13.1589 18.9768 13.543L13.6954 18.654C13.0609 19.2681 12 18.8185 12 17.9354V0.999958Z" fill="#0363FE"/>
              </svg>
              {{ $t("trade") }}
            </button>
            <button class="custom-table__btn white"
                    v-if="item.needImport"
                    @click="createWalletByUser(item.currency)">
              {{ $t("needImport") }}
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import {loadImageMixin} from "@/utils/mixins";

export default {
  name: "BalanceDesktopTable",
  mixins: [loadImageMixin],
  props: {
    wallets: {
      type: Array,
      required: true
    }
  },
  methods: {
    goToTrade(currency) {
      this.$emit('goToTrade', currency);
    },
    goToWithdraw(currency) {
      this.$emit('goToWithdraw', currency);
    },
    goToDeposit(currency) {
      this.$emit('goToDeposit', currency);
    },
    createWalletByUser(currency) {
      this.$emit('createWalletByUser', currency);
    }
  }
}
</script>
