<template>
  <div class="mobile-table mobile-table--stats">

    <div class="mobile-table__head mobile-table-head">
      <p class="mobile-table__title">
        {{ $t("statsTableSymbol") }}
      </p>
      <p class="mobile-table__title">
        PnL, $
      </p>
      <p class="mobile-table__title">
        PnL, %
      </p>
      <p class="mobile-table__title">{{ $t("ordersTableInfo") }}</p>
    </div>

    <div class="mobile-table-item"
         v-for="(item, index) in history"
         :class="{active: item.isShow}"
         :key="index">

      <button
          class="mobile-table-item__toggle"
          @click="item.isShow = !item.isShow"
          :class="{close: item.isShow}"
      ></button>
      <div class="mobile-table-item__values">
        <div class="mobile-table-item__row mobile-table-head">

          <div class="mobile-table-item__col">
            <p>{{ item.firstCurrency }} / {{ item.secondCurrency }}</p>
          </div>

          <div class="mobile-table-item__col">
            <p class="danger">${{ formatNumber(item.price) }}</p>
          </div>

          <div class="mobile-table-item__col">
            <p class="primary">{{ formatNumber(item.profitPercentage, true) }}%</p>
          </div>
        </div>

        <div class="mobile-table-item__block" v-show="item.isShow">
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("statsTableSize") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{ formatNumber(item.amount) }}</p>
            </div>
          </div>
          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("statsTableOpen") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{ formatNumber(item.openingPrice) }}</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("statsTableClose") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{ formatNumber(item.closingPrice) }}</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("statsTableDuration") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{timestampsToRange(item.openingTime, item.closingTime)}}</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("statsTableOpenTime") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{item.openingTime | timestampToDateTimePipe}}</p>
            </div>
          </div>

          <div class="mobile-table-item__row">
            <div class="mobile-table-item__col">
              <p class="mobile-table__title">{{ $t("statsTableCurrent") }}</p>
            </div>

            <div class="mobile-table-item__col">
              <p>{{item.closingTime | timestampToDateTimePipe}}</p>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {formatNumberMixin, timestampToDateMixin, timestampToDateTimeMixin} from "@/utils/mixins";
import timestampsToRangeMixin from "@/mixins/timestampsToRangeMixin";

export default {
  name: 'TradeHistoryMobileTable',
  mixins: [formatNumberMixin, timestampToDateMixin, timestampsToRangeMixin, timestampToDateTimeMixin],
  props: {
    history: {
      type: Array,
      required: true
    }
  }
}
</script>
