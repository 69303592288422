<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup twoFa-modal">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="popup__title twoFa-modal__title">
            {{ $t("twoFaDisableTitle") }}
          </div>

          <div class="popup__text twoFa-modal__text">
            {{ $t("twoFaDisableText") }}
          </div>

          <div class="popup__msg popup__msg--sm">
            {{ $t("twoFaDisableTextSm") }}
          </div>

          <form class="twoFa-modal__form">
            <div class="page-input" :class="{error:errorRepeatPassword}">
              <div class="page-input__title">
                {{ $t("repeatPassword") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType"
                       class="input"
                       @focus="deleteError"
                       v-model="repeatPassword">


                <button @click="toggleRepeatPasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorRepeatPassword }}</p>
              </div>
            </div>

            <div class="page-input">
              <div class="page-input__title">
                {{ $t("googleAuthCodeTitle") }}
              </div>
            </div>
            <TwoFaGoogleAuthForm @twaCode="setTwaCode"/>
          </form>


          <div class="stepper-footer">
            <div class="stepper-footer__control">
              <button
                  class="page-btn page-btn--border"
                  @click="close"
              >
                {{ $t("backBtn") }}
              </button>
              <button
                  @click="disableTwoFa"
                  v-focus
                  class="page-btn"
                  :disabled="!repeatPassword"
              >
                {{ $t("nextBtn") }}
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoFaGoogleAuthForm from "../utility/TwoFaGoogleAuthForm";
import api from "../../api/api";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "TwoFaGoogleDisable",
  components: {
    TwoFaGoogleAuthForm
  },
  data() {
    return {
      errorRepeatPassword: '',
      passFieldType: 'password',
      repeatPassword: null,
      showPass: false,
      code: null
    }
  },
  computed: {
    ...mapGetters({
      getSettings: 'user/getSettings'
    })
  },
  methods: {
    ...mapMutations({
      setSettings: 'user/setSettings',
      setLoading: 'general/setLoading',
      setError: 'general/setError',
      setSuccess: 'general/setSuccess'
    }),
    setTwaCode(twaCode) {
      this.code = twaCode;
    },
    close() {
      this.$emit('close');
    },
    deleteError() {
      this.errorRepeatPassword = ""
    },
    toggleRepeatPasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
    async disableTwoFa() {
      this.setLoading(true);
      try {
        await api.disableTwoFa({
          password: this.repeatPassword,
          code: this.code
        })
        this.setSettings({
          mailCode: this.getSettings.mailCode,
          twoFa: false
        })
        this.setSuccess({
          message: 'Google 2fa disabled',
          type: '2FA',
          twoFaStatus: 'DISABLED'
        });
        this.close();
      } catch (e) {
        this.setError({
          message: e.response.data.message
        })
        console.log(e);
      } finally {
        this.setLoading(false);
      }
    },
  }
}
</script>

<style scoped>

</style>
