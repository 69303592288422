<template>
  <div class="popup-container" >
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup settings-popup">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="settings-popup__head">
            <div class="settings-popup__img">
              <img src="@/assets/img/settings.png" alt="">
            </div>

            <div class="settings-popup__title">
              {{$t("settingsModalTitle")}}
            </div>
          </div>

          <div class="settings-popup__wrap">
            <div class="settings-field">
              <div class="title-sm"> {{$t("settingsNameTitle")}}</div>

              <div class="d-flex">
              <div class="settings-field__field">
                {{getFullName}}
              </div>
                <button class="edit-btn" type="button" @click="isChangeName = true"></button>
              </div>
            </div>

            <div class="settings-field">
              <div class="title-sm"> {{$t("settingsEmailTitle")}}</div>

              <div class="d-flex">
                <div class="settings-field__field">
                  {{getEmail}}
                </div>
                <button class="edit-btn" type="button" @click="isChangeEmail = true"></button>
              </div>
            </div>

            <div class="settings-field">
              <div class="title-sm"> {{$t("settingsPassTitle")}}</div>

              <div class="d-flex">
                <div class="settings-field__field">
                  ********
                </div>
                <button class="edit-btn" type="button" @click="isChangePass = true"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChangePass v-if="isChangePass" @close="isChangePass = false"/>
    <ChangeEmail v-if="isChangeEmail" @close="isChangeEmail = false"/>
    <SafetyKey v-if="isSafetyKey" @close="isSafetyKey = false"/>
    <ChangeName v-if="isChangeName" @close="isChangeName = false"/>
  </div>
</template>

<script>
import ChangePass from "./ChangePass";
import ChangeEmail from "./ChangeEmail";
import SafetyKey from "./SafetyKey";
import ChangeName from "./ChangeName";
import {mapGetters} from "vuex";

export default {
  name: "AccountSettings",
  data: () => ({
    isChangePass: false,
    isChangeEmail: false,
    isSafetyKey: false,
    isChangeName: false,
  }),
  computed: {
    ...mapGetters({
      getFullName: 'user/getFullName',
      getEmail: 'user/getEmail'
    })
  },
  components: {
    ChangePass,
    ChangeEmail,
    SafetyKey,
    ChangeName
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>