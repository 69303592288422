<template>
  <div class="google-auth">
    <div class="ga-form-input">
      <input
        ref="ref1"
        class="two-fa-input form-control"
        maxlength="1"
        autofocus
        @input="input_1"
        @paste="paste"
        @keyup.delete="inp1 = ''"
        v-model="inp1"
      />
    </div>
    <div class="ga-form-input">
      <input
        ref="ref2"
        class="two-fa-input form-control"
        maxlength="1"
        @input="input_2"
        @paste="paste"
        @keyup.delete="inp2 = ''; $refs.ref1.focus()"
        v-model="inp2"
      />
    </div>
    <div class="ga-form-input">
      <input
        ref="ref3"
        class="two-fa-input form-control"
        maxlength="1"
        @input="input_3"
        @keyup.delete="inp3 = ''; $refs.ref2.focus()"
        @paste="paste"
        v-model="inp3"
      />
    </div>
    <div class="ga-form-input">
      <input
        ref="ref4"
        class="two-fa-input form-control"
        maxlength="1"
        @input="input_4"
        @paste="paste"
        @keyup.delete="inp4 = ''; $refs.ref3.focus()"
        v-model="inp4"
      />
    </div>
    <div class="ga-form-input">
      <input
        ref="ref5"
        class="two-fa-input form-control"
        maxlength="1"
        @input="input_5"
        @paste="paste"
        @keyup.delete="inp5 = ''; $refs.ref4.focus()"
        v-model="inp5"
      />
    </div>
    <div class="ga-form-input">
      <input
        ref="ref6"
        class="two-fa-input form-control"
        maxlength="1"
        @input="input_6"
        @paste="paste"
        @keyup.delete="inp6 = ''; $refs.ref5.focus()"
        v-model="inp6"
      />
    </div>

  </div>
</template>

<script>
export default {
  name: "TwoFaGoogleAuthForm",
  data: () => ({
    text: '',
    inp1: '',
    inp2: '',
    inp3: '',
    inp4: '',
    inp5: '',
    inp6: '',
    twaCode: ''
  }),
  methods: {
    sendInputsValue(){
      this.twaCode += this.inp1;this.twaCode += this.inp2;this.twaCode += this.inp3;
      this.twaCode += this.inp4;this.twaCode += this.inp5;this.twaCode += this.inp6;
      this.$emit('twaCode',this.twaCode);

      this.twaCode = "";
    },
    paste(e) {
      this.text = e.clipboardData.getData('text').replace(/[^\d;]/g, '');
      if(this.text.length === 0) {
        this.inp1 = '';
        this.inp2 = '';
        this.inp3 = '';
        this.inp4 = '';
        this.inp5 = '';
        this.inp6 = '';
      }
      else if(this.text.length === 1) {
        this.inp1 = this.text[0];
        this.inp2 = '';
        this.inp3 = '';
        this.inp4 = '';
        this.inp5 = '';
        this.inp6 = '';
      }
      else if(this.text.length === 2) {
        this.inp1 = this.text[0];
        this.inp2 = this.text[1];
        this.inp3 = '';
        this.inp4 = '';
        this.inp5 = '';
        this.inp6 = '';
      }
      else if(this.text.length === 3) {
        this.inp1 = this.text[0];
        this.inp2 = this.text[1];
        this.inp3 = this.text[2];
        this.inp4 = '';
        this.inp5 = '';
        this.inp6 = '';
      }
      else if(this.text.length === 4) {
        this.inp1 = this.text[0];
        this.inp2 = this.text[1];
        this.inp3 = this.text[2];
        this.inp4 = this.text[3];
        this.inp5 = '';
        this.inp6 = '';
      }
      else if(this.text.length === 5) {
        this.inp1 = this.text[0];
        this.inp2 = this.text[1];
        this.inp3 = this.text[2];
        this.inp4 = this.text[3];
        this.inp5 = this.text[4];
        this.inp6 = '';
      }
      else if(this.text.length === 6) {
        this.inp1 = this.text[0];
        this.inp2 = this.text[1];
        this.inp3 = this.text[2];
        this.inp4 = this.text[3];
        this.inp5 = this.text[4];
        this.inp6 = this.text[5];
      }
      else {
        this.inp1 = this.text[0];
        this.inp2 = this.text[1];
        this.inp3 = this.text[2];
        this.inp4 = this.text[3];
        this.inp5 = this.text[4];
        this.inp6 = this.text[5];
      }
      this.sendInputsValue()
      setTimeout(() => {
        this.inp1 = this.inp1[0];
        this.$refs.ref6.focus();
      }, 0)


    },
    input_1(value){
      if (value.data !== null){
        this.$refs.ref2.focus();
      }
    },
    input_2(value){
      if (value.data !== null){
        this.$refs.ref3.focus();
      } else {
        this.$refs.ref1.focus();
      }
    },
    input_3(value){
      if (value.data !== null){
        this.$refs.ref4.focus();
      } else {
        this.$refs.ref2.focus();
      }
    },
    input_4(value){
      if (value.data !== null){
        this.$refs.ref5.focus();
      } else {
        this.$refs.ref3.focus();
      }
    },
    input_5(value){
      if (value.data !== null){
        this.$refs.ref6.focus();
      } else {
        this.$refs.ref4.focus();
      }
    },
    input_6(value){
      if (value.data !== null){
        this.sendInputsValue()
      } else {
        this.$refs.ref5.focus();
      }
    },
    clearField(){
      setTimeout(()=>{
        this.twaCode= '';
        this.inp6 = '';
        this.$refs.ref5.focus()
      },200);
      setTimeout(()=>{
        this.inp5 = '';
        this.$refs.ref4.focus()
      },400);
      setTimeout(()=>{
        this.inp4 = '';
        this.$refs.ref3.focus()
      },600);
      setTimeout(()=>{
        this.inp3 = '';
        this.$refs.ref2.focus()
      },800);
      setTimeout(()=>{
        this.inp2 = '';
        this.inp1 = '';
        this.$refs.ref1.focus()
      },1000)
    }
  },
  mounted() {
    setTimeout(()=> {
      this.$refs.ref1.focus();
    },300)
  }
};
</script>
