<template>
  <div class="page404">
    <div class="container">
   <div class="page404__wrap">
      <div class="page404__title-big">
        404
      </div>
     <div class="page404__title">
       {{ $t("page404Title") }}
     </div>

     <div class="page404__text">
       {{ $t("page404Text") }}
     </div>

     <router-link to="/" class="page-btn">{{ $t("page404Btn") }}</router-link>
   </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
