<template>
  <div class="no-records">
    <div class="no-records__img">
      <img src="@/assets/img/svg/no-records.svg" alt="">
    </div>

    <div class="no-records__msg">
      {{ $t("noRecordsTitle") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NoRecordsFound"
}
</script>

<style scoped>

</style>