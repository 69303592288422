<template>
  <div class="pairs">
    <div class="box">
      <div class="box-head">
        <div class="box__title">
          {{ $t("pairsTitle") }}
        </div>

        <div class="toggle">
          <button class="toggle__btn"
                  @click="setCurrentTab('FAVOURITE')"
                  :class="{active: activeTab === 'FAVOURITE'}" v-if="getIsAuthorized">
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M6.5 10L2.97329 11.8541L3.64683 7.92705L0.793661 5.1459L4.73664 4.57295L6.5 1L8.26336 4.57295L12.2063 5.1459L9.35317 7.92705L10.0267 11.8541L6.5 10Z"
                    stroke="#FCFCFC" stroke-width="0.5"/>
            </svg>
          </button>
          <button class="toggle__btn"
                  @click="setCurrentTab('BTC')"
                  :class="{active: activeTab === 'BTC'}">
            BTC
          </button>
          <button class="toggle__btn"
                  @click="setCurrentTab('ETH')"
                  :class="{active: activeTab === 'ETH'}">
            ETH
          </button>
          <button class="toggle__btn"
                  @click="setCurrentTab('USDT')"
                  :class="{active: activeTab === 'USDT'}">
            USDT
          </button>
        </div>
      </div>

      <div class="search">
        <input type="text" class="search__field" :placeholder="$t('searchField')">
        <div class="search__icon">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.6759 4.59785e-09C6.73392 -4.25723e-05 7.7709 0.295617 8.66981 0.853613C9.56872 1.41161 10.2938 2.20973 10.7632 3.15791C11.2325 4.10609 11.4276 5.16658 11.3262 6.2197C11.2249 7.27283 10.8312 8.27667 10.1896 9.11794L13.7792 12.709C13.915 12.8452 13.9938 13.0279 13.9996 13.2201C14.0055 13.4123 13.938 13.5995 13.8108 13.7437C13.6837 13.8879 13.5064 13.9784 13.3149 13.9966C13.1235 14.0148 12.9323 13.9595 12.7802 13.8419L12.7091 13.7791L9.11794 10.1896C8.40123 10.7361 7.56468 11.104 6.67751 11.263C5.79035 11.422 4.87807 11.3674 4.01618 11.1039C3.15428 10.8403 2.36755 10.3753 1.72107 9.74726C1.07459 9.11926 0.586956 8.34635 0.298517 7.49248C0.0100785 6.6386 -0.0708726 5.72832 0.0623624 4.83695C0.195597 3.94558 0.539188 3.09876 1.0647 2.36654C1.59022 1.63432 2.28255 1.03777 3.08441 0.626243C3.88627 0.214716 4.7746 4.75688e-05 5.6759 4.59785e-09ZM5.6759 1.5136C4.57192 1.5136 3.51316 1.95214 2.73253 2.73274C1.95191 3.51335 1.51335 4.57207 1.51335 5.67601C1.51335 6.77995 1.95191 7.83868 2.73253 8.61928C3.51316 9.39988 4.57192 9.83842 5.6759 9.83842C6.77987 9.83842 7.83863 9.39988 8.61926 8.61928C9.39989 7.83868 9.83844 6.77995 9.83844 5.67601C9.83844 4.57207 9.39989 3.51335 8.61926 2.73274C7.83863 1.95214 6.77987 1.5136 5.6759 1.5136Z"
                fill="#70707C"/>
          </svg>

        </div>
      </div>

      <div class="box__header" v-if="pairs.length">

        <div class="box__header-title">

          <button class="table-filter">
            {{ $t("pair") }}
          </button>
        </div>
        <div class="box__header-title">
          <button class="table-filter">
            {{ $t("price") }}
          </button>
        </div>
        <div class="box__header-title">
          <button class="table-filter">
            {{ $t("changes") }}
          </button>
        </div>
      </div>

      <div class="pairs-table">
        <vue-scroll :ops="ops" ref="vueScroll" v-if="pairs.length">
          <div class="box-table">
            <ul>
              <li v-for="(item, index) in pairs" :key="index" @click="selectPair(item.id, item.pairName)">
                <p class="box-table__value">
                  <button class="box-table__btn"
                          @click="toggleFavorite(item.id, index, item.favourite)"
                          :class="{active: item.favourite}" v-if="getIsAuthorized">
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6.5 10L2.97329 11.8541L3.64683 7.92705L0.793661 5.1459L4.73664 4.57295L6.5 1L8.26336 4.57295L12.2063 5.1459L9.35317 7.92705L10.0267 11.8541L6.5 10Z"
                            stroke="#FCFCFC" stroke-width="0.5"/>
                    </svg>

                  </button>
                  <span>{{ item.firstCurrency }}</span>/{{ item.secondCurrency }}
                </p>
                <p class="box-table__value" :class="item.changePercent > 0 ? 'primary' : 'danger'">
                  {{ formatNumber(item.price) }}</p>
                <p class="box-table__value" :class="item.changePercent > 0 ? 'primary' : 'danger'">
                  <span v-if="item.changePercent > 0" class="primary">+</span>
                  <span v-else class="danger">-</span>
                  {{ formatNumber(item.changePercent) }}%
                </p>
              </li>
            </ul>
          </div>
        </vue-scroll>
        <NoRecordsFound v-if="!pairs.length && !loading"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "../../api/api";
import {formatNumberMixin, opsMixin} from "@/utils/mixins";
import NoRecordsFound from "@/components/utility/NoRecordsFound";

export default {
  name: "Pairs",
  components: {NoRecordsFound},
  mixins: [formatNumberMixin, opsMixin],
  data: () => ({
    pairs: [],
    usdtPrice: 0,
    loading: false,
    enableLabel: false,
    labelPositionTop: 0,
    labelPositionRight: 0,
    activeTab: 'USDT',
  }),
  computed: {
    ...mapGetters({
      getIsAuthorized: 'user/getIsAuthorized',
      getPairs: 'socket/getPairs'
    })
  },
  watch: {
    getPairs(val) {
      if (this.activeTab !== 'FAVOURITE') {
        this.pairs = val.filter(i => i.pairName.split('-')[1] === this.activeTab);
      }
    }
  },
  methods: {
    ...mapMutations({
      setPair: 'pair/setPair',
    }),
    async loadPairInfo(pair) {
      try {
        const response = await api.loadPairInfo({
          firstCurrency: pair.split('-')[0],
          quotedCurrency: pair.split('-')[1]
        });
        this.usdtPrice = Number(response.price.toFixed(2));
        return response;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    pairPercent(item) {
      if (item.changePercent === 0) {
        return '';
      }
      return item.changePercent > 0 ? 'pair-volume--green' : 'pair-volume--red'
    },
    async initLabel($event, index, firstCurrency) {
      let element = this.$refs.pairs[index];
      let liDistanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
      this.labelPositionRight = '380px'
      this.labelPositionTop = `${liDistanceToTop}px`;
      this.enableLabel = true;
      let pairs = JSON.parse(localStorage.getItem('pairs')) || [];
      if (pairs.length) {
        const pair = pairs.find(i => i.pairName === `${firstCurrency}-USDT`);
        if (pair) {
          this.usdtPrice = Number(pair.price.toFixed(2));
          return;
        }
      }
      const response = await this.loadPairInfo(`${firstCurrency}-USDT`);
      pairs.push(response);
      localStorage.setItem('pairs', JSON.stringify(pairs));
    },
    async toggleFavorite(pairId, index, isFavourite) {
      try {
        if (isFavourite) {
          await api.removePairFromFavourite({pairId})
        } else {
          await api.addPairToFavourite({pairId});
        }
        this.pairs[index].favourite = !this.pairs[index].favourite;
      } catch (e) {
        console.log(e);
      }
    },
    setCurrentTab(tab) {
      this.activeTab = tab;
      if (this.activeTab === 'FAVOURITE') {
        this.loadFavouritePairs();
      } else {
        this.loadPairs();
      }
    },
    selectPair(id, pair) {
      this.setPair({
        index: id,
        pair
      });
      this.$router.push(`/trading/${pair}`)
    },
    async loadPairs() {
      this.loading = true;
      try {
        this.pairs = await api.loadPairs({
          page: 0,
          size: 100,
          quotedCurrency: this.activeTab,
          isPublic: !this.getIsAuthorized
        });
        this.$emit('pairsLoaded', this.pairs);
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async loadFavouritePairs() {
      try {
        const response = await api.loadFavouritePairs({
          page: 0,
          size: 100
        });
        console.log(response)
        this.pairs = response.content;
      } catch (e) {
        console.log(e)
      }
    }
  },
  mounted() {
    this.loadPairs();
  }
}
</script>
