<template>
  <nav class="mobile-nav">
    <ul class="mobile-nav__list">
      <li class="mobile-nav__item" :class="{active: $route.name === 'Trading'}">
        <router-link class="mobile-nav__link" to="/trading/:pair"></router-link>
        <div class="mobile-nav__icon">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.52933 0.000183105V19.7137H6.4665V4.5837L2.0267 8.63012L0.592163 7.23408L8.52933 0.000183105ZM12.655 0.286617H14.7178V15.4167L19.1576 11.3702L20.5922 12.7663L12.655 20.0002V0.286617Z"
                  fill="#0363FE"/>
          </svg>
        </div>
        <span>{{ $t("menuTrade") }}</span>
      </li>

      <li class="mobile-nav__item" :class="{active: $route.name === 'Orders'}">
        <router-link class="mobile-nav__link" to="/orders"></router-link>
        <div class="mobile-nav__icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0817 8.88167L20 10L9.99833 15.8333L0 10L1.9175 8.88167L9.99833 13.5967L18.0817 8.88167ZM9.99833 17.7625L1.9175 13.0475L0 14.1667L9.99833 20L20 14.1667L18.0817 13.0483L9.99833 17.7625ZM9.99833 1.92917L16.6917 5.83333L9.99833 9.7375L3.3075 5.83333L9.99833 1.92917ZM9.99833 0L0 5.83333L9.99833 11.6667L20 5.83333L9.99833 0V0Z"
                fill="#0363FE"/>
          </svg>
        </div>
        <span>{{ $t("menuOrders") }}</span>
      </li>
      <li class="mobile-nav__item mobile-nav__item--drop" @click="openNavDrop = !openNavDrop"
          :class="{open : openNavDrop}">
        <div class="mobile-nav__icon">
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5188 5.38679L12.3849 2L14.3452 5.39529L6.5188 5.38679Z"
                  stroke="#0363FE" stroke-width="1.53" stroke-linecap="square"/>
            <path
                d="M1.00112 5.825C1.00112 5.35554 0.956762 5.4003 1.42621 5.4003H16.7263C17.1957 5.4003 17.1513 5.78084 17.1513 6.2503V18.1503C17.1513 18.6198 17.1957 19.0003 16.7263 19.0003H1.42621C0.956762 19.0003 1.00112 18.6198 1.00112 18.1503V5.825Z"
                stroke="#0363FE" stroke-width="1.53"/>
            <path
                d="M13.5387 14.75H16.7262V10.5H13.5387C12.4825 10.5 11.6262 11.4514 11.6262 12.625C11.6262 13.7986 12.4825 14.75 13.5387 14.75Z"
                stroke="#0363FE" stroke-width="1.53"/>
          </svg>
        </div>
        <span>{{ $t("menuWallets") }}</span>

        <div class="mobile-nav-drop" v-show="openNavDrop">
          <ul class="mobile-nav-drop__list">
            <li class="mobile-nav-drop__item">
              <router-link class="mobile-nav-drop__link" to="/balance"></router-link>
              <span>{{ $t("menuBalance") }}</span>
            </li>
            <li class="mobile-nav-drop__item">
              <router-link class="mobile-nav-drop__link" to="/deposit"></router-link>
              <span>{{ $t("menuDeposit") }}</span>
            </li>
            <li class="mobile-nav-drop__item">
              <router-link class="mobile-nav-drop__link" to="/withdraw"></router-link>
              <span>{{ $t("menuWithdraw") }}</span>
            </li>
            <li class="mobile-nav-drop__item">
              <router-link class="mobile-nav-drop__link" to="/transactions-history"></router-link>
              <span>{{ $t("menuTransactions") }}</span>
            </li>
          </ul>
        </div>
      </li>
      <li class="mobile-nav__item mobile-nav__item--new" style="pointer-events: none; opacity: 0.5;">
        <router-link class="mobile-nav__link" to="/"></router-link>
        <div class="mobile-nav__icon">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9914 14.502V13.4093C12.2229 13.1946 12.8998 12.3802 12.8998 11.4263C12.8998 10.4616 12.3855 9.87275 11.1109 9.42133C10.1988 9.07829 9.82316 8.85351 9.82316 8.49992C9.82316 8.19952 10.0479 7.89934 10.7459 7.89934C11.5169 7.89934 12.0095 8.145 12.2892 8.26415L12.599 7.05256C12.247 6.88142 11.7635 6.73172 11.0458 6.69995V5.75623H9.99484V6.77415C8.84953 6.99848 8.18422 7.73887 8.18422 8.68171C8.18422 9.72129 8.96714 10.2583 10.1152 10.6433C10.907 10.9116 11.2498 11.1689 11.2498 11.5768C11.2498 12.005 10.8326 12.2408 10.2217 12.2408C9.52529 12.2408 8.89251 12.0156 8.44296 11.7692L8.12146 13.0235C8.52737 13.2596 9.22511 13.452 9.94263 13.4839V14.5021H10.9914V14.502Z"
                fill="#0363FE"/>
            <path
                d="M10.6106 0C6.93626 0 3.56636 2.0455 1.85161 5.25995L0.505344 4.65048L0.934235 8.99916L4.48588 6.45374L3.18366 5.86385C4.65969 3.17002 7.50836 1.46112 10.6106 1.46112C13.9274 1.46112 16.9548 3.41373 18.324 6.43565L19.2622 5.7565C17.6567 2.21306 14.4994 0 10.6106 0Z"
                fill="#0363FE"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.77949 15.8562L3.15671 16.4104C2.84944 16.0651 2.5656 15.6976 2.30797 15.3104L3.00204 14.8486L3.69612 14.3869C3.91033 14.7089 4.14648 15.0146 4.40228 15.3021L3.77949 15.8562ZM2.37372 13.7426L1.62166 14.1023C1.42329 13.6875 1.25261 13.2559 1.11221 12.8098L1.90741 12.5595L2.70261 12.3093C2.81925 12.68 2.96102 13.0384 3.12578 13.383L2.37372 13.7426ZM1.61163 11.3218L0.789269 11.4585C0.751548 11.2315 0.721399 11.0016 0.699109 10.769C0.674095 10.5081 0.659433 10.2485 0.654785 9.99075L1.48829 9.97572L2.32179 9.96069C2.32566 10.1754 2.33788 10.3919 2.35878 10.61C2.3774 10.8042 2.40256 10.996 2.434 11.1852L1.61163 11.3218Z"
                  fill="#0363FE"/>
            <path
                d="M10.6759 0.754822C15.6483 0.754822 19.6792 4.86038 19.6792 9.92485C19.6792 14.9893 15.6483 19.0949 10.6759 19.0949C8.37 19.0949 6.26656 18.2119 4.67371 16.7599"
                stroke="#0363FE" stroke-width="1.41719"/>
          </svg>
        </div>
        <span>{{ $t("menuInvestor") }}</span>
      </li>
      <li class="mobile-nav__item">
        <router-link class="mobile-nav__link" to="/traders"></router-link>
        <div class="mobile-nav__icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.0003 10.1184C11.1838 10.1184 12.1431 9.15906 12.1431 7.97553C12.1431 6.79201 11.1838 5.8327 10.0003 5.8327C8.81679 5.8327 7.85754 6.79201 7.85754 7.97553C7.85754 9.15885 8.81679 10.1184 10.0003 10.1184ZM13.3335 13.2136V14.166H6.66687V13.2136C6.66687 11.8986 7.73269 10.8327 9.04771 10.8327H10.9524C12.2676 10.8325 13.3335 11.8986 13.3335 13.2136Z"
                class="fill-light" fill="#0363FE"/>
            <path
                d="M10.1017 0.0789185C6.4287 0.0789185 3.06005 2.12366 1.34594 5.33691L0.000179291 4.72766L0.428911 9.07471L3.97923 6.53024L2.67749 5.94058C4.15297 3.24776 7.00058 1.53949 10.1017 1.53949C13.4172 1.53949 16.4435 3.49137 17.8121 6.51216L19.1425 5.90965C17.5376 2.36753 13.989 0.0789185 10.1017 0.0789185Z"
                class="fill-light" fill="#0363FE"/>
            <path
                d="M10.102 18.4596C6.87298 18.4596 3.97047 16.6634 2.52738 13.7725L1.22096 14.4248C2.91286 17.8142 6.316 19.9199 10.102 19.9199C13.6745 19.9199 16.9041 18.0437 18.679 14.9843L20 15.6433L19.7339 11.283L16.091 13.6931L17.37 14.3313C15.8466 16.8927 13.1173 18.4596 10.102 18.4596Z"
                class="fill-light" fill="#0363FE"/>
          </svg>
        </div>
        <span>{{ $t("menuTraders") }}</span>
      </li>
      <li class="mobile-nav__item">
        <router-link class="mobile-nav__link" to="/deposits"></router-link>
        <div class="mobile-nav__icon">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.2026 18.7498V17.5361C15.5704 17.2977 16.3222 16.3931 16.3222 15.3336C16.3222 14.262 15.751 13.608 14.3353 13.1066C13.3223 12.7256 12.905 12.476 12.905 12.0832C12.905 11.7496 13.1546 11.4162 13.9299 11.4162C14.7863 11.4162 15.3334 11.689 15.6441 11.8213L15.9882 10.4756C15.5972 10.2855 15.0601 10.1193 14.263 10.084V9.03577H13.0957V10.1664C11.8236 10.4156 11.0846 11.2379 11.0846 12.2851C11.0846 13.4398 11.9542 14.0363 13.2294 14.4639C14.1089 14.7619 14.4896 15.0477 14.4896 15.5007C14.4896 15.9763 14.0262 16.2382 13.3477 16.2382C12.5741 16.2382 11.8713 15.9882 11.372 15.7145L11.0149 17.1076C11.4657 17.3698 12.2407 17.5836 13.0377 17.619V18.75H14.2026V18.7498Z"
                class="fill-light" fill="#0363FE"/>
            <circle cx="13.6667" cy="13.6664" r="7.50006" class="stroke-light" stroke="#0363FE" stroke-width="1.66668"/>
            <mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-3" y="-3" width="23" height="22">
              <path
                  d="M6.25953 -2.53701L19.2224 6.72228L14.1299 6.2593L10.8891 6.72228L7.46487 9.03713L6.12255 13.1743L7.46487 17.5564L6.81347 18.7831L-2.99991 13.5719L6.25953 -2.53701Z"
                  class="fill-light" fill="#0363FE"/>
            </mask>
            <g mask="url(#mask0)">
              <circle cx="9.19433" cy="10.1203" r="7.50006" transform="rotate(27.9695 9.19433 10.1203)"
                      class="stroke-light" stroke="#0363FE" stroke-width="1.66668"/>
            </g>
          </svg>
        </div>
        <span>{{ $t("menuDeposits") }}</span>
      </li>
      <li class="mobile-nav__item">
        <router-link class="mobile-nav__link" to="/news"></router-link>
        <div class="mobile-nav__icon">
          <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 4.50049H6.5V13.5005H1V4.50049Z" stroke="#0363FE" stroke-width="1.7" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M6.5 4.50049L15.5 1.00049V17.0005L6.5 13.5005" stroke="#0363FE" stroke-width="1.7"
                  stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19 5.50049H20" stroke="#0363FE" stroke-width="1.7" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M18.5 9.50049H21" stroke="#0363FE" stroke-width="1.7" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M19 13.5005H20" stroke="#0363FE" stroke-width="1.7" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </div>
        <span>{{ $t("menuNews") }}</span>
      </li>
      <li class="mobile-nav__item" style="pointer-events: none; opacity: 0.5;">
        <router-link class="mobile-nav__link" to="/"></router-link>
        <div class="mobile-nav__icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9.25" class="stroke-light" stroke="#0363FE" stroke-width="1.5"/>
            <path
                d="M15.8625 8.40055L11.6579 8.03798L10.0146 4.16669L8.37137 8.04383L4.16675 8.40055L7.35969 11.1666L6.40064 15.2777L10.0146 13.0964L13.6286 15.2777L12.6754 11.1666L15.8625 8.40055ZM10.0146 12.0028L7.81582 13.3303L8.40061 10.8274L6.45912 9.14323L9.02048 8.92101L10.0146 6.56432L11.0146 8.92686L13.576 9.14908L11.6345 10.8333L12.2193 13.3362L10.0146 12.0028Z"
                class="fill-light" fill="#0363FE"/>
          </svg>
        </div>
        <span>{{ $t("menuProgram") }}</span>
      </li>

      <li class="mobile-nav__item mobile-nav__item--drop mobile-nav__item--lang" @click="openLangDrop = !openLangDrop"
          :class="{open: openLangDrop}">
        <div class="mobile-nav__icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.81818 6.72727H18.1818M10 19C14.9705 19 19 14.9705 19 10C19 5.02955 14.9705 1 10 1C5.02955 1 1 5.02955 1 10C1 14.9705 5.02955 19 10 19ZM10 19C12.4545 19 13.2727 14.9091 13.2727 10C13.2727 5.09091 12.4545 1 10 1C7.54545 1 6.72727 5.09091 6.72727 10C6.72727 14.9091 7.54545 19 10 19ZM1.81818 13.2727H18.1818H1.81818Z"
                stroke="#0363FE" stroke-width="1.5"/>
          </svg>

        </div>
        <span>{{ $t(currentLang) }}</span>

        <div class="mobile-nav-drop" v-show="openLangDrop">
          <ul class="mobile-nav-drop__list">
            <li class="mobile-nav-drop__item" v-for="(item,index) in langList"
                :key="index"
                @click="toggleLanguage(item.name)">
              {{ $t(item.name) }}
            </li>
          </ul>
        </div>
      </li>
      <li class="mobile-nav__item ">
        <div class="mobile-nav__icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C4.486 0 0 4.486 0 10V14.143C0 15.167 0.897 16 2 16H3C3.26522 16 3.51957 15.8946 3.70711 15.7071C3.89464 15.5196 4 15.2652 4 15V9.857C4 9.59178 3.89464 9.33743 3.70711 9.14989C3.51957 8.96236 3.26522 8.857 3 8.857H2.092C2.648 4.987 5.978 2 10 2C14.022 2 17.352 4.987 17.908 8.857H17C16.7348 8.857 16.4804 8.96236 16.2929 9.14989C16.1054 9.33743 16 9.59178 16 9.857V16C16 17.103 15.103 18 14 18H12V17H8V20H14C16.206 20 18 18.206 18 16C19.103 16 20 15.167 20 14.143V10C20 4.486 15.514 0 10 0Z"
                fill="#0363FE"/>
          </svg>
        </div>
        <span>{{ $t("sidebarSupport") }}</span>

        <div class="theme-toggle">
          <div class="theme-toggle__item">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M5.99305 10C5.99305 12.761 8.23905 15.007 11 15.007C13.761 15.007 16.007 12.761 16.007 10C16.007 7.239 13.761 4.993 11 4.993C8.23905 4.993 5.99305 7.239 5.99305 10ZM11 6.993C12.658 6.993 14.007 8.342 14.007 10C14.007 11.658 12.658 13.007 11 13.007C9.34205 13.007 7.99305 11.658 7.99305 10C7.99305 8.342 9.34205 6.993 11 6.993ZM9.99805 17H11.998V20H9.99805V17ZM9.99805 0H11.998V3H9.99805V0ZM0.998047 9H3.99805V11H0.998047V9ZM17.998 9H20.998V11H17.998V9ZM3.21905 16.363L5.33905 14.241L6.75405 15.655L4.63405 17.777L3.21905 16.363ZM15.24 4.344L17.362 2.222L18.776 3.636L16.654 5.758L15.24 4.344ZM5.34205 5.759L3.22005 3.637L4.63505 2.223L6.75505 4.345L5.34205 5.759ZM18.776 16.364L17.362 17.778L15.24 15.656L16.654 14.242L18.776 16.364Z"
                  fill="#0363FE"/>
            </svg>
          </div>
        </div>
      </li>
    </ul>

    <div class="mobile-nav__btns" v-show="!getIsAuthorized">
      <router-link tag="button" to="/sign-in" class="page-btn">
        {{ $t("signInBtn") }}
      </router-link>
      <router-link tag="button" to="/sign-up" class="page-btn page-btn--border">
        {{ $t("registrationBtn") }}
      </router-link>
    </div>
  </nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'MobileMenu',
  data: () => ({
    openNavDrop: false,
    openLangDrop: false,
    currentLang: '',
    langList: [
      {
        name: 'langNameRu'
      },
      {
        name: 'langNameEng',
      }
    ]
  }),
  computed: {
    ...mapGetters({
      getIsAuthorized: 'user/getIsAuthorized'
    })
  },
  watch: {
    $route() {
      this.close();
    }
  },
  methods: {
    toggleLanguage(lang) {
      this.currentLang = lang;
    },
    initCurrentLanguage() {
      this.currentLang = this.$t("langNameRu");
    },
    close() {
      this.$emit('close')
    }
  },
  mounted() {
    this.initCurrentLanguage();
  }
}
</script>
