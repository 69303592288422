export default {
  namespaced: true,
  state: {
    timeFrame: 'FIVE_MINUTE',
    buyOrders: [],
    sellOrders: [],
    candle: null,
    statistic: null,
    lastPrice: 0,
    trade: null,
    pairs: [],
    lastTrade: null,
    notifications: [],
    notificationsCount: 0,
  },
  mutations: {
    setNotificationsCount(state, payload) {
      state.notificationsCount = payload;
    },
    closeNotification(state, payload) {
      if(payload) {
        state.notifications = state.notifications.filter(i => i.id !== payload);
      } else {
        state.notifications.shift();
      }
    },
    closeAllNotifications(state) {
      state.notifications = [];
    },
    setNotifications(state, payload) {
      state.notifications.push(payload.notifications);
      state.notificationsCount++;
    },
    readNotification(state) {
      state.notificationsCount--;
    },
    setTimeFrame(state, payload) {
      state.timeFrame = payload;
    },
    setStatistic(state, payload) {
      state.statistic = payload.statistic;
      if(!state.lastPrice) {
        state.lastPrice = payload.statistic.actualPrice;
      }
    },
    setPairs(state, payload) {
      state.pairs = payload.pairs;
    },
    unsetLastPrice(state) {
      state.lastPrice = 0
    },
    setTrades(state, payload) {
      state.trade = payload.trade;
    },
    setCandle(state, payload) {
      state.candle = payload.candle;
    },
    setOrders(state, payload) {
      state.buyOrders = [];
      state.lastTrade = {
        lastTradePrice: payload.orders.lastTradePrice,
        lastTradeSide: payload.orders.lastTradeSide
      };
      for(let [item, value] of Object.entries(payload.orders.orderBook.BUY)) {
        state.buyOrders.push({
          price: Number(item),
          amount: Number(value),
          total: Number((item * value).toFixed(8)),
          percentage: Math.random() * 100
        })
      }

      state.sellOrders = [];
      for(let [item, value] of Object.entries(payload.orders.orderBook.SELL)) {
        state.sellOrders.unshift({
          price: Number(item),
          amount: Number(value),
          total: Number((item * value).toFixed(8)),
          percentage: Math.random() * 100
        })
      }
    }
  },
  getters: {
    buyOrders: state => state.buyOrders,
    sellOrders: state => state.sellOrders,
    getCandle: state => state.candle,
    getTrade: state => state.trade,
    getStatistic: state => state.statistic,
    getLastPrice: state => state.lastPrice,
    getPairs: state => state.pairs,
    getTimeFrame: state => state.timeFrame,
    getLastTrade: state => state.lastTrade,
    getNotifications: state => state.notifications,
    getNotificationsLength: state => state.notifications.length,
    getNotificationsCount: state => state.notificationsCount,
  }
}
