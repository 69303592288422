<template>
  <div class="news-item" :class="{disabled: isOver(date)}">
   <div class="news-item__top">

     <div class="news-item__img">
       <img :src="image" alt="">
     </div>

     <div class="news-item__title">
       {{truncate(itemTitle, 50)}}
     </div>

     <div class="news-item__plash" :class="{event: isEvent(date), online: isOnline(date)}">
        <span v-show="isEvent(date)">{{$t('newsEvent')}}</span>
        <span v-show="isOnline(date)">Online</span>
     </div>
   </div>

    <div class="news-item__bottom">
      <div class="news-item__row">
        <div class="news-item__wrap">
          <div class="news-item__mark"  :class="{primary: isNearest(date), danger: !isNearest(date)}">
             <span v-show="isNearest(date)">{{$t('newsNearest')}}</span>
             <span v-show="isOver(date)">{{$t('newsOver')}}</span>
          </div>

          <div class="news-item__speakers">
            <div class="news-item__wrap">
            <img src="@/assets/img/svg/icon-speakers.svg" alt="">
              {{$t('newsSpeakers')}}
            </div>

            <p>
              {{speakers}}
            </p>

          </div>
        </div>

        <div class="item-calendar">
          <div class="item-calendar__icon">
            <img src="@/assets/img/svg/icon-calendar.svg" alt="">
            {{ eventDate(date) }}
          </div>

          <div class="item-calendar__time">
            {{eventTime(date)}} Мск
          </div>
        </div>

      </div>
      <div class="news-item__row">
       <div class="news-item__place" v-show="isEvent(date)">
         <img src="@/assets/img/svg/icon-location.svg" alt="">

         Нур-Султан, Казахстан
       </div>

        <div class="news-item__web" v-show="isOnline(date)">
          <img src="@/assets/img/svg/icon-vebinar.svg" alt="">

          <a :href="link" target="_blank" class="news-item__link">
            {{truncate(link, 40)}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {truncateMixin} from "@/utils/mixins";
import eventIdentifierMixin from "@/mixins/eventIdentifierMixin";

  export default {
    name: 'NewsEventsItem',
    mixins: [truncateMixin, eventIdentifierMixin],
    props: {
      itemTitle: {
        type: String,
        required: true
      },
      speakers: {
        type: String,
        required: true
      },
      image: {
        type: String,
      },
      link: {
        type: String,
      },
      date: {
        type: Number
      }
    }
  }
</script>
