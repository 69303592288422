<template>
  <div class="orders-sell">
    <div class="box">
      <div class="box__header">
        <p class="box__header-title">
            {{ $t("price") }} ({{getRightPairPart}})
        </p>
        <p class="box__header-title">
            {{ $t("amount") }} ({{getLeftPairPart}})
        </p>
        <p class="box__header-title">
            {{ $t("total") }}
        </p>
      </div>

      <div class="orders-table">
      <vue-scroll :ops="ops" ref="vueScroll" >
      <div class="box-table">
        <ul>
          <li v-for="(item, index) in orders"
              :key="index"
              @click="setSellValues({
                sellPrice: avgRightPair,
                sellAmount: sumLeftPair
              })"
              @mouseover="initLabel($event, index)"
              ref="orders"
          >
            <p class="box-table__value">{{formatNumber(item.price)}}</p>
            <p class="box-table__value">{{formatNumber(item.amount)}}</p>
            <p class="box-table__value">{{formatNumber(item.total)}}</p>

            <span
                :style="{ width: item.percentage + '%' }"
                class="progress-line"
            ></span>
          </li>
        </ul>
      </div>
      </vue-scroll>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import api from "../../api/api";
import {formatNumberMixin, opsMixin} from "@/utils/mixins";

export default {
  name: 'SellOrders',
  mixins: [formatNumberMixin, opsMixin],
  data: () => ({
    orders: [],
    windowWidth: window.innerWidth,
    labelPositionLeft: 0,
    labelPositionTop: 0,
    coverHeight: 0,
    showBuyMarketLabel: false,
    enableLabel: true,

    avgRightPair: 0,
    sumLeftPair: 0,
    sumRightPair: 0,
  }),
  computed: {
    ...mapGetters({
      sellOrders: 'socket/sellOrders',
      getPairName: 'pair/getPairName',
      getLastTrade: 'socket/getLastTrade',
      getPairIndex: 'pair/getPairIndex'
    }),
    getLeftPairPart() {
      return this.getPairName.split('-')[0];
    },
    getRightPairPart() {
      return this.getPairName.split('-')[1];
    }
  },
  methods: {
    ...mapMutations({
      setSellValues: 'market/setSellValues',
    }),
    async loadOrderBook() {
      try {
        const response = await api.loadOrderBook({
          page: 0,
          size: 100,
          pairName: this.getPairName
        })
        for(let [item, value] of Object.entries(response.orderBook.SELL)) {
          this.orders.unshift({
            price: Number(item),
            amount: Number(value),
            total: Number((item * value).toFixed(8)),
            percentage: Math.random() * 100
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    initLabel($event, index) {
      let element = this.$refs.orders[index];
      let liDistanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
      this.coverHeight = `${window.pageYOffset - element.getBoundingClientRect().top - 30}px`;
      this.labelPositionLeft = '320px'
      this.labelPositionTop = `${liDistanceToTop}px`;
      this.showBuyMarketLabel = true;
      let selectedOrders = this.orders.slice(-(this.orders.length - index));
      let sumRightPair = selectedOrders.reduce((prev, current) => {
        return prev + current.price;
      }, 0);
      this.avgRightPair = sumRightPair / (this.orders.length - index)
      this.sumLeftPair = selectedOrders.reduce((prev, current) => {
        return prev + current.amount;
      }, 0)
      this.sumRightPair = selectedOrders.reduce((prev, current) => {
        return prev + current.total;
      }, 0);
    },
    scrollBottom(){
      setTimeout(() => {
        if (!this.$refs.vueScroll.$el.querySelector('.__rail-is-vertical')) {
          this.scrollBottom()//still not loaded
          return
        }
        this.$refs.vueScroll.$el.querySelector('.__panel').scrollTop=2000;

      }, 500);
    },
  },
  watch: {
    sellOrders: {
      handler(val) {
        this.orders = [...val]
      },
      deep: true
    },
    windowWidth(val) {
      if(val < 1437) {
        this.enableLabel = false;
      }
    },
    getPairIndex() {
      this.loadOrderBook();
    }
  },
  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    });
    this.scrollBottom();
    await this.loadOrderBook();
  },
}
</script>
