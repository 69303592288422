<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup change-popup change-popup--email change-popup--name">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="popup__title popup__title--sm">
            {{ $t("changeNameTitle") }}
          </div>

          <div class="popup__msg popup__msg--sm">
            <span> {{ $t("changeEmailNote") }}</span> {{ $t("changeNameMsg") }}
          </div>

          <div class="popup__text">
            {{ $t("changeNameCurrent") }} <span>{{getFullName}}</span>
          </div>

          <form class="popup__form" @submit.prevent="changeName">
            <div class="page-input">
              <div class="page-input__title">
                {{ $t("changeNameNew") }}
              </div>
              <input type="text" v-model="newName"  class="input">
            </div>


            <div class="btn-wrap">
              <button class="page-btn page-btn--border page-btn--cancel" @click="close">{{$t("restorePassDisable")}}</button>
              <button class="page-btn" type="submit">
                {{$t('submitBtn')}}
              </button>
            </div>

          </form>



        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import api from "@/api/api";
import setLoadingMixin from "@/mixins/setLoadingMixin";

export default {
  name: "ChangeName",
  mixins: [setLoadingMixin],
  data: () => ({
    newName: null,
  }),
  computed: {
    ...mapGetters({
      getFullName: 'user/getFullName',
    })
  },
  methods: {
    ...mapMutations({
      setError: 'general/setError',
      setName: 'user/setName'
    }),
    isValid() {
      const words = this.newName ? this.newName.split(' ').length : 0;
      if(words !== 2) {
        this.setError({
          message: 'Your name should contain two words'
        })
        return false;
      }
      if (this.newName.length>30 ) {
        this.setError({
          message: 'Your name should have less than 20 symbols'
        })
        return false;
      }
      return true;
    },
    async changeName() {
      if(!this.isValid()) {
        return;
      }
      try {
        this.setLoading(true);
        await api.changePersonalInfo({
          name: this.newName.split(' ')[0],
          surname: this.newName.split(' ')[1],
          country: '',
          nickName: '',
          phone: ''
        })
        this.setName(this.newName);
      } catch (e) {
        console.log(e);
        this.setError({
          message: e.response.message
        })
      } finally {
        this.setLoading(false);
      }
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>