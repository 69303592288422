export default {
    methods: {
        timestampsToRange(startStamp, endStamp) {
            let start = new Date(startStamp);
            let end = new Date(endStamp);
            let diffMs = (end - start); // milliseconds between now & Christmas
            let diffDays = Math.floor(diffMs / 86400000); // days
            let diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
            let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
            if(!diffHrs) {
                return diffMins + " m";
            }
            if(!diffDays) {
                return diffHrs + " h " + diffMins + " m";
            }
            return diffDays + " d " + diffHrs + " h " + diffMins + " m";

        }
    }
}
