<template>
  <div>
    <TransactionsHistoryFilter ref="filter"
                               @selectTab="selectTab"
                               @makeInput="makeInput"
                               :startDate="startDate"
                               :endDate="endDate"
                               @setTimeRange="setTimeRange"/>
    <TransactionsHistoryDesktopTable :transactions="transactions"/>
    <TransactionsHistoryMobileTable :transactions="transactions"/>
    <NoRecordsFound v-if="!transactions.length && !getLoading"/>
  </div>
</template>

<script>
import TransactionsHistoryFilter from "../../../components/transactionsHistory/TransactionsHistoryFilter";
import TransactionsHistoryDesktopTable from "../../../components/transactionsHistory/TransactionsHistoryDesktopTable";
import TransactionsHistoryMobileTable from "../../../components/transactionsHistory/TransactionsHistoryMobileTable";
import api from "../../../api/api";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import {mapGetters, mapMutations} from "vuex";
import debounceMixin from "@/mixins/debounceMixin";
export default {
  name: 'TransactionsHistory',
  mixins: [debounceMixin],
  data: () => ({
    page: 0,
    size: 10,
    tab: "deposit-withdraw",
    transactions: [],
    total: 0,
    startDate: null,
    endDate: null,
    startDateCopy: null,
    endDateCopy: null,
    debouncedLoadTransactions: null,
  }),
  computed: {
    ...mapGetters({
      getLoading: 'general/getLoading'
    })
  },
  methods: {
    ...mapMutations({
      setLoading: 'general/setLoading'
    }),
    async setTimeRange(val) {
      this.setLoading(true);
      this.page = 0;
      this.startDate = val.start.getTime();
      this.endDate = val.end.getTime();
      await this.selectTab(this.tab);
      this.setLoading(false);
    },
    makeInput(val) {
      this.debouncedLoadTransactions();
      if(!val) {
        this.loadTransactionsByUser();
      }
    },
    async getTransactionsTimeRange() {
      try {
        const response = await api.getTransactionsTimeRange();
        this.startDate = this.startDateCopy = response.timeOfTheFirstTransaction;
        this.endDate = this.endDateCopy = response.timeOfTheLastTransaction;
      } catch (e) {
        console.log(e);
      }
    },
    selectTab(tab) {
      this.tab = tab;
      this.setLoading(true);
      switch (tab) {
        case 'deposit-withdraw': this.loadTransactionsByUser();break;
        default: this.transactions = [];
      }
      this.setLoading(false);
    },
    async loadTransactionsByUser() {
      try {
        const response = await api.loadTransactionsByUser({
          page: this.page,
          size: this.size,
          startDate: this.startDate,
          endDate: this.endDate,
          keyword: this.$refs.filter.search || null,
          sort: 'created,desc'
        });
        response.content.forEach(i => this.$set(i, 'isShow', false));
        this.transactions = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getTransactionsTimeRange();
    await this.loadTransactionsByUser();
    this.debouncedLoadTransactions = this.debounce(this.loadTransactionsByUser, 10);
    this.setLoading(false);
  },
  components: {
    NoRecordsFound,
    TransactionsHistoryMobileTable, TransactionsHistoryDesktopTable, TransactionsHistoryFilter}
}
</script>
