export const traderInfoMixin = {
    computed: {
        traderSite() {
            return this.trader.site;
        },
        traderFacebook() {
            return this.trader.facebook;
        },
        traderTwitter() {
            return this.trader.twitter;
        },
        traderTelegram() {
            return this.trader.telegram;
        },
        winningDeals() {
            return this.trader.winningDeals;
        },
        loosingDeals() {
            return this.trader.loosingDeals;
        },
        traderStatus: {
            get() {
                return this.trader.status;
            },
            set(val) {
                this.trader.status = val;
            }
        },
        averageMonthlyProfit() {
            return this.trader.averageMonthlyProfit;
        },
        bestMonthProfitPercentage() {
            return this.trader.bestMonthProfitPercentage;
        },
        companyVerified() {
            return this.trader.traderDescription.companyVerified;
        },
        passportVerified() {
            return this.trader.traderDescription.passportVerified;
        },
        isCrown() {
            return this.trader.traderDescription.crown
        },
        isTopOfTheMonth() {
            return this.trader.traderDescription.rank === 'TOP_OF_THE_MONTH'
        },
        isTopOfTheWeek() {
            return this.trader.traderDescription.rank === 'TOP_OF_THE_WEEK'
        },
        isTopOfTheYear() {
            return this.trader.traderDescription.rank === 'TOP_OF_THE_YEAR'
        },
        isNone() {
            return this.trader.traderDescription.rank === 'NONE' || !this.trader.traderDescription.rank
        },
        isNew() {
            return this.trader.traderDescription.new;
        },
        limitPercent() {
            return this.trader.bagAmount * 100 / this.trader.maxBagAmount;
        },
        isLimitOver() {
            return this.traderBagAmount === this.traderMaxBagAmount;
        },
        traderTrustScore() {
            return this.trader.trustScore;
        },
        traderTerm() {
            return this.trader.traderDescription.term;
        },
        traderInvestmentsCount() {
            return this.trader.investmentsCount;
        },
        traderTotalEarningPercentage() {
            return this.trader.totalEarningPercentage;
        },
        traderAverageMonthlyProfit() {
            return this.trader.averageMonthlyProfit;
        },
        traderAvatar() {
            return this.trader.avatar || '';
        },
        traderName() {
            return this.trader.traderDescription.name;
        },
        traderBagAmount() {
            return this.trader.bagAmount;
        },
        traderMaxBagAmount() {
            return this.trader.maxBagAmount;
        },
        traderMinInvestmentSum() {
            return this.trader.minInvestmentSum;
        },
        traderSurname() {
            return this.trader.traderDescription.surname;
        },
        traderFullName() {
            return `${this.traderName} ${this.traderSurname}`;
        },
        traderId() {
            return this.trader.id;
        },
        traderProfileLink() {
            return `trader-profile/${this.traderId}`;
        },
        traderDescription() {
            return this.trader.traderDescription.description;
        },
        traderVerified() {
            return true;
        },
        isFavorite: {
            get() {
                return this.trader.favorite;
            },
            set(val) {
                this.trader.favorite = val;
            }
        }
    }
}