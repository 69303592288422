<template>
  <div class="user-page trader-cabinet" v-if="trader">
    <h1 class="page-title">{{ $t("traderCabinetTitle") }}</h1>
    <div class="user-page__col-1">
      <UserInfoCard :trader="trader"/>

      <div class="trader-cabinet__wrap">
        <DealCorrelation :loosingDeals="loosingDeals" :winningDeals="winningDeals"/>

        <div class="trader-cabinet__mobile">
          <UserStats :traderId="traderId"/>
        </div>
      </div>

    </div>

    <div class="user-page__col-2">
      <div class="user-page__row trader-cabinet__desktop">
        <UserStats :traderId="traderId"/>
      </div>

      <div class="user-page__row">
        <div class="user-page__col-sm">
          <StrategyDescription :description="traderDescription"/>
        </div>

        <div class="user-page__col-sm">
          <PersonalManager v-if="manager && code" :manager="manager" :code="code"/>
        </div>
      </div>

      <div class="user-page__row">
        <div class="trader-cabinet__tables">

          <div class="table-tabs">
            <div class="table-tabs__left">
              <div class="table-tabs__item" @click="setTab('INCOME')" :class="{active: tab === 'INCOME'}">
                {{ $t("traderCabinetIncome") }}
              </div>
              <div class="table-tabs__item" @click="setTab('WITHDRAW')" :class="{active: tab === 'WITHDRAW'}">
                {{ $t("traderCabinetWithdraw") }}
              </div>
            </div>
            <div class="table-tabs__right">
              <div class="table-tabs__item" @click="setTab('HISTORY')" :class="{active: tab === 'HISTORY'}">
                {{ $t("traderCabinetHistory") }}
              </div>
              <div class="table-tabs__item" @click="setTab('STATS')" :class="{active: tab === 'STATS'}">
                {{ $t("traderCabinetStats") }}
              </div>
            </div>
          </div>

          <div class="trader-filter">
            <div class="trader-filter__wrap">
              <div class="popup-statistic__title">
                {{ $t("statisticModalHistory") }}

                <div class="tooltip" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                  <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                        fill="#0363FE"/>
                  </svg>

                  <div class="tooltip__content" v-show="showTooltip">
                    {{ $t("tooltipText") }}
                  </div>
                </div>
              </div>

              <div class="popup-statistic__subtitle" v-if="startDate">
                {{ $t('statisticModalSubtitle') }} {{ startDateCopy | timestampToDatePipe }} —
                {{ endDateCopy | timestampToDatePipe }}
              </div>
            </div>


            <DateRangeFilter v-if="startDate && endDate"
                             @change="setTimeRange"
                             :startDate="startDate"
                             :endDate="endDate"/>
          </div>

          <div v-show="tab === 'INCOME'">
            <IncomeHistoryDesktopTable :items="openInvestments"/>
            <IncomeHistoryMobileTable :items="openInvestments"/>
            <NoRecordsFound v-if="!openInvestments.length && !getLoading"/>
          </div>

          <div v-show="tab === 'WITHDRAW'">
            <WithdrawHistoryDesktopTable :items="closedInvestments"/>
            <WithdrawHistoryMobileTable :items="closedInvestments"/>
            <NoRecordsFound v-if="!closedInvestments.length && !getLoading"/>
          </div>

          <div v-show="tab === 'HISTORY'">
            <TradeHistoryDesktopTable :history="history"/>
            <TradeHistoryMobileTable :history="history"/>
            <NoRecordsFound v-if="!history.length && !getLoading"/>
          </div>

          <div v-show="tab === 'STATS'">
            <IncomeStatisticDesktopTable :profits="profitsHistory"/>
            <IncomeStatisticMobileTable :profits="profitsHistory"/>
            <NoRecordsFound v-if="!profitsHistory.length && !getLoading"/>
          </div>
          <Pagination :pagesQt="total" v-if="total > 1" @change="togglePage" ref="pagination"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserInfoCard from "../../components/traderCabinet/UserInfoCard";
import DateRangeFilter from "@/components/utility/DateRangeFilter"
import DealCorrelation from "../../components/traderCabinet/DealCorrelation";
import PersonalManager from "../../components/traderCabinet/PersonalManager";
import StrategyDescription from "../../components/traderCabinet/StrategyDescription";
import UserStats from "../../components/traderCabinet/UserStats";
import TradeHistoryDesktopTable from "../../components/traderCabinet/TradeHistoryDesktopTable";
import Pagination from "../../components/utility/Pagination";
import TradeHistoryMobileTable from "../../components/traderCabinet/TradeHistoryMobileTable";
import IncomeHistoryDesktopTable from "../../components/traderCabinet/IncomeHistoryDesktopTable";
import WithdrawHistoryDesktopTable from "../../components/traderCabinet/WithdrawHistoryDesktopTable";
import IncomeStatisticDesktopTable from "../../components/traderProfile/IncomeStatisticDesktopTable";
import IncomeHistoryMobileTable from "../../components/traderCabinet/IncomeHistoryMobileTable";
import WithdrawHistoryMobileTable from "../../components/traderCabinet/WithdrawHistoryMobileTable";
import IncomeStatisticMobileTable from "../../components/traderProfile/IncomeStatisticMobileTable";
import loadTraderProfitHistoryMixin from "@/mixins/loadTraderProfitHistoryMixin";
import loadTraderInfoMixin from "@/mixins/loadTraderInfoMixin";
import loadTraderProfitHistoryTimeRangeMixin from "@/mixins/loadTraderProfitHistoryTimeRangeMixin";
import setLoadingMixin from "@/mixins/setLoadingMixin";
import {timestampToDateMixin} from "@/utils/mixins";
import loadTraderMonthlyProfitHistoryMixin from "@/mixins/loadTraderMonthlyProfitHistoryMixin";
import {traderInfoMixin} from "@/mixins/traderInfoMixin";
import api from "@/api/api";
import NoRecordsFound from "@/components/utility/NoRecordsFound";
import getLoadingMixin from "@/mixins/getLoadingMixin";

export default {
  name: 'Profile',
  mixins: [loadTraderProfitHistoryMixin, loadTraderInfoMixin, loadTraderProfitHistoryTimeRangeMixin,
    setLoadingMixin, timestampToDateMixin, loadTraderMonthlyProfitHistoryMixin, traderInfoMixin, getLoadingMixin],
  data: () => ({
    tab: 'INCOME',
    showTooltip: false,
    openInvestments: [],
    closedInvestments: [],
    manager: null,
    code: null,
  }),
  computed: {
    traderDescription() {
      return this.trader.traderDescription.description;
    },
  },
  methods: {
    async initTable() {
      switch (this.tab) {
        case "HISTORY":
          this.toggleHistoryPage();
          break
        case "INCOME":
          await this.loadTraderOpenInvestments();
          break;
        case "WITHDRAW":
          await this.loadTraderClosedInvestments();
          break;
        case "STATS":
          await this.loadTraderMonthlyProfitHistory();
          break;
      }
    },
    togglePage(btn) {
      this.page = btn - 1;
      this.profitsHistoryPage = btn - 1;
      this.initTable();
    },
    async setTab(tab) {
      this.setLoading(true);
      this.tab = tab;
      this.page = 0;
      this.profitsHistoryPage = 0;
      switch (this.tab) {
        case "INCOME":
          await this.loadOpenInvestmentsTimeRange();
          break;
        case "WITHDRAW":
          await this.loadClosedInvestmentsTimeRange();
          break;
        case "HISTORY":
          await this.loadTraderProfitHistoryTimeRange();
          break;
      }
      await this.initTable();
      if (this.total > 1) {
        this.$refs.pagination.resetPagination();
      }
      this.setLoading(false);
    },
    async setTimeRange(val) {
      this.setLoading(true);
      this.page = 0;
      this.profitsHistoryPage = 0;
      this.startDate = val.start.getTime();
      this.endDate = val.end.getTime();
      await this.initTable();
      this.setLoading(false);
    },
    async loadTraderOpenInvestments() {
      try {
        const response = await api.loadTraderOpenInvestments({
          traderId: this.traderId,
          startTime: this.startDate,
          endTime: this.endDate,
          page: this.page,
          size: this.size
        });
        response.content.forEach(i => i.isShow = false);
        this.openInvestments = response.content;
        this.total = response.totalPages;
      } catch (e) {
        this.total = 0;
        console.log(e);
      }
    },
    async loadOpenInvestmentsTimeRange() {
      try {
        const response = await api.loadOpenInvestmentsTimeRange({
          traderId: this.traderId
        });
        this.startDate = this.startDateCopy = response.firstInvestmentTime;
        this.endDate = this.endDateCopy = response.lastInvestmentTime;
      } catch (e) {
        console.log(e);
      }
    },
    async loadClosedInvestmentsTimeRange() {
      try {
        const response = await api.loadClosedInvestmentsTimeRange({
          traderId: this.traderId
        });
        this.startDate = this.startDateCopy = response.periodStart;
        this.endDate = this.endDateCopy = response.periodEnd;
      } catch (e) {
        console.log(e);
      }
    },
    async loadTraderClosedInvestments() {
      try {
        const response = await api.loadTraderClosedInvestments({
          traderId: this.traderId,
          startTime: this.startDate,
          endTime: this.endDate,
          page: this.page,
          size: this.size
        });
        this.closedInvestments = response.content;
        this.total = response.totalPages;
      } catch (e) {
        this.total = 0;
        console.log(e);
      }
    },
    async loadTraderManager() {
      try {
        this.manager = await api.loadTraderManager({
          traderId: this.traderId
        })
      } catch (e) {
        console.log(e);
      }
    },
    async getTraderManagerCode() {
      try {
        this.code = await api.getTraderManagerCode({
          traderId: this.traderId
        })
      } catch (e) {
        console.log(e);
      }
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.loadTraderInfo();
    this.loadTraderManager();
    this.getTraderManagerCode();
    await this.loadOpenInvestmentsTimeRange();
    await this.loadTraderOpenInvestments();
    this.setLoading(false);
  },
  components: {
    NoRecordsFound,
    IncomeStatisticMobileTable,
    WithdrawHistoryMobileTable,
    DateRangeFilter,
    IncomeHistoryMobileTable,
    IncomeStatisticDesktopTable,
    WithdrawHistoryDesktopTable,
    IncomeHistoryDesktopTable,
    TradeHistoryMobileTable,
    Pagination,
    TradeHistoryDesktopTable,
    UserStats, StrategyDescription, PersonalManager, DealCorrelation, UserInfoCard
  },
}
</script>
