<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup twoFa-modal twoFa-modal--email">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="popup__title twoFa-modal__title">
            {{ $t("twoFaEnableEmailTitle") }}
          </div>

          <div class="popup__text">
            {{ $t("twoFaEnableEmailText") }}
          </div>


          <form class="twoFa-modal__form">
            <div class="page-input" :class="{error:errorPassword}">
              <div class="page-input__title">
                {{ $t("twoFaEnableEmailPassword") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType" v-model="password"  class="input"  @focus="deleteError()">



                <button @click="toggleRepeatPasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass}"

                ></button>
              </div>
              <div class="error__msg">
                <p>{{ errorPassword }}</p>
              </div>
            </div>

          </form>


          <div class="stepper-footer">
            <div class="stepper-footer__control">
              <button
                  class="page-btn page-btn--border"
                  @click="close"
              >
                {{$t("backBtn")}}
              </button>
              <button
                  v-focus
                  class="page-btn"
                  :disabled="password === null"
              >
                {{$t("nextBtn")}}
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoFaEmailEnable",
  data() {
    return {
      errorPassword: '',
      passFieldType: 'password',
      showPass: false,
      password: null
    }
  },
  methods: {
    deleteError() {
          this.errorPassword=""
      },
    toggleRepeatPasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>