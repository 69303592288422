<template>
  <div>
    <date-picker v-model="date"
                 :max-date="new Date()"
                 is-dark
                 :masks='{ input:["DD.MM.YYYY"] }'
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="relative flex sm:flex-row justify-start items-center calendar" v-if="type === 'DEFAULT'">
          <div class="relative flex-grow calendar-wrap">

            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-on="inputEvents">
              <path d="M10.625 2.375H13.125C13.2908 2.375 13.4497 2.44085 13.5669 2.55806C13.6842 2.67527 13.75 2.83424 13.75 3V13C13.75 13.1658 13.6842 13.3247 13.5669 13.4419C13.4497 13.5592 13.2908 13.625 13.125 13.625H1.875C1.70924 13.625 1.55027 13.5592 1.43306 13.4419C1.31585 13.3247 1.25 13.1658 1.25 13V3C1.25 2.83424 1.31585 2.67527 1.43306 2.55806C1.55027 2.44085 1.70924 2.375 1.875 2.375H4.375V1.125H5.625V2.375H9.375V1.125H10.625V2.375ZM12.5 7.375H2.5V12.375H12.5V7.375ZM9.375 3.625H5.625V4.875H4.375V3.625H2.5V6.125H12.5V3.625H10.625V4.875H9.375V3.625ZM3.75 8.625H5V9.875H3.75V8.625ZM6.875 8.625H8.125V9.875H6.875V8.625ZM10 8.625H11.25V9.875H10V8.625Z" fill="#0363FE"/>
            </svg>

          </div>
        </div>
        <div v-if="type === 'INPUT'" class="relative flex sm:flex-row justify-start items-center calendar">
          <div class="relative flex-grow calendar-wrap">
            <input  type="text" class="input" v-on="inputEvents" :value="inputValue">
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.625 2.375H13.125C13.2908 2.375 13.4497 2.44085 13.5669 2.55806C13.6842 2.67527 13.75 2.83424 13.75 3V13C13.75 13.1658 13.6842 13.3247 13.5669 13.4419C13.4497 13.5592 13.2908 13.625 13.125 13.625H1.875C1.70924 13.625 1.55027 13.5592 1.43306 13.4419C1.31585 13.3247 1.25 13.1658 1.25 13V3C1.25 2.83424 1.31585 2.67527 1.43306 2.55806C1.55027 2.44085 1.70924 2.375 1.875 2.375H4.375V1.125H5.625V2.375H9.375V1.125H10.625V2.375ZM12.5 7.375H2.5V12.375H12.5V7.375ZM9.375 3.625H5.625V4.875H4.375V3.625H2.5V6.125H12.5V3.625H10.625V4.875H9.375V3.625ZM3.75 8.625H5V9.875H3.75V8.625ZM6.875 8.625H8.125V9.875H6.875V8.625ZM10 8.625H11.25V9.875H10V8.625Z" fill="#0363FE"/>
            </svg>
          </div>
        </div>
      </template>

    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'DatePickerItem',
  props: {
    type: {
      type: String,
      required: false,
      default: () => "DEFAULT",
    }
  },
  data: () => ({
    date: null,
  }),
  watch: {
    date(val) {
      this.$emit('change', val);
    }
  },
  mounted() {
    this.date = new Date().toISOString().split('T')[0];
  },
  components: {
    DatePicker
  },

}
</script>
