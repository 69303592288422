import api from "@/api/api";

export default {
    data: () => ({
        history: [],
        page: 0,
        size: 5,
        total: 0,
    }),
    methods: {
        toggleHistoryPage(page) {
            if(page) {
                this.page = page - 1;
            }
            this.loadTraderProfitHistory();
        },
        async loadTraderProfitHistory() {
            try {
                const response = await api.loadTraderProfitHistory({
                    traderId: this.id || this.traderId,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    page: this.page,
                    size: this.size
                });
                response.content.forEach(i => i.isShow = false);
                this.history = response.content;
                this.total = response.totalPages;
            } catch (e) {
                console.log(e);
            }
        },
    }
}