<template>
  <div class="card-traders" :class="{disabled: !isSubscribe & !isInvest, dead : !isEnabled}">

    <div class="card-traders__top">
      <div class="card-traders__avatar">
        <img :src="loadAvatar(traderAvatar)" alt="">
      </div>

      <div class="card-traders__wrap">
        <router-link :to="traderProfileLink" class="card-traders__name" tag="div">

          {{ truncate(traderName)}} {{ truncate(traderSurname) }}

        </router-link>

        <div class="card-traders__status" :class="{topM: isTopOfTheMonth, new: isNew, topY: isTopOfTheYear,
        topW: isTopOfTheWeek}" v-if="!isNone || isNew">
          <span v-show="isTopOfTheMonth"> {{$t("topOfTheMonth")}}</span>
          <span v-show="isTopOfTheYear"> {{$t("topOfTheYear")}}</span>
          <span v-show="isTopOfTheWeek"> {{$t("topOfTheWeek")}}🔥</span>
          <span v-show="isNew"> {{$t("cardNew")}}</span>
        </div>
      </div>

      <div class="card-traders__elect" :class="{active: isFavorite}"
           @click="toggleFavorite">
        <span v-show="isFavorite">{{ $t("tradersCardChosen") }}</span>

        <div class="star">
          <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.5 10L2.97329 11.8541L3.64683 7.92705L0.793661 5.1459L4.73664 4.57295L6.5 1L8.26336 4.57295L12.2063 5.1459L9.35317 7.92705L10.0267 11.8541L6.5 10Z"
                  stroke="#FCFCFC" stroke-width="0.5"/>
          </svg>

        </div>
      </div>
    </div>

    <div class="card-traders__content">
      <div class="card-traders__row">
        <div class="card-traders__col card-traders__col--limit">
          <div class="title-sm" v-show="!isLimitOver">
            {{ $t("tradersCardLimitTitle") }}
          </div>
          <div class="title-sm danger" v-show="isLimitOver">
            Достигнут лимит
          </div>

          <p>${{traderBagAmount}} из {{traderMaxBagAmount}}$</p>
          <div class="status-bar" :class="{danger: isLimitOver}">
            <span :style="{width: limitPercent + '%'}"></span>
          </div>
        </div>
        <div class="card-traders__col">
          <div class="card-traders__row">
            <div class="card-traders__icon shadow">
              <img src="@/assets/img/svg/crown.svg" alt="">
            </div>
            <p>{{formatNumber(traderTrustScore, true)}} %</p>
            <div class="tooltip" @click="isTooltipVisible = true">
              <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
                    fill="#0363FE"/>
              </svg>

              <div class="tooltip__content" v-show="isTooltipVisible">
                {{ $t("tooltipText") }}
              </div>
            </div>
          </div>

          <div class="card-traders__row">
            <div class="card-traders__icon shadow">
              <img src="@/assets/img/svg/traders-check.svg" alt="">
            </div>
            <div class="select-sm">
              <div class="select-sm__current" @click="isOpenSelect = !isOpenSelect">
                <p> API Binance</p>
                <div class="select-sm__icon">
                </div>

              </div>

              <div class="select-sm__drop" v-show="isOpenSelect">
                <ul>
                  <li class="select-sm__item">
                    API Binance
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-traders__row">
        <div class="card-traders__col">
          <div class="title-sm">Срок</div>
          <p>{{traderTerm}} дня</p>
        </div>

        <div class="card-traders__col">
          <div class="title-sm">Количество инвесторов</div>
          <p>{{traderInvestmentsCount}}</p>
        </div>
      </div>
      <div class="card-traders__row">
        <div class="card-traders__col">
          <div class="title-sm">Общая прибыль</div>
          <p>{{formatNumber(traderTotalEarningPercentage, true)}} %</p>
        </div>

        <div class="card-traders__col">
          <div class="title-sm">Среднемесячная прибыль</div>
          <p>{{formatNumber(traderAverageMonthlyProfit, true)}} %</p>
        </div>
      </div>
    </div>

    <div class="card-traders__footer">
      <button class="traders-btn" @click="isInvestModalVisible = true"
              :class="{yellow : isInvest & !isSubscribe, danger : !isSubscribe & !isInvest}">
        <div class="traders-btn__top">
          <p class="traders-btn__price">{{formatNumber(traderMinInvestmentSum)}} USDT</p>

          <span v-show="isInvest & isSubscribe">Invest</span>
          <span v-show="isInvest & !isSubscribe">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M17.6471 9.44186C17.6471 7.96158 17.0521 6.54193 15.9931 5.49522C14.934 4.4485 13.4977 3.86047 12 3.86047C10.5023 3.86047 9.06596 4.4485 8.00693 5.49522C6.9479 6.54193 6.35294 7.96158 6.35294 9.44186V16.8837H17.6471V9.44186ZM19.5294 17.5042L19.9059 18C19.9583 18.0691 19.9903 18.1513 19.9981 18.2373C20.0059 18.3233 19.9894 18.4098 19.9503 18.4871C19.9112 18.5643 19.8512 18.6293 19.7768 18.6747C19.7025 18.7201 19.6168 18.7442 19.5294 18.7442H4.47059C4.38319 18.7442 4.29753 18.7201 4.22319 18.6747C4.14884 18.6293 4.08877 18.5643 4.04968 18.4871C4.0106 18.4098 3.99405 18.3233 4.0019 18.2373C4.00975 18.1513 4.04168 18.0691 4.09412 18L4.47059 17.5042V9.44186C4.47059 7.46816 5.26386 5.57529 6.6759 4.17967C8.08794 2.78405 10.0031 2 12 2C13.9969 2 15.9121 2.78405 17.3241 4.17967C18.7361 5.57529 19.5294 7.46816 19.5294 9.44186V17.5042ZM9.64706 19.6744H14.3529C14.3529 20.2912 14.105 20.8827 13.6638 21.3189C13.2225 21.755 12.624 22 12 22C11.376 22 10.7775 21.755 10.3362 21.3189C9.89496 20.8827 9.64706 20.2912 9.64706 19.6744Z"
                  fill="#FCCE5E"/>
              <path
                  d="M19.6087 2L18.127 3.45455C19.0095 4.31029 19.7102 5.3295 20.1885 6.45308C20.6667 7.57667 20.913 8.78228 20.913 10H23C23 6.99872 21.7896 4.11012 19.6087 2ZM1.00002 10H3.08697C3.08697 7.54161 4.08871 5.18566 5.87306 3.45455L4.39132 2C3.31433 3.04642 2.45992 4.29252 1.87773 5.66588C1.29555 7.03924 0.997199 8.51248 1.00002 10Z"
                  fill="#FCCE5E"/>
            </svg>
          </span>
          <span v-show="!isSubscribe & !isInvest">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M13.6471 7.44186C13.6471 5.96158 13.0521 4.54193 11.9931 3.49522C10.934 2.4485 9.49769 1.86047 8 1.86047C6.50231 1.86047 5.06596 2.4485 4.00693 3.49522C2.9479 4.54193 2.35294 5.96158 2.35294 7.44186V14.8837H13.6471V7.44186ZM15.5294 15.5042L15.9059 16C15.9583 16.0691 15.9903 16.1513 15.9981 16.2373C16.0059 16.3233 15.9894 16.4098 15.9503 16.4871C15.9112 16.5643 15.8512 16.6293 15.7768 16.6747C15.7025 16.7201 15.6168 16.7442 15.5294 16.7442H0.470588C0.383195 16.7442 0.297527 16.7201 0.223186 16.6747C0.148844 16.6293 0.0887652 16.5643 0.0496815 16.4871C0.0105978 16.4098 -0.00594673 16.3233 0.00190173 16.2373C0.00975018 16.1513 0.0416815 16.0691 0.0941178 16L0.470588 15.5042V7.44186C0.470588 5.46816 1.26386 3.57529 2.6759 2.17967C4.08794 0.784051 6.00308 0 8 0C9.99692 0 11.9121 0.784051 13.3241 2.17967C14.7361 3.57529 15.5294 5.46816 15.5294 7.44186V15.5042ZM5.64706 17.6744H10.3529C10.3529 18.2912 10.105 18.8827 9.66378 19.3189C9.22252 19.755 8.62404 20 8 20C7.37596 20 6.77748 19.755 6.33622 19.3189C5.89496 18.8827 5.64706 18.2912 5.64706 17.6744Z"
                  fill="#FD3844"/>
              </svg>
            </span>
        </div>

        <div class="traders-btn__bottom">
          <span v-show="isInvest & !isSubscribe"> Подписаны на уведомление </span>
          <span v-show="isInvest & isSubscribe"> Передать в управление </span>
          <span v-show="!isSubscribe & !isInvest">Подписка на уведомление</span>
        </div>

      </button>

      <button class="card-traders__btn"></button>
    </div>

    <transition name="fade">
      <InvestModal v-if="isInvestModalVisible"
                   :minInvestmentSum="traderMinInvestmentSum"
                   :traderId="traderId"
                   :name="traderName"
                   :surname="traderSurname"
                   :avatar="traderAvatar"
                   @close="isInvestModalVisible = false"
                  @investmentCreated="investmentCreated"/>
    </transition>

  </div>
</template>

<script>
import InvestModal from "../modals/Invest/InvestModal";
import {formatNumberMixin, loadAvatarMixin, truncateMixin} from "@/utils/mixins";
import api from "@/api/api";
import {traderInfoMixin} from "@/mixins/traderInfoMixin";


export default {
  name: 'TradersCard',
  mixins: [formatNumberMixin, truncateMixin, loadAvatarMixin, traderInfoMixin],
  props: {
    trader: {
      type: Object,
      required: true
    }
  },
  components: {
    InvestModal,
  },
  data: () => ({
    isTooltipVisible: false,
    isOpenSelect: false,
    isInvest: true,
    isSubscribe: true,
    isEnabled: true,
    isInvestModalVisible: false,
  }),
  methods: {
    investmentCreated() {
      this.$emit('investmentCreated');
    },
    async toggleFavorite() {
      try {
        if(this.isFavorite) {
          await api.deleteFromFavorite({
            traderId: this.traderId
          })
        } else {
          await api.addToFavorite({
            traderId: this.traderId
          })
        }
        this.isFavorite = !this.isFavorite;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    document.body.addEventListener('click',(e)=>{
      if (!e.target.closest('.tooltip__icon') && !e.target.closest('.tooltip__content')){
        this.isTooltipVisible = false;
      }
    })

    document.addEventListener('click', () => {
      const tooltipItem = document.body.querySelector('.tooltip');


        let blockRect = tooltipItem.getBoundingClientRect();

        let blockRightX = blockRect.x + blockRect.width;

        if (blockRect.x < 0) {
          tooltipItem.classList.remove('tooltip--side');

        } else if (blockRightX > window.outerWidth) {
          tooltipItem.classList.add('tooltip--side');

          console.log('fuck')
        }


    });
  }

}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
