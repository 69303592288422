<template>
  <div class="my-deposits">
    <div class="my-deposits__head">
      <DepositsStats :profitInfo="profitInfo" v-if="profitInfo"/>

      <div class="my-deposits__wrap">
        <h1 class="page-title">{{ $t("myDepositsTitle") }}
         </h1>

        <PrimaryCheckBox @change="toggleClosedInvestments"  :title="$t('myBalanceCheckbox')"/>
      </div>

    </div>

    <DepositsDesktopTable :deposits="deposits"
                          v-show="!getLoading"
                          @openWithdrawModal="openWithdrawModal"
                          @openInvestmentStatistic="openInvestmentStatistic"
                          @changeInvestmentAutoReinvest="changeInvestmentAutoReinvest"/>
    <DepositsMobileTable :deposits="deposits"
                         v-show="!getLoading"
                         @openWithdrawModal="openWithdrawModal"
                         @openInvestmentStatistic="openInvestmentStatistic"
                         @changeInvestmentAutoReinvest="changeInvestmentAutoReinvest"/>
<!--    <DelegateToManagement/>-->
    <StatisticModal v-if="statisticModalVisible"
                    @close="statisticModalVisible = false"
                    :id="traderId"
                    :investmentId="investmentId"/>
    <Pagination v-if="total > 1" :pagesQt="+total" @change="togglePage"/>
    <NoDepositsFound v-if="!deposits.length && !getLoading"/>
    <ReferralBanner v-if="!getLoading && deposits.length"/>
    <DelegatingBanner v-if="!getLoading && !deposits.length" />

    <transition name="fade">
      <InvestWithdraw v-if="isWithdrawModalVisible"
                      :amount="amount"
                      :investmentId="investmentId"
                      @close="isWithdrawModalVisible = false"
                      @next="isAcceptedModalVisible = true"/>
    </transition>
    <transition name="fade">
      <AppAccepted v-if="isAcceptedModalVisible" @close="isAcceptedModalVisible = false"/>
    </transition>

  </div>
</template>

<script>
import DepositsStats from "../../components/deposits/DepositsStats";
import PrimaryCheckBox from "../../components/utility/PrimaryCheckBox";
import DepositsDesktopTable from "../../components/deposits/DepositsDesktopTable";
import DepositsMobileTable from "../../components/deposits/DepositsMobileTable";
import ReferralBanner from "../../components/utility/ReferralBanner";
import NoDepositsFound from "../../components/deposits/NoDepositsFound";
import DelegatingBanner from "../../components/utility/DelegatingBanner";
import api from "@/api/api";
import InvestWithdraw from "@/components/modals/MyDeposits/InvestWithdraw";
import AppAccepted from "@/components/modals/MyDeposits/AppAccepted";
import {mapGetters, mapMutations} from "vuex";
import Pagination from "@/components/utility/Pagination";
import StatisticModal from "@/components/modals/MyDeposits/StatisticModal";

export default {
    name: 'Deposits',
    data: () => ({
      depositsLength: 0,
      deposits: [],
      page: 0,
      size: 5,
      total: 0,
      showClosed: false,
      amount: null,
      investmentId: null,
      traderId: null,
      isWithdrawModalVisible: false,
      isAcceptedModalVisible: false,
      statisticModalVisible: false,
      profitInfo: null,
    }),
    computed: {
      ...mapGetters({
        getLoading: 'general/getLoading'
      })
    },
    methods: {
      ...mapMutations({
        setLoading: 'general/setLoading'
      }),
      async loadUserInvestorProfitInfo() {
        try {
          this.profitInfo = await api.loadUserInvestorProfitInfo();
        } catch (e) {
          console.log(e);
        }
      },
      toggleClosedInvestments(val) {
        this.showClosed = val;
        this.loadInvestmentsForUser();
      },
      openInvestmentStatistic(payload) {
        this.investmentId = payload.investmentId;
        this.traderId = payload.traderId
        this.statisticModalVisible = true;
      },
      togglePage(page) {
        this.page = page - 1;
        this.loadInvestmentsForUser();
      },
      async changeInvestmentAutoReinvest(payload) {
        try {
          await api.changeInvestmentAutoReinvest(payload);
        } catch (e) {
          console.log(e);
        }
      },
      openWithdrawModal(payload) {
        this.isWithdrawModalVisible = true;
        this.amount = payload.amount;
        this.investmentId = payload.investmentId;
      },
      async loadInvestmentsForUser() {
        try {
          this.setLoading(true);
          const response = await api.loadInvestmentsForUser({
            page: this.page,
            size: this.size,
            showClosed: this.showClosed
          });
          response.content.forEach(i => i.isShow = false);
          this.deposits = response.content;
          this.total = response.totalPages;
        } catch (e) {
          console.log(e);
        } finally {
          this.setLoading(false);
        }
      }
    },
    mounted() {
      this.loadUserInvestorProfitInfo();
      this.loadInvestmentsForUser();
    },

    components: {
      StatisticModal,
      Pagination,
      InvestWithdraw,
      AppAccepted,
      ReferralBanner,
      DepositsMobileTable, DepositsDesktopTable, PrimaryCheckBox, DepositsStats, NoDepositsFound,
      DelegatingBanner},

  }
</script>
