<template>
<div class="tutorial-step tutorial-step--one">
  <div class="tutorial-step__title">
    {{$t('tutorialOneTitle')}}
  </div>

  <div class="tutorial-step__text">
    {{$t('tutorialOneText')}}
  </div>


  <div class="profit-table">
    <table>
      <thead>
      <tr>
        <td>
          {{$t("profitTableTitle1")}}
        </td>
        <td>
          {{$t("profitTableTitle2")}}
          <p class="profit-table__separator">
            <span>{{$t("investor")}}</span><span style="color: #FCFCFC"> /</span>  {{$t("trader")}}</p>
        </td>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(item,index) in profits" :key="index">
        <td>{{item.minInvestPrice}}</td>
        <td>
          <p class="profit-table__separator">
            <span>60%</span> <span style="color: #FCFCFC"> /</span> 40%
          </p>
        </td>
      </tr>

      </tbody>
    </table>
  </div>

  <div class="tutorial-step__footer">
    <button class="page-btn page-btn--border page-btn--cancel" :class="{active: isSixMonth}">6 {{$t("month")}}</button>
    <button class="page-btn page-btn--border page-btn--cancel " :class="{active: !isSixMonth}">12 {{$t("month")}}</button>
  </div>
</div>
</template>

<script>
export default {
  name: "TutorialStep1",
  data() {
    return {
      profits: [
        {
          minInvestPrice: '500 USDT'
        },
        {
          minInvestPrice: '1500 USDT'
        },
        {
          minInvestPrice: '5000 USDT'
        },
        {
          minInvestPrice: '1000 USDT'
        },
        {
          minInvestPrice: '25000 USDT'
        },
        {
          minInvestPrice: '50000 USDT'
        },
        {
          minInvestPrice: '250000 USDT'
        },
      ],
      isSixMonth: true,
    }
  }
}
</script>

<style scoped>

</style>