<template>
<div class="box about-box">
  <div class="about-box__head">
 <div class="about-box__title">
   {{$t('userAccAboutTitle')}}
 </div>
  </div>

  <div class="about-box__content about-box__info">
    <div class="about-box__wrap">
      <div class="about-box__title-sm">
        {{$t('userAccAboutUid')}}
      </div>
      <div class="about-box__value">
        1323456472
      </div>
    </div>
    <div class="about-box__wrap">
      <div class="about-box__title-sm">
        {{$t('userAccAboutDate')}}
      </div>
      <div class="about-box__value">
        26/11/2019
      </div>
    </div>
    <div class="about-box__wrap">
      <div class="about-box__title-sm">
        {{$t('userAccAboutInvite')}}
      </div>
      <div class="about-box__value">
        <a href="#"> s*j*gq*o@gmail.com </a>
      </div>
    </div>
    <div class="about-box__wrap">
      <div class="about-box__title-sm">
        {{$t('userAccAboutCommission')}}
      </div>
      <div class="about-box__value">
        0.1%
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "UserAbout"
}
</script>

<style scoped>

</style>