<template>
  <div class="tabs">
   <button @click=" clicked(index) "
           class="tabs__item"
           v-for="(item, index) in tabs"
           :key="index"
           :class="{active: isCurrentTab === index}"
   >
     {{item.name}}
   </button>
  </div>
</template>

<script>
  export default {
    name: 'Tabs',
    props: {
      tabs: {
        type: Array,
        required: true,
      }
    },
    data: () => ({
      isCurrentTab: 0,
    }),
    methods: {
     clicked(i) {
       this.isCurrentTab = i;
       this.$emit('clicked', this.isCurrentTab)
     }
    }
  }
</script>
