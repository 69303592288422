<template>
  <div class="popup-bottom" :class="{active: isShow}">

    <div class="popup-bottom__head">
      <div class="popup-bottom__title">
        {{$t("pairsTitle")}}
      </div>

      <div class="search">
        <input type="text" class="search__field" :placeholder="$t('searchField')">

        <div class="search__icon">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.6759 4.59785e-09C6.73392 -4.25723e-05 7.7709 0.295617 8.66981 0.853613C9.56872 1.41161 10.2938 2.20973 10.7632 3.15791C11.2325 4.10609 11.4276 5.16658 11.3262 6.2197C11.2249 7.27283 10.8312 8.27667 10.1896 9.11794L13.7792 12.709C13.915 12.8452 13.9938 13.0279 13.9996 13.2201C14.0055 13.4123 13.938 13.5995 13.8108 13.7437C13.6837 13.8879 13.5064 13.9784 13.3149 13.9966C13.1235 14.0148 12.9323 13.9595 12.7802 13.8419L12.7091 13.7791L9.11794 10.1896C8.40123 10.7361 7.56468 11.104 6.67751 11.263C5.79035 11.422 4.87807 11.3674 4.01618 11.1039C3.15428 10.8403 2.36755 10.3753 1.72107 9.74726C1.07459 9.11926 0.586956 8.34635 0.298517 7.49248C0.0100785 6.6386 -0.0708726 5.72832 0.0623624 4.83695C0.195597 3.94558 0.539188 3.09876 1.0647 2.36654C1.59022 1.63432 2.28255 1.03777 3.08441 0.626243C3.88627 0.214716 4.7746 4.75688e-05 5.6759 4.59785e-09ZM5.6759 1.5136C4.57192 1.5136 3.51316 1.95214 2.73253 2.73274C1.95191 3.51335 1.51335 4.57207 1.51335 5.67601C1.51335 6.77995 1.95191 7.83868 2.73253 8.61928C3.51316 9.39988 4.57192 9.83842 5.6759 9.83842C6.77987 9.83842 7.83863 9.39988 8.61926 8.61928C9.39989 7.83868 9.83844 6.77995 9.83844 5.67601C9.83844 4.57207 9.39989 3.51335 8.61926 2.73274C7.83863 1.95214 6.77987 1.5136 5.6759 1.5136Z" fill="#70707C"/>
          </svg>

        </div>
      </div>

      <div class="popup__close" @click="close"></div>
    </div>

    <div class="popup-bottom__content">
      <Pairs/>
    </div>
  </div>
</template>

<script>
import Pairs from "../Pairs";

export default {
  name: "PairsPopup",
  components: {
    Pairs
  },
  props: {
    isShow: {
      default: true
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>