<template>
<div>

  <div class="popup__head">
    <div class="popup__title popup__title--sm">
      {{$t('investTitle')}}
    </div>
  </div>

  <div class="invest">
    <div class="invest-col invest-col-1">
      <div class="invest-user">
        <div class="invest-user__ava">
          <img :src="loadAvatar(avatar)" alt="">

          <div class="invest-user__icon">
            <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 3.43596L3.85148 6L9 1" stroke="#F2F2F2" stroke-width="2"/>
            </svg>

          </div>
        </div>

        <div class="invest-user__info">
          <div class="invest-user__name">
            {{truncate(name)}} {{truncate(surname)}}
          </div>

<!--          <div class="invest-user__id">-->
<!--            ID 5915-->
<!--          </div>-->
        </div>
      </div>

      <div class="deposit-info">
        <div class="invest__title">
          {{$t("investDepositTitle")}}
        </div>

        <div class="d-flex">
          <div class="toggle">
            <button class="toggle__btn"
                    :class="{active: duration === 'SIX_MONTH'}"
                    @click="setDuration('SIX_MONTH')">6 {{$t("toggleTabMonth")}}</button>
            <button class="toggle__btn"
                    :class="{active: duration === 'TWELVE_MONTH'}"
                    @click="setDuration('TWELVE_MONTH')">12 {{$t("toggleTabMonth")}}</button>
          </div>

          <div class="page-input">
            <div class="page-input__wrap">
              <input type="number" class="input" v-model="amount" :placeholder="$t('transactionsTableSum')">

              <div class="page-input__icon">
                USDT
              </div>
            </div>
          </div>
        </div>

        <div class="fill-frame">

          <div class="fill-frame__item">
            <span>{{investorPercent}}%</span>
            {{$t("investor")}}
          </div>

          <div class="fill-frame__item">
            <span>{{100 - investorPercent}}%</span>
            {{$t("trader")}}
          </div>
          <span class="fill-frame__color" :style="`width: ${investorPercent}%`"></span>
        </div>

        <div class="tooltip">
          <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
          </svg>


          <div class="tooltip__descr">
            {{ $t("tradersTopToolText1")}}
            <span> {{ $t("tradersTopToolText2")}}</span>
            <a href="#" class="link"> {{ $t("tradersTopToolLink")}}</a>
          </div>
        </div>
      </div>

      <div class="invest__wrap">
        <div class="invest__title">
          {{$t("investProfitTitle")}}
        </div>

        <div class="row">
          <div class="col-sm">
            <div class="title-xs">
              {{$t("investTitleXs1")}}
            </div>
            <p>14%</p>
          </div>

          <div class="col-sm">
            <div class="title-xs">
              {{$t("investTitleXs2")}}
            </div>
            <p>1400 USDT / месяц</p>
          </div>
        </div>
      </div>

      <div class="invest__bottom-wrap">
        {{$t("investWarning1")}}
        <span>{{$t("investWarningSpan1")}}</span>
        {{$t("investWarning2")}}
        <span>{{$t("investWarningSpan2")}}</span>
        {{$t("investWarning3")}}
      </div>
    </div>

    <div class="invest-col invest-col-2">
      <div class="invest__title">
        {{$t("investConditionsTitle")}}
      </div>

      <ul class="custom-list">
        <li class="custom-list__item">
          <p class="custom-list__text">
            {{$t("investListItem1Part1")}}
            <span>  {{$t("investListItem1Span")}}</span>
            {{$t("investListItem1Part2")}}
          </p>
        </li>
        <li class="custom-list__item">
          <p class="custom-list__text">
            {{$t("investListItem2Part1")}}
            <span class="blue">({{amount}} USDT) </span>
            {{$t("investListItem2Part2")}} <span>{{ calcUnlockDate }}</span>
          </p>
        </li>
        <li class="custom-list__item">
          <p class="custom-list__text">
            {{$t("investListItem3Part1")}}
            <span>{{$t("investListItem3Span")}}</span>
            {{$t("investListItem3Part2")}}
          </p>
        </li>
        <li class="custom-list__item">
          <p class="custom-list__text">
            {{$t("investListItem4Part1")}}
            <span> {{$t("investListItem4Span")}}</span>
            {{$t("investListItem4Part2")}}
            <span>  15% </span> </p>
        </li>
      </ul>

      <div class="checkbox-wrap">
        <div class="default-checkbox">
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="check1"/>
              <span class="checkbox__content">
               <span class="checkbox__box"></span>
               <span class="checkbox__text">{{ $t("investCheckbox1") }}</span>
             </span>
            </label>
          </div>
        </div>
        <div class="default-checkbox">
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="check2"/>
              <span class="checkbox__content">
               <span class="checkbox__box"></span>
               <span class="checkbox__text">{{ $t("investCheckbox2") }}</span>
             </span>
            </label>
          </div>
        </div>
      </div>

      <button :disabled="nextDisabled" class="page-btn" type="button" @click="next"> {{$t("investBtn")}} {{amount}} USDT</button>
    </div>
  </div>
</div>
</template>

<script>

import {loadAvatarMixin, truncateMixin} from "@/utils/mixins";
import api from "@/api/api";
import debounceMixin from "@/mixins/debounceMixin";

export default {
  name: "InvestTrader",
  mixins: [truncateMixin, loadAvatarMixin, debounceMixin],
  props: {
    minInvestmentSum: {
      type: Number,
      required: true
    },
    traderId: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    surname: {
      type: String,
      required: true
    },
    avatar: {
      type: String,
      required: true
    }
  },
  data: () => ({
    debouncedLoadPercent: null,
    investorPercent: 0,
    amount: 0,
    autoReinvest: false,
    duration: 'SIX_MONTH',
    check1: false,
    check2: false,
  }),
  computed: {
    calcUnlockDate() {
      let date = new Date(Date.now());
      let endDate = null;
      switch (this.duration) {
        case "SIX_MONTH": endDate = new Date(date.setMonth(date.getMonth() + 6));break;
        case "TWELVE_MONTH": endDate = new Date(date.setMonth(date.getMonth() + 12));break;
        default: endDate = date;
      }
      return endDate.toLocaleDateString('en-GB');
    },
    nextDisabled() {
      return !this.amount || this.amount <= 0 || !this.check1 || !this.check2
    }
  },
  watch: {
    amount(val) {
      this.debouncedLoadPercent();
      if(!val) {
        this.loadInvestorPercent();
      }
    },
  },
  methods: {
    async loadInvestorPercent() {
      try {
        this.investorPercent = await api.loadInvestorPercent({
          investmentBody: this.amount,
          traderId: this.traderId
        })
      } catch (e) {
        console.log(e);
      }
    },
    setDuration(duration) {
      this.duration = duration;
    },
    next() {
      this.$emit('next')
    }
  },
  mounted() {
    this.amount = this.minInvestmentSum;
    this.debouncedLoadPercent = this.debounce(this.loadInvestorPercent, 500);
  }
}
</script>