<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup change-popup change-popup--safety">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="change-popup__img">
            <img src="@/assets/img/2fa-enabled.png" alt="">
          </div>

          <div class="popup__title popup__title--sm">
            {{ $t("safetyKeyTitle") }}
          </div>

          <div class="popup__text">
            {{ $t("safetyKeyText") }}
          </div>

          <div class="popup__msg">{{userEmail}}</div>


          <form class="popup__form">
            <div class="page-input">
              <div class="page-input__title">
                {{ $t("safetyKeyInput") }}
              </div>
              <TwoFaGoogleAuthForm/>
            </div>

              <div class="link">
                {{ $t("safetyKeySent") }}
              </div>

            <button class="page-btn" type="submit">
              {{$t('submitBtn')}}
            </button>
          </form>



        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TwoFaGoogleAuthForm from "../utility/TwoFaGoogleAuthForm";

export default {
  name: "SafetyKey",
  data() {
    return {
      userEmail: 'bill.sanders@example.com'
    }
  },
  components: {
    TwoFaGoogleAuthForm
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>