<template>
  <div class="twoFa-modal__wrap twoFa-modal__step3">
    <div class="twoFa-modal__title">
      {{$t("googleStepOneTitle")}}
    </div>

    <div class="popup__text">
      {{$t("googleStepTreeText")}}

    </div>

   <div class="twoFa-modal__img">
     <img :src="qrCodeLink" alt="">
   </div>

    <div class="popup__text twoFa-modal__text">
      {{$t("googleStepTreeQrText")}}
      <p class="code">{{codeSecret}}</p>
    </div>

  </div>
</template>

<script>
import api from "../../../api/api";
import {mapMutations} from "vuex";

export default {
  name: "StepThree",
  data: () => ({
    qrCodeLink: null,
    codeSecret: null
  }),
  methods: {
    ...mapMutations({
      setLoading: 'general/setLoading'
    }),
    async loadTwoFaSecret() {
      try {
        this.codeSecret = await api.loadTwoFaSecret();
      } catch (e) {
        console.log(e)
      }
    },
    async loadTwoFaQrLink() {
      try {
        this.qrCodeLink = await api.loadTwoFaQrLink();
      } catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    this.setLoading(true);
    await this.loadTwoFaQrLink();
    await this.loadTwoFaSecret();
    this.setLoading(false);
  }
}
</script>

<style scoped>

</style>
