<template>
  <div class="custom-table custom-table--stats">

    <table cellpadding="0" cellspacing="0">
      <thead class="custom-table-head">
      <tr>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableSize") }}
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableSymbol") }}
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableOpen") }}
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableClose") }}
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              PnL, $
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              PnL, %
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableDuration") }}
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableOpenTime") }}
            </div>
          </div>
        </td>
        <td>
          <div class="custom-table-head__title">
            <div class="custom-table-head__title">
              {{ $t("statsTableCurrent") }}
            </div>
          </div>
        </td>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(item, index) in history" :key="index">
        <td>
          <p>{{formatNumber(item.amount)}}</p>
        </td>
        <td>
          <p>{{item.firstCurrency}} / {{item.secondCurrency}}</p>
        </td>

        <td>
          <p>{{formatNumber(item.openingPrice)}}</p>
        </td>
        <td>
          <p>{{formatNumber(item.closingPrice)}}</p>
        </td>
        <td>
          <p class="danger">${{item.price}}</p>
        </td>
        <td>
          <p class="primary">{{item.profitPercentage}}%</p>
        </td>
        <td>
          <p>{{timestampsToRange(item.openingTime, item.closingTime)}}</p>
        </td>
        <td>
          <p>{{item.openingTime | timestampToDateTimePipe}}</p>
        </td>
        <td>
          <p>{{item.closingTime | timestampToDateTimePipe}}</p>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import {formatNumberMixin, timestampToDateTimeMixin} from "@/utils/mixins";
import timestampsToRangeMixin from "@/mixins/timestampsToRangeMixin";

  export default {
    name: 'TradeHistoryDesktopTable',
    mixins: [formatNumberMixin, timestampToDateTimeMixin, timestampsToRangeMixin],
    props: {
      history: {
        type: Array,
        required: true
      }
    }
  }
</script>
