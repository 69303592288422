<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup photo-modal selfie-modal">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="photo-modal__title">
            {{ $t("selfieModalTitle") }}
          </div>


          <ul class="modal-list">
            <li class="modal-list__item">
              {{ $t("selfieListText1") }}
            </li>

            <li class="modal-list__item">
              {{ $t("selfieListText2") }}
            </li>

            <li class="modal-list__item">
              {{ $t("selfieListText3") }}
            </li>

            <li class="modal-list__item">
              {{ $t("selfieListText4") }}
            </li>

          </ul>

          <div class="selfie-modal__img">
            <img src="@/assets/img/document-selfie.png" alt="">
          </div>

          <button class="page-btn" @click="close">
            {{ $t("photoModalBtn") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelfieModal",
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>