<template>
  <div class="trader-strategy">
    <div class="box trader-box">

      <div class="trader-box__title">
        {{ $t("traderStrategyTitle") }}
      </div>

      <div class="trader-strategy__text" v-show="$route.name !== 'Profile'">
        <div v-show="!strText">
          {{description}}
        </div>

        <div v-show="strText">
          <p>
            {{ strText }}
          </p>
        </div>
      </div>


      <div class="trader-strategy__wrap" v-show="$route.name === 'Profile'">
        <div class="default-textarea">
          <textarea cols="30" rows="10" class="textarea" autofocus :value="description"></textarea>
        </div>

        <div class="trader-strategy__bottom">
          <div class="trader-strategy__notice">
            {{ $t("traderStrategyNotice") }}
          </div>

          <button class="page-btn page-btn--border" @click="saveText" v-show="!isPublished">
            {{ $t("traderStrategyBtn") }}
          </button>
          <button class="page-btn page-btn--border" @click="saveText" v-show="isPublished">
            {{ $t("traderStrategyPub") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StrategyDescription',
  props: {
    description: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    isText: false,
    inputText: '',
    strText: '',
    isPublished: false
  }),
  methods: {
    saveText() {
      this.strText = this.inputText;
      this.isText = true
    }
  }
}
</script>
