<template>
  <div class="coin-balance">
    <div class="coin-balance__wrap">
      <div class="title-sm">
        {{ $t("coinBalanceTotal")}}
      </div>

      <div class="coin-balance__value">
        {{wallet.currency === 'USDT'
          ? Number(wallet.totalBalance.toFixed(2).replace(/.$/,''))
          : Number(wallet.totalBalance.toFixed(8).replace(/.$/,''))}}
        {{wallet.currency}}
      </div>
    </div>

    <div class="coin-balance__wrap">
      <div class="title-sm">
        {{ $t("coinBalanceLocked")}}

        <div class="tooltip"  @mouseover="showTooltip = true"  @mouseleave="showTooltip = false">
          <svg class="tooltip__icon" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 12.75C3.04813 12.75 0.25 9.95187 0.25 6.5C0.25 3.04813 3.04813 0.25 6.5 0.25C9.95187 0.25 12.75 3.04813 12.75 6.5C12.75 9.95187 9.95187 12.75 6.5 12.75ZM6.5 11.5C7.82608 11.5 9.09785 10.9732 10.0355 10.0355C10.9732 9.09785 11.5 7.82608 11.5 6.5C11.5 5.17392 10.9732 3.90215 10.0355 2.96447C9.09785 2.02678 7.82608 1.5 6.5 1.5C5.17392 1.5 3.90215 2.02678 2.96447 2.96447C2.02678 3.90215 1.5 5.17392 1.5 6.5C1.5 7.82608 2.02678 9.09785 2.96447 10.0355C3.90215 10.9732 5.17392 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z" fill="#0363FE"/>
          </svg>

          <div class="tooltip__content" v-show="showTooltip">
            {{ $t("tooltipWithdrawText")}}
          </div>
        </div>
      </div>

      <div class="coin-balance__value">
        {{ wallet.currency === 'USDT'
          ? Number(wallet.lockedBalance.toFixed(2).replace(/.$/,''))
          : Number(wallet.lockedBalance.toFixed(8).replace(/.$/,''))}} {{wallet.currency}}
      </div>
    </div>

    <div class="coin-balance__wrap">
      <div class="title-sm">
        {{ $t("coinBalanceAvailable")}}
      </div>

      <div class="coin-balance__value">
        {{ wallet.currency === 'USDT'
          ? Number(wallet.availableBalance.toFixed(2).replace(/.$/,''))
          : Number(wallet.availableBalance.toFixed(8).replace(/.$/,''))}} {{wallet.currency}}
      </div>
    </div>

    <div class="coin-balance__wrap" v-if="isWithdraw">
      <div class="title-sm">
        {{ $t("coinBalanceTx")}}
      </div>

      <div class="coin-balance__value">
        {{ wallet.currency === 'USDT'
          ? Number(fee.toFixed(2).replace(/.$/,''))
          : Number(fee.toFixed(8).replace(/.$/,''))}}
        {{wallet.currency}}
      </div>
    </div>

    <div class="coin-balance__wrap" v-if="isWithdraw">
      <div class="title-sm">
        {{ $t("coinBalanceGet")}}
      </div>

      <div class="coin-balance__value coin-balance__value--big">
        {{ wallet.currency === 'USDT'
          ? Number(willGet.toFixed(2).replace(/.$/,''))
          : Number(willGet.toFixed(8).replace(/.$/,''))}}
        {{wallet.currency}}
      </div>
    </div>


    <div class="coin-balance__text" v-show="!isWithdraw">
      <div class="coin-balance__title">

        {{ $t("coinBalanceTextTitleLeft") }} {{wallet.currency}} {{$t("coinBalanceTextTitleRight")}}
      </div>
      <div class="coin-balance__msg">

        {{ $t("coinBalanceTextLeft")}} {{wallet.currency}}{{$t("coinBalanceTextRight")}}
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CoinBalance",
  props: {
    isWithdraw: {
      default: false
    },
    wallet: {
      type: Object,
      required: true
    },
    fee: {
      type: Number,
      required: false
    },
    willGet: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    showTooltip: false
  })
}
</script>

<style scoped>

</style>
