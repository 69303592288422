import { render, staticRenderFns } from "./DelegatingBanner.vue?vue&type=template&id=abf11334&scoped=true&"
import script from "./DelegatingBanner.vue?vue&type=script&lang=js&"
export * from "./DelegatingBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abf11334",
  null
  
)

export default component.exports