<template>
  <div class="personal-info">
    <div class="box about-box personal-box" v-if="getKyc && isVerified">
      <div class="about-box__head">
        <div class="about-box__title">
          <span class="desktop"> {{ $t('userAccPersonalTitle') }}</span>
          <span class="mobile"> {{ $t('userAccPersonalTitleXs') }}</span>
        </div>

        <div class="about-box__check">
          <p> {{ $t('userAccPersonalChecked') }} </p>
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.5" width="12" height="12" rx="6" fill="#0363FE"/>
            <path
                d="M5.06695 9.50985L2.80029 7.24319L3.59929 6.4442L5.06695 7.90619L8.80126 4.17188L9.60026 4.97654L5.06695 9.50985Z"
                fill="#FCFCFC"/>
          </svg>

        </div>
      </div>

      <div class="about-box__content">
        <div class="about-box__wrap">
          <div class="about-box__title-sm">
            {{ $t('userAccPersonalName') }}
          </div>
          <div class="about-box__value">
            {{getKyc.firstName}}
          </div>
        </div>
        <div class="about-box__wrap">
          <div class="about-box__title-sm">
            {{ $t('userAccPersonalSurname') }}
          </div>
          <div class="about-box__value">
            {{getKyc.lastName}}
          </div>
        </div>
        <div class="about-box__wrap">
          <div class="about-box__title-sm">
            {{ $t('userAccPersonalDate') }}
          </div>
          <div class="about-box__value">
            {{getKyc.birthDate | timestampToDatePipe}}
          </div>
        </div>
        <div class="about-box__wrap">
          <div class="about-box__title-sm">
            {{ $t('userAccPersonalAddress') }}
          </div>
          <div class="about-box__value">
            {{getKyc.country}}, {{getKyc.city}}, {{getKyc.address}}
          </div>
        </div>
      </div>
    </div>

    <div class="box about-box personal-box--notVerified" v-if="!isVerified">

      <div class="table-sm-top">
        <div class="table-sm-top__title">
          {{ $t("userNotVerifiedShort") }}
        </div>
        <div class="table-sm-top__title">
          {{ $t("userVerifiedShort") }}
        </div>
      </div>
      <div class="table-sm">
        <table cellpadding="0" cellspacing="0">
          <thead class="custom-table-head">
          <tr>
            <td>
            </td>
            <td>
              {{ $t("userNotVerified") }}
            </td>
            <td>
              {{ $t("userVerified") }}
            </td>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td>
              <p class="table-sm__title">
                {{ $t("tableSmDepositTitle1") }}
              </p>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-ok.svg" alt="">
              </div>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-ok.svg" alt="">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <p class="table-sm__title">
                {{ $t("tableSmDepositTitle2") }}
              </p>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-ok.svg" alt="">
              </div>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-ok.svg" alt="">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <p class="table-sm__title">
                <span class="desktop">{{ $t("tableSmDepositTitle3") }}</span>
                <span class="mobile">{{ $t("tableSmDepositTitle3Mob") }}</span>
              </p>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-no.svg" alt="">
              </div>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-ok.svg" alt="">
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <p class="table-sm__title">
                <span class="desktop">{{ $t("tableSmDepositTitle4") }}</span>
                <span class="mobile">{{ $t("tableSmDepositTitle4Mob") }}</span>
              </p>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-no.svg" alt="">
              </div>
            </td>
            <td>
              <div class="table-sm__icon">
                <img src="@/assets/img/svg/icon-ok.svg" alt="">

              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {timestampToDateMixin} from "@/utils/mixins";

export default {
  name: "UserPersonalInfo",
  mixins: [timestampToDateMixin],
  props: {
    isVerified: {
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getKyc: 'user/getKyc',
    })
  }
}
</script>

<style scoped>

</style>