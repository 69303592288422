<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-help">
        <button @click="close" class="popup__close"></button>

        <div class="popup-help__title--sm">
          {{$t("popupHelpTitleSm")}}
        </div>

        <div class="popup-help__wrap">
          <div class="popup-help__img">
            <img src="@/assets/img/help.png" alt="">
          </div>

          <div class="popup-help__title">
            {{$t("popupHelpTitle")}}
          </div>
        </div>

        <div class="popup__content">
            <ul class="custom-list">
              <li class="custom-list__item">
                <p class="custom-list__text">{{$t("helpListItem1")}}</p>
                <div class="custom-list__icon"></div>
              </li>

              <li class="custom-list__item">
                <p class="custom-list__text">{{$t("helpListItem2")}}</p>
                <div class="custom-list__icon"></div>
              </li>

              <li class="custom-list__item">
                <p class="custom-list__text">{{$t("helpListItem3")}}</p>
                <div class="custom-list__icon"></div>
              </li>
            </ul>


        </div>

        <div class="popup-help__footer">
          <div class="popup-help__title--sm"> {{$t("popupHelpFooterTitle")}}</div>

          <div class="d-flex">
            <button class="round-btn">
              <div class="round-btn__icon">
                <img src="@/assets/img/svg/icon-telega.svg" alt="">
              </div>

              <div class="round-btn__name">Telegram</div>
            </button>

            <button class="round-btn">
              <div class="round-btn__icon">
                <img src="@/assets/img/svg/icon-support-chat.svg" alt="">
              </div>

              <div class="round-btn__name">{{ $t("helpChat") }}</div>
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpModal",
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>