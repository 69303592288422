<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup change-popup change-popup--email">
        <button @click="close" class="popup__close"></button>
        <div class="popup__content">

          <div class="popup__title popup__title--sm">
            {{ $t("changeEmailTitle") }}
          </div>

          <div class="popup__text">
            {{ $t("changeEmailText") }}
          </div>

          <div class="popup__msg popup__msg--sm">
            <span> {{ $t("changeEmailNote") }}</span> {{ $t("changeEmailMsg") }}
          </div>

          <form class="popup__form" @submit.prevent="changeEmail">
            <div class="page-input">
              <div class="page-input__title">
                {{ $t("changeEmailNew") }}
              </div>
              <input type="text" v-model="newEmail" class="input">
            </div>

            <div class="page-input">
              <div class="page-input__title">
                {{ $t("password") }}
              </div>

              <div class="page-input__wrap">
                <input :type="passFieldType" v-model="password" class="input">


                <button @click="togglePasswordVisible"
                        type="button"
                        class="page-input__icon pass-toggle"
                        :class="{active: showPass}"

                ></button>
              </div>

            </div>


            <button class="page-btn" :disabled="disableChangeBtn">
              {{ $t('submitBtn') }}
            </button>
          </form>


        </div>
      </div>
    </div>

    <SafetyKey v-if="isSafetyKeyModal" @close="isSafetyKeyModal = false"/>
  </div>
</template>

<script>
import SafetyKey from "./SafetyKey";
import processingMixin from "@/mixins/processingMixin";
import api from "@/api/api";
import {mapMutations} from "vuex";

export default {
  name: "ChangeEmail",
  mixins: [processingMixin],
  data: () => ({
    passFieldType: 'password',
    newEmail: null,
    isSafetyKeyModal: false,
    showPass: false,
    password: null,
  }),
  computed: {
    disableChangeBtn() {
      return !this.newEmail || !this.password;
    }
  },
  methods: {
    ...mapMutations({
      setEmail: 'user/setEmail'
    }),
    isValid() {
      let rules = {
        email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      }
      if(!rules.email.test(String(this.newEmail).toLowerCase())) {
        this.setError({
          message: this.$t('wrongEmail')
        })
        return false;
      }
      return true;
    },
    async changeEmail() {
      if(!this.isValid()) {
        return
      }
      try {
        this.setLoading(true);
        await api.changeEmail({
          password: this.password,
          newEmail: this.newEmail
        });
        this.setEmail(this.newEmail);
        this.setSuccess({
          message: 'Email has been changed',
        });
        this.close();
      } catch (e) {
        this.setError({
          message: e.response.data.message,
        })
      } finally {
        this.setLoading(false);
      }
    },
    togglePasswordVisible() {
      this.passFieldType === 'password' ? this.passFieldType = 'text' : this.passFieldType = 'password';
      this.showPass = !this.showPass;
    },
    close() {
      this.$emit('close');
    }
  },
  components: {
    SafetyKey
  },
}
</script>

<style scoped>

</style>