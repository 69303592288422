<template>
<div class="coin-select">
  <div class="coin-select__current" @click="toggleSelect = !toggleSelect" :class="{active: toggleSelect}">
      <div class="coin">
        <div class="coin__icon">
          <img :src="loadImage(currentWallet.currency)"
               alt="coin">
        </div>

        <div class="coin__name" v-if="currentWallet">
          {{ currentWallet.currency }}
        </div>
      </div>

      <div class="coin-select__icon">
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.99999 5.17217L11.95 0.222168L13.364 1.63617L6.99999 8.00017L0.635986 1.63617L2.04999 0.222168L6.99999 5.17217Z" fill="#0363FE"/>
        </svg>

      </div>
  </div>

  <div class="coin-select__drop" v-show="toggleSelect">
      <ul class="coin-select__list">
        <li v-for="(item, index) in wallets"
            class="coin-select__item"
            :key="index"
            @click="selectWallet(item)">
          <div class="coin">
            <div class="coin__icon">
              <img :src="loadImage(item.currency)" alt="">
            </div>

            <div class="coin__name">
              {{ item.currency }}
            </div>
          </div>
        </li>
      </ul>
  </div>
</div>
</template>

<script>
import {loadImageMixin} from "@/utils/mixins";

export default {
  name: "CoinSelect",
  mixins: [loadImageMixin],
  props: {
    wallets: {
      type: Array,
      required: true
    },
    currentWallet: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    toggleSelect: false,
  }),
  methods: {
    selectWallet(wallet) {
      this.currentWallet = wallet;
      this.$emit('select', wallet);
    }
  },
  mounted() {
    document.body.addEventListener('click',(e)=>{
      if (!e.target.closest('.coin-select__current')){
        this.toggleSelect = false;
      }
    })
  }
}
</script>

<style scoped>

</style>
