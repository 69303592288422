<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup confirmation-modal">
        <button @click="close" class="popup__close"></button>

        <div class="popup__content">
          <div class="popup__wrap">
            <div class="confirmation-modal__img">
              <img src="@/assets/img/verification-img.png" alt="">
            </div>

            <div class="confirmation-modal__title">
              {{ $t("verificationModalTitle") }}

            </div>
          </div>

          <div class="confirmation-modal__wrap">
            <img src="@/assets/img/svg/icon-shield.svg" alt="">

            {{ $t("verificationModalReview") }}
          </div>

          <div class="confirmation-modal__msg">
            <p> {{ $t("verificationModalMsg1") }}</p>
            <p> {{ $t("verificationModalMsg2") }} "<span>{{ $t("verificationModalBtn") }}</span>".</p>
          </div>

          <button class="page-btn" type="button" @click="open">
            {{ $t("verificationModalBtn") }}
          </button>

          <div class="attention">
            <div class="attention__icon">
              <img src="@/assets/img/svg/icon-caution.svg" alt="">
            </div>

            <div class="attention__text">
              {{ $t("verificationModalAtt") }}
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationModal",
  methods: {
    close() {
      this.$emit('close')
    },
    open() {
      this.$emit('open')
    }
  }
}
</script>

<style scoped>

</style>